import Vue from "vue";

import {
  getAgencySettings,
  updateAgency,
  updateAgencyCommunicationPreferences,
  getAgencyExternalResponders,
  addAgencyExternalResponder,
  deleteAgencyExternalResponder,
  updateAddressRecord,
  deleteAgency,
  uploadAgencyDocument
} from "@/api/agencies.service";
import { setActiveEft, createNewEft } from "@/api/eft.service";
import { deleteConnection } from "@/api/connections.service";
import { deleteEo } from "@/api/eos.service";
import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user";
import { createLogin, destroyLogin } from "@/api/logins.service";
import { deleteDocument } from "@/api/documents.service";

import { getCommunicationPreferenceRequest } from "@/factories/CommunicationPreferencesFactory";
import { AgencySettings } from "@/factories/AgencySettingsFactory";
import { ErrorsOmissions } from "@/factories/EoFactory";

export const AGENCY_SETTINGS = "agency";

export const useAgencySettingsStore = id =>
  defineStore(`agency-settings-${id}`, {
    state: () => AgencySettings(),
    actions: {
      async getAgencyContractingData(id) {
        this.$reset();
        const params = new URLSearchParams();
        params.append("contracting", "true");
        this.loading = true;
        const agency = await getAgencySettings(id);
        this.$patch(agency);

        // needed for vue 2 reactivity
        Vue.set(this, "efts", agency.efts);
        Vue.set(this, "logins", agency.logins);
        Vue.set(this, "connections", agency.connections);
        Vue.set(this, "relatedDocuments", agency.relatedDocuments);
        Vue.set(this, "primaryLogin", agency.primaryLogin);
        this.loading = false;
      },
      async getExternalResponders() {
        const emails = await getAgencyExternalResponders(this.id);
        this.trustedSenders.splice(0, this.trustedSenders.length);
        this.trustedSenders.push(...emails);
      },
      addExternalResponder(email) {
        return addAgencyExternalResponder(this.id, email);
      },
      deleteExternalResponder(email) {
        return deleteAgencyExternalResponder(this.id, email);
      },
      async deleteConnection(id) {
        await deleteConnection(id);
        Vue.delete(this.connections, id);
      },

      async destroyEo() {
        await deleteEo(this.eo.id);
        Vue.set(this, "eo", ErrorsOmissions());
      },
      updateCommissionSchedules() {
        return updateAgency(this.id, {
          schedule: {
            annuity: this.commissions.current.annuity,
            disability: this.commissions.current.disability,
            life: this.commissions.current.life,
            linked_benefit: this.commissions.current.linkedBenefit,
            ltc: this.commissions.current.ltc,
            variable_life: this.commissions.current.variableLife
          }
        });
      },
      updateHideCommission() {
        return updateAgency(this.id, {
          hide_commission: this.hideCommission
        });
      },
      updateCommissionLock() {
        return updateAgency(this.id, {
          commission_lock: this.lockCommissions
        });
      },
      updateAnnualization() {
        return updateAgency(this.id, {
          annualization: this.annualization
        });
      },
      updateHierarchy() {
        const reqConnections = [];

        Object.keys(this.connections).forEach(connectionId => {
          const connection = this.connections[connectionId];
          reqConnections.push({
            hierarchy_order: connection.hierarchyOrder,
            id: connection.id,
            hierarchy: connection.hierarchy
          });
        });

        return updateAgency(this.id, {
          connections_attributes: reqConnections
        });
      },
      async createEft(eft) {
        const newEft = await createNewEft(eft);
        this.addNewEft(newEft);
      },
      updateActiveEft(id) {
        Object.keys(this.efts).forEach(otherEft => {
          Vue.set(this.efts[otherEft], "current", 0);
        });
        Vue.set(this.efts[id], "current", 1);

        return setActiveEft(this.efts[id]);
      },
      updateMarketingManager() {
        return updateAgency(this.id, {
          marketing_manager_id: this.marketingManager.marketing_manager_id
        });
      },
      updateNpn() {
        return updateAgency(this.id, { npn: this.npn });
      },
      updateName() {
        return updateAgency(this.id, { name: this.name });
      },
      updateCategory() {
        return updateAgency(this.id, { category: this.category });
      },
      updateTin() {
        return updateAgency(this.id, { tin: this.tin });
      },
      updateLegalStructure() {
        return updateAgency(this.id, {
          legal_structure: this.legalStructure
        });
      },
      updatePhoneWork() {
        return updateAgency(this.id, { phone_work: this.phoneWork });
      },
      updateEmail() {
        return updateAgency(this.id, { email: this.email });
      },
      // used by AddressItemDialog
      updateBusinessAddress(addressId, businessAddress) {
        return updateAddressRecord(this.id, addressId, businessAddress);
      },
      updateCommunicationPreference(type) {
        return updateAgencyCommunicationPreferences(
          this.id,
          getCommunicationPreferenceRequest(this.communicationPreferences, type)
        );
      },
      setValidPage({ page, valid }) {
        this.pageValidation[page] = valid;
      },
      addNewEft(value) {
        Object.keys(this.efts).forEach(eftId => {
          Vue.set(this.efts[eftId], "current", 0);
        });
        Vue.set(this.efts, value.id, value);
      },
      addEft(value) {
        Object.keys(this.efts).forEach(eftId => {
          Vue.set(this.efts[eftId], "current", 0);
        });
        Vue.set(this.efts, value.id, value);
      },
      addRelatedDocument(relatedDocument) {
        Vue.set(this.relatedDocuments, relatedDocument.uid, relatedDocument);
      },
      async deleteRelatedDocument(id) {
        await deleteDocument(id);
        Vue.delete(this.relatedDocuments, id);
      },
      storeMarketingManager(value) {
        Vue.set(this, "marketingManager", value);
      },
      storeNpn(value) {
        Vue.set(this, "npn", value);
      },
      storeName(value) {
        Vue.set(this, "name", value);
      },
      storeTin(value) {
        Vue.set(this, "tin", value);
      },
      storeLegalStructure(value) {
        Vue.set(this, "legalStructure", value);
      },
      storePhoneWork(value) {
        Vue.set(this, "phoneWork", value);
      },
      storeEmail(value) {
        Vue.set(this, "email", value);
      },
      storeBusinessAddress(value) {
        const { street_address, state, city, zip } = value;
        Vue.set(this.businessAddress, "street_address", street_address);
        Vue.set(this.businessAddress, "state", state);
        Vue.set(this.businessAddress, "city", city);
        Vue.set(this.businessAddress, "zip", zip);
      },
      addConnection(connection) {
        Vue.set(this.connections, connection.id, connection);
      },
      updateConnection(connection) {
        Vue.set(this.connections, connection.id, connection);
      },
      setHierarchy(newHierarchy) {
        const allConnectionIds = [...Object.keys(this.connections)];
        newHierarchy.forEach((connection, index) => {
          Vue.set(this.connections[connection.id], "hierarchy", 1);
          Vue.set(this.connections[connection.id], "hierarchyOrder", index + 1);
          const connectionIndex = allConnectionIds.findIndex(
            val => +val === +connection.id
          );
          allConnectionIds.splice(connectionIndex, 1);
        });

        allConnectionIds.forEach(connection => {
          Vue.set(this.connections[connection], "hierarchy", 0);
          Vue.set(this.connections[connection], "hierarchyOrder", null);
        });
      },
      async createLogin(payload) {
        const user = useUserStore();
        const login = await createLogin(payload);
        if (user.login.id === login.id) {
          const userStore = useUserStore();
          userStore.addUserLogin(login);
        }
        Vue.set(this.logins, login.id, login);
      },
      async deleteLogin(id) {
        const user = useUserStore();
        await destroyLogin(id);
        if (id === user.id) {
          const userStore = useUserStore();
          userStore.removeUserLogin(id);
        }
        if (this.primaryLogin.id === id) {
          Vue.set(this, "primaryLogin", null);
        }
        Vue.delete(this.logins, id);
      },
      delete() {
        return deleteAgency(this.id);
      },
      async uploadDocument(file) {
        const document = await uploadAgencyDocument(this.id, file);
        this.addRelatedDocument(document);
      }
    }
  })();
