const baseUrl = "api/boss/build_charts";
import { setBuildChartFromRequest } from "@/factories/BuildChart";
import { serializeObject } from "@/util/helpers";

import { getHttpClient } from "@/http-client";

export async function getBuildCharts(params) {
  const { data } = await getHttpClient().get(baseUrl, { params });
  return data.build_charts.map(setBuildChartFromRequest);
}

function BuildChart(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    gender: model?.gender,
    complete: model?.complete,
    documentUid: model?.documentUid
  };
}

function setBuildCharts(req = {}) {
  const buildChart = BuildChart();

  buildChart.name = req?.name;
  buildChart.id = req?.id;
  buildChart.gender = req?.gender;
  buildChart.complete = req?.is_completed;
  buildChart.documentUid = req?.document?.uid;

  return buildChart;
}

export async function buildChartsIndex() {
  const { data } = await getHttpClient().get(baseUrl);
  const charts = data?.build_charts;
  return charts.map(setBuildCharts);
}
export function updateBuildChart(id, is_completed) {
  return getHttpClient().put(`${baseUrl}/${id}`, {
    build_chart: { is_completed }
  });
}
export async function uploadBuildChartDocument(id, file) {
  return getHttpClient().post(
    `${baseUrl}/${id}/documents`,
    await serializeObject({ file })
  );
}
