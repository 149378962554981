<template>
  <v-card flat color="section">
    <v-card-title>Scheduling Links</v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-for="(value, creditKey) in credits" :key="creditKey" cols="12">
          <v-card flat class="my-3">
            <v-row align="center" class="ma-0">
              <v-col cols="12">
                <v-row class="ma-0">
                  <strong> {{ credits[creditKey].name }} </strong>
                  <active-save-indicator
                    :controller="savingBuffer[creditKey]"
                  />
                </v-row>
              </v-col>
              <v-col
                cols="12"
                v-for="(link, index) in credits[creditKey].schedulingLinks"
                :key="index"
              >
                <v-row>
                  <v-col cols="12" lg="6" class="text-lg-right">
                    <v-text-field
                      v-model="credits[creditKey].schedulingLinks[index].text"
                      data-lpignore="true"
                      outlined
                      dense
                      label="Schedule a Meeting Link Text"
                      :prepend-inner-icon="mdiCalendar"
                      :data-testid="`${credits[creditKey].name}-${index}-text`"
                      hide-details
                      :placeholder="credits[creditKey].name"
                      :success="
                        Boolean(credits[creditKey].schedulingLinks[index].text)
                      "
                      @input="updateCredit(creditKey)"
                    />
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-row class="ma-0">
                      <v-text-field
                        v-model="credits[creditKey].schedulingLinks[index].url"
                        data-lpignore="true"
                        outlined
                        dense
                        label="Schedule a Meeting Link"
                        :prepend-inner-icon="mdiCalendar"
                        hide-details
                        :data-testid="`${credits[creditKey].name}-${index}-url`"
                        :success="
                          Boolean(credits[creditKey].schedulingLinks[index].url)
                        "
                        @input="updateCredit(creditKey)"
                      />
                      <v-btn
                        icon
                        color="error"
                        :data-testid="`${credits[creditKey].name}-${index}-delete`"
                        @click="deleteLink(credits[creditKey], index)"
                      >
                        <v-icon>{{ mdiDelete }}</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="accent"
                  class="text-none"
                  data-testid="add-credit-link"
                  @click="addLinkToCredit(credits[creditKey])"
                >
                  <v-icon>{{ mdiPlus }}</v-icon>
                  Add Link
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { storeToRefs } from "pinia";
import { mdiCalendar, mdiDelete, mdiPlus } from "@mdi/js";

const agent = useAgentSettingsStore();

const { credits } = storeToRefs(agent);

const savingBuffer = {};
Object.keys(credits.value).forEach(key => {
  savingBuffer[key] = useActiveSave();
});

function updateCredit(creditId) {
  savingBuffer[creditId].debounceUpdate(() => agent.updateCredit(creditId));
}

function addLinkToCredit(credit) {
  agent.addNewSchedulingLinkToCredit(credit);
}

function deleteLink(credit, index) {
  agent.removeSchedulingLinkFromCredit({ credit, index });
  return updateCredit(credit.id);
}
</script>
