<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <v-data-table
        data-testid="appointment-hierarchy-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        class="transparent-data-table"
      >
        <template #top v-if="isGroupTwoPlus">
          <appointment-search
            v-model="newHierarchy"
            data-testid="new-hierarchy-search"
            class="has-append-outer-button"
            :appointment-id="appointment.id"
            :success="Boolean(newHierarchy)"
          >
            <template #append-outer>
              <v-btn
                data-testid="add-hierarchy-button"
                color="accent"
                class="text-none"
                :loading="addingHierarchy"
                @click="addNewHierarchy"
              >
                <v-icon>{{ mdiPlus }}</v-icon> Add Hierarchy
              </v-btn>
            </template>
          </appointment-search>
        </template>
        <template #body="props">
          <draggable
            tag="tbody"
            data-testid="hierarchy-table-body"
            draggable=".draggable"
            :list="props.items"
            @change="updateHierarchy"
          >
            <tr
              v-for="(hierarchy, index) in props.items"
              :key="hierarchy.id"
              :class="{
                draggable: index > 0 && isGroupTwoPlus && props.items.length > 2
              }"
            >
              <td>
                <v-icon
                  v-if="isGroupTwoPlus"
                  :style="{
                    opacity: index > 0 && props.items.length > 2 ? 1 : 0
                  }"
                >
                  {{ mdiDrag }}
                </v-icon>
                {{ index + 1 }}
              </td>
              <td>
                <v-btn
                  v-if="hierarchy.additional.advisorRouterLink"
                  :to="hierarchy.additional.advisorRouterLink"
                  class="text-none"
                  color="primary"
                  depressed
                  small
                >
                  <v-icon class="mr-1" small>{{ mdiAccountTie }}</v-icon>
                  {{ hierarchy.advisorName }}
                </v-btn>
              </td>
              <td>
                <v-btn
                  v-if="hierarchy.additional.appointmentRouterLink"
                  :to="hierarchy.additional.appointmentRouterLink"
                  class="text-none"
                  color="primary"
                  depressed
                  small
                >
                  <v-icon class="mr-1" small>{{ mdiCalendar }}</v-icon>
                  {{ hierarchy.appointmentName }}
                </v-btn>
              </td>
              <td>{{ hierarchy.compBuilderName }}</td>
              <td v-if="isGroupTwoPlus">
                <v-btn
                  v-if="index > 0"
                  data-testid="delete-hierarchy-button"
                  icon
                  color="error"
                  @click="deleteHierarchy(hierarchy.additional.id)"
                >
                  <v-icon>{{ mdiDelete }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import draggable from "vuedraggable";
import AppointmentSearch from "@/components/shared/AppointmentSearch.vue";
import { parseErrorMessage } from "@/util/helpers";

import { markRaw, ref } from "vue";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useAppointmentView } from "@/stores/appointment-view";
import { useDialogStore } from "@/stores/dialog";

import { useTable } from "@/composables/table.composable";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import {
  mdiPlus,
  mdiDrag,
  mdiAccountTie,
  mdiCalendar,
  mdiDelete
} from "@mdi/js";

const { isGroupThreePlus, isGroupTwoPlus } = storeToRefs(useUserStore());
const appointment = useAppointmentView();
const { hierarchy } = storeToRefs(appointment);
const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const table = useTable({
  headers: [
    new TableHeader({ text: "Order", value: "order", map: "order" }),
    new TableHeader({
      text: "Advisor Name",
      value: "advisorName",
      map: "advisorName"
    }),
    new TableHeader({
      text: "Advisor Code",
      value: "appointmentName",
      map: "appointmentName"
    }),
    new TableHeader({
      text: "Comp Builder",
      value: "compBuilderName",
      map: "compBuilderName"
    }),
    isGroupThreePlus.value ? { text: "Actions", value: "actions" } : null
  ].filter(Boolean),
  items: hierarchy
});

const newHierarchy = ref("");
const addingHierarchy = ref(false);

function deleteHierarchy(id) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Confirm Hierarchy Deletion",
    subtitle: "This cannot be undone",
    func: () => appointment.deleteHierarchy(id)
  });
}

async function updateHierarchy(event) {
  if (!isGroupTwoPlus.value) return;
  try {
    await appointment.updateHierarchy(
      event.moved.oldIndex,
      event.moved.newIndex
    );
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function addNewHierarchy() {
  if (!newHierarchy.value?.id) return;
  addingHierarchy.value = true;
  try {
    await appointment.addHierarchy(newHierarchy.value);
    newHierarchy.value = null;
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: "Unable to add to Hierarchy: " + parseErrorMessage(e)
    });
  } finally {
    addingHierarchy.value = false;
  }
}
</script>
