<template>
  <v-card>
    <v-card-title> {{ title }}</v-card-title>
    <v-card-text id="question-container" class="py-1">
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="currentQuestion.question"
            label="Question Text"
            data-testid="question-text"
            outlined
            dense
            :autofocus="!currentQuestion.question"
            :success="questionValidation.success"
            :error-messages="questionValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="currentQuestion.required"
            :success="Boolean(currentQuestion.required)"
            class="mt-0 pt-0"
            label="Required"
            data-testid="question-required"
            hide-details
          />
        </v-col>
        <v-col v-if="isSelectType" cols="12" class="mt-6">
          <v-card
            outlined
            :class="{
              'error-sheet-outlined':
                selectOptionsValidation.errorMessages.length
            }"
          >
            <v-card-title style="font-size: 16px">
              Select Options
            </v-card-title>
            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="12">
                  <approved-domain-question-selectable-option
                    v-for="(option, index) in currentQuestion.selectOptions"
                    :duplicate-values="duplicateValues.get(option.key)"
                    :data-testid="`option-${index}`"
                    :autofocus="Boolean(currentQuestion.question) && index > 0"
                    :key="option.key"
                    :text.sync="option.text"
                    :value.sync="option.value"
                    @delete="deleteOption(option.key)"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="mx-2 mb-3">
              <v-btn
                class="text-none"
                color="accent"
                data-testid="option-add"
                @click="addSelectOption"
              >
                <v-icon>{{ mdiPlus }}</v-icon>
                Add Option
              </v-btn>
            </v-card-actions>
            <div
              v-if="selectOptionsValidation.errorMessages.length"
              class="options-parent-error-text pa-3"
            >
              <div class="v-messages__message options-error-text mt-1">
                {{ selectOptionsValidation.errorMessages.join() }}
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        data-testid="question-cancel"
        class="text-none"
        outlined
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="question-save"
        :loading="saving"
        @click="save()"
      >
        {{ actionText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import ApprovedDomainQuestionSelectableOption from "@/components/approved-domain/ApprovedDomainQuestionSelectableOption.vue";
import {
  ApprovedDomainQuestion,
  SelectOption,
  APPROVED_DOMAIN_QUESTION_TYPES,
  setRawFromApprovedDomainQuestion
} from "@/factories/ApprovedDomain";
import {
  updateApprovedDomainQuestion,
  createApprovedDomainQuestion
} from "@/api/approved-domain.service";
import {
  parseErrorMessage,
  someTextValidator,
  validationComputeV2
} from "@/util/helpers";

import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";

import { computed, defineProps, nextTick, ref } from "vue";

import useVuelidate from "@vuelidate/core";
import { useVuetify } from "@/composables/compatible.composables";
import { mdiPlus } from "@mdi/js";

const props = defineProps({
  question: { type: Object, required: true },
  approvedDomainId: { type: [Number, String], required: true }
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const vuetify = useVuetify();

const currentQuestion = ref(ApprovedDomainQuestion(props.question));
const saving = ref(false);

const isTextType =
  currentQuestion.value.inputType === APPROVED_DOMAIN_QUESTION_TYPES.TEXT;

const isSelectType =
  currentQuestion.value.inputType === APPROVED_DOMAIN_QUESTION_TYPES.SELECT;

const existingQuestion = Boolean(currentQuestion.value.id);

function addSelectOption() {
  currentQuestion.value.selectOptions.push(SelectOption());
}

let title, actionText;
(function () {
  let typeText;
  if (isTextType) typeText = "Text";
  if (isSelectType) typeText = "Select";
  title = `${existingQuestion ? "Edit" : "New"} ${typeText} Question`;
  actionText = currentQuestion.value.id ? "Save" : "Create";
})();

const v$ = useVuelidate(
  {
    question: {
      required: v => someTextValidator(true, v)
    },
    selectOptions: {
      required: v => !isSelectType || v?.length > 0,
      uniqueOptionText: v => {
        if (!isSelectType) return true;
        const textMap = new Map();

        for (let i = 0; i < v.length; ++i) {
          const text = v[+i].text.trim();

          if (textMap.has(text)) return false;
          textMap.set(text, true);
        }
        return true;
      },
      uniqueOptionValues: v => {
        if (!isSelectType) return true;
        const valueMap = new Map();

        for (let i = 0; i < v.length; ++i) {
          const value = v[+i].value.trim();

          if (valueMap.has(value)) return false;
          valueMap.set(value, true);
        }
        return true;
      }
    }
  },
  currentQuestion,
  {
    $autoDirty: true,
    $scope: "question-editor"
  }
);

const duplicateValues = computed(() => {
  if (!isSelectType) return false;
  const valueMap = new Map();
  const textMap = new Map();
  const dupeMap = new Map();

  const setForDupeMap = (key, value) => {
    dupeMap.set(key, { ...dupeMap.get(key), ...value });
  };

  for (let i = 0; i < currentQuestion.value.selectOptions.length; ++i) {
    const option = currentQuestion.value.selectOptions[+i];
    const value = option.value.trim();
    const text = option.text.trim();

    dupeMap.set(option.key, { value: false, text: false });

    if (valueMap.has(value)) {
      setForDupeMap(option.key, { value: true });
      setForDupeMap(valueMap.get(value), { value: true });
    } else {
      valueMap.set(value, option.key);
    }

    if (textMap.has(text)) {
      setForDupeMap(option.key, { text: true });
      setForDupeMap(textMap.get(text), { text: true });
    } else {
      textMap.set(text, option.key);
    }
  }

  return dupeMap;
});

const questionValidation = computed(() => {
  const model = v$.value.question;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

const selectOptionsValidation = computed(() => {
  const model = v$.value.selectOptions;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    {
      key: "uniqueOptionText",
      message: "All options must have unique texts"
    },
    {
      key: "uniqueOptionValues",
      message: "All options must have unique values"
    }
  ]);
});

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    nextTick(() => {
      const el = document.getElementsByClassName("v-input error--text")[0];
      vuetify.goTo(el, { container: "#question-container" });
      snackbar.showErrorSnackbar({ message: "Invalid fields detected" });
    });
    return;
  }

  let func = () => createApprovedDomainQuestion(props.approvedDomainId, body);

  const body = setRawFromApprovedDomainQuestion(currentQuestion.value);

  if (existingQuestion) {
    func = () =>
      updateApprovedDomainQuestion(
        props.approvedDomainId,
        currentQuestion.value.id,
        body
      );
  }

  saving.value = true;
  try {
    const res = await func();
    dialog.closeDialog({ question: res });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

function deleteOption(key) {
  const index = currentQuestion.value.selectOptions.findIndex(
    v => v.key === key
  );
  if (index === -1) return;
  currentQuestion.value.selectOptions.splice(index, 1);
}

if (isSelectType && !currentQuestion.value.selectOptions.length) {
  addSelectOption();
}
</script>
