<template>
  <v-row>
    <v-col cols="12" :md="showIRABalance ? 6 : 12">
      <v-select
        label="Concept"
        v-model="concept"
        outlined
        dense
        success
        data-testid="concept"
        :items="QUOTE_CONCEPTS"
      />
    </v-col>
    <v-col v-if="showIRABalance" cols="12" md="6">
      <currency-input
        v-model="qualifiedPlanBalance"
        label="IRA Balance"
        :prepend-inner-icon="mdiCurrencyUsd"
        outlined
        dense
        data-testid="qualified-plan-balance"
        :success="qualifiedPlanBalanceValidation.success"
        :error-messages="qualifiedPlanBalanceValidation.errorMessages"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import { computedValidation } from "@/util/helpers";
import { useQuoteRequestStore } from "@/stores/quote-request";
import useVuelidate from "@vuelidate/core";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { mdiCurrencyUsd } from "@mdi/js";
const ADVANCED_MARKETS = "Advanced Markets";
const BUSINESS_SOLUTIONS = "Business Solutions";
const IRA_MAX = "IRA Max";
const KAI_ZEN = "Kai-Zen";

const QUOTE_CONCEPTS = [
  { text: "None", value: null },
  { text: ADVANCED_MARKETS, value: ADVANCED_MARKETS },
  { text: BUSINESS_SOLUTIONS, value: BUSINESS_SOLUTIONS },
  { text: IRA_MAX, value: IRA_MAX },
  { text: KAI_ZEN, value: KAI_ZEN }
];

const quoteRequest = useQuoteRequestStore();
const { concept, qualifiedPlanBalance } = storeToRefs(quoteRequest);

const showIRABalance = computed(() => quoteRequest.concept === IRA_MAX);

const v$ = useVuelidate(
  {
    concept: { required: v => QUOTE_CONCEPTS.some(c => c.value === v) },
    qualifiedPlanBalance: {
      required: (val, vm) => vm.concept !== IRA_MAX || Boolean(val)
    }
  },
  { concept, qualifiedPlanBalance },
  { $scope: "quote-request" }
);
const qualifiedPlanBalanceValidation = computedValidation(
  v$.value.qualifiedPlanBalance,
  [{ key: "required", message: "Required" }]
);
</script>
