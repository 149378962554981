import AdvisorElectronicApplicationSettingsFactory from "@/factories/AdvisorElectronicApplicationSettingsFactory";
import { setAgentProfileFromRequest } from "@/factories/AdvisorProfile";
import { RawWebhooksToObj } from "@/factories/Webhook";
import { setCommissionSplitFromRequest } from "@/factories/CommissionSplitFactory";
import { setAgentSettingsfromRaw } from "@/factories/AgentSettingsFactory";
import {
  setRawCreateFromConnection,
  setConnectionFromRawCreate
} from "@/factories/ConnectionFactory";
import { setAddressFromRaw } from "@/factories/AddressFactory";
import { setApprovedDomainSearchItemFromRequest } from "@/factories/ApprovedDomain";
import { setInsuranceLicenseFromRequest } from "@/factories/AdvisorInsuranceLicense";
import { setDocumentFromRequest } from "@/factories/DocumentFactory";

import { getHttpClient } from "@/http-client";
import { serializeObject } from "@/util/helpers";
const baseUrl = "api/boss/agents";

export const uploadAgentAvatar = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return getHttpClient().put(`${baseUrl}/${id}/avatar`, formData);
};

/**
 * getAgentInformation
 * Utilized on the agent profile page
 * @param {*} id
 * @returns {Promise<AgentProfileFactory>}
 */
export const getAgentSummary = async id => {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  const advisor = setAgentProfileFromRequest(data?.agent);

  const canViewContracting = Boolean(data?.access?.contracting);
  return { advisor, canViewContracting };
};

/**
 * getAgentSettings (getAgentContracting)
 * @param {*} id
 * @returns {Promise}
 */
export const getAgentSettings = async id => {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}/contracting`);
  return setAgentSettingsfromRaw(data);
};

export async function getAgentWebhooks(id) {
  const { data } = await getHttpClient().get(`/${baseUrl}/${id}/webhooks`);
  return RawWebhooksToObj(data);
}

export const createAgentWebhook = (id, webhook) => {
  return getHttpClient().post(`/${baseUrl}/${id}/webhooks`, {
    webhook
  });
};

export async function getAgentLicenses(id) {
  const { data } = await getHttpClient().get(
    `/${baseUrl}/${id}/insurance-licenses`
  );

  const mappedLicenses = data.map(setInsuranceLicenseFromRequest);
  mappedLicenses.sort((a, b) => a.state.localeCompare(b.state));

  return mappedLicenses;
}

/**
 * returns an array of trusted sender emails
 * @param {number} id agentid
 * @returns {Promise<Array>}  Array of emails
 */
export const getAgentExternalResponders = async id => {
  const { data } = await getHttpClient().get(
    `/${baseUrl}/${id}/external-responders`
  );
  return data;
};

/**
 * Creates a trusted sender
 * @param {number} id agentid
 * @returns {Promise<Array>}  Array of emails
 */
export const addAgentExternalResponder = (id, email) => {
  return getHttpClient().post(`/${baseUrl}/${id}/external-responders`, {
    email
  });
};

/**
 * Deletes a trusted sender
 * @param {number} id agentid
 * @returns {Promise<Array>}  Array of emails
 */
export const deleteAgentExternalResponder = (id, email) => {
  return getHttpClient().delete(`/${baseUrl}/${id}/external-responders`, {
    params: { email }
  });
};

/**
 * Created
 * @param {number} agentId
 * @returns
 */
export async function createAgentConnection(agentId, connection) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${agentId}/connections`,
    {
      connection: setRawCreateFromConnection(connection)
    }
  );

  return setConnectionFromRawCreate(data.connection);
}

export async function createAddressRecord(agentId, body) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${agentId}/addresses`,
    body
  );

  return setAddressFromRaw(data);
}
export async function updateAddressRecord(agentId, addressId, body) {
  const { data } = await getHttpClient().put(
    `${baseUrl}/${agentId}/addresses/${addressId}`,
    body
  );

  return setAddressFromRaw(data);
}
export function deleteAddressRecord(agentId, id) {
  return getHttpClient().delete(`${baseUrl}/${agentId}/addresses/${id}`);
}

export function refreshAgentLicenses(agentId) {
  return getHttpClient().put(`${baseUrl}/${agentId}/insurance-licenses`);
}

export function deleteCommissionSplit(agentId, commissionSplitId) {
  return getHttpClient().delete(
    `${baseUrl}/${agentId}/commission_splits/${commissionSplitId}`
  );
}

export async function updateCommissionSplit(agentId, commissionSplitId, body) {
  const { data } = await getHttpClient().put(
    `${baseUrl}/${agentId}/commission_splits/${commissionSplitId}`,
    body
  );

  return setCommissionSplitFromRequest(data.commission_split);
}

export function appointmentManagerSearch(name) {
  const params = new URLSearchParams();
  params.append("search", name);
  return getHttpClient().get(`/${baseUrl}/appointment_manager_search`, {
    params
  });
}
export async function getAgentSsn(id) {
  const { data } = await getHttpClient().get(`/${baseUrl}/${id}/sensitive`);
  return data?.ssn;
}

function Contact(model = {}) {
  return {
    name: model?.name || null,
    role: model?.role || null,
    email: model?.email || null,
    phone: model?.phone || null,
    id: model?.id || null,
    type: model?.type || null
  };
}

function setContactFromRequest(raw = {}) {
  const contact = Contact();
  if (!raw) return contact;

  contact.name = raw.name;
  contact.role = raw.title;
  contact.email = raw.email;
  contact.phone = raw.phone_work;
  contact.id = raw.id;
  contact.type = raw.type.model;
  return contact;
}

export async function getContacts() {
  const { data } = await getHttpClient().get(`${baseUrl}/contacts`);

  return {
    items: data.contacts.map(setContactFromRequest)
  };
}
export function updateAgent(id, agent) {
  return getHttpClient().put(`/${baseUrl}/${id}`, { agent });
}
/**
 *
 * @param {*} id
 * @param {*} type
 * @returns {Promise} <AdvisorElectronicApplicationSettingsFactory|Reject>
 */
export async function getAgentElectronicApplicationSettings(agentId) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${agentId}/electronic_application_setting`
  );
  const electronicApplicationSettings =
    new AdvisorElectronicApplicationSettingsFactory();

  electronicApplicationSettings.setFromRequest(
    data.electronic_application_setting
  );
  return electronicApplicationSettings;
}
export function useAgentsOwnersAvatar(ownerId, body) {
  return getHttpClient().put(`${baseUrl}/${ownerId}/use_owners_avatar`, body);
}
export async function createCommissionSplit(agentId, body) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${agentId}/commission_splits`,
    body
  );
  return setCommissionSplitFromRequest(data.commission_split);
}

export function updateAgentCommunicationPreferences(
  agentId,
  communication_preference
) {
  return getHttpClient().put(
    `${baseUrl}/${agentId}/communication-preferences`,
    {
      communication_preference
    }
  );
}

export async function getDefaultableApprovedDomains(agentId, params) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${agentId}/defaultable_approved_domains`,
    {
      params
    }
  );

  return data.map(v => setApprovedDomainSearchItemFromRequest(v));
}

export async function deleteAgent(agentId) {
  return getHttpClient().delete(`${baseUrl}/${agentId}`);
}

export async function createAgentForUser(body) {
  const { data } = await getHttpClient().post(baseUrl, { agent: body });
  return data;
}

export async function uploadAgentDocument(agentId, file) {
  const body = await serializeObject({ file });
  const { data } = await getHttpClient().post(
    `${baseUrl}/${agentId}/documents`,
    body
  );
  return setDocumentFromRequest(data.document);
}
