<template>
  <div class="preliminary-card">
    <v-card light>
      <v-form id="login-form" @submit.prevent="submitSignIn">
        <v-col>
          <v-row class="ma-0" align="center" justify="center">
            <app-logo large />
          </v-row>
        </v-col>
        <v-card-title class="justify-center font-weight-bold text-h5 mb-6">
          Sign in to BOSS
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                label="Email Address"
                id="username"
                name="username"
                autocomplete="username"
                outlined
                dense
                autofocus
                data-testid="email"
                autocorrect="off"
                autocapitalize="off"
                type="text"
                :success="Boolean(email)"
                :prepend-inner-icon="mdiEmail"
              />
            </v-col>
            <v-scroll-y-transition mode="out-in">
              <v-col v-if="showPassword" cols="12">
                <v-text-field
                  v-model="authForm.password"
                  label="Password"
                  type="password"
                  outlined
                  dense
                  autocomplete="off"
                  persistent-hint
                  hint=" "
                  data-testid="password"
                  :autofocus="showPassword"
                  :prepend-inner-icon="mdiLock"
                  :success="Boolean(authForm.password)"
                >
                  <template #message>
                    <router-link :to="{ name: 'ForgotPassword' }" tabindex="4">
                      Forgot Password?
                    </router-link>
                  </template>
                </v-text-field>
              </v-col>
            </v-scroll-y-transition>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="text-none"
            color="primary"
            block
            type="submit"
            data-testid="sign-in"
            :loading="loading"
          >
            <v-fade-transition mode="out-in">
              <span v-if="showPassword" key="sign-in"> Sign in </span>
              <span v-else key="check-federation"> Continue </span>
            </v-fade-transition>
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-card-text class="pt-0">
        Don't have an account?
        <router-link :to="{ name: 'SignUp' }">Sign up</router-link>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { mdiEmail, mdiLock } from "@mdi/js";
import AppLogo from "@/components/AppLogo.vue";

import { computed, ref } from "vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { useSignInStore } from "@/stores/sign-in";
import { parseErrorMessage } from "@/util/helpers";
import { useRouter } from "@/composables/compatible.composables";
import {
  authenticateAndSetDetails,
  handleRedirectAfterAuthentication,
  signIn
} from "@/api/auth.service";
import { storeToRefs } from "pinia";
import { useHead } from "@unhead/vue";
import { checkUserEmail } from "@/api/users.service";
import {
  executeOAuthSso,
  executeSamlSso
} from "@/components/settings/security/sso.composable";

useHead({
  title: "Sign In"
});

const signInStore = useSignInStore();
const { email: storedEmail } = storeToRefs(signInStore);

const showPassword = ref(false);
const loading = ref(false);
const snackbar = useSnackbarStore();
const router = useRouter();

const authForm = ref({
  email: storedEmail.value,
  password: ""
});

const email = computed({
  get: () => authForm.value.email,
  set: val => {
    authForm.value.email = val;
    storedEmail.value = val;
  }
});

async function checkForSso() {
  try {
    loading.value = true;
    const { provider, method } = await checkUserEmail(authForm.value.email);

    if (provider && method) {
      const handlingSso = await handleSso(provider, method);
      if (handlingSso) return;
    }
    showPassword.value = true;
    loading.value = false;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    showPassword.value = true;
    loading.value = false;
  }
}

async function submitSignIn() {
  if (!showPassword.value) return checkForSso();
  try {
    loading.value = true;
    const { provider, method } = await signIn(authForm.value);
    if (provider && method) return handleSso(provider, method);
    await authenticateAndSetDetails();
    signInStore.$reset();
    await handleRedirectAfterAuthentication(router);
  } catch (e) {
    if (e?.response?.status === 401) {
      snackbar.showErrorSnackbar({ message: "Invalid email or password" });
    } else {
      snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    }
  } finally {
    loading.value = false;
  }
}

async function handleSso(provider, method) {
  if (method === "saml") {
    executeSamlSso(provider);
    return true;
  } else if (method === "oauth") {
    executeOAuthSso(provider, email.value);
    return true;
  }
}
</script>

<style lang="scss">
.nice-outline {
  border-color: lightgrey;
  border-width: 1px;
}
</style>
