<template>
  <v-data-table
    must-sort
    data-testid="form-mapping-forms-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :server-items-length="table.meta.value.total"
    :loading="table.loading.value"
    :options="table.options.value"
    :footer-props="footerProps"
    @update:options="updateOptions"
  >
    <template #top>
      <v-row class="ma-0" align="center" dense>
        <h1 class="text-h5">Forms</h1>
        <v-spacer />
        <div>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                data-testid="form-mapping-forms-refresh"
                v-on="on"
                v-bind="attrs"
                @click="getData"
              >
                <v-icon>{{ mdiRefresh }}</v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                data-testid="form-mapping-forms-create"
                v-on="on"
                v-bind="attrs"
                @click="openCreateFormDialog"
              >
                <v-icon>{{ mdiPlusCircle }}</v-icon>
              </v-btn>
            </template>
            <span>Create Form</span>
          </v-tooltip>
        </div>
      </v-row>
      <generic-table-filter-header
        :value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
    </template>
    <template #[`item.form`]="{ item }">
      <router-link
        :to="{
          name: 'FormMappingEditor',
          params: { id: item.additional.id }
        }"
      >
        {{ item.form }}
      </router-link>
    </template>
    <template #[`item.category`]="{ item }">
      <friendly-form-category :value="item.category" />
    </template>
    <template #[`item.updatedAt`]="{ item }">
      <timestamp-formatter :value="item.updatedAt" />
    </template>
    <template #[`item.followUpAt`]="{ item }">
      <timestamp-formatter :value="item.followUpAt" />
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn
        icon
        data-testid="download"
        :loading="item.additional.downloading"
        v-bind="downloadDocument(item.additional)"
      >
        <v-icon color="primary"> {{ mdiDownload }}</v-icon>
      </v-btn>
      <v-btn
        v-if="item.additional.status === 'Incomplete'"
        icon
        data-testid="delete"
        @click="deleteRow(item)"
      >
        <v-icon color="error">{{ mdiDelete }}</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import FriendlyFormCategory from "@/components/shared/FriendlyFormCategory.vue";

import { states } from "@/data/states";
import { formFilterOptions } from "@/data/filter-options";

import { downloadFileAsLink, parseErrorMessage } from "@/util/helpers";

import TableHeader from "@/classes/data-table/TableHeader";

import { deleteForm, getForms, getFormPdfUrl } from "@/api/forms.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { computed } from "vue";
import { mdiRefresh, mdiPlusCircle, mdiDownload, mdiDelete } from "@mdi/js";

import { useRouter, useVuetify } from "@/composables/compatible.composables";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const router = useRouter();
const vuetify = useVuetify();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Form",
      value: "form",
      map: "name",
      sortFilterMap: "forms.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Category",
      value: "category",
      map: "category",
      displayMap: "text",
      sortKey: "forms.category",
      sortFilterMap: [{ key: "forms.category", value: "value" }],
      ...TableHeader.IS_FORM_CATEGORY_SEARCH,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrierName",
      sortFilterMap: "carriers.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "State",
      value: "states",
      map: "stateCount",
      sortFilterMap: "forms.state",
      selectableOptions: states.map(val => ({
        text: val.text,
        value: val.text
      })),
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Updated",
      value: "updatedAt",
      map: "updatedAt",
      sortFilterMap: "forms.updated_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Follow Up",
      value: "followUpAt",
      map: "followUpAt",
      sortFilterMap: "forms.follow_up_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      selectableOptions: formFilterOptions,
      sortFilterMap: "status",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: ""
    }),
    new TableHeader({
      text: "Contracting",
      value: "contracting",
      map: "contracting",
      sortFilterMap: "contracting",
      selectableOptions: [
        { text: "Yes", value: "true" },
        { text: "No", value: "false" }
      ],
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    }),
    new TableHeader({
      text: "Form Set",
      sortFilterMap: [{ key: "form_set_id", value: "id" }],
      displayMap: "name",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FORM_SET_SEARCH,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    }),
    new TableHeader({
      text: "With States",
      sortFilterMap: "with_states",
      value: "with_states",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    }),
    new TableHeader({
      text: "Advisor",
      sortFilterMap: [
        { key: "ownable_id", value: "id" },
        { key: "ownable_type", value: "type" }
      ],
      value: "advisor",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    })
  ],
  getData: getForms
});

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

async function openCreateFormDialog() {
  const res = await dialog.showDialog({
    component: "CreateFormDialog",
    persistent: true
  });
  if (res?.id) {
    router.push({ name: "FormMappingEditor", params: { id: res.id } });
  }
}

async function deleteRow(row) {
  const res = await dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Delete ${row.form}?`,
    subtitle: "This cannot be undone",
    func: () => deleteForm(row.additional.id)
  });

  if (res?.confirm) getData();
}

function downloadDocument({ id }) {
  return downloadFileAsLink(getFormPdfUrl(id), vuetify.breakpoint.mdAndDown);
}

getData();
</script>
