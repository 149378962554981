<template>
  <v-card color="section" flat>
    <v-card-title> Email Address </v-card-title>
    <v-card-subtitle> Change where emails go </v-card-subtitle>
    <v-card-text>
      <v-text-field
        autocomplete="off"
        id="do-not-autofill-email"
        data-testid="advisor-email"
        outlined
        dense
        success
        readonly
        label="Email"
        style="max-width: 30rem"
        :disabled="props.readonly"
        :value="email"
        :prepend-inner-icon="mdiEmail"
        @click="showChangeEmailDialog"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import ChangeEmailDialog from "@/dialogs/ChangeEmailDialog.vue";
import { mdiEmail } from "@mdi/js";

import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import { useDialogStore } from "@/stores/dialog";
import { useSettingsViewStore } from "@/stores/settings-view";
import { storeToRefs } from "pinia";
import { defineProps, markRaw } from "vue";
const props = defineProps({
  readonly: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});

let store;
if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const { email } = storeToRefs(store);
const dialog = useDialogStore();
async function showChangeEmailDialog() {
  if (props.readonly) return;
  dialog.showDialog({
    component: markRaw(ChangeEmailDialog),
    saveFunc: store.updateEmail,
    storeFunc: v => (email.value = v),
    value: email.value
  });
}
</script>
