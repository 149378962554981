<template>
  <v-row class="ma-0" justify="center">
    <v-card max-width="500" width="100%">
      <v-card-title>
        <v-icon class="mr-1">{{ mdiCellphone }}</v-icon> Incoming Call
      </v-card-title>
      <v-card-text>
        <integer-input
          v-model.lazy="phoneNumber"
          :prepend-inner-icon="mdiPhone"
          label="Phone Number"
          mask="(###) ###-####x######"
          outlined
          hide-details
          data-testid="phone-number"
        />

        <v-col cols="12" class="px-0">
          <h3 style="font-weight: 400">Include the following:</h3>
          <v-chip-group v-model="include" column multiple>
            <v-chip
              v-for="{ title, value } in CATEGORIES"
              :data-testid="`include-${title}`"
              :value="value"
              :key="title"
              :color="include.includes(value) ? 'primary' : null"
            >
              {{ title }}
            </v-chip>
          </v-chip-group>
        </v-col>

        <v-fade-transition mode="out-in">
          <v-row v-if="loading" class="ma-0" key="loader">
            <h3 style="font-weight: 400">Loading Results</h3>
            <v-progress-circular
              indeterminate
              width="2"
              size="24"
              class="ml-2"
            />
          </v-row>
          <v-col v-else key="results" class="pa-0">
            <h3 style="font-weight: 400">Results:</h3>
            <v-list>
              <v-list-item
                v-for="result in results"
                :data-testid="`result-${result.uniqueId}`"
                :key="result.uniqueId"
                :to="result.routerLink"
                target="_blank"
                @click="confirmTrackCall(result)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ result.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="grey--text">
                      {{ result.displayType }}
                    </span>
                    {{ result.phones }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="flex-nowrap">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-on="on"
                          v-bind="attrs"
                          :data-testid="`result-${result.uniqueId}-open`"
                        >
                          <v-icon> {{ mdiOpenInNew }} </v-icon>
                        </v-btn>
                      </template>
                      <span>View </span>
                    </v-tooltip>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="!results.length">
                <v-list-item-content>
                  <v-list-item-title>No Results </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-fade-transition>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script setup>
import IntegerInput from "@/components/shared/IntegerInput.vue";
import {
  CALL_DIRECTIONS,
  inboundCallSearch,
  trackCall
} from "@/api/calls.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { defineProps, ref, watch } from "vue";
import { mdiCellphone, mdiPhone, mdiOpenInNew } from "@mdi/js";
const props = defineProps({
  phone: { type: String, required: true }
});

const CATEGORIES = [
  { title: "Agents", value: "agents" },
  { title: "Contract Parties", value: "contract_parties" },
  {
    title: "eApps",
    value: "individuals"
  }
];
const phoneNumber = ref(props.phone);
const results = ref([]);
const loading = ref(false);

const include = ref(Object.values(CATEGORIES).map(({ value }) => value));
const tracked = ref([]);

async function getRelatedData() {
  try {
    loading.value = true;
    const relatedData = await inboundCallSearch(
      phoneNumber.value.replace(/\D+/g, ""),
      include.value
    );
    results.value.splice(0, results.value.length);
    results.value.push(...relatedData);
  } catch (e) {
    useSnackbarStore().showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}

let timer;
function debounceAndGetRelatedData() {
  loading.value = true;
  if (timer) clearTimeout(timer);
  timer = setTimeout(getRelatedData, 300);
}

watch(phoneNumber, debounceAndGetRelatedData, { immediate: true });
watch(include, debounceAndGetRelatedData);

async function confirmTrackCall(result) {
  if (tracked.value.includes(result.uniqueId)) return;
  try {
    trackCall({
      type: result.callableType,
      id: result.callableId,
      callerNumber: phoneNumber.value,
      direction: CALL_DIRECTIONS.INBOUND
    });
    tracked.value.push(result.uniqueId);
  } catch (e) {
    //do nothing
  }
}
</script>
