<template>
  <v-data-table
    must-sort
    data-testid="impaired-risk-quotes-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :server-items-length="table.meta.value.total"
    :loading="table.loading.value"
    :options="table.options.value"
    :footer-props="footerProps"
    @update:options="updateOptions"
  >
    <template #top>
      <v-row v-if="!props.simplifiedHeader" class="ma-0 px-3" align="center">
        <h1 class="text-h5">Impaired Risk Quotes</h1>
        <v-spacer />
        <v-btn
          icon
          data-testid="impaired-risk-quotes-table-refresh"
          :loading="table.loading.value"
          @click="getData"
        >
          <v-icon>{{ mdiRefresh }}</v-icon>
        </v-btn>
        <v-btn
          icon
          data-testid="impaired-risk-quotes-table-create"
          :to="{ name: 'ImpairedRiskQuoteCreate' }"
        >
          <v-icon>{{ mdiPlusCircle }}</v-icon>
        </v-btn>
      </v-row>
      <generic-table-filter-header
        :value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
    </template>
    <template #[`item.createdAt`]="{ item }">
      <timestamp-formatter :value="item.createdAt" />
    </template>
    <template #[`item.insuredName`]="{ item }">
      <router-link
        :to="{
          name: 'ImpairedRiskQuoteView',
          params: { id: item.additional.id }
        }"
        class="text-none"
      >
        {{ item.insuredName }}
      </router-link>
    </template>
    <template #[`item.offers`]="{ item }">
      {{ item.additional.offers }} of {{ item.additional.requests }}
    </template>
  </v-data-table>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import { mdiRefresh, mdiPlusCircle } from "@mdi/js";

import {
  IMPAIRMENTS,
  IMPAIRED_RISK_STATUSES
} from "@/factories/ImpairedRiskQuote";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";
import { parseErrorMessage } from "@/util/helpers";

import { getQuickQuotes } from "@/api/quick-quotes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";

import { computed, defineExpose, defineProps, defineEmits } from "vue";
import { useTable } from "@/composables/table.composable";

const props = defineProps({ simplifiedHeader: Boolean });

//use defineExpose & refs if possible
const emit = defineEmits(["get-data"]);

emit("get-data", getData);

const user = useUserStore();

let filter = {};
if (user.isGroupTwoPlus) {
  filter.view = user.isGroupThreePlus ? "all" : "my";
}

const table = useTable({
  headers: [
    new TableHeader({
      text: "Insured Name",
      value: "insuredName",
      map: "insuredName",
      sortFilterMap: "insured",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Advisor",
      value: "advisorName",
      map: "advisorName",
      sortFilterMap: [
        { key: "advisor_id", value: "id" },
        { key: "advisor_type", value: "type" }
      ],
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Created",
      value: "createdAt",
      map: "createdAt",
      sortFilterMap: "quick_quotes.created_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "# Offers",
      value: "offers",
      map: "offers"
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      selectableOptions: IMPAIRED_RISK_STATUSES,
      sortFilterMap: "status",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Impairment",
      value: "impairment",
      map: "impairment",
      selectableOptions: IMPAIRMENTS,
      sortFilterMap: "impairment",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER
    })
  ],
  getData: getQuickQuotes,
  options: new TableOptions(["createdAt"], [true]),
  filter
});

if (user.isGroupTwoPlus) {
  table.headers.value.unshift(
    new TableHeader({
      text: "View",
      value: "view",
      map: "view",
      selectableOptions: [
        { text: "All Impaired Risk Quotes", value: "all" },
        { text: "My Impaired Risk Quotes", value: "my" }
      ],
      sortFilterMap: "view",
      order: 1,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_MANDATORY,
      ...TableHeader.IS_QUERY_FILTER
    })
  );
}

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

defineExpose({ getData });

const snackbar = useSnackbarStore();

getData();

async function getData() {
  const additionalFilter = {};
  if (user.isGroupOne) additionalFilter.view = "my";

  try {
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}
</script>
