<template>
  <v-card
    data-testid="otp-enrollment"
    outlined
    class="pt-6 pa-3"
    @click="openOtpEnrollmentDialog"
  >
    <div class="w-100 flex-row justify-center">
      <v-icon size="64">{{ mdiTwoFactorAuthentication }} </v-icon>
    </div>
    <v-card-title class="justify-start text-h5">
      <div>Two Factor Authentication</div>
    </v-card-title>
    <v-card-subtitle class="text-body-2">
      <strong v-if="enrolled" class="success--text">Enrolled</strong>
      <strong v-else class="error--text">Not Enrolled</strong>
    </v-card-subtitle>
    <v-card-text class="text-left">
      <div style="max-width: 25em">
        Boost your account security by activating two-factor authentication.
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn class="text-none" text color="primary">Manage Enrollment </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import SecurityTwoFactorEnrollmentDialog from "@/components/settings/security/SecurityTwoFactorEnrollmentDialog.vue";
import { useDialogStore } from "@/stores/dialog";
import { useUserStore } from "@/stores/user";
import { mdiTwoFactorAuthentication } from "@mdi/js";
import { storeToRefs } from "pinia";
import { markRaw } from "vue";

const user = useUserStore();
const { otp_enrolled: enrolled } = storeToRefs(user);

const dialog = useDialogStore();
function openOtpEnrollmentDialog() {
  dialog.showDialog({
    component: markRaw(SecurityTwoFactorEnrollmentDialog),
    width: "30rem"
  });
}
</script>
