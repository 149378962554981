<template>
  <v-card>
    <v-card-text>
      <v-data-table
        data-testid="backnine-contact-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :loading="table.loading.value"
        :search="search"
      >
        <template #top>
          <v-row class="ma-0">
            <h1 class="text-h5">BackNine Contacts</h1>
            <v-spacer />
            <v-text-field
              v-model="search"
              data-testid="backnine-contact-table-search"
              :prepend-inner-icon="mdiMagnify"
              outlined
              dense
              hide-details
              placeholder="Type to Search"
              style="max-width: 30rem"
            />
          </v-row>
        </template>
        <template #[`item.email`]="{ item }">
          <a v-if="item.email" :href="`mailto:${item.email}`">
            {{ item.email }}
          </a>
        </template>
        <template #[`item.phone`]="{ item }">
          <a
            v-if="item.phone"
            :href="`tel:${item.phone}`"
            :data-outbound-type="item.additional.type"
            :data-outbound-id="item.additional.id"
            :data-outbound-number="item.phone"
          >
            {{ item.phone }}
          </a>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { getContacts } from "@/api/agents.service";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { ref } from "vue";
import { mdiMagnify } from "@mdi/js";

useHead({ title: "BackNine Contacts" });

const search = ref("");

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Role",
      value: "role",
      map: "role",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Email",
      value: "email",
      map: "email",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Phone",
      value: "phone",
      map: "phone",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    })
  ],
  getData: getContacts
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getData();
</script>
