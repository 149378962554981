<template>
  <div>
    <v-row v-if="!disabled" align="center" class="ma-3">
      <agent-search
        v-model="newAgent"
        hide-details
        label="Agent"
        data-testid="commission-split-agent-search"
        :disabled="disabled"
      />
      <v-btn
        class="text-none ml-2"
        color="accent"
        data-testid="commission-split-agent-create"
        :loading="creatingCommissionSplit"
        :disabled="disabled"
        @click="createCommissionSplit"
      >
        <v-icon> {{ mdiPlus }} </v-icon> Add
      </v-btn>
    </v-row>
    <v-list v-if="hasCommissionSplits">
      <v-list-item v-for="split in commissionSplits" :key="split.id">
        <v-list-item-avatar>
          <v-img :src="split.agent.avatar" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <v-row class="pa-3" align="center">
              {{ split.agent.name }}
              <v-btn
                v-if="!disabled"
                icon
                small
                :data-testid="`commission-split-delete-${split.agent.name}`"
                :loading="split.loading"
                @click="deleteCommissionSplit(split)"
              >
                <v-icon color="error">{{ mdiDelete }}</v-icon>
              </v-btn>
              <v-spacer />
              <active-save-indicator
                :controller="savingBuffer[split.id].controller.value"
                class="mr-2"
              />
              {{ split.splitPercent }}%
            </v-row></v-list-item-title
          >
          <v-slider
            v-if="!disabled"
            v-model="split.splitPercent"
            min="1"
            max="99"
            hide-details
            :data-testid="`commission-split-slider-${split.agent.name}`"
            :disabled="disabled"
            @change="updateCommissionSplit(split)"
          />
        </v-list-item-content>
      </v-list-item>
      <div class="px-4">
        <v-divider />
        <v-row class="pa-3">
          <v-spacer />
          <h3
            class="pt-3"
            :class="{
              'success--text': validPercents,
              'error--text': !validPercents
            }"
          >
            {{ advisorName }}'s Split: {{ agentSplit }}%
          </h3>
        </v-row>
      </div>
    </v-list>
    <v-row
      v-else-if="!disabled"
      style="height: 100px"
      align="center"
      justify="center"
    >
      <p style="text-align: center">
        No Commission Splits Found. You can add an agent to split commissions
        with above.
      </p>
    </v-row>
  </div>
</template>

<script setup>
import AgentSearch from "@/components/shared/AgentSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiPlus, mdiDelete } from "@mdi/js";
import { useActiveSave } from "@/composables/active-save.composable";
import { ref, watch, defineEmits, defineProps, computed, set } from "vue";

const props = defineProps({
  value: Array,
  advisorName: String,
  updateFunc: Function,
  createFunc: Function,
  deleteFunc: Function,
  disabled: Boolean
});

const emit = defineEmits(["input"]);

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const savingBuffer = {};
props.value.forEach(i => {
  savingBuffer[i.id] = useActiveSave();
});

const newAgent = ref(null);
const commissionSplits = ref(JSON.parse(JSON.stringify(props.value)));
const creatingCommissionSplit = ref(false);

watch(commissionSplits, v => emit("input", v), { deep: true });

const hasCommissionSplits = computed(() => {
  return Boolean(commissionSplits.value.length);
});
const accumulatedSplits = computed(() => {
  const reducer = (accumulator, current) => accumulator + current.splitPercent;
  return commissionSplits.value.reduce(reducer, 0);
});
const agentSplit = computed(() => {
  return 100 - accumulatedSplits.value;
});
const validPercents = computed(() => {
  return agentSplit.value >= 1;
});

function updateCommissionSplit(cs) {
  if (props.disabled) return;
  if (!validPercents.value) {
    snackbar.showErrorSnackbar({
      message:
        "Commission Splits Exceed 100%, You must decrease an agents commission split."
    });
    return;
  }

  // Only can change split percent for now
  savingBuffer[cs.id].update(() => props.updateFunc(cs));
}
function deleteCommissionSplit(commissionSplit) {
  if (props.disabled) return;

  const func = async () => {
    await props.deleteFunc(commissionSplit);
    const index = commissionSplits.value.findIndex(
      s => s.id === commissionSplit.id
    );
    commissionSplits.value.splice(index, 1);
  };
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Are you sure that you want to remove ${commissionSplit.agent.name} from ${props.advisorName}'s commission splits?`,
    subtitle: "This cannot be undone",
    func
  });
}
async function createCommissionSplit() {
  if (props.disabled) return;
  if (!newAgent.value?.id) return;
  if (2 + accumulatedSplits.value > 100) {
    snackbar.showErrorSnackbar({
      message: `Commission Splits will exceed 100% by adding ${newAgent.value.name}, You must decrease an agents commission split.`
    });
    return;
  }
  creatingCommissionSplit.value = true;
  try {
    const commissionSplit = await props.createFunc(newAgent.value);
    commissionSplits.value.push(commissionSplit);

    set(newAgent, "value", null);

    savingBuffer[commissionSplit.id] = useActiveSave();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: 5000
    });
  } finally {
    creatingCommissionSplit.value = false;
  }
}
</script>
