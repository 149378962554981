<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-row justify="center" class="ma-0 mt-3">
        <div class="width-full">
          <to-do-list-view
            :subject="{
              id: quoteView.id,
              type: 'Quote',
              name: 'This Quote'
            }"
            :highlight-todo="highlightTodo"
            @due-count="todoCount = $event"
          />
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ToDoListView from "@/components/todo/ToDoListView.vue";
import { useQuoteViewStore } from "@/stores/quote-view";
import { storeToRefs } from "pinia";
import { defineProps, toRef } from "vue";

const props = defineProps({
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  }
});

const highlightTodo = toRef(props, "highlightTodo");

const quoteView = useQuoteViewStore();

const { todoCount } = storeToRefs(quoteView);
</script>
