<template>
  <v-card
    v-if="item.href"
    hover
    :outlined="item.outlined"
    height="100%"
    style="padding-bottom: 2rem"
    :href="href"
    :target="target"
    :class="item.class + '-' + themeClass"
  >
    <v-row
      v-if="item.img || item.icon"
      class="ma-0"
      justify="center"
      align="center"
      style="height: 5rem"
    >
      <img
        v-if="item.img"
        :src="item.img"
        style="width: auto; max-height: 5rem; height: auto; max-width: 14rem"
      />
      <v-icon v-else-if="item.icon" :color="item.iconColor" x-large>
        {{ item.icon }}
      </v-icon>
    </v-row>
    <v-card-title>
      <v-col class="pa-0">
        <div class="grey--text font-weight-light">{{ item.type }}</div>
        <div style="line-height: 1.2" class="mb-1">
          <v-icon
            v-if="item.text === 'Quote & Apply'"
            :color="buttonColor"
            class="mr-1"
          >
            {{ mdiRocketLaunch }}
          </v-icon>
          {{ item.text }}
        </div>
      </v-col>
    </v-card-title>
    <v-card-subtitle> {{ item.description }} </v-card-subtitle>

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          v-on="on"
          v-bind="attrs"
          style="position: absolute; bottom: 0; right: 0"
          :loading="item.loading"
          color="accent"
          class="text-none"
          icon
        >
          {{ mdiPlus }}
        </v-icon>
      </template>
      <span>Start Quoting</span>
    </v-tooltip>
  </v-card>
  <v-card
    v-else-if="item.to"
    hover
    :outlined="item.outlined"
    height="100%"
    style="padding-bottom: 2rem"
    :to="to"
    :class="item.class + '-' + themeClass"
  >
    <v-row
      v-if="item.img || item.icon"
      class="ma-0"
      justify="center"
      align="center"
      style="height: 5rem"
    >
      <img v-if="item.img" :src="item.img" style="width: auto; height: 3rem" />
      <v-icon v-else-if="item.icon" :color="item.iconColor" x-large>
        {{ item.icon }}
      </v-icon>
    </v-row>
    <v-card-title>
      <v-col class="pa-0">
        <div class="grey--text font-weight-light">{{ item.type }}</div>
        <div style="line-height: 1.2" class="mb-1">
          <v-icon
            v-if="item.text === 'Quote & Apply'"
            :color="buttonColor"
            class="mr-1"
          >
            {{ mdiRocketLaunch }}
          </v-icon>
          {{ item.text }}
        </div>
      </v-col>
    </v-card-title>
    <v-card-subtitle> {{ item.description }} </v-card-subtitle>

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          v-on="on"
          v-bind="attrs"
          style="position: absolute; bottom: 0; right: 0"
          :loading="item.loading"
          :color="buttonColor"
          class="text-none"
          icon
        >
          {{ mdiPlus }}
        </v-icon>
      </template>
      <span>Start Quoting</span>
    </v-tooltip>
  </v-card>
  <v-card
    v-else
    height="100%"
    hover
    :outlined="item.outlined"
    style="padding-bottom: 2rem"
    :style="{ backgroundColor: item.outlined ? 'transparent' : null }"
    @click="$emit('click')"
    :class="item.class + '-' + themeClass"
  >
    <v-row
      v-if="item.img || item.icon"
      class="ma-0"
      justify="center"
      align="center"
      style="height: 5rem"
    >
      <img v-if="item.img" :src="item.img" style="width: auto; height: 3rem" />
      <v-icon v-else-if="item.icon" :color="item.iconColor" x-large>
        {{ item.icon }}
      </v-icon>
    </v-row>
    <v-card-title>
      <v-col class="pa-0">
        <div class="grey--text font-weight-light">{{ item.type }}</div>
        <div style="line-height: 1.2" class="mb-1">
          <v-icon
            v-if="item.text === 'Quote & Apply'"
            :color="buttonColor"
            class="mr-1"
          >
            {{ mdiRocketLaunch }}
          </v-icon>
          {{ item.text }}
        </div>
      </v-col>
    </v-card-title>
    <v-card-subtitle> {{ item.description }} </v-card-subtitle>

    <div style="position: absolute; bottom: 5px; right: 5px">
      <v-progress-circular indeterminate v-if="item.loading" color="accent" />
      <v-tooltip bottom v-else>
        <template #activator="{ on, attrs }">
          <v-icon
            v-on="on"
            v-bind="attrs"
            :color="buttonColor"
            class="text-none"
            icon
          >
            {{ mdiPlus }}
          </v-icon>
        </template>
        <span>Start Quoting</span>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script>
import { mdiRocketLaunch, mdiPlus } from "@mdi/js";
export default {
  name: "QuoteMethod",
  props: {
    href: String,
    target: String,
    to: Object,
    click: Function,
    item: Object
  },
  data: () => ({ mdiRocketLaunch, mdiPlus }),
  computed: {
    themeClass() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    buttonColor() {
      if (this.item.key === "requestAQuote") return "primary";
      if (this.item.key === "impairedRiskQuote") return "orange";
      return "accent";
    }
  }
};
</script>
