import { defineStore } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { getCsrfToken } from "@/api/api.service";
export const useInstanceStore = defineStore("instance", {
  state: () => ({
    wss: null,
    apiUrl: null,
    helpCenterUrl: null,
    breadcrumb: null,
    fullscreen: false,
    nonce: null,
    csrfToken: null,
    hasSentRequest: false
  }),
  actions: {
    async setCsrfToken() {
      let error = null;
      try {
        this.csrfToken = await getCsrfToken();
      } catch (e) {
        error = e;
      } finally {
        if (error) {
          const snackbar = useSnackbarStore();
          snackbar.showErrorSnackbar({ message: parseErrorMessage(error) });
        }
      }
    }
  }
});
