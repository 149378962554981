<template>
  <v-card flat tile>
    <v-row class="ma-0 px-3">
      <v-spacer />
      <v-btn
        outlined
        rounded
        data-testid="delete-lead"
        class="text-none"
        color="error"
        @click="deleteLead"
      >
        <v-icon> {{ mdiDelete }} </v-icon> Delete Lead
      </v-btn>
    </v-row>
  </v-card>
</template>

<script setup>
import { useRouter } from "@/composables/compatible.composables";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { useDialogStore } from "@/stores/dialog";
import { useLeadView } from "@/stores/lead-view";
import { useSnackbarStore } from "@/stores/snackbar";
import { markRaw } from "vue";
import { mdiDelete } from "@mdi/js";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const leadView = useLeadView();
const router = useRouter();

function deleteLead() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure you want to permanently delete this lead?",
    subtitle: "This cannot be undone",
    func: remove,
    showErrorMessage: true
  });
}

async function remove() {
  await leadView.deleteLead();
  snackbar.showSuccessSnackbar({
    message: `Lead has been successfully deleted`,
    timeout: 6000
  });
  if (router) router.push({ name: "Tables", query: { page: "leads" } });
}
</script>
