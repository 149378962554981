import { getHttpClient } from "@/http-client";
import { setProductBuildChartFromRequest } from "@/factories/Product";

const baseUrl = "api/boss/product_build_charts";
export async function addProductBuildChart(params) {
  const { data } = await getHttpClient().post(baseUrl, params);
  return setProductBuildChartFromRequest(data.product_build_chart);
}

export function removeProductBuildChart(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}
