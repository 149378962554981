import { getHttpClient } from "@/http-client";
import { serializeObject } from "@/util/helpers";
import { setSmokerChartFromRequest } from "@/factories/Product";

const baseUrl = "api/boss/smoker_charts";

let cancelToken = null;
export async function getSmokerCharts(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!response?.data) return;

  return response.data.smoker_charts.map(setSmokerChartFromRequest);
}

export async function updateCompleteness(id, complete) {
  return getHttpClient().put(`${baseUrl}/${id}`, {
    smoker_chart: { complete }
  });
}

export async function uploadSmokerChartDocument(id, file) {
  return getHttpClient().post(
    `${baseUrl}/${id}/documents`,
    await serializeObject({ file })
  );
}
