<template>
  <v-row dense>
    <v-col cols="12">
      <v-text-field
        v-model="compBuilder.name"
        data-testid="comp-builder-name"
        data-lpignore="true"
        label="Name"
        outlined
        dense
        :disabled="disabled"
        v-bind="nameValidation"
        @input="updateName"
      >
        <template #append-outer>
          <active-save-indicator
            class="mx-1"
            :controller="savingBuffer.name.controller.value"
          />
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <div class="checkbox-width">
        <v-checkbox
          v-model="compBuilder.street"
          data-testid="comp-builder-street"
          class="mt-0"
          dense
          :disabled="disabled"
          :success="compBuilder.street"
          @change="updateStreet"
        >
          <template #label>
            Street
            <active-save-indicator
              class="mx-1"
              :controller="savingBuffer.street.controller.value"
            />
          </template>
        </v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" md="6">
      <div class="checkbox-width">
        <v-checkbox
          v-model="compBuilder.noPay"
          data-testid="comp-builder-no-pay"
          class="mt-0"
          dense
          :disabled="disabled"
          :success="compBuilder.noPay"
          @change="updateNoPay"
        >
          <template #label>
            No Pay
            <active-save-indicator
              class="mx-1"
              :controller="savingBuffer.noPay.controller.value"
            />
          </template>
        </v-checkbox>
      </div>
    </v-col>
  </v-row>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { CompBuilder } from "@/factories/CompBuilder";
import { updateCompBuilder } from "@/api/comp-builders.service";
import { defineProps, defineEmits, ref, watch } from "vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { computedValidation } from "@/util/helpers";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  value: Object,
  disabled: Boolean,
  validationScope: {
    type: String,
    required: true
  }
});

const emit = defineEmits(["input"]);

const compBuilder = ref(CompBuilder(props.value));

const savingBuffer = {
  name: useActiveSave(),
  street: useActiveSave(),
  noPay: useActiveSave()
};

const v$ = useVuelidate(
  {
    compBuilder: {
      name: { required: Boolean }
    }
  },
  { compBuilder },
  { $scope: props.validationScope, $autoDirty: true }
);

const nameValidation = computedValidation(v$.value.compBuilder.name, {
  required: "Required"
});

function updateName() {
  if (!compBuilder.value.id || props.disabled) return;

  savingBuffer.name.debounceUpdate(() =>
    updateCompBuilder(compBuilder.value.id, { name: compBuilder.value.name })
  );
}
function updateStreet() {
  if (!compBuilder.value.id || props.disabled) return;

  savingBuffer.street.debounceUpdate(() =>
    updateCompBuilder(compBuilder.value.id, {
      street: compBuilder.value.street
    })
  );
}
function updateNoPay() {
  if (!compBuilder.value.id || props.disabled) return;

  savingBuffer.noPay.debounceUpdate(() =>
    updateCompBuilder(compBuilder.value.id, {
      no_pay: compBuilder.value.noPay
    })
  );
}

watch(compBuilder, () => emit("input", compBuilder.value), { deep: true });
</script>
