<template>
  <v-card>
    <v-sheet class="pa-3 mb-3 px-9" color="secondary">
      <h3 class="mt-2">Change Access</h3>
    </v-sheet>
    <v-card-text>
      <v-row class="ma-0">
        <v-col :cols="highestGroup > 1 ? 6 : 12">
          <v-select
            :prepend-inner-icon="mdiAccount"
            label="Current Login"
            :items="logins"
            v-model="currentLogin"
            item-text="name"
            outlined
            dense
            success
            return-object
          />
        </v-col>
        <v-col v-if="highestGroup > 1" cols="6">
          <v-select
            :prepend-inner-icon="mdiShieldLock"
            label="Group Level"
            :items="availableAccessLevels"
            v-model="accessLevel"
            outlined
            dense
            success
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-6 pb-3">
      <v-spacer />
      <v-btn class="text-none mr-1" outlined @click="closeDialog" color="grey"
        >Cancel</v-btn
      >
      <v-btn class="text-none" color="primary" :loading="loading" @click="apply"
        >Apply</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { switchLogin } from "@/api/logins.service";
import { updateUser } from "@/api/users.service";
import { useUserStore } from "@/stores/user";
import { mapActions, mapState } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { mdiAccount, mdiShieldLock } from "@mdi/js";
export default {
  data() {
    const user = useUserStore();
    const availableAccessLevels = [];
    for (let i = 1; i <= user.highest_group; i++) {
      availableAccessLevels.push({ text: i, value: i });
    }

    return {
      currentLogin: {
        name: user.loginable.name,
        id: user.login.id
      },
      accessLevel: user.group,
      availableAccessLevels,
      loading: false,
      mdiAccount,
      mdiShieldLock
    };
  },
  computed: {
    ...mapState(useUserStore, {
      highestGroup: "highest_group",
      allLogins: "logins",
      loginable: "loginable",
      userGroup: "group",
      userId: "id"
    }),
    logins() {
      const logins = [...this.allLogins];
      logins.sort((a, b) => a.name.localeCompare(b.name));
      return logins;
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["closeDialog"]),
    apply() {
      this.loading = true;
      const promises = [];
      if (this.currentLogin.id !== this.loginable.id) {
        const params = {
          id: this.currentLogin.id
        };
        promises.push(switchLogin(params));
      }
      if (this.userGroup !== this.accessLevel) {
        promises.push(updateUser(this.userId, { group: this.accessLevel }));
      }
      if (!promises.length) {
        this.loading = false;
        this.closeDialog();
        return;
      }
      Promise.allSettled(promises)
        .then(() => {
          this.closeDialog({ applying: true });
          location.reload();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
