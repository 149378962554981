export function BuildChart(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || "",
    gender: model?.gender || null,
    isCompleted: model?.isCompleted || false
  };
}

export function setBuildChartFromRequest(raw = {}) {
  const model = BuildChart();
  model.id = raw?.id;
  model.name = raw?.name;
  model.gender = raw?.gender;
  model.isCompleted = raw?.is_completed;
  return model;
}
