import { ref } from "vue";

export const useCacheableSearch = (method, debounceMs = 2) => {
  const state = ref({
    items: [],
    timer: null,
    loading: false,
    dirty: false,
    method
  });

  function debounceFetch() {
    if (state.value.loading || (state.value.items.length && !state.value.dirty))
      return;
    return new Promise((resolve, reject) => {
      if (state.value.timer) clearTimeout(state.value.timer);

      state.value.timer = setTimeout(
        () => fetch().then(resolve).catch(reject),
        debounceMs
      );
    });
  }

  async function fetch() {
    try {
      const items = await state.value.method();
      state.value.items.splice(0, state.value.items.length);
      state.value.items.push(...items);
      state.value.loading = false;
    } catch (e) {
      state.value.loading = false;
      throw e;
    } finally {
      state.value.dirty = false;
    }
  }
  return { state, fetch: debounceFetch };
};
