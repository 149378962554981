<template>
  <v-autocomplete
    v-model="caseManager"
    autocomplete="false"
    placeholder="Search Case Managers"
    outlined
    item-text="name"
    return-object
    dense
    :prepend-inner-icon="mdiAccountTie"
    ref="caseManagerInput"
    no-filter
    hide-no-data
    :label="label"
    :hide-details="hideDetails"
    :clearable="clearable"
    :disabled="disabled"
    :search-input.sync="caseManagerSearch"
    :success="success"
    :error-messages="errorMessages"
    :items="caseManagers"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
    @change="$emit('change')"
    @click:clear="$emit('input', {})"
  >
    <template #append-outer>
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import sortBy from "lodash/sortBy";
import { searchCaseManagers } from "@/api/search.service";
import { mdiAccountTie } from "@mdi/js";
export default {
  props: {
    hideDetails: Boolean,
    clearable: Boolean,
    success: Boolean,
    label: {
      type: String,
      default: "Case Manager"
    },
    errorMessages: Array,
    disabled: Boolean,
    value: [Object, String]
  },
  data() {
    let caseManagers = [];
    let caseManager = null;
    if (this.value && Object.keys(this.value).length) {
      caseManagers.push(this.value);
      caseManager = this.value;
    }
    return {
      caseManagerSearch: "",
      caseManagerTimer: null,
      loading: false,
      caseManagers,
      caseManager,
      mdiAccountTie
    };
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.caseManager = null;
        this.caseManagers = [];
        return;
      }
      this.caseManager = value;
      this.caseManagers = [value];
    },
    caseManager(value) {
      this.$emit("input", value);
    },
    caseManagerSearch(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (
        this.caseManagers.some(val => val?.name === value) &&
        this.caseManagers.length === 1
      ) {
        return;
      }

      if (this.caseManagerTimer) {
        clearTimeout(this.caseManagerTimer);
      }

      this.caseManagerTimer = setTimeout(() => {
        this.loading = true;
        return searchCaseManagers(value)
          .then(response => {
            this.caseManagers = sortBy(response.data.case_managers, "name");
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    focus() {
      this.$refs.caseManagerInput.$refs.input.focus();
    },
    clear() {
      this.caseManager = null;
      this.caseManagers = [];
    }
  }
};
</script>
