<template>
  <v-card outlined @click="emit('edit')" width="100%">
    <v-card-title
      style="font-weight: 300; font-size: 18px"
      class="pb-1"
      :data-testid="`${question.questionId}-${explanationId}-title`"
    >
      <v-row class="ma-0">
        Event:
        <strong class="pl-1">{{ explanation.action }} - {{ time }} </strong>
        <template v-if="!props.readonly">
          <v-spacer />
          <v-btn
            icon
            color="accent"
            :data-testid="`${question.questionId}-${explanationId}-edit`"
            @click.stop="emit('edit')"
          >
            <v-icon>{{ mdiPencil }}</v-icon>
          </v-btn>
          <v-btn
            icon
            color="error"
            :data-testid="`${question.questionId}-${explanationId}-delete`"
            @click.stop="emit('delete')"
          >
            <v-icon>{{ mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col
          cols="12"
          :data-testid="`${question.questionId}-${explanationId}-reason`"
        >
          Reason:
          {{ explanation.reason }}
        </v-col>
        <v-col
          cols="12"
          :data-testid="`${question.questionId}-${explanationId}-explanation`"
        >
          Explanation:
          {{ explanation.explanation }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { timestampFormatter } from "@/util/helpers";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { defineProps, defineEmits, computed } from "vue";
import { mdiPencil, mdiDelete } from "@mdi/js";

const props = defineProps({
  readonly: Boolean,
  questionId: [Number, String],
  explanationId: [Number, String]
});

const emit = defineEmits(["delete", "edit"]);
const agent = useAgentSettingsStore();
const question = agent.contractingQuestions[props.questionId];
const explanation = computed(
  () =>
    agent.contractingQuestions[props.questionId].explanations[
      props.explanationId
    ]
);

const time = computed(() =>
  timestampFormatter(explanation.value.actionDate, "sole-day", "basic")
);
</script>
