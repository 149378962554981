<template>
  <v-card>
    <v-card-text>
      <v-data-table
        data-testid="personnel-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :loading="table.loading.value"
        :options="table.options.value"
        :footer-props="footerProps"
        :search="search"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Carrier Personnel</h1>
            <v-spacer />
            <v-text-field
              v-model="search"
              outlined
              dense
              hide-details
              label="Search"
              data-lpignore="true"
              :prepend-inner-icon="mdiMagnify"
              data-testid="personnel-table-search"
            />
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="personnel-table-refresh"
                  :loading="table.loading.value"
                  @click="getData"
                >
                  <v-icon>{{ mdiRefresh }}</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
        </template>
        <template #[`item.name`]="{ item }">
          <router-link
            :to="{
              name: 'PersonnelView',
              params: { id: item.additional.id }
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template #[`item.phone`]="{ item }">
          <a
            v-if="item.phone"
            :href="`tel:${item.phone}`"
            data-outbound-type="Personnel"
            :data-outbound-id="item.additional.id"
            :data-outbound-number="item.phone"
          >
            {{ item.phone }}
          </a>
        </template>
        <template #[`item.mobile`]="{ item }">
          <a
            v-if="item.mobile"
            :href="`tel:${item.mobile}`"
            data-outbound-type="Personnel"
            :data-outbound-id="item.additional.id"
            :data-outbound-number="item.mobile"
          >
            {{ item.mobile }}
          </a>
        </template>
        <template #[`item.email`]="{ item }">
          <a v-if="item.email" :href="`mailto:${item.email}`">
            {{ item.email }}
          </a>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TableOptions from "@/classes/data-table/TableOptions";
import TableHeader from "@/classes/data-table/TableHeader";
import { getAllPersonnel } from "@/api/personnel.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { computed, ref } from "vue";
import { mdiMagnify, mdiRefresh } from "@mdi/js";

useHead({
  title: "Personnel"
});

const snackbar = useSnackbarStore();
const search = ref("");

const table = useTable({
  getData: async () => {
    const items = await getAllPersonnel();
    return { items };
  },
  options: new TableOptions(["carrier"], [false]),
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrier.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Department",
      value: "department",
      map: "department",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Phone",
      value: "phone",
      map: "phone",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Mobile",
      value: "mobile",
      map: "mobile",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Email",
      value: "email",
      map: "email",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    })
  ]
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(
      table.options.value,
      table.items.value.length
    ),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

getData();
</script>
