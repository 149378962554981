<template>
  <v-autocomplete
    autocomplete="false"
    :prepend-inner-icon="mdiPound"
    placeholder="Search Policy Numbers"
    label="Policy Number"
    outlined
    dense
    v-model="policy"
    item-text="policy_number"
    return-object
    no-filter
    hide-no-data
    auto-select-first
    :autofocus="autofocus"
    :search-input.sync="search"
    :success="success"
    :error-messages="errorMessages"
    :items="policies"
    :loading="loading"
    :hide-details="hideDetails"
    :clearable="clearable"
    :disabled="disabled"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    ref="search"
    @blur="$emit('blur')"
    @change="$emit('change')"
  />
</template>

<script>
import sortBy from "lodash/sortBy";
import { mdiPound } from "@mdi/js";
import {
  policyNumberSearch,
  simplePolicyNumberSearch
} from "@/api/search.service";
import { searchCaseTransactions } from "@/api/transactions.service";
export default {
  props: {
    success: Boolean,
    errorMessages: Array,
    value: [Object, String],
    type: String,
    hideDetails: Boolean,
    simple: Boolean,
    clearable: Boolean,
    disabled: Boolean,
    autofocus: Boolean
  },
  data() {
    let policies = [];
    let policy = null;
    if (this.value && Object.keys(this.value).length && this.value.id) {
      policies = [this.value];
      policy = this.value;
    }

    return {
      search: "",
      policyTimer: null,
      loading: false,
      policies,
      policy,
      mdiPound
    };
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.policy = null;
        this.policies = [];
        return;
      }
      this.policy = value;
      this.policies = [value];
    },
    policy(value) {
      this.$emit("input", value);
    },
    search(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (
        this.policies.some(val => `${val?.policy_number}` === `${value}`) &&
        this.policies.length === 1
      ) {
        return;
      }

      if (this.policyTimer) {
        clearTimeout(this.policyTimer);
      }

      this.policyTimer = setTimeout(() => {
        this.loading = true;
        let method = searchCaseTransactions;
        if (this.type !== "transaction") {
          method = this.simple ? simplePolicyNumberSearch : policyNumberSearch;
        }
        method(value)
          .then(response => {
            this.policies = sortBy(response, "policy_date");
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    focus() {
      this.$refs.payorSearch.$refs.input.focus();
    },
    clear() {
      this.policy = null;
      this.policies = [];
    }
  }
};
</script>
