<template>
  <v-col v-if="loaded" class="py-0">
    <v-row class="mb-1" align="center" dense>
      <v-col cols="12" md="6">
        <app-breadcrumbs data-testid="breadcrumbs" />
      </v-col>
      <v-col cols="12" md="6">
        <v-row class="ma-0" justify="end" align="center">
          <v-btn
            text
            class="text-none mr-1 px-1"
            color="grey darken-1"
            :loading="verifyingActiveStatus"
            @click="verifyActiveStatus"
          >
            <v-icon class="mr-1">{{ mdiRefresh }}</v-icon> Follow Up At
            <timestamp-formatter :value="followUpAt" class="ml-1" />
          </v-btn>
          <v-btn
            icon
            class="mr-1"
            color="primary"
            :loading="downloadingForm"
            @click="downloadForm"
          >
            <v-icon>{{ mdiDownload }}</v-icon>
          </v-btn>
          <v-btn
            icon
            class="mr-1"
            color="info"
            :href="previewLink"
            target="_blank"
          >
            <v-icon>{{ mdiPrinterEye }}</v-icon>
          </v-btn>
          <div class="mr-1">
            <form-mapping-form-status-selector
              :loading="verifyingForm"
              :status="status"
              @status-update="toggleFormStatus"
            />
          </div>
          <v-btn icon @click="toggleFullscreen">
            <v-icon v-if="fullscreen">{{ mdiFullscreenExit }}</v-icon>
            <v-icon v-else>{{ mdiFullscreen }}</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-tabs v-model="activeTab">
      <v-tab class="text-none">
        <v-icon class="mr-1"> {{ mdiMapOutline }} </v-icon>
        <v-badge
          v-if="incompleteQuestionCount"
          color="error"
          :content="incompleteQuestionCount"
        >
          Field Mapping
        </v-badge>
        <template v-else> Field Mapping </template>
      </v-tab>
      <v-tab class="text-none">
        <v-icon class="mr-1"> {{ mdiRelationOneToMany }} </v-icon>
        Form Relations
      </v-tab>
      <v-tab class="text-none" data-testid="form-details">
        <v-icon class="mr-1">{{ mdiBookInformationVariant }}</v-icon> Form
        Details
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab" touchless>
      <v-tab-item eager>
        <v-card flat tile>
          <v-card-text>
            <v-col class="pa-0">
              <v-sheet
                color="section"
                class="py-3"
                :class="{ 'mb-1': !copiedFormId || !loadedCopiedForm }"
              >
                <v-row class="pa-3" dense>
                  <v-col cols="12">
                    <form-search
                      label="Form to Copy From"
                      v-model="copiedFormObj"
                      hide-details
                      clearable
                      :current-form-id="id"
                      @click:clear="clearCopiedForm"
                    />
                  </v-col>
                </v-row>
              </v-sheet>
              <v-row dense class="my-1" style="position: relative">
                <!-- New Form -->
                <v-col :cols="copiedFormReady ? 6 : 12">
                  <form-mapping-form-view
                    style="min-height: 40rem"
                    ref="fieldMappingManager"
                    key="new-form"
                    :copying-field="copyingField"
                    :form-id="id"
                    :is-copying-form="copiedFormReady"
                    :sample-id="modelId"
                    :vertical="copiedFormReady"
                    :readonly="readonly"
                  />
                </v-col>
                <!-- Copied Form -->
                <v-col v-if="copiedFormId" cols="6">
                  <form-mapping-form-view
                    v-if="copiedFormId && loadedCopiedForm"
                    :key="`${copiedFormId}-copied-form`"
                    is-copying-form
                    is-copied-form
                    vertical
                    readonly
                    :copying-field="copyingField"
                    :form-id="copiedFormId"
                    :sample-id="modelId"
                    @copy-field="copy"
                    @copy-to-new="copyToNew"
                  />
                  <v-skeleton-loader
                    v-else-if="loadingCopiedForm"
                    type="card"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item eager>
        <v-card flat tile>
          <v-card-text>
            <form-mapping-form-relations
              v-model="relations"
              :readonly="readonly"
              :form-id="id"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item eager>
        <v-card flat tile>
          <v-card-text>
            <form-mapping-form-details
              :name.sync="name"
              :category.sync="category"
              :carrier.sync="carrier"
              :is-contracting.sync="isContracting"
              :formSets.sync="formSets"
              :form-id="id"
              :submission-method="submissionMethod"
              :advisor.sync="advisor"
              editing
              :readonly="readonly"
              @form-updated="() => instantiateForm(true)"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-col>
  <v-skeleton-loader type="card" v-else />
</template>
<script>
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import FormMappingFormRelations from "@/components/form-mapping/FormMappingFormRelations.vue";
import FormMappingFormDetails from "@/components/form-mapping/FormMappingFormDetails.vue";
import FormMappingFormStatusSelector from "@/components/form-mapping/FormMappingFormStatusSelector.vue";
import FormMappingFormView from "@/components/form-mapping/FormMappingFormView.vue";
import FormSearch from "@/components/shared/FormSearch.vue";

import { downloadFile, parseErrorMessage } from "@/util/helpers";
import { useFormMappingStore } from "@/stores/form-mapping";
import { mapActions, mapWritableState, storeToRefs } from "pinia";
import { ref } from "vue";
import { getPreviewUrl } from "@/api/forms.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";
import {
  mdiRefresh,
  mdiDownload,
  mdiPrinterEye,
  mdiFullscreenExit,
  mdiFullscreen,
  mdiMapOutline,
  mdiRelationOneToMany,
  mdiBookInformationVariant
} from "@mdi/js";

export default {
  props: {
    id: [String, Number]
  },
  components: {
    AppBreadcrumbs,
    FormMappingFormRelations,
    FormMappingFormDetails,
    FormMappingFormStatusSelector,
    TimestampFormatter,
    FormMappingFormView,
    FormSearch
  },
  created() {
    this.instantiateForm();
    this.instantiateCopiedForm();
  },
  computed: {
    ...mapWritableState(useInstanceStore, ["breadcrumb", "fullscreen"]),
    readonly() {
      return this.status !== "Incomplete" || this.verifyingForm;
    },
    previewLink() {
      return getPreviewUrl(this.id);
    },
    copiedFormId() {
      return this.copiedFormObj?.id;
    },
    copiedFormReady() {
      return this.copiedFormId && this.loadedCopiedForm;
    }
  },
  destroyed() {
    const formMapper = useFormMappingStore(this.id);
    formMapper.$reset();
    this.fullscreen = false;
    this.breadcrumb = "";
  },
  setup({ id }) {
    const formMapper = useFormMappingStore(id);
    const {
      followUpAt,
      status,
      relations,
      name,
      submissionMethod,
      pdfUrl,
      carrier,
      category,
      advisor,
      incompleteQuestionCount,
      formSets,
      isContracting,
      currentFieldId,
      positionallySortedFields,
      modelId
    } = storeToRefs(formMapper);

    const head = useHead({ title: "Form Mapping" });

    return {
      followUpAt,
      status,
      relations,
      name,
      submissionMethod,
      pdfUrl,
      formSets,
      modelId,
      copyField: formMapper.copyField,
      incompleteQuestionCount,
      carrier,
      isContracting,
      category,
      advisor,
      currentFieldId,
      head,
      positionallySortedFields,
      verifyStatus: formMapper.verifyStatus,
      initializeForm: formMapper.initializeForm,
      updateFormStatus: formMapper.updateFormStatus,
      changeActiveField: formMapper.changeActiveField,
      loading: ref(false),
      loaded: ref(false),
      verifyingForm: ref(false),
      downloadingForm: ref(false),
      verifyingActiveStatus: ref(false)
    };
  },
  data: () => ({
    copiedFormObj: {},
    loadedCopiedForm: false,
    loadingCopiedForm: false,
    copyingField: false,
    copyTimeout: null,
    activeTab: 0,
    mdiRefresh,
    mdiDownload,
    mdiPrinterEye,
    mdiFullscreenExit,
    mdiFullscreen,
    mdiMapOutline,
    mdiRelationOneToMany,
    mdiBookInformationVariant
  }),
  watch: {
    currentFieldId(v) {
      if (this.$route.query["aql-id"] === v) return;
      if (v) {
        this.$router.replace({ query: { ...this.$route.query, "aql-id": v } });
      }
    },
    copiedFormId() {
      if (this.copyTimeout) clearTimeout(this.copyTimeout);
      this.copyTimeout = setTimeout(() => {
        this.getCopiedForm();
      });
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showSuccessSnackbar",
      "showErrorSnackbar"
    ]),
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    downloadForm() {
      downloadFile(this.pdfUrl, this.$vuetify.breakpoint.mdAndDown);
    },
    async verifyActiveStatus() {
      this.verifyingActiveStatus = true;
      try {
        await this.verifyStatus();
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.verifyingActiveStatus = false;
      }
    },
    async toggleFormStatus(newStatus) {
      this.verifyingForm = true;
      try {
        await this.updateFormStatus(newStatus);
        this.status = newStatus;
        this.showSuccessSnackbar({
          message: `Successfully updated status to ${newStatus}`
        });
      } catch (e) {
        this.showErrorSnackbar({
          message: `Error Verifying Form: ${parseErrorMessage(e)}`,
          timeout: -1
        });
      } finally {
        this.verifyingForm = false;
      }
    },
    async instantiateForm(showSnackbar = false) {
      if (showSnackbar) {
        this.showSuccessSnackbar({ message: "Reloading Form..." });
      }
      this.loaded = false;
      this.loading = true;
      try {
        await this.initializeForm(this.id);
        this.breadcrumb = this.name;
        this.head.patch({ title: this.name });
        const prevAqlId = this.$route.query["aql-id"];
        if (
          prevAqlId &&
          this.positionallySortedFields.some(({ id }) => id === prevAqlId)
        ) {
          this.changeActiveField(prevAqlId);
        } else if (this.positionallySortedFields.length) {
          const id = this.positionallySortedFields[0].id;
          this.changeActiveField(id);
        }
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
        this.$router.replace({ name: "Home" });
      } finally {
        this.loading = false;
        this.loaded = true;
      }
    },
    async instantiateCopiedForm() {
      if (!this.$route.query["copied-form"]) return;
      try {
        this.copiedFormObj = JSON.parse(this.$route.query["copied-form"]);
        this.getCopiedForm(this.copiedFormId);
      } catch (e) {
        // do nothing
      }
    },
    copyToNew(field) {
      return this.copy(field, true);
    },
    async copy(field) {
      this.copyingField = true;
      try {
        await this.copyField(field, this.copiedFormId);
        this.showSuccessSnackbar({
          message: "Successfully copied"
        });
      } catch (e) {
        this.showErrorSnackbar({
          message: parseErrorMessage(e),
          timeout: -1
        });
      } finally {
        this.copyingField = false;
      }
    },
    clearCopiedForm() {
      this.copiedFormObj = {};
      this.loadedCopiedForm = false;
      const query = { ...this.$route.query };
      delete query["copied-form-id"];
      this.$router.replace({ ...this.$route, query }).catch(() => {});
    },
    async getCopiedForm() {
      this.$router
        .replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            "copied-form": JSON.stringify(this.copiedFormObj)
          }
        })
        .catch(() => {});
      if (!this.copiedFormId) return;
      this.loadedCopiedForm = false;
      this.loadingCopiedForm = true;
      const copiedForm = useFormMappingStore(this.copiedFormId);

      const currentFormStore = useFormMappingStore(this.id);
      currentFormStore.otherFormId = this.copiedFormId;
      try {
        await copiedForm.initializeForm();
        copiedForm.otherFormId = this.id;
        let activeField = null;
        if (copiedForm.fields.ids.length) {
          activeField = copiedForm.positionallySortedFields[0].id;
        }
        copiedForm.changeActiveField(activeField);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loadedCopiedForm = true;
        this.loadingCopiedForm = false;
      }
    }
  }
};
</script>
