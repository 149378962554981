import CategoryStatesDialog from "@/dialogs/CategoryStatesDialog.vue";
import ChangeAccessDialog from "@/dialogs/ChangeAccessDialog.vue";
import ChangeAvatarDialog from "@/dialogs/ChangeAvatarDialog.vue";
import ChatTemplateViewer from "@/dialogs/ChatTemplateViewer.vue";
import CommissionDialog from "@/dialogs/CommissionDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import ContactDetailsDialog from "@/dialogs/ContactDetailsDialog.vue";
import CopyDialog from "@/dialogs/CopyDialog.vue";
import CreateAffiliationDialog from "@/dialogs/CreateAffiliationDialog.vue";
import CreateFormDialog from "@/dialogs/CreateFormDialog.vue";
import ContractingQuestionExplanationDialog from "@/dialogs/ContractingQuestionExplanationDialog.vue";
import CreateQuoteAndApplySite from "@/dialogs/CreateQuoteAndApplySite.vue";
import CreateTrainingDialog from "@/dialogs/CreateTrainingDialog.vue";
import CreatorImageDialog from "@/components/help-center/HelpCenterCreatorImageDialog.vue";
import DeleteDialog from "@/dialogs/DeleteDialog.vue";
import EditConnectionDialog from "@/dialogs/EditConnectionDialog.vue";
import EditPayPeriodDialog from "@/dialogs/EditPayPeriodDialog.vue";
import EditVendorDialog from "@/dialogs/EditVendorDialog.vue";
import EmployeeReviewDialog from "@/dialogs/EmployeeReviewDialog.vue";
import EmploymentHistoryDialog from "@/dialogs/EmploymentHistoryDialog.vue";
import HelpCenterReader from "@/components/help-center/HelpCenterReader.vue";
import LeadContactedDialog from "@/dialogs/LeadContactedDialog.vue";
import MarketingManagerEditDialog from "@/dialogs/MarketingManagerEditDialog.vue";
import NewAgencyDialog from "@/dialogs/NewAgencyDialog.vue";
import NewCommissionAssignmentDialog from "@/dialogs/NewCommissionAssignmentDialog.vue";

import SmokingTypeDialog from "@/dialogs/SmokingTypeDialog.vue";
import TransactionCreate from "@/components/commissions/TransactionCreate.vue";
import WelcomeTourDialog from "@/dialogs/WelcomeTourDialog.vue";

import DocumentDialog from "@/dialogs/DocumentDialog.vue";
import TextEditDialog from "@/dialogs/TextEditDialog.vue";

export default {
  CategoryStatesDialog,
  ChangeAccessDialog,
  ChangeAvatarDialog,
  ChatTemplateViewer,
  CommissionDialog,
  ConfirmationDialog,
  ContactDetailsDialog,
  CopyDialog,
  CreateAffiliationDialog,
  CreateFormDialog,
  ContractingQuestionExplanationDialog,
  CreateQuoteAndApplySite,
  CreateTrainingDialog,
  CreatorImageDialog,
  DeleteDialog,
  DocumentDialog,
  EditConnectionDialog,
  EditPayPeriodDialog,
  EditVendorDialog,
  EmployeeReviewDialog,
  EmploymentHistoryDialog,
  HelpCenterReader,
  LeadContactedDialog,
  MarketingManagerEditDialog,
  NewAgencyDialog,
  NewCommissionAssignmentDialog,
  SmokingTypeDialog,
  TransactionCreate,
  WelcomeTourDialog,
  TextEditDialog
};
