<template>
  <v-card
    flat
    :outlined="focused"
    :ripple="false"
    style="position: relative; overflow: hidden"
    @click="$emit('click')"
  >
    <v-row class="ma-3" align="center" dense>
      <v-col cols="12" :md="showText ? 4 : 9">
        <v-text-field
          data-lpignore="true"
          :prepend-inner-icon="mdiForum"
          label="Value"
          dense
          outlined
          hide-details
          :readonly="readonly"
          :value="value"
          :disabled="readonly"
          :success="valueValidation.success"
          :error-messages="valueValidation.errorMessages"
          @input="update('value', $event)"
        />
      </v-col>
      <v-col v-if="showText" cols="12" md="5">
        <v-text-field
          data-lpignore="true"
          :prepend-inner-icon="mdiForum"
          label="Text"
          dense
          outlined
          hide-details
          :value="text"
          :readonly="readonly"
          :disabled="readonly"
          :success="textValidation.success"
          :error-messages="textValidation.errorMessages"
          @input="update('text', $event)"
        />
      </v-col>
      <v-col v-if="canBeVirtual || !readonly" cols="12" md="3">
        <v-btn
          v-if="canBeVirtual"
          icon
          @click="update('virtual', !virtual)"
          :color="virtual ? 'orange' : ''"
          :disabled="readonlyVirtual"
        >
          <v-icon v-if="virtual" color="orange">{{ mdiEyeOff }}</v-icon>
          <v-icon v-else>{{ mdiEye }}</v-icon>
        </v-btn>
        <v-btn v-if="!readonly" icon @click="$emit('delete')" color="error">
          <v-icon>{{ mdiDelete }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="focused" style="position: absolute; bottom: 0; right: 5px">
      <div
        :style="{
          'background-color': $vuetify.theme.dark
            ? 'rgba(255, 255, 255, 0.12)'
            : 'rgba(0, 0, 0, 0.12)'
        }"
        style="
          width: 100px;
          height: 100px;
          position: absolute;
          bottom: -65px;
          right: -65px;
          transform: rotate(-45deg);
        "
      ></div>
      <v-icon small>{{ mdiBullseyeArrow }}</v-icon>
    </div>
    <div v-if="hasErrorMessages" class="options-parent-error-text pa-3">
      <div class="v-messages__message options-error-text">
        {{ errorMessages.join() }}
      </div>
    </div>
  </v-card>
</template>

<script>
import {
  mdiForum,
  mdiEyeOff,
  mdiEye,
  mdiDelete,
  mdiBullseyeArrow
} from "@mdi/js";
export default {
  props: {
    readonlyVirtual: Boolean,
    readonly: Boolean,
    focused: Boolean,
    success: Boolean,
    showText: Boolean,
    canBeVirtual: Boolean,
    errorMessages: {
      type: Array,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    virtual: {
      type: Boolean,
      required: false
    }
  },
  data: () => ({ mdiForum, mdiEyeOff, mdiEye, mdiDelete, mdiBullseyeArrow }),
  computed: {
    hasErrorMessages() {
      let isValid = this.valueValidation.success;
      if (this.showText) isValid = isValid && this.textValidation.success;
      return !isValid;
    },
    textValidation() {
      const errorMessages = [];
      if (this.errorMessages.length && !this.text)
        errorMessages.push("required");
      return {
        success: !errorMessages.length,
        errorMessages
      };
    },
    valueValidation() {
      const errorMessages = [];
      if (this.errorMessages.length && !this.value)
        errorMessages.push("required");
      return {
        success: !errorMessages.length,
        errorMessages
      };
    }
  },
  methods: {
    update(propType, value) {
      this.$emit(`update:${propType}`, value);
      this.$emit("update");
    }
  }
};
</script>
