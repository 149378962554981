<template>
  <v-tooltip v-if="isCopyable" bottom key="is-copyable">
    <template #activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        icon
        :loading="loading"
        @click="$emit('copy-to-new')"
      >
        <v-icon>{{ mdiContentCopy }}</v-icon>
      </v-btn>
    </template>
    <span>Copy to new field</span>
  </v-tooltip>
  <v-tooltip v-else bottom key="not-copyable">
    <template #activator="{ on, attrs }">
      <span v-on="on" v-bind="attrs">
        <v-btn icon disabled>
          <v-icon> {{ mdiContentCopy }} </v-icon>
        </v-btn>
      </span>
    </template>
    <span>
      {{ notCopyableText }}
    </span>
  </v-tooltip>
</template>

<script>
import { mdiContentCopy } from "@mdi/js";
export default {
  props: {
    notCopyableText: String,
    loading: Boolean
  },
  computed: {
    isCopyable() {
      return !this.notCopyableText;
    }
  },
  data: () => ({ mdiContentCopy })
};
</script>
