<template>
  <v-autocomplete
    placeholder="Search Smoker Charts"
    label="Smoker Chart"
    outlined
    item-text="name"
    return-object
    dense
    v-model="smokerChart"
    :prepend-inner-icon="mdiSmoking"
    no-filter
    hide-no-data
    :hide-details="hideDetails"
    :clearable="clearable"
    :disabled="disabled"
    :search-input.sync="smokerChartSearch"
    :success="success"
    :error-messages="errorMessages"
    :items="smokerCharts"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
    @change="$emit('change')"
    @click:clear="$emit('input', {})"
  >
    <template #append-outer>
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import { mdiSmoking } from "@mdi/js";
import { getSmokerCharts } from "@/api/smoker-charts.service";

export default {
  props: {
    hideDetails: Boolean,
    clearable: Boolean,
    success: Boolean,
    errorMessages: Array,
    disabled: Boolean,
    value: [Object, String]
  },
  data() {
    let smokerCharts = [];
    let smokerChart = null;
    if (this.value && Object.keys(this.value).length) {
      smokerCharts.push(this.value);
      smokerChart = this.value;
    }
    return {
      smokerChartSearch: "",
      smokerCharts,
      smokerChartTimer: null,
      smokerChart,
      loading: false,
      mdiSmoking
    };
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.smokerChart = null;
        this.smokerCharts = [];
        return;
      }
      this.smokerChart = value;
      this.smokerCharts = [value];
    },
    smokerChart(value) {
      this.$emit("input", value);
    },
    smokerChartSearch(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (
        this.smokerCharts.some(val => val?.name === value) &&
        this.smokerCharts.length === 1
      ) {
        return;
      }

      if (this.smokerChartTimer) {
        clearTimeout(this.smokerChartTimer);
      }

      this.smokerChartTimer = setTimeout(() => {
        this.loading = true;
        const params = new URLSearchParams();
        params.append("name", value);
        getSmokerCharts(params)
          .then(response => {
            this.smokerCharts = response.toSorted((a, b) =>
              a.carrierName.localeCompare(b.carrierName)
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  }
};
</script>
