<template>
  <div data-testid="saving-indicator" v-if="saving || saved || error">
    <v-fade-transition mode="out-in">
      <v-progress-circular
        v-if="saving"
        key="saving"
        indeterminate
        :size="small ? 16 : 24"
        width="2"
      />
      <v-icon
        v-else-if="saved"
        key="saved"
        color="success"
        :size="small ? 16 : 24"
      >
        {{ mdiCheckboxMarkedCircleOutline }}
      </v-icon>
      <v-tooltip v-else-if="error" key="error" bottom>
        <template #activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-icon color="error" :size="small ? 16 : 24">
              {{ mdiAlertCircleOutline }}
            </v-icon>
          </div>
        </template>
        <span>Save failed because {{ errorMessage.substr(0, 200) }} </span>
      </v-tooltip>
    </v-fade-transition>
  </div>
</template>

<script>
import { mdiCheckboxMarkedCircleOutline, mdiAlertCircleOutline } from "@mdi/js";
export default {
  props: {
    controller: Object,
    small: Boolean
  },
  data: () => ({ mdiCheckboxMarkedCircleOutline, mdiAlertCircleOutline }),
  computed: {
    saved() {
      return this.controller?.saved;
    },
    saving() {
      return this.controller?.saving;
    },
    error() {
      return this.controller?.error;
    },
    errorMessage() {
      return typeof this.controller?.errorMessage === "string"
        ? this.controller.errorMessage
        : "";
    }
  }
};
</script>
