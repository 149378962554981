<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" md="6">
        <agent-search
          v-model="advisor.principal"
          label="Principal Agent"
          placeholder="Search Principal Agent"
          data-testid="agency-create-principal-agent"
          :disabled="loading"
          :success="principalValidation.success"
          :error-messages="principalValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12" md="6">
        <user-search
          v-model="advisor.user"
          label="Existing User"
          placeholder="Search User by Email Address"
          data-testid="agency-create-user"
          :disabled="loading"
          :success="userValidation.success"
          :error-messages="userValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12" md="8">
        <basic-email-input
          v-model="advisor.email"
          outlined
          dense
          data-lpignore="true"
          label="E-Mail Address"
          data-testid="agency-create-email"
          :prepend-inner-icon="mdiEmail"
          :disabled="loading"
          :success="emailValidation.success"
          :error-messages="emailValidation.errorMessages"
          @valid="emailIsValid = $event"
        />
      </v-col>
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="advisor.marketing"
            class="mt-1"
            label="Subscribe to Marketing Emails"
            hide-details
            data-testid="agency-create-marketing"
            :disabled="loading"
          />
        </div>
      </v-col>
      <v-col cols="12" md="8" v-if="advisor.licensed">
        <npn-input
          v-model="advisor.npn"
          data-lpignore="true"
          :prepend-inner-icon="mdiPound"
          label="NPN"
          validate
          data-testid="agency-create-npn"
          :disabled="loading"
          :success="npnValidation.success"
          :error-messages="npnValidation.errorMessages"
          @error-message="npnErrorMessage = $event"
          @valid="npnIsValid = $event"
        />
      </v-col>
      <v-col cols="12" md="8" v-else>
        <v-text-field
          v-model="advisor.name"
          data-lpignore="true"
          :prepend-inner-icon="mdiDomain"
          outlined
          dense
          label="Agency Name"
          data-testid="agency-create-name"
          :disabled="loading"
          :success="nameValidation.success"
          :error-messages="nameValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="advisor.licensed"
            class="mt-1"
            hide-details
            label="Agency has NPN"
            data-testid="agency-create-has-npn"
            :disabled="loading"
          />
        </div>
      </v-col>
      <v-col cols="12">
        <marketing-manager-search
          v-model="advisor.marketingManager"
          return-object
          data-testid="agency-create-marketing-manager"
          :disabled="loading"
          :success="marketingManagerValidation.success"
          :error-messages="marketingManagerValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          class="text-none"
          data-testid="agency-create-action"
          :loading="loading"
          @click="createAgency"
        >
          <v-icon class="mr-1"> {{ mdiPlus }} </v-icon> Create Agency
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import MarketingManagerSearch from "@/components/shared/MarketingManagerSearch.vue";
import AgentSearch from "@/components/shared/AgentSearch.vue";
import UserSearch from "@/components/shared/UserSearch.vue";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import NpnInput from "@/components/shared/NpnInput.vue";

import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { inviteAgency } from "@/api/invites.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { ref } from "vue";
import { useRouter } from "@/composables/compatible.composables";
import useVuelidate from "@vuelidate/core";

import { mdiEmail, mdiPound, mdiDomain, mdiPlus } from "@mdi/js";

const snackbar = useSnackbarStore();
const router = useRouter();
const advisor = ref({
  user: null,
  marketing: false,
  email: "",
  licensed: true,
  name: "",
  marketingManager: null,
  npn: "",
  principal: null
});

const emailIsValid = ref("");
const npnIsValid = ref("");
const loading = ref(false);
const npnErrorMessage = ref("");

const v$ = useVuelidate(
  {
    advisor: {
      licensed: {},
      principal: { required: Boolean },
      user: { required: Boolean },
      marketingManager: { required: Boolean },
      name: {
        required: (val, vm) => Boolean(val) || vm.licensed
      },
      npn: {
        required: (val, vm) => Boolean(val) || !vm.licensed,
        isValid: (val, vm) => Boolean(npnIsValid.value) || !vm.licensed
      },
      email: {
        required: Boolean,
        isValid: () => Boolean(emailIsValid.value)
      }
    }
  },
  { advisor },
  { $scope: null, $autoDirty: true }
);

const principalValidation = computedValidation(v$.value.advisor.principal, {
  required: "Required"
});
const userValidation = computedValidation(v$.value.advisor.user, {
  required: "Required"
});
const emailValidation = computedValidation(v$.value.advisor.email, {
  required: "Required",
  isValid: "Invalid Email"
});
const npnValidation = computedValidation(v$.value.advisor.npn, {
  required: "Required",
  isValid: "Invalid NPN"
});
const nameValidation = computedValidation(v$.value.advisor.name, {
  required: "Required"
});
const marketingManagerValidation = computedValidation(
  v$.value.advisor.marketingManager,
  { required: "Required" }
);

async function createAgency() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  loading.value = true;
  try {
    const id = await inviteAgency({
      email: advisor.value.email,
      user_id: advisor.value.user.id,
      signer_id: advisor.value.principal.id,
      subscribe_to_marketing_emails: advisor.value.marketing,
      marketing_manager_id: advisor.value.marketingManager.id,
      name: advisor.value.licensed ? null : advisor.value.name,
      npn: advisor.value.licensed ? advisor.value.npn : null
    });
    router.push({
      name: "AgencyView",
      params: { id }
    });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(error),
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}
</script>
