import { getHttpClient } from "@/http-client";
import { setProductSmokerChartFromRequest } from "@/factories/Product";

const baseUrl = "api/boss/product_smoker_charts";
export async function addProductSmokerChart(params) {
  const { data } = await getHttpClient().post(baseUrl, params);
  return setProductSmokerChartFromRequest(data.product_smoker_chart);
}

export const removeProductSmokerChart = id => {
  return getHttpClient().delete(`${baseUrl}/${id}`);
};
