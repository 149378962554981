<template>
  <v-card flat tile>
    <v-card-text>
      <v-data-table
        data-testid="build-table"
        class="transparent-data-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :server-items-length="table.meta.value.total"
        :loading="table.loading.value"
        :options="table.options.value"
        :footer-props="table.footerProps.value"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0">
            <span class="text-h5"> Height & Weight </span>
            <v-spacer />
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="build-table-refresh"
                  :disabled="table.loading.value"
                  @click="getData"
                >
                  <v-icon> {{ mdiRefresh }} </v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
            <v-tooltip bottom v-if="user.isGroupFour">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  data-testid="build-table-create"
                  :disabled="table.loading.value"
                  @click="createBuild"
                >
                  <v-icon> {{ mdiPlusCircle }} </v-icon>
                </v-btn>
              </template>
              <span>Create Build</span>
            </v-tooltip>
          </v-row>
          <generic-table-filter-header
            :value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            icon
            color="accent"
            data-testid="edit"
            @click="editBuild(item.additional)"
          >
            <v-icon>{{ mdiPencil }}</v-icon>
          </v-btn>
          <v-btn
            icon
            color="error"
            data-testid="delete"
            @click="destroyBuild(item.additional)"
          >
            <v-icon>{{ mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import AddBuildDialog from "@/components/builds/AddBuildDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import TableHeader from "@/classes/data-table/TableHeader";

import { ratingOptions } from "@/data/filter-options";
import { parseErrorMessage } from "@/util/helpers";
import { deleteBuild, getBuilds } from "@/api/builds.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiRefresh, mdiPlusCircle, mdiPencil, mdiDelete } from "@mdi/js";
import { useTable } from "@/composables/table.composable";
import { markRaw } from "vue";

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const table = useTable({
  getData: getBuilds,
  shouldIncludeCancelToken: true,
  headers: [
    new TableHeader({
      text: "Build Chart",
      value: "build",
      map: "buildChart.name",
      sortFilterMap: "build_charts.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Inches",
      value: "inches",
      map: "inches",
      sortFilterMap: "builds.inches",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Rating",
      value: "rating",
      map: "rating",
      selectableOptions: ratingOptions,
      sortFilterMap: "builds.rating",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Gender",
      value: "gender",
      map: "buildChart.gender",
      selectableOptions: ["Male", "Female", "Unisex"],
      sortFilterMap: "build_charts.gender",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Min Weight",
      value: "min",
      map: "minWeight",
      ...TableHeader.IS_STRING_FILTER_TYPE
    }),
    new TableHeader({
      text: "Max Weight",
      value: "max",
      map: "maxWeight",
      ...TableHeader.IS_STRING_FILTER_TYPE
    })
  ]
});
if (user.isGroupFour) {
  table.headers.value.push(
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  );
}

function destroyBuild(build) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure you want to permanently delete this Build?",
    subtitle: "This cannot be undone",
    func: async () => {
      await deleteBuild(build.id);
      getData();
    }
  });
}

async function createBuild() {
  const res = await dialog.showDialog({
    component: markRaw(AddBuildDialog),
    persistent: true,
    scrollable: true
  });
  if (res?.created) getData();
}

async function editBuild(value) {
  const res = await dialog.showDialog({
    component: markRaw(AddBuildDialog),
    persistent: true,
    value
  });
  if (res?.created) getData();
}

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  getData();
}

getData();
</script>
