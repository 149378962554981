<template>
  <v-card>
    <v-card-title>Add Product Citizenship Profile</v-card-title>
    <v-card-text>
      <v-select
        v-model="productCitizenshipProfile.citizenshipProfile"
        label="Citizenship Profile"
        outlined
        dense
        item-value="additional"
        data-testid="citizenship-profile-select"
        :prepend-inner-icon="mdiCube"
        :loading="citizenshipProfiles.loading"
        :items="citizenshipProfiles.items"
        v-bind="citizenshipProfileValidation"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        color="grey"
        class="text-none"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Close
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="confirm-button"
        :loading="loading"
        @click="save"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import {
  ProductCitizenshipProfile,
  ProductCitizenshipProfileToCreateRequest
} from "@/factories/Product";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { addProductCitizenshipProfile } from "@/api/product-citizenship-profile.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiCube } from "@mdi/js";
import { defineProps, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { storeToRefs } from "pinia";
import { useSearchItemsCache } from "@/stores/search-items-cache";

const props = defineProps({ productId: { type: Number, required: true } });

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const cache = useSearchItemsCache();
const { citizenshipProfiles } = storeToRefs(cache);
const productCitizenshipProfile = ref(
  ProductCitizenshipProfile({ productId: props.productId })
);
const loading = ref(false);

const v$ = useVuelidate(
  {
    productCitizenshipProfile: {
      citizenshipProfile: { required: v => Boolean(v?.id) }
    }
  },
  { productCitizenshipProfile },
  { $autoDirty: true, $scope: null }
);

const citizenshipProfileValidation = computedValidation(
  v$.value.productCitizenshipProfile.citizenshipProfile,
  { required: "Required" }
);

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid || loading.value) return;
  loading.value = true;
  try {
    const createdProfile = await addProductCitizenshipProfile(
      ProductCitizenshipProfileToCreateRequest(productCitizenshipProfile.value)
    );
    dialog.closeDialog({ productCitizenshipProfile: createdProfile });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: `Unable to create citizenship profile: ${parseErrorMessage(
        error
      )}`,
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}
async function getCitizenshipProfiles() {
  try {
    await cache.getCitizenshipProfiles();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getCitizenshipProfiles();
</script>
