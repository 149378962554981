<template>
  <v-sheet
    rounded
    class="pa-2 mb-5"
    :class="{
      'error-sheet-outlined':
        errorMessages.length && !activeField.coordinates.length
    }"
  >
    <v-row class="px-6 py-3" align="center">
      <h3 style="text-align: left; height: 20px">
        <v-row class="pa-3" align="center"> Field Options </v-row>
      </h3>
      <v-spacer />
      <v-btn icon @click="addFormOption" v-if="!readonly">
        <v-icon>{{ mdiPlus }}</v-icon>
      </v-btn>
    </v-row>
    <!--  -->
    <v-row v-if="useOptions">
      <v-col
        v-for="(fieldOption, index) in activeField.coordinates[0].options"
        cols="12"
        :key="index"
      >
        <form-mapping-form-aql-field-option
          :text.sync="fieldOption.text"
          :value.sync="fieldOption.value"
          :error-messages="errorMessages"
          :success="success"
          :readonly="readonly"
          :show-text="showText"
          :readonly-virtual="readonlyVirtual"
          @click="currentCoordinateIndex = index"
          @delete="deleteOption(index)"
          @update="$emit('input')"
          @update:virtual="handleVirtual(index, $event)"
        />
      </v-col>
    </v-row>
    <!-- Radio/Not on PDF Options -->
    <transition-group
      v-else
      name="flip-list"
      mode="out-in"
      tag="div"
      class="row"
    >
      <v-col
        v-for="(fieldOption, index) in activeField.coordinates"
        cols="12"
        :key="fieldOption.uuid"
        :order="fieldOption.order"
      >
        <form-mapping-form-aql-field-option
          :text.sync="fieldOption.text"
          :value.sync="fieldOption.value"
          :virtual.sync="fieldOption.virtual"
          :error-messages="errorMessages"
          :success="success"
          :readonly="readonly"
          :focused="currentCoordinateIndex === index"
          :show-text="showText"
          :readonly-virtual="readonlyVirtual"
          :can-be-virtual="radioCanBeVirtual"
          @click="currentCoordinateIndex = index"
          @delete="deleteOption(index)"
          @update="$emit('input')"
          @update:virtual="handleVirtual(index, $event)"
        />
      </v-col>
    </transition-group>
  </v-sheet>
</template>

<script>
import { mdiPlus } from "@mdi/js";
import { useFormMappingStore } from "@/stores/form-mapping";
import { storeToRefs } from "pinia";
import {
  AqlField,
  AqlFieldOption,
  NOT_ON_PDF_OPTION_TYPE,
  RADIO_OPTION_DIAMETER,
  RADIO_OPTION_TYPE,
  CHECKBOX_OPTION_TYPE,
  TEXT_OPTION_TYPE,
  CHECKBOX_SIDE_LENGTH
} from "@/factories/FormMappingFactory";

import FormMappingFormAqlFieldOption from "@/components/form-mapping/FormMappingFormAqlFieldOption.vue";

export default {
  components: {
    FormMappingFormAqlFieldOption
  },
  props: {
    formId: [String, Number],
    readonly: Boolean,
    showText: Boolean,
    success: Boolean,
    errorMessages: Array,
    readonlyVirtual: Boolean
  },
  setup({ formId }) {
    const formStore = useFormMappingStore(formId);
    const { currentCoordinateIndex, activeField, currentPage, viewport } =
      storeToRefs(formStore);
    return {
      currentCoordinateIndex,
      activeField,
      currentPage,
      viewport,
      mdiPlus
    };
  },
  computed: {
    useOptions() {
      return this.activeField.pdfFieldType === TEXT_OPTION_TYPE;
    },
    radioCanBeVirtual() {
      return this.activeField.pdfFieldType !== NOT_ON_PDF_OPTION_TYPE;
    }
  },
  created() {
    if ([RADIO_OPTION_TYPE].includes(this.activeField.quoteAndApplyType)) {
      const isValid = this.validateRadio();
      if (!isValid) this.initializeRadioOptions();
    }
    // check if radio
    // check if select
  },
  methods: {
    validateRadio() {
      const coordinates = this.activeField.coordinates;
      if (coordinates.length !== 2) return false;

      const yesOption = coordinates.find(({ value }) => value === "1");
      const noOption = coordinates.find(({ value }) => value === "0");

      return Boolean(yesOption && noOption);
    },
    initializeRadioOptions() {
      if (this.useOptions) {
        this.activeField.coordinates[0].options.splice(
          0,
          this.activeField.coordinates[0].options.length
        );
      } else {
        this.activeField.coordinates.splice(
          0,
          this.activeField.coordinates.length
        );
      }
      this.addFormOption({ value: "1", text: "Yes" });
      this.addFormOption({ value: "0", text: "No" });
    },
    handleVirtual(index, value) {
      if (value) return;
      const fieldOption = this.activeField.coordinates[index];
      if (fieldOption.x) return;
      const copyFromIndex = index === 0 ? 1 : index - 1;
      const copyFrom = this.activeField.coordinates[copyFromIndex];
      this.activeField.coordinates[index].x =
        copyFrom.x + RADIO_OPTION_DIAMETER + 5;

      this.activeField.coordinates[index].y = copyFrom.y;
      this.activeField.coordinates[index].page = copyFrom.page;
    },
    addFormOption({ text = null, value = null }) {
      if (this.useOptions) {
        this.activeField.coordinates[0].options.push(
          new AqlFieldOption({ text, value })
        );
        return;
      }
      const optionCount = this.activeField.coordinates?.length;

      const aqlField = new AqlField({
        isFieldOption: true,
        virtual: ![CHECKBOX_OPTION_TYPE, RADIO_OPTION_TYPE].includes(
          this.activeField.pdfFieldType
        ),
        text,
        value
      });
      if (
        [CHECKBOX_OPTION_TYPE, RADIO_OPTION_TYPE].includes(
          this.activeField.pdfFieldType
        )
      ) {
        aqlField.x = 10;
        aqlField.y = this.viewport.height - 10;
        aqlField.page = this.currentPage;
        if (optionCount) {
          const { coordinates } = this.activeField;
          const lastPositionedCoordinate = [...coordinates]
            .reverse()
            .find(({ virtual }) => !virtual);

          let shapeWidth = RADIO_OPTION_DIAMETER;
          if (this.activeField.pdfFieldType === CHECKBOX_OPTION_TYPE) {
            shapeWidth = CHECKBOX_SIDE_LENGTH;
          }

          if (lastPositionedCoordinate) {
            aqlField.x = lastPositionedCoordinate.x + shapeWidth + 5;
            aqlField.y = lastPositionedCoordinate.y;
            aqlField.page = lastPositionedCoordinate.page;
          }
        }
      }

      this.activeField.coordinates.push(aqlField);
      this.currentCoordinateIndex = this.activeField.coordinates.length - 1;

      this.$emit("input");
    },
    deleteOption(index) {
      if (this.useOptions) {
        this.activeField.coordinates[0].options.splice(index, 1);
      } else {
        this.activeField.coordinates.splice(index, 1);
        this.currentCoordinateIndex = this.activeField.coordinates.length - 1;
      }
      this.$emit("input");
    }
  }
};
</script>

<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}
</style>
