<template>
  <v-card>
    <v-card-title>
      <template v-if="createNew"> Create New User </template>
      <template v-else> Add Login </template>
    </v-card-title>
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="12" v-if="createNew">
          <basic-email-input
            v-model="email"
            data-lpignore="true"
            data-testid="new-login-email"
            :prepend-inner-icon="mdiEmail"
            label="User Email"
            dense
            outlined
            persistent-hint
            validate-on-created
            hint=" "
            :success="emailValidation.success"
            :error-messages="emailValidation.errorMessages"
            @valid="emailIsValid = $event"
          >
            <template #message="{ message }">
              <v-row class="pa-3">
                {{ message }}
                <v-spacer />
                <slot name="message-inner" />
                <a @click="createNew = false" data-testid="toggle-user-search">
                  User already exists?
                </a>
              </v-row>
            </template>
          </basic-email-input>
        </v-col>
        <v-col cols="12" v-else>
          <user-search
            v-model="user"
            data-testid="existing-user-search"
            show-message
            include-new
            :success="userValidation.success"
            :error-messages="userValidation.errorMessages"
            @create-new="handleCreateNew"
          >
            <template #message-inner>
              <a @click="createNew = true" data-testid="toggle-new-user">
                Can't find User?
              </a>
            </template>
          </user-search>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="text-none"
        outlined
        color="grey"
        :loading="loading"
        @click="dialog.closeDialog()"
      >
        Cancel</v-btn
      >
      <v-btn
        color="primary"
        class="text-none"
        data-testid="create-login"
        :loading="loading"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import UserSearch from "@/components/shared/UserSearch.vue";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { computed, ref, defineProps } from "vue";
import useVuelidate from "@vuelidate/core";
import { mdiEmail } from "@mdi/js";

const props = defineProps({
  loginableId: {
    type: Number,
    required: true
  },
  loginableType: {
    type: String,
    required: true
  },
  func: {
    type: Function,
    required: true
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const user = ref(null);
const loading = ref(false);
const createNew = ref(false);
const email = ref("");
const emailIsValid = ref(null);

const v$ = useVuelidate(
  {
    email: {
      required: v => Boolean(!createNew.value || v),
      isValid: () => Boolean(!createNew.value || emailIsValid.value)
    },
    user: {
      required: v => Boolean(createNew.value || v?.id)
    }
  },
  {
    user,
    email
  },
  {
    $autoDirty: true,
    $scope: false
  }
);

const userValidation = computed(() => {
  const model = v$.value.user;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

const emailValidation = computed(() => {
  const model = v$.value.email;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "isValid", message: "Invalid Email" }
  ]);
});

function handleCreateNew(newEmail) {
  createNew.value = true;
  email.value = newEmail;
}

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  snackbar.showInfoSnackbar({
    message:
      "Adding login. Click the Send Forgot Password Email button to send this login an email.",
    timeout: 8000
  });

  let params = {
    login: {
      loginable_id: props.loginableId,
      loginable_type: props.loginableType,
      user_attributes: undefined,
      user_id: undefined
    }
  };

  if (createNew.value) {
    params.login.user_attributes = { email: email.value };
  } else {
    params.login.user_id = user.value.id;
  }

  loading.value = true;
  try {
    const login = await props.func(params);
    dialog.closeDialog({ login });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: `Unable to add login. ${parseErrorMessage(error)}`,
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}
</script>
