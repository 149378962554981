<template>
  <v-data-table
    data-testid="smoker-charts-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
    :options="table.options.value"
    :footer-props="footerProps"
    :search="search"
  >
    <template #top>
      <v-row class="ma-0">
        <span class="text-h5"> Smoker Charts </span>
        <v-spacer />
        <v-text-field
          data-lpignore="true"
          label="Search"
          data-testid="smoker-charts-table-search"
          :prepend-inner-icon="mdiMagnify"
          v-model="search"
          outlined
          hide-details
          dense
        />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              data-testid="smoker-charts-table-refresh"
              :disabled="table.loading.value"
              @click="getData"
            >
              <v-icon> {{ mdiRefresh }} </v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
    </template>

    <template #[`item.actions`]="{ item }">
      <template v-if="isGroupFour">
        <v-btn
          data-testid="edit-chart"
          icon
          color="accent"
          @click="editSmokerChart(item.additional)"
        >
          <v-icon>{{ mdiPencil }}</v-icon>
        </v-btn>
        <v-btn icon color="orange" @click="toggleCompleteness(item.additional)">
          <v-icon v-if="item.additional.complete" data-testid="is-complete">
            {{ mdiCheckDecagram }}
          </v-icon>
          <v-icon v-else data-testid="is-incomplete">
            {{ mdiDecagramOutline }}
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        v-if="item.additional.documentUrl"
        icon
        data-testid="download-document"
        color="primary"
        :href="item.additional.documentUrl"
      >
        <v-icon>{{ mdiDownload }}</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import UploadDocumentDialog from "@/dialogs/UploadDocumentDialog.vue";
import { parseErrorMessage } from "@/util/helpers";
import {
  getSmokerCharts,
  updateCompleteness,
  uploadSmokerChartDocument
} from "@/api/smoker-charts.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { computed, markRaw, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { getDocumentUrl } from "@/api/documents.service";
import TableOptions from "@/classes/data-table/TableOptions";
import {
  mdiMagnify,
  mdiRefresh,
  mdiPencil,
  mdiCheckDecagram,
  mdiDecagramOutline,
  mdiDownload
} from "@mdi/js";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const search = ref("");

const user = useUserStore();
const { isGroupFour } = storeToRefs(user);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrierName",
      map: "carrierName",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  ],
  options: new TableOptions(["name"], [false]),
  getData: async () => {
    const items = await getSmokerCharts();
    const mapped = items.map(v => ({
      ...v,
      documentUrl: v.documentUid ? getDocumentUrl(v.documentUid) : null
    }));
    return { items: mapped };
  }
});

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(
      table.options.value,
      table.items.value.length
    ),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

async function toggleCompleteness({ id, name, complete }) {
  const toggledValue = !complete;

  const res = await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Mark ${name} as ${toggledValue ? "Complete" : "Incomplete"}`,
    subtitle: "Please confirm that you are intentionally making this change",
    showErrorMessage: true,
    func: () => updateCompleteness(id, toggledValue)
  });
  if (res?.confirm) getData();
}

async function editSmokerChart({ id }) {
  if (!this.isGroupFour) return;
  dialog
    .showDialog({
      component: markRaw(UploadDocumentDialog),
      uploadFunction: ({ file }) => uploadSmokerChartDocument(id, file),
      single: true
    })
    .then(document => {
      if (!document) return;
      this.getData();
    });
}

getData();
</script>
