<template>
  <v-card>
    <v-card-title>{{ alias.id ? "Edit" : "Create" }} Alias</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="alias.engine"
            data-testid="alias-engine"
            label="Engine"
            dense
            outlined
            clearable
            auto-select-first
            :loading="engines.loading"
            :items="engines.items"
            :success="Boolean(alias.engine)"
            :disabled="saving"
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="alias.includedStates"
            data-testid="alias-included-states"
            auto-select-first
            label="Included States"
            multiple
            dense
            outlined
            clearable
            :disabled="saving"
            :items="allStates"
            :success="includedStatesValidation.success"
            :error-messages="includedStatesValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="alias.excludedStates"
            data-testid="alias-excluded-states"
            label="Excluded States"
            auto-select-first
            multiple
            dense
            outlined
            clearable
            :items="allStates"
            :success="excludedStatesValidation.success"
            :error-messages="excludedStatesValidation.errorMessages"
            :disabled="saving"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="alias.parameters"
            data-testid="alias-parameters"
            outlined
            dense
            label="Parameters (JSON)"
            :success="parametersValidation.success"
            :error-messages="parametersValidation.errorMessages"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :disabled="saving"
        @click="dialog.closeDialog()"
      >
        Close
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        data-testid="save-alias"
        :loading="saving"
        @click="saveAlias"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { createAlias, updateAlias } from "@/api/products.service";
import { states } from "@/data/states";
import { ProductAlias, ProductAliasToRequest } from "@/factories/Product";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { computed, ref, defineProps } from "vue";
import useVuelidate from "@vuelidate/core";
import { useSearchItemsCache } from "@/stores/search-items-cache";
import { storeToRefs } from "pinia";
const allStates = states.map(({ text, full }) => ({ text: full, value: text }));
const props = defineProps({
  productId: { type: Number, required: true },
  value: {
    type: Object,
    default: () => ({ parameters: '{"name": "replace-me"}' })
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const cache = useSearchItemsCache();
const { engines } = storeToRefs(cache);

const alias = ref(ProductAlias(props.value));
const saving = ref(false);

const v$ = useVuelidate(
  {
    alias: {
      includedStates: {
        cannotIntersectStates: () => !intersectingStates.value
      },
      excludedStates: {
        cannotIntersectStates: () => !intersectingStates.value
      },
      parameters: {
        hasBeenReplaced: val => val !== '{"name": "replace-me"}',
        validJson: val => {
          try {
            JSON.parse(val);
            return true;
          } catch (e) {
            return false;
          }
        }
      }
    }
  },
  { alias },
  { $autoDirty: true, $scope: null }
);

const intersectingStates = computed(() => {
  const allStates = [
    ...alias.value.includedStates,
    ...alias.value.excludedStates
  ];
  const stateSet = new Set(allStates);
  return stateSet.size !== allStates.length;
});

const parametersValidation = computedValidation(v$.value.alias.parameters, {
  validJson: "Invalid JSON",
  hasBeenReplaced: "Please replace the default JSON"
});
const includedStatesValidation = computedValidation(
  v$.value.alias.includedStates,
  { cannotIntersectStates: "Must not intersect with Included States" }
);
const excludedStatesValidation = computedValidation(
  v$.value.alias.excludedStates,
  { cannotIntersectStates: "Must not intersect with Excluded States" }
);

async function saveAlias() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  saving.value = true;
  try {
    if (alias.value.id) {
      await updateAlias(
        props.productId,
        alias.value.id,
        ProductAliasToRequest(alias.value)
      );
    } else {
      await createAlias(props.productId, ProductAliasToRequest(alias.value));
    }
    dialog.closeDialog({ alias: alias.value });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

async function getEngines() {
  try {
    await cache.getEngines();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getEngines();
</script>
