<template>
  <v-data-table
    must-sort
    data-testid="missing-product-aliases-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
    :options="table.options.value"
  >
    <template #top>
      <v-row class="ma-0" align="center">
        <h1 class="text-h5">Missing Product Aliases</h1>
        <v-spacer />
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              data-testid="missing-product-aliases-table-refresh"
              @click="getData"
            >
              <v-icon>{{ mdiRefresh }}</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
    </template>

    <template #[`item.actions`]="{ item }">
      <v-btn
        color="accent"
        data-testid="edit"
        icon
        @click="applyAlias(item.additional)"
      >
        <v-icon> {{ mdiPencil }} </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script setup>
import ApplyMissingProductAliasDialog from "@/components/products/dialogs/ApplyMissingProductAliasDialog.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { parseErrorMessage } from "@/util/helpers";
import { fetchMissingAliases } from "@/api/engines.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTable } from "@/composables/table.composable";
import { mdiRefresh, mdiPencil } from "@mdi/js";
import { markRaw } from "vue";
import { useDialogStore } from "@/stores/dialog";

const dialog = useDialogStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Carrier Name",
      value: "carrierName",
      map: "carrierName",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Plan Name",
      value: "planName",
      map: "planName",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Type",
      value: "type",
      map: "type",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  ],
  getData: fetchMissingAliases,
  options: new TableOptions(["carrierName"], [false])
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function applyAlias(item) {
  dialog.showDialog({
    component: markRaw(ApplyMissingProductAliasDialog),
    planName: item.planName.trim()
  });
}

getData();
</script>
