<template>
  <v-card class="ma-0">
    <v-card-title class="text-h5">Create a New Case</v-card-title>
    <v-divider />
    <!-- Agent and Carrier-->
    <v-card flat tile>
      <v-card-title class="text-h6">Carrier and Advisors </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <carrier-search
              v-model="newCase.carrier"
              outlined
              dense
              label="Carrier"
              placeholder="Search Carriers"
              data-testid="carrier"
              :success="carrierValidation.success"
              :error-messages="carrierValidation.errorMessages"
              @change="getProducts"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              auto-select-first
              v-model="newCase.product"
              :prepend-inner-icon="mdiCube"
              outlined
              dense
              return-object
              placeholder="Select Product"
              label="Product"
              item-text="name"
              data-testid="product"
              :disabled="!products.length"
              :loading="loadingProducts"
              :items="products"
              :success="productValidation.success"
              :error-messages="productValidation.errorMessages"
            />
          </v-col>
        </v-row>
        <case-create-advisor-item
          v-for="(advisor, index) in newCase.room"
          v-model="newCase.room[index]"
          :data-testid="`advisor-${index}`"
          :carrier="carrier"
          :product="product"
          :key="advisor.key"
          @delete-advisor="deleteAdvisor(advisor.key)"
        />

        <v-btn
          data-testid="add-advisor"
          color="accent"
          class="text-none"
          @click="addAdvisor"
        >
          <v-icon>{{ mdiPlus }}</v-icon> Add Advisor
        </v-btn>
      </v-card-text>
    </v-card>
    <v-divider />
    <!--Contract Parties and Roles-->
    <v-card flat tile>
      <v-card-title class="text-h6">Contract Parties and Roles</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col
            v-for="(contractParty, index) in newCase.contractParties"
            :key="contractParty.key"
            cols="12"
            rounded
          >
            <case-create-contract-party-item
              v-model="newCase.contractParties[index]"
              :data-testid="`contract-party-${index}`"
              :product-line="productLine"
              :insured="insured"
              @delete-contract-party="deleteParty(contractParty.key)"
            />
          </v-col>

          <v-col cols="12">
            <v-btn
              data-testid="add-contract-party"
              color="accent"
              class="text-none"
              @click="addContractParty"
            >
              <v-icon>{{ mdiPlus }}</v-icon>
              Add Another Contract Party
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider />
    <!-- Policy Details -->
    <v-card flat tile>
      <v-card-title class="text-h6">Policy Details</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="newCase.policyNumber"
              outlined
              dense
              data-testid="policy-number"
              data-lpignore="true"
              :prepend-inner-icon="mdiPound"
              label="Policy Number"
              :success="policyNumberValidation.success"
              :error-messages="policyNumberValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              auto-select-first
              v-model="newCase.status"
              outlined
              dense
              data-testid="status"
              :prepend-inner-icon="mdiListStatus"
              placeholder="Choose Status"
              label="Status"
              :items="CASE_STATUSES"
              :success="statusValidation.success"
              :error-messages="statusValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              auto-select-first
              v-model="newCase.state"
              outlined
              dense
              data-testid="state"
              :prepend-inner-icon="mdiSignRealEstate"
              placeholder="Choose State"
              label="State"
              :items="states"
              :success="stateValidation.success"
              :error-messages="stateValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="isLife">
            <div class="checkbox-width">
              <v-checkbox
                v-model="newCase.temporaryInsurance"
                dense
                class="mt-1"
                label="Temporary Insurance"
                data-testid="temporary-insurance"
                :success="!!newCase.temporaryInsurance"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6" v-if="canHaveLtcRider">
            <div class="checkbox-width">
              <v-checkbox
                v-model="newCase.ltcRider"
                dense
                class="mt-1"
                label="LTC Rider"
                data-testid="ltc-rider"
                :success="!!newCase.ltcRider"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <date-input
              v-model="newCase.applicationSignDate"
              class="mt-1"
              dense
              data-testid="application-sign-date"
              :success="
                applicationSignDateValidation.success &&
                !!newCase.applicationSignDate
              "
              :error-messages="applicationSignDateValidation.errorMessages"
              :label="`${
                requiresApplicationSignDate
                  ? 'Application Sign Date'
                  : 'Application Sign Date (optional)'
              }`"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-input
              v-model="newCase.submittedDate"
              class="mt-1"
              dense
              data-testid="submitted-date"
              :success="
                submittedDateValidation.success && !!newCase.submittedDate
              "
              :error-messages="submittedDateValidation.errorMessages"
              :label="`${
                requiresSubmittedDate
                  ? 'Submitted Date'
                  : 'Submitted Date (optional)'
              }`"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-input
              v-model="newCase.approvedDate"
              class="mt-1"
              dense
              data-testid="approved-date"
              :success="
                approvedDateValidation.success && !!newCase.approvedDate
              "
              :error-messages="approvedDateValidation.errorMessages"
              :label="`${
                requiresApprovedDate
                  ? 'Approved Date'
                  : 'Approved Date (optional)'
              }
                `"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-input
              v-model="newCase.issueDate"
              class="mt-1"
              dense
              data-testid="issue-date"
              :success="issueDateValidation.success && !!newCase.issueDate"
              :error-messages="issueDateValidation.errorMessages"
              :label="`${
                requiresIssueDate ? 'Issue Date' : 'Issue Date (optional)'
              }`"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-input
              v-model="newCase.policyDate"
              class="mt-1"
              dense
              data-testid="policy-date"
              :success="policyDateValidation.success && !!newCase.policyDate"
              :error-messages="policyDateValidation.errorMessages"
              :label="`${
                requiresPolicyDate ? 'Policy Date' : 'Policy Date (optional)'
              }`"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider />
    <!-- Financial Details -->
    <v-card flat tile>
      <v-card-title class="text-h6">Financial Details</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <currency-input
              v-model="newCase.premium"
              include-decimals
              outlined
              dense
              data-testid="premium"
              label="Premium"
              :prepend-inner-icon="mdiCurrencyUsd"
              :success="premiumValidation.success"
              :error-messages="premiumValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              auto-select-first
              v-model="newCase.mode"
              :prepend-inner-icon="mdiCalendarWeek"
              outlined
              dense
              placeholder="Choose Mode"
              label="Mode"
              data-testid="mode"
              :items="MODES"
              :success="modeValidation.success"
              :error-messages="modeValidation.errorMessages"
            />
          </v-col>

          <template>
            <v-col v-if="showBenefitAmount" cols="12" md="6">
              <currency-input
                v-model="newCase.benefitAmount"
                include-decimals
                outlined
                dense
                data-testid="benefit-amount"
                :prepend-inner-icon="mdiCurrencyUsd"
                label="Benefit Amount"
                :success="benefitAmountValidation.success"
                :error-messages="benefitAmountValidation.errorMessages"
              />
            </v-col>
            <v-col v-if="showBenefitPeriod" cols="12" md="6">
              <v-autocomplete
                auto-select-first
                v-model="newCase.benefitPeriod"
                :prepend-inner-icon="mdiCalendar"
                label="Benefit Period"
                outlined
                dense
                item-value="text"
                item-text="text"
                data-testid="benefit-period"
                :items="benefitPeriodOptions"
                :success="benefitPeriodValidation.success"
                :error-messages="benefitPeriodValidation.errorMessages"
              />
            </v-col>
            <v-col v-if="isLTC" cols="12" md="6">
              <v-autocomplete
                auto-select-first
                v-model="newCase.benefitMode"
                outlined
                dense
                data-testid="benefit-mode"
                :prepend-inner-icon="mdiCurrencyUsd"
                label="Benefit Mode"
                :items="BENEFIT_MODES"
                :success="benefitModeValidation.success"
                :error-messages="benefitModeValidation.errorMessages"
              />
            </v-col>
            <v-col v-if="showEliminationPeriod" cols="12" md="6">
              <v-autocomplete
                auto-select-first
                v-model="newCase.eliminationPeriod"
                outlined
                dense
                data-testid="elimination-period"
                :prepend-inner-icon="mdiCalendar"
                label="Elimination Period"
                :items="ELIMINATION_PERIODS"
                :success="eliminationPeriodValidation.success"
                :error-messages="eliminationPeriodValidation.errorMessages"
              />
            </v-col>
            <v-col v-if="showInflation" cols="12" md="6">
              <v-autocomplete
                auto-select-first
                v-if="isDisability"
                v-model="newCase.inflation"
                outlined
                dense
                :prepend-inner-icon="mdiCalendar"
                label="COLA"
                item-value="value"
                item-text="text"
                data-testid="inflation"
                :items="COLA_OPTIONS"
                :success="inflationValidation.success"
                :error-messages="inflationValidation.errorMessages"
              />
              <v-autocomplete
                auto-select-first
                v-else
                v-model="newCase.inflation"
                outlined
                dense
                :prepend-inner-icon="mdiPercent"
                label="Inflation"
                item-value="value"
                item-text="text"
                data-testid="inflation"
                :items="INFLATION_ITEMS"
                :success="inflationValidation.success"
                :error-messages="inflationValidation.errorMessages"
              />
            </v-col>
            <v-col v-if="isAnnuity" cols="12" md="6">
              <v-autocomplete
                auto-select-first
                v-model="newCase.taxType"
                outlined
                dense
                data-testid="tax-type"
                :prepend-inner-icon="mdiPercent"
                label="Tax Type"
                item-value="text"
                item-text="text"
                :items="TAX_OPTIONS"
                :success="taxTypeValidation.success"
                :error-messages="taxTypeValidation.errorMessages"
              />
            </v-col>
          </template>
          <v-col cols="12" md="6" v-if="isLife">
            <currency-input
              v-model="newCase.faceAmount"
              include-decimals
              outlined
              dense
              data-testid="face-amount"
              :prepend-inner-icon="mdiCurrencyUsd"
              label="Death Benefit"
              :success="faceAmountValidation.success"
              :error-messages="faceAmountValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              auto-select-first
              v-model="newCase.billingPlan"
              outlined
              dense
              data-testid="billing-plan"
              placeholder="Choose Billing Plan"
              :prepend-inner-icon="mdiCash"
              label="Billing Plan"
              :items="BILLING_PLANS"
              :success="billingPlanValidation.success"
              :error-messages="billingPlanValidation.errorMessages"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider />
    <!-- Financial Details -->
    <v-card flat tile>
      <v-card-title class="text-h6">Additional Details</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <currency-input
              v-model="newCase.cashReceived"
              include-decimals
              outlined
              dense
              data-testid="cash-received"
              :prepend-inner-icon="mdiCurrencyUsd"
              label="Cash Received (optional)"
              :success="
                Boolean(newCase.cashReceived || newCase.cashReceived === 0)
              "
            />
          </v-col>

          <v-col cols="12" md="6">
            <currency-input
              v-model="newCase.lumpSum"
              include-decimals
              outlined
              dense
              data-testid="lump-sum"
              :prepend-inner-icon="mdiCurrencyUsd"
              label="Lump Sum (optional)"
              :success="Boolean(newCase.lumpSum || newCase.lumpSum === 0)"
            />
          </v-col>
          <v-col v-if="isAnnuity" cols="12" md="6">
            <currency-input
              v-model="newCase.exchangeAmount"
              include-decimals
              outlined
              dense
              data-testid="exchange-amount"
              :prepend-inner-icon="mdiCurrencyUsd"
              label="Exchange (optional)"
              :success="
                Boolean(newCase.exchangeAmount || newCase.exchangeAmount === 0)
              "
            />
          </v-col>
          <v-col v-else cols="12" md="6">
            <currency-input
              v-model="newCase.transfer"
              include-decimals
              outlined
              dense
              data-testid="transfer"
              label="Transfer (optional)"
              :prepend-inner-icon="mdiCurrencyUsd"
              :success="Boolean(newCase.transfer || newCase.transfer === 0)"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="!isAnnuity">
            <currency-input
              v-model="newCase.targetPremium"
              outlined
              dense
              include-decimals
              data-testid="target-premium"
              label="Target Premium (optional)"
              :prepend-inner-icon="mdiCurrencyUsd"
              :success="
                Boolean(newCase.targetPremium || newCase.targetPremium === 0)
              "
            />
          </v-col>
          <v-col cols="12">
            <file-drag-and-drop
              v-model="newCase.files"
              multiple
              label="Case Documents (optional)"
              data-testid="case-documents"
              :success="Boolean(newCase.files.length)"
              :error-messages="fileValidation.errorMessages"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider />
    <!-- Case Create -->
    <v-card-actions>
      <v-btn
        class="text-none ml-2"
        color="primary"
        data-testid="create-case"
        :loading="creatingCase"
        @click="create"
      >
        <v-icon>{{ mdiPlus }}</v-icon> Create New Case
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import CaseCreateAdvisorItem from "@/components/cases/case-create/CaseCreateAdvisorItem.vue";
import CaseCreateContractPartyItem from "@/components/cases/case-create/CaseCreateContractPartyItem.vue";
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import DateInput from "@/components/shared/DateInput.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import { states } from "@/data/states";
import {
  TAX_OPTIONS,
  COLA_OPTIONS,
  CASE_STATUSES,
  DISABILITY_BENEFIT_PERIODS,
  BILLING_PLANS,
  MODES,
  ELIMINATION_PERIODS,
  CASE_STATUS,
  BENEFIT_MODES
} from "@/data/case-data";
import { INFLATION_ITEMS, BENEFIT_PERIODS } from "@/data/quote-request-items";

import {
  NewCase,
  newCaseToRequest,
  NewCaseAdvisor,
  NewCaseContractParty
} from "@/factories/NewCase";

import { productSearch } from "@/api/search.service";
import { createCase } from "@/api/cases.service";

import {
  computedValidation,
  getRouterLink,
  parseErrorMessage
} from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import { computed, nextTick, ref } from "vue";
import { useRouter } from "@/composables/compatible.composables";
import useVuelidate from "@vuelidate/core";

import {
  mdiCube,
  mdiPlus,
  mdiPound,
  mdiListStatus,
  mdiSignRealEstate,
  mdiCurrencyUsd,
  mdiCalendarWeek,
  mdiCalendar,
  mdiPercent,
  mdiCash
} from "@mdi/js";

useHead({ title: "Create Case" });

const snackbar = useSnackbarStore();
const router = useRouter();

const newCase = ref(
  NewCase({
    room: [NewCaseAdvisor()],
    contractParties: [NewCaseContractParty({ roles: ["Insured"] })]
  })
);

const creatingCase = ref(false);
const loadingProducts = ref(false);
const products = ref([]);

const product = computed(() => newCase.value?.product);
const productLine = computed(() => product.value?.line);
const isLife = computed(() =>
  ["variable_life", "linked_benefit", "life"].includes(productLine.value)
);
const isLinkedBenefit = computed(() => productLine.value === "linked_benefit");
const isLTC = computed(() => productLine.value === "ltc");
const isDisability = computed(() => productLine.value === "disability");
const isAnnuity = computed(() => productLine.value === "annuity");

const showInflation = computed(
  () => isDisability.value || isLTC.value || isLinkedBenefit.value
);
const showBenefitPeriod = computed(
  () => isLTC.value || isDisability.value || isLinkedBenefit.value
);
const showBenefitAmount = computed(
  () => isLTC.value || isDisability.value || isLinkedBenefit.value
);
const showEliminationPeriod = computed(
  () => isLTC.value || isDisability.value || isLinkedBenefit.value
);
const canHaveLtcRider = computed(() =>
  Boolean(newCase.value.product?.ltc_rider)
);
const carrier = computed(() => newCase.value?.carrier);

const insured = computed(() =>
  newCase.value.contractParties.find(value =>
    value.roles.some(role => ["Insured", "Annuitant"].includes(role))
  )
);

const benefitPeriodOptions = computed(() =>
  isDisability.value ? DISABILITY_BENEFIT_PERIODS : BENEFIT_PERIODS
);

const requiresApplicationSignDate = computed(() =>
  [
    CASE_STATUS.SUBMITTED,
    CASE_STATUS.APPROVED,
    CASE_STATUS.AWAITING_REISSUED_POLICY,
    CASE_STATUS.ISSUED,
    CASE_STATUS.AWAITING_DELIVERY_REQUIREMENTS,
    CASE_STATUS.RECEIVED_DELIVERY_REQUIREMENTS,
    CASE_STATUS.ACTIVE,
    CASE_STATUS.ACTIVE_PENDING_REQUIREMENTS
  ].includes(newCase.value.status)
);

const requiresSubmittedDate = computed(() =>
  [
    CASE_STATUS.SUBMITTED,
    CASE_STATUS.APPROVED,
    CASE_STATUS.AWAITING_REISSUED_POLICY,
    CASE_STATUS.ISSUED,
    CASE_STATUS.AWAITING_DELIVERY_REQUIREMENTS,
    CASE_STATUS.RECEIVED_DELIVERY_REQUIREMENTS,
    CASE_STATUS.ACTIVE,
    CASE_STATUS.ACTIVE_PENDING_REQUIREMENTS
  ].includes(newCase.value.status)
);

const requiresApprovedDate = computed(() =>
  [
    CASE_STATUS.APPROVED,
    CASE_STATUS.AWAITING_REISSUED_POLICY,
    CASE_STATUS.ISSUED,
    CASE_STATUS.AWAITING_DELIVERY_REQUIREMENTS,
    CASE_STATUS.RECEIVED_DELIVERY_REQUIREMENTS,
    CASE_STATUS.ACTIVE,
    CASE_STATUS.ACTIVE_PENDING_REQUIREMENTS
  ].includes(newCase.value.status)
);
const requiresIssueDate = computed(() =>
  [
    CASE_STATUS.ISSUED,
    CASE_STATUS.AWAITING_DELIVERY_REQUIREMENTS,
    CASE_STATUS.RECEIVED_DELIVERY_REQUIREMENTS,
    CASE_STATUS.ACTIVE,
    CASE_STATUS.ACTIVE_PENDING_REQUIREMENTS
  ].includes(newCase.value.status)
);
const requiresPolicyDate = computed(() =>
  [CASE_STATUS.ACTIVE, CASE_STATUS.ACTIVE_PENDING_REQUIREMENTS].includes(
    newCase.value.status
  )
);

async function create() {
  const isValid = await v$.value.$validate();
  if (needsInsured.value) {
    snackbar.showErrorSnackbar({
      message: "An Insured is required.",
      timeout: 10000
    });
  }
  if (!isValid) return;
  creatingCase.value = true;
  const createCaseBody = await newCaseToRequest(newCase.value);

  try {
    const id = await createCase({ case: createCaseBody });
    if (router) router.push(getRouterLink("Case", id));
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  } finally {
    creatingCase.value = false;
  }
}

function addAdvisor() {
  newCase.value.room.push(NewCaseAdvisor());
}
function deleteAdvisor(key) {
  const index = newCase.value.room.findIndex(k => k.key === key);
  if (index === -1) return;
  newCase.value.room.splice(index, 1);
}

function deleteParty(key) {
  const index = newCase.value.contractParties.findIndex(k => k.key === key);
  newCase.value.contractParties.splice(index, 1);
}
function addContractParty() {
  newCase.value.contractParties.push(NewCaseContractParty());
}

async function getProducts() {
  await nextTick();
  if (!newCase.value?.carrier?.id) return;
  loadingProducts.value = true;
  try {
    products.value = await productSearch(newCase.value.carrier.id);
    if (!newCase.value.product) return;
    if (products.value.some(p => p?.id === newCase.value.product?.id)) return;

    newCase.value.product = undefined;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingProducts.value = false;
  }
}

const needsInsured = computed(() => !insured.value);

function isValidDate(date) {
  try {
    return isValid(parse(date, "yyyy-MM-dd", new Date()));
  } catch (e) {
    return false;
  }
}

const v$ = useVuelidate(
  {
    newCase: {
      files: {
        minSize: value => {
          return !value?.length || value.every(val => val?.size > 0);
        }
      },
      carrier: { required: Boolean },
      product: { required: Boolean },
      room: {
        required: v => Boolean(v.length)
      },
      contractParties: {
        required: () => !needsInsured.value
      },
      policyNumber: { required: Boolean },
      status: { required: Boolean },
      premium: { required: v => ![null, undefined].includes(v) && v >= 0 },
      state: { required: Boolean },
      mode: { required: Boolean },
      billingPlan: {
        required: Boolean
      },
      taxType: {
        required: value => !isAnnuity.value || Boolean(value)
      },
      benefitAmount: {
        required: value => !showBenefitAmount.value || Boolean(value)
      },
      benefitMode: {
        required: value => !isLTC.value || Boolean(value)
      },
      benefitPeriod: {
        required: value => !showBenefitPeriod.value || Boolean(value)
      },
      eliminationPeriod: {
        required: value => !showEliminationPeriod.value || Boolean(value)
      },
      inflation: {
        required: v => {
          if (!showInflation.value) return true;
          let items = INFLATION_ITEMS;
          if (isDisability.value) items = COLA_OPTIONS;
          return items.some(({ value }) => value === v);
        }
      },
      faceAmount: { required: value => !isLife.value || Boolean(value) },
      applicationSignDate: {
        required: v => !requiresApplicationSignDate.value || isValidDate(v)
      },
      submittedDate: {
        required: v => !requiresSubmittedDate.value || isValidDate(v)
      },
      approvedDate: {
        required: v => !requiresApprovedDate.value || isValidDate(v)
      },
      issueDate: {
        required: v => !requiresIssueDate.value || isValidDate(v)
      },
      policyDate: {
        required: v => !requiresPolicyDate.value || isValidDate(v)
      }
    }
  },
  { newCase },
  { $scope: "new-case", $autoDirty: true }
);

const requiredV = [{ key: "required", message: "Required" }];
const benefitModeValidation = computedValidation(
  v$.value.newCase.benefitMode,
  requiredV
);
const carrierValidation = computedValidation(
  v$.value.newCase.carrier,
  requiredV
);
const productValidation = computedValidation(
  v$.value.newCase.product,
  requiredV
);
const policyNumberValidation = computedValidation(
  v$.value.newCase.policyNumber,
  requiredV
);
const statusValidation = computedValidation(v$.value.newCase.status, requiredV);
const stateValidation = computedValidation(v$.value.newCase.state, requiredV);
const premiumValidation = computedValidation(
  v$.value.newCase.premium,
  requiredV
);
const modeValidation = computedValidation(v$.value.newCase.mode, requiredV);
const benefitAmountValidation = computedValidation(
  v$.value.newCase.benefitAmount,
  requiredV
);
const benefitPeriodValidation = computedValidation(
  v$.value.newCase.benefitPeriod,
  requiredV
);
const eliminationPeriodValidation = computedValidation(
  v$.value.newCase.eliminationPeriod,
  requiredV
);
const inflationValidation = computedValidation(
  v$.value.newCase.inflation,
  requiredV
);
const taxTypeValidation = computedValidation(
  v$.value.newCase.taxType,
  requiredV
);
const faceAmountValidation = computedValidation(
  v$.value.newCase.faceAmount,
  requiredV
);
const billingPlanValidation = computedValidation(
  v$.value.newCase.billingPlan,
  requiredV
);
const applicationSignDateValidation = computedValidation(
  v$.value.newCase.applicationSignDate,
  requiredV
);
const submittedDateValidation = computedValidation(
  v$.value.newCase.submittedDate,
  requiredV
);
const approvedDateValidation = computedValidation(
  v$.value.newCase.approvedDate,
  requiredV
);
const issueDateValidation = computedValidation(
  v$.value.newCase.issueDate,
  requiredV
);
const policyDateValidation = computedValidation(
  v$.value.newCase.policyDate,
  requiredV
);
const fileValidation = computedValidation(v$.value.newCase.files, [
  { key: "minSize", message: "Please reupload your files" }
]);
</script>
