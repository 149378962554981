<template>
  <v-card height="100%" width="100%" flat tile color="section">
    <v-card v-if="!noFields" flat tile :color="isCopied ? 'accent' : 'primary'">
      <v-card-title class="pa-1 white--text justify-space-between">
        <span class="pl-3">
          <slot name="copy" />
          {{ isCopied ? "[COPY]" : null }}
          {{ activeField.verboseDisplayText }}
        </span>
        <v-spacer />
        <span>
          <v-btn
            :disabled="!canGoPrevious"
            class="text-none mr-1 white--text"
            icon
            @click="nextField(-1)"
          >
            <v-icon>{{ mdiChevronLeft }}</v-icon>
          </v-btn>
          <v-btn
            :disabled="!canGoNext"
            class="text-none white--text"
            icon
            @click="nextField(1)"
          >
            <v-icon>{{ mdiChevronRight }}</v-icon>
          </v-btn>
        </span>
      </v-card-title>
    </v-card>
    <v-card
      v-if="isCopyingForm"
      :color="isCopied ? 'accent darken-1' : 'primary darken-1'"
      flat
      tile
      height="30"
    >
      <v-card-subtitle
        v-if="questionsAreLinked"
        class="white--text text-center py-1"
      >
        Questions Are Linked!
      </v-card-subtitle>
    </v-card>
    <pdf-viewer
      :form-id="formId"
      :can-delete="!isCopied && !readonly && pageCount > 1"
      @delete-page="handlePageDelete"
      @page-count="pageCount = $event"
    >
      <template #pdf-overlay>
        <high-dpi-canvas
          v-if="window.width"
          :id="`pdf-overlay-${formId}`"
          :height="window.height"
          :width="window.width"
          :scale="scale"
          @click="handleCanvasClick"
          @redraw="redraw(true)"
          @ready="redraw(true)"
        />
      </template>
      <template #edit-field-overlay>
        <form-mapping-form-aql-pdf-editor
          :readonly="readonly"
          :all-visible="allVisible"
          :form-id="formId"
          :key="currentFieldId"
          :current-page="currentPage"
          @click="handleCanvasClick"
          @active-coordinate="$emit('active-coordinate', $event)"
          @toggle-other-visibility="toggleOtherVisibility"
        />
      </template>
    </pdf-viewer>
  </v-card>
</template>

<script>
import FormMappingFormAqlPdfEditor from "@/components/form-mapping/FormMappingFormAqlPdfEditor.vue";
import PdfViewer from "@/components/shared/PdfViewer.vue";
import HighDpiCanvas from "@/components/shared/HighDpiCanvas.vue";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

import { useFormMappingStore } from "@/stores/form-mapping";
import { mapActions, storeToRefs } from "pinia";
import { ref } from "vue";
import {
  CHECKBOX_OPTION_TYPE,
  CHECKBOX_RADII,
  CHECKBOX_SIDE_LENGTH,
  RADIO_OPTION_RADIUS
} from "@/factories/FormMappingFactory";
import {
  clickedWithinBoundingBox,
  drawCheckbox,
  drawCircle,
  drawRectangle
} from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";

export default {
  components: {
    PdfViewer,
    FormMappingFormAqlPdfEditor,
    HighDpiCanvas
  },
  props: {
    formId: [Number, String],
    isCopyingForm: Boolean,
    vertical: Boolean,
    isCopied: Boolean,
    noFields: Boolean,
    readonly: Boolean
  },
  setup({ formId }) {
    const formStore = useFormMappingStore(formId);

    const {
      fields,
      activeField,
      currentFieldId,
      positionallySortedFields,
      currentCoordinateIndex,
      currentPage,
      viewport,
      window,
      scale,
      otherFields,
      otherActiveField,
      pageCount
    } = storeToRefs(formStore);
    return {
      fields,
      currentFieldId,
      positionallySortedFields,
      activeField,
      currentCoordinateIndex,
      currentPage,
      viewport,
      window,
      scale,
      otherFields,
      otherActiveField,
      pageCount,
      changeActiveField: formStore.changeActiveField,
      deletePage: formStore.deletePage,
      allVisible: ref(true),
      mdiChevronLeft,
      mdiChevronRight
    };
  },
  mounted() {
    let page;
    if (this.activeFieldCoordinates.length) {
      const firstPageValue = this.activeField.coordinates.find(
        ({ virtual, page }) => !virtual && page
      );
      page = firstPageValue.page;
    }
    this.currentPage = page || 1;
  },
  watch: {
    currentPage() {
      this.redraw();
    },
    currentFieldId() {
      this.redraw();
    },
    activeFieldPage() {
      if (this.activeFieldPage === null || !this.activeFieldCoordinates.length)
        return;
      this.currentPage = this.activeFieldPage || 1;
    },
    "activeField.id"() {
      if (!this.activeFieldCoordinates.length) return;
      const firstPageValue = this.activeField.coordinates.find(
        ({ virtual, page }) => !virtual && page
      );
      if (!firstPageValue) return;
      this.currentPage = firstPageValue.page || 1;
    },
    fieldCount() {
      this.redraw();
    }
  },
  computed: {
    questionsAreLinked() {
      if (!this.isCopyingForm) return false;
      if (this.isCopied) {
        return this.activeField.id === this.otherActiveField?.referenceField;
      }
      return this.activeField?.referenceField === this.otherActiveField.id;
    },
    traversableFields() {
      return this.positionallySortedFields
        .filter(({ coordinates }) => Boolean(coordinates?.length))
        .map(val => val.id);
    },
    fieldsOnPage() {
      return this.positionallySortedFields.filter(({ coordinates }) => {
        if (!coordinates?.length) return false;
        return coordinates.some(
          ({ page }) => `${page}` === `${this.currentPage}`
        );
      });
    },
    fieldCount() {
      return this.positionallySortedFields.length;
    },
    activePageRectangles() {
      const rectangles = [];
      for (const fieldOnPage of this.fieldsOnPage) {
        if (!fieldOnPage?.coordinates?.length) return;
        fieldOnPage.coordinates.forEach(
          (
            { x, y, height, width, isFieldOption, virtual },
            coordinateIndex
          ) => {
            if (virtual) return;
            let rectY = this.viewport.height - y;
            if (!isFieldOption) rectY -= height;
            // is copying && !is copied form
            // if the current field is copied, fetch the color based on the copied form's field.by_id[referenceField]
            let color = fieldOnPage.color;

            if (
              this.otherFields.by_id &&
              this.otherFields.by_id[fieldOnPage.referenceField]
            ) {
              color = this.otherFields.by_id[fieldOnPage.referenceField].color;
            }

            rectangles.push({
              x,
              y: rectY,
              width,
              height,
              isFieldOption,
              aql: fieldOnPage,
              color,
              coordinateIndex
            });
          }
        );
      }
      return rectangles;
    },
    activeFieldCoordinates() {
      return this.activeField.coordinates || [];
    },
    activeFieldPage() {
      if (!this.activeFieldCoordinates.length) return null;
      const firstPageValue = this.activeField.coordinates.find(
        ({ virtual, page }) => !virtual && page
      );
      return firstPageValue?.page;
    },
    activeFieldIndex() {
      return this.traversableFields.indexOf(this.currentFieldId);
    },
    canGoNext() {
      return this.activeFieldIndex < this.traversableFields.length - 1;
    },
    canGoPrevious() {
      return this.activeFieldIndex > 0;
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    async handlePageDelete() {
      await this.showDialog({
        component: "ConfirmationDialog",
        title: "Confirm Page Delete",
        subtitle: "The page will reload once complete",
        func: async () => {
          await this.deletePage();
          location.reload();
        }
      });
    },
    toggleOtherVisibility(forcedVisibility = null) {
      if ([true, false].includes(forcedVisibility)) {
        this.allVisible = forcedVisibility;
      } else {
        this.allVisible = !this.allVisible;
      }
      this.redraw(this.allVisible);
    },
    nextField(offset = 1) {
      if (!this.canGoNext && offset > 0) return;
      if (!this.canGoPrevious && offset < 0) return;

      const nextIndex = this.activeFieldIndex + offset;
      this.changeActiveField(this.traversableFields[nextIndex]);
      const firstPageValue = this.activeField.coordinates.find(
        ({ virtual, page }) => !virtual && page
      );
      this.currentPage = firstPageValue.page || 1;
      this.redraw();
    },
    drawAllBoxesOnPage(ctx) {
      const drawnParents = [];
      this.activePageRectangles.forEach(
        ({ x, aql, isFieldOption, y, width, height, color }) => {
          const isActive = aql.id === this.activeField.id;
          let isActiveParent = false;
          let drawParentText = false;
          if (this.activeField.parentQuestion) {
            isActiveParent =
              `${aql.id}` === `${this.activeField?.parentQuestion}`;
          }
          if (isActiveParent && !drawnParents.includes(aql.id)) {
            drawParentText = true;
            drawnParents.push(aql.id);
          }
          if (isActive) return;
          const isUnmapped = !aql.applicationQuestion?.id;

          let func = (fillColor, strokeColor, text) => {
            if (!aql.obscureBackground) {
              return drawRectangle(ctx, {
                x,
                y,
                width,
                height,
                text,
                fillColor,
                strokeColor,
                comb: aql.comb
              });
            }
            drawRectangle(ctx, {
              x,
              y,
              width,
              height,
              text,
              fillColor,
              strokeColor
            });
            drawRectangle(ctx, {
              x: x + 4,
              y: y + 4,
              width: width - 8,
              height: height - 8,
              fillColor: "#ffffff",
              strokeColor: "#ffffff"
            });
            drawRectangle(ctx, {
              x,
              y,
              width,
              height,
              text,
              fillColor: "rgba(0,0,0,0)",
              strokeColor,
              comb: aql.comb
            });
          };

          if (aql.pdfFieldType === CHECKBOX_OPTION_TYPE) {
            func = (fillColor, strokeColor, text) =>
              drawCheckbox(ctx, {
                sideLength: CHECKBOX_SIDE_LENGTH,
                radii: CHECKBOX_RADII,
                x,
                y,
                strokeColor,
                fillColor,
                text
              });
          } else if (isFieldOption) {
            func = (fillColor, strokeColor, text) =>
              drawCircle(ctx, {
                radius: RADIO_OPTION_RADIUS,
                x,
                y,
                strokeColor,
                fillColor,
                text
              });
          }

          if (isUnmapped) {
            func("red", "red", "Unmapped");
          } else if (isActiveParent) {
            func(null, color, drawParentText ? "Parent" : null);
          } else {
            func(color, color);
          }
        }
      );
    },
    getCurrentCanvas() {
      return document.getElementById(`pdf-overlay-${this.formId}`);
    },
    handleCanvasClick(e) {
      const el = this.getCurrentCanvas();
      const yRatio = this.viewport.height / el.clientHeight;
      const xRatio = this.viewport.width / el.clientWidth;
      const mouseY = yRatio * e.offsetY;
      const mouseX = xRatio * e.offsetX;
      const index = this.activePageRectangles.findIndex(rect =>
        clickedWithinBoundingBox({
          mouseX,
          mouseY,
          rectX: rect.x,
          rectY: rect.y,
          rectWidth: rect.width,
          rectHeight: rect.height,
          isCircle: rect.isFieldOption,
          radius: RADIO_OPTION_RADIUS
        })
      );
      if (index === -1) return;
      this.changeActiveField(this.activePageRectangles[index].aql.id);
      this.currentCoordinateIndex =
        this.activePageRectangles[index].coordinateIndex;
      this.redraw();
    },
    async redraw(draw = true) {
      const el = this.getCurrentCanvas();
      if (!el) return;

      const ctx = el.getContext("2d");
      ctx.clearRect(0, 0, el.width, el.height);
      if (draw) this.drawAllBoxesOnPage(ctx);
    }
  }
};
</script>
