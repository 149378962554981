<template>
  <v-container>
    <v-card flat>
      <v-card-title class="text-h4 mb-4">
        <v-icon class="mr-2" style="color: inherit" large>
          {{ mdiNewspaper }}
        </v-icon>
        Create Statement
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <payor-search
              v-model="statement.payor"
              autofocus
              label="Payor"
              placeholder="Search Payors"
              data-testid="payor-search"
              :success="payorValidation.success"
              :error-messages="payorValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12">
            <date-input
              v-model="statement.statementDate"
              outlined
              dense
              label="Statement Date"
              :prepend-inner-icon="mdiCalendar"
              data-testid="statement-date"
              :success="statementDateValidation.success"
              :error-messages="statementDateValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6">
            <currency-input
              v-model="statement.premium"
              include-decimals
              include-negative
              outlined
              dense
              label="Premium"
              :prepend-inner-icon="mdiCurrencyUsd"
              data-testid="premium"
              :success="premiumValidation.success"
              :error-messages="premiumValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6">
            <currency-input
              v-model="statement.amount"
              include-decimals
              include-negative
              outlined
              dense
              label="Amount"
              :prepend-inner-icon="mdiCurrencyUsd"
              data-testid="amount"
              :success="amountValidation.success"
              :error-messages="amountValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12">
            <file-drag-and-drop
              v-model="statement.file"
              outlined
              dense
              prepend-icon
              label="Statement"
              placeholder="Please Upload Statement"
              :prepend-inner-icon="mdiPaperclip"
              accept=".csv, .pdf, .png, .jpg, .jpeg, .webp"
              data-testid="statement-file"
              :success="fileValidation.success"
              :error-messages="fileValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12">
            <v-row class="ma-0" align="center" dense>
              <h4
                class="text-h6 mr-3"
                style="width: 165px"
                :class="{
                  'error--text': statementTypeValidation.errorMessages.length
                }"
              >
                Statement Type:
              </h4>
              <v-chip-group
                v-model="statement.statementType"
                :success="statementTypeValidation.success"
                :error-messages="statementTypeValidation.errorMessages"
              >
                <v-chip
                  v-for="type in STATEMENT_TYPES"
                  :data-testid="`statement-type-${type}`"
                  :key="type"
                  :value="type"
                  :color="statement.statementType === type ? 'accent' : null"
                >
                  {{ type }}
                </v-chip>
              </v-chip-group>
              <v-fade-transition>
                <v-col
                  v-if="statementTypeValidation.errorMessages.length"
                  class="error--text pa-0"
                  cols="12"
                >
                  {{ statementTypeValidation.errorMessages.join(" ") }}
                </v-col>
              </v-fade-transition>
            </v-row>
          </v-col>

          <v-fade-transition mode="out-in">
            <v-col v-if="isCsv" cols="12">
              <v-row class="ma-0" align="center" dense>
                <h4
                  class="text-h6 mr-3"
                  style="width: 165px"
                  :class="{
                    'error--text': actionValidation.errorMessages.length
                  }"
                >
                  Import Action:
                </h4>
                <v-chip-group
                  v-model="statement.action"
                  :success="actionValidation.success"
                  :error-messages="actionValidation.errorMessages"
                >
                  <v-chip
                    v-for="{ text, value } in ACTIONS"
                    :data-testid="`action-${value}`"
                    :key="value"
                    :value="value"
                    :color="statement.action === value ? 'accent' : null"
                  >
                    {{ text }}
                  </v-chip>
                </v-chip-group>
                <v-fade-transition>
                  <v-col
                    v-if="actionValidation.errorMessages.length"
                    class="error--text pa-0"
                    cols="12"
                  >
                    {{ actionValidation.errorMessages.join(" ") }}
                  </v-col>
                </v-fade-transition>
              </v-row>
              <v-fade-transition mode="out-in">
                <div v-if="mustConsent" class="mt-4">
                  <span class="mb-0" style="font-size: 16px; line-height: 1.5">
                    Before uploading a CSV file, make sure the following is
                    applied:
                    <ul>
                      <li>
                        Column Headers include <code>policy_number</code>,
                        <code>statement_date</code>, <code>premium</code>,
                        <code>percent</code>, <code>commission_type</code>, and
                        <code>override</code>
                      </li>
                      <li>
                        <code>commission_type</code> should be in a format
                        documented
                        <a
                          style="font-size: 16px; line-height: 1.5"
                          href="https://app.back9ins.com/help-center/articles/36"
                          target="_blank"
                        >
                          here.
                        </a>
                      </li>
                      <li>There must be no line breaks within a cell</li>
                      <li>
                        All dates such as <code>January 30, 2020</code> must be
                        in the format <code>M[M]/D[D]/YYYY</code> e.g.
                        01/30/2020
                      </li>
                      <li>
                        A fractional percentage such as
                        <code>0.01%</code> should be formatted as a number with
                        5 places after the decimal e.g. <code>0.00010</code>
                      </li>
                      <li>
                        Currency values such as <code>$1,000</code> be formatted
                        as numbers without commas and signs e.g.
                        <code>1000</code>
                      </li>
                    </ul>
                  </span>

                  <v-checkbox
                    v-model="canUploadStatement"
                    label="I have read and applied the above text."
                    data-testid="consent-checkbox"
                    :success="canUploadStatementValidation.success"
                    :error-messages="canUploadStatementValidation.errorMessages"
                  />
                </div>
              </v-fade-transition>
              <v-fade-transition mode="out-in">
                <v-row v-if="canProvideOptionalPdf" class="ma-0" dense>
                  <v-col cols="12">
                    <file-drag-and-drop
                      v-model="statement.optionalPdf"
                      outlined
                      dense
                      prepend-icon
                      label="Optional PDF Version"
                      placeholder="a PDF version of the statement"
                      :prepend-inner-icon="mdiPaperclip"
                      accept=".pdf"
                      data-testid="optional-statement-file"
                      :success="Boolean(statement.optionalPdf)"
                    />
                  </v-col>
                </v-row>
              </v-fade-transition>
            </v-col>
          </v-fade-transition>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-4 pb-5">
        <v-btn
          class="text-none"
          color="primary"
          data-testid="create-statement"
          :loading="loading"
          @click="create"
        >
          <v-icon class="mr-1"> {{ mdiPlus }} </v-icon> Create Statement
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script setup>
import { useHead } from "@unhead/vue";
import { computed, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";

import PayorSearch from "@/components/shared/PayorSearch.vue";
import DateInput from "@/components/shared/DateInput.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";

import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";
import { createStatement } from "@/api/statements.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useRouter } from "@/composables/compatible.composables";
import {
  StatementCreate,
  StatementCreateToRequest,
  STATEMENT_TYPES,
  ACTIONS_DICT,
  ACTIONS
} from "@/factories/StatementFactory";
import {
  mdiNewspaper,
  mdiCalendar,
  mdiCurrencyUsd,
  mdiPaperclip,
  mdiPlus
} from "@mdi/js";

useHead({ title: "Create Statement" });
const snackbar = useSnackbarStore();
const loading = ref(false);
const statement = ref(StatementCreate());
const canUploadStatement = ref(false);

const mustConsent = computed(
  () => statement.value.action === ACTIONS_DICT.PAYMENTS_ONLY
);
const isCsv = computed(() => statement.value.file?.name?.endsWith(".csv"));

const canProvideOptionalPdf = computed(() =>
  [ACTIONS_DICT.ENQUEUE, ACTIONS_DICT.PAYMENTS_ONLY].includes(
    statement.value.action
  )
);

const v$ = useVuelidate(
  {
    statement: {
      statementDate: { required: v => Boolean(v) },
      payor: { required: v => Boolean(v) },
      premium: { required: v => Boolean(v) },
      amount: { required: v => Boolean(v) },
      statementType: { required: v => Boolean(v) },
      file: {
        required: v => Boolean(v),
        validSize: val => {
          return val?.size > 0;
        }
      },
      action: { required: v => !isCsv.value || Boolean(v) }
    },
    canUploadStatement: {
      required: v => Boolean(v) || !mustConsent.value
    }
  },
  { statement, canUploadStatement },
  { $autoDirty: true, $scope: false }
);

watch(mustConsent, () => (canUploadStatement.value = false));
watch(isCsv, () => (statement.value.action = null));
watch(canProvideOptionalPdf, () => (statement.value.optionalPdf = null));

const fileValidation = computed(() => {
  return validationComputeV2(v$.value.statement.file, [
    { key: "required", message: "Required" },
    { key: "validSize", message: "Please reupload this file" }
  ]);
});

const statementDateValidation = computed(() => {
  return validationComputeV2(v$.value.statement.statementDate, [
    { key: "required", message: "Required" }
  ]);
});

const payorValidation = computed(() => {
  return validationComputeV2(v$.value.statement.payor, [
    { key: "required", message: "Required" }
  ]);
});

const premiumValidation = computed(() => {
  return validationComputeV2(v$.value.statement.premium, [
    { key: "required", message: "Required" }
  ]);
});

const amountValidation = computed(() => {
  return validationComputeV2(v$.value.statement.amount, [
    { key: "required", message: "Required" }
  ]);
});

const statementTypeValidation = computed(() => {
  return validationComputeV2(v$.value.statement.statementType, [
    { key: "required", message: "Required" }
  ]);
});

const actionValidation = computed(() => {
  return validationComputeV2(v$.value.statement.action, [
    { key: "required", message: "Required" }
  ]);
});

const canUploadStatementValidation = computed(() => {
  return validationComputeV2(v$.value.canUploadStatement, [
    { key: "required", message: "Required" }
  ]);
});

const router = useRouter();

async function create() {
  const valid = v$.value.$validate();
  if (!valid) return;
  loading.value = true;

  try {
    const created = await createStatement(
      StatementCreateToRequest(statement.value)
    );

    if (router)
      if (statement.value.action === ACTIONS_DICT.ENQUEUE) {
        router.push({ name: "BackNinePendingTransactions" });
      } else {
        router.push({ name: "StatementView", params: { id: created.id } });
      }
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: `Unable to create statement ${parseErrorMessage(e)}`,
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}
</script>
