import { BuildChart, setBuildChartFromRequest } from "@/factories/BuildChart";

export function Build(model = {}) {
  return {
    buildChart: BuildChart(model?.buildChart),
    id: model.id,
    inches: model.inches,
    maxWeight: model.maxWeight,
    minWeight: model.minWeight,
    rating: model.rating
  };
}

export function setBuildFromRequest(raw = {}) {
  const build = Build();

  build.id = raw.id;
  build.inches = raw.inches;
  build.maxWeight = raw.max_weight;
  build.minWeight = raw.min_weight;
  build.rating = raw.rating;
  build.buildChart = setBuildChartFromRequest(raw.build_chart);

  return build;
}
