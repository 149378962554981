<template>
  <div>
    <h2 class="mb-3 font-weight-light">Series and Videos</h2>
    <v-row dense>
      <v-col
        cols="12"
        v-for="(series, index) in videoSeries"
        :key="`${series.name}-${index}`"
      >
        <v-card
          class="ma-1 pa-2"
          :to="{
            name: 'HelpCenterSeries',
            params: { id: series.id }
          }"
          :hover="!series.isCompleted"
          :flat="series.isCompleted"
          :color="series.isCompleted ? 'section' : ''"
        >
          <v-row class="ma-0" align="center">
            <div class="px-3">
              <v-icon v-if="series.isCompleted" color="accent">
                {{ mdiCheckboxMarkedCircleOutline }}
              </v-icon>
              <v-icon v-else size="large"> {{ mdiPlayCircle }} </v-icon>
            </div>
            <div>
              <v-card-title class="video-title pb-0">
                {{ series.name }}
                <span v-if="series.isCompleted" class="grey--text ml-1">
                  (Completed)
                </span>
              </v-card-title>
              <v-card-subtitle class="pb-0 mt-0">
                {{ series.subtitle }}
              </v-card-subtitle>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  AVAILABLE_SERIES,
  useWalkthroughComposable
} from "@/composables/series-composable";
import { mdiCheckboxMarkedCircleOutline, mdiPlayCircle } from "@mdi/js";
export default {
  setup() {
    return {
      [AVAILABLE_SERIES.WALKTHROUGH]: useWalkthroughComposable()
    };
  },
  data() {
    return {
      videoSeries: [
        {
          id: this[AVAILABLE_SERIES.WALKTHROUGH].listId,
          name: this[AVAILABLE_SERIES.WALKTHROUGH].listTitle,
          subtitle: this[AVAILABLE_SERIES.WALKTHROUGH].listSubtitle,
          isCompleted: this[AVAILABLE_SERIES.WALKTHROUGH].isCompleted.value
        }
      ],
      mdiCheckboxMarkedCircleOutline,
      mdiPlayCircle
    };
  }
};
</script>
