<template>
  <span class="ma-0 row" :class="{ 'justify-end': end }" dense>
    <slot />
    <v-card outlined v-for="stat in stats" :key="stat.text" class="ma-1">
      <v-card-text v-if="stat.type === 'loading'" class="pa-2">
        <v-progress-circular indeterminate size="18" width="2" />
      </v-card-text>
      <template v-else>
        <v-row class="ma-0 pa-2" align="center">
          <span class="grey--text pr-1"> {{ stat.text }}:</span>
          <span>
            <animated-number
              v-if="stat.type === 'number'"
              :value="stat.value"
              :format-value="number"
              :duration="1000"
              :round="1"
            />
            <animated-number
              v-else-if="stat.type === 'dollar'"
              :value="stat.value"
              :format-value="wholeCurrencyFormatter"
              :duration="1000"
              :round="1"
            />
            <animated-number
              v-else-if="stat.type === 'percent'"
              :value="stat.value"
              :format-value="percentage"
              :duration="1000"
              :round="1"
            />
            <animated-number
              v-else-if="stat.type === 'fractionalPercent'"
              :value="stat.value"
              :format-value="percent"
              :duration="1000"
              :round="1"
            />
            <animated-number
              v-else
              :value="stat.value"
              :duration="1000"
              :round="1"
            />
          </span>
          <v-btn
            v-if="stat.hint"
            icon
            x-small
            @click="$emit('hint', stat)"
            class="ml-1"
          >
            <v-icon x-small>{{ mdiInformation }}</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-card>
  </span>
</template>

<script>
import AnimatedNumber from "@/components/shared/AnimatedNumber.vue";
import { currencyFormat, numberFormat, percentageFormat } from "@/util/helpers";
import { mdiInformation } from "@mdi/js";

export default {
  components: {
    AnimatedNumber
  },
  props: {
    end: Boolean,
    stats: {
      required: false,
      type: Array,
      default: value => (value && value.length ? value : [])
    }
  },
  data() {
    return {
      number: numberFormat,
      percentage: percentageFormat,
      wholeCurrencyFormatter: v => currencyFormat(v, 0),
      mdiInformation
    };
  }
};
</script>
