<template>
  <v-select
    v-model="line"
    :prepend-inner-icon="mdiCube"
    label="Quote Request Type"
    outlined
    dense
    data-testid="line"
    :items="quoteRequestTypes"
    :success="lineValidation.success"
    :error-messages="lineValidation.errorMessages"
  />
</template>

<script setup>
import * as QUOTE_TYPE from "@/constants/quote-types.constants";
import { useQuoteRequestStore } from "@/stores/quote-request";
import { storeToRefs } from "pinia";
import useVuelidate from "@vuelidate/core";
import { computedValidation } from "@/util/helpers";
import { mdiCube } from "@mdi/js";

const quoteRequest = useQuoteRequestStore();
const { line } = storeToRefs(quoteRequest);

const quoteRequestTypes = [
  { text: "Annuity", value: QUOTE_TYPE.ANNUITY },
  { text: "Disability", value: QUOTE_TYPE.DISABILITY },
  { text: "Life", value: QUOTE_TYPE.LIFE },
  { text: "LTC", value: QUOTE_TYPE.LTC }
];

const v$ = useVuelidate(
  { line: { required: v => !!v } },
  { line },
  { $scope: "quote-request" }
);

const lineValidation = computedValidation(v$.value.line, [
  { key: "required", message: "Required" }
]);
</script>
