import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/temporary_insurance_profiles";
export async function getAllTemporaryInsuranceProfiles(carrierId) {
  const params = new URLSearchParams();
  params.append("carrier_id", carrierId);
  const { data } = await getHttpClient().get(baseUrl, {
    params
  });
  return data.temporary_insurance_profiles;
}
