<template>
  <v-img
    v-if="showAvatar && avatar"
    style="border-radius: 5px"
    max-height="100"
    max-width="300"
    contain
    :src="avatar"
    @error="$emit('avatar-error')"
  >
    <div
      v-if="editable"
      style="
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 8px;
      "
    >
      <div
        style="
          width: 200px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        "
      >
        <v-btn @click="editAvatar" elevation="4" icon class="accent">
          <v-icon color="white"> {{ mdiPencil }} </v-icon>
        </v-btn>
      </div>
    </div>
  </v-img>
  <v-row v-else align="center" justify="center">
    <v-col class="px-7">
      <h3>
        No Avatar
        <v-btn color="primary" icon @click="editAvatar(false)" v-if="editable">
          <v-icon>{{ mdiUpload }}</v-icon>
        </v-btn>
      </h3>
    </v-col>
  </v-row>
</template>

<script>
import { mdiPencil, mdiUpload } from "@mdi/js";
import { mapActions } from "pinia";
import { useDialogStore } from "@/stores/dialog";
export default {
  props: {
    showAvatar: {
      default: true,
      type: Boolean
    },
    avatar: String,
    id: Number,
    deletable: Boolean,
    type: String,
    editable: {
      default: true,
      type: Boolean
    }
  },
  data: () => ({ mdiPencil, mdiUpload }),

  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    editAvatar(deletable = true) {
      this.showDialog({
        component: "ChangeAvatarDialog",
        type: this.type,
        id: this.id,
        deletable: deletable && this.deletable
      }).then(res => {
        if (res?.deleted) {
          this.$emit("avatar-delete");
        } else if (res?.updated) {
          this.$emit("avatar-update", res.newUrl);
        }
      });
    }
  }
};
</script>
