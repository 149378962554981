<template>
  <v-card>
    <v-card-title class="px-7">Submit Case</v-card-title>
    <v-card-subtitle class="px-7">
      Securely upload non-Quote & Apply applications.
    </v-card-subtitle>
    <v-card-text>
      <v-col cols="12">
        <v-text-field
          data-lpignore="true"
          label="Client Name"
          v-model="caseSubmission.insuredName"
          :prepend-inner-icon="mdiAccount"
          outlined
          dense
          :success="insuredNameValidation.success"
          :error-messages="insuredNameValidation.errorMessages"
        />
        <file-drag-and-drop
          label="Application Document(s)"
          v-model="caseSubmission.documents"
          :prepend-inner-icon="mdiFileDocumentMultiple"
          multiple
          :success="documentsValidation.success"
          :error-messages="documentsValidation.errorMessages"
        />
        <v-text-field
          data-lpignore="true"
          label="Policy Number (optional)"
          v-model="caseSubmission.policyNumber"
          :prepend-inner-icon="mdiPound"
          outlined
          dense
          success
        />
        <v-textarea
          label="Additional Application Details (optional)"
          v-model="caseSubmission.description"
          :prepend-inner-icon="mdiText"
          outlined
          dense
          success
        />
      </v-col>
    </v-card-text>
    <v-card-actions class="mx-5 pb-6">
      <v-btn
        color="primary"
        class="text-none"
        @click="submitCase"
        :loading="submitting"
      >
        <v-icon>{{ mdiPlus }}</v-icon>
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { createCaseUpload } from "@/api/cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import {
  mdiAccount,
  mdiFileDocumentMultiple,
  mdiPound,
  mdiText,
  mdiPlus
} from "@mdi/js";
export default {
  setup() {
    useHead({ title: "Case Submission" });
  },
  components: {
    FileDragAndDrop
  },
  data: () => ({
    mdiAccount,
    mdiFileDocumentMultiple,
    mdiPound,
    mdiText,
    mdiPlus,
    caseSubmission: {
      insuredName: "",
      policyNumber: "",
      description: "",
      documents: []
    },
    submitting: false
  }),
  computed: {
    isNotCurrentLoginable() {
      return this.caseSubmission.agent?.id !== this.currentLoginableAgent.id;
    },
    insuredNameValidation() {
      const success = !this.$v.caseSubmission.insuredName.$invalid;
      const errorMessages = [];
      if (!this.$v.caseSubmission.insuredName.$dirty)
        return { success, errorMessages };
      else if (!this.$v.caseSubmission.insuredName.required)
        errorMessages.push("Required");
      return { success, errorMessages };
    },
    documentsValidation() {
      const success = !this.$v.caseSubmission.documents.$invalid;
      const errorMessages = [];
      if (!this.$v.caseSubmission.documents.$dirty)
        return { success, errorMessages };
      else if (!this.$v.caseSubmission.documents.required)
        errorMessages.push("Required");
      return { success, errorMessages };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    submitCase() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.submitting = true;
      createCaseUpload({
        description: this.caseSubmission.description,
        documents: this.caseSubmission.documents,
        policyNumber: this.caseSubmission.policyNumber,
        insuredName: this.caseSubmission.insuredName
      })
        .then(() => {
          this.caseSubmission.insuredName = "";
          this.caseSubmission.documents = [];
          this.caseSubmission.policyNumber = "";
          this.caseSubmission.description = "";
          this.showSuccessSnackbar({
            message: "Successfully Submitted Case!",
            timeout: 10000
          });
          this.$v.$reset();
        })
        .catch(e => {
          this.showErrorSnackbar({
            message: parseErrorMessage(e),
            timeout: 10000
          });
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  },
  validations: {
    caseSubmission: {
      insuredName: { required: Boolean },
      documents: { required: v => v.length > 0 }
    }
  }
};
</script>
