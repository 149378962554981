<template>
  <v-row class="ma-0" :key="formKey">
    <v-col>
      <v-row class="ma-0">
        <v-col>
          <h1>Create a Payment</h1>
        </v-col>
      </v-row>
      <v-sheet color="section" rounded class="pa-3 my-2">
        <v-row class="ma-0">
          <v-col cols="12" md="3">
            <statement-search
              :success="!$v.payment.statement.$invalid"
              :error-messages="
                $v.payment.statement.$dirty && !$v.payment.statement.required
                  ? ['Required']
                  : []
              "
              v-model="payment.statement"
            />
          </v-col>
          <v-col cols="12" md="3">
            <policy-search
              :success="!$v.payment.policy.$invalid"
              :error-messages="
                $v.payment.policy.$dirty && !$v.payment.policy.required
                  ? ['Required']
                  : []
              "
              v-model="payment.policy"
            />
          </v-col>
          <v-col cols="12" md="3">
            <currency-input
              include-decimals
              include-negative
              :prepend-inner-icon="mdiCurrencyUsd"
              v-model="payment.premium"
              :success="!$v.payment.premium.$invalid"
              :error-messages="
                $v.payment.premium.$dirty && !$v.payment.premium.required
                  ? ['Required']
                  : []
              "
              outlined
              dense
              label="Premium"
            />
          </v-col>
          <v-col cols="12" md="3">
            <currency-input
              include-decimals
              include-negative
              :prepend-inner-icon="mdiCurrencyUsd"
              v-model="payment.override"
              :success="!$v.payment.override.$invalid"
              :error-messages="
                $v.payment.override.$dirty && !$v.payment.override.required
                  ? ['Required']
                  : []
              "
              outlined
              dense
              label="Override"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :prepend-inner-icon="mdiSack"
              outlined
              dense
              :items="COMMISSION_TYPES"
              label="Commission Type"
              placeholder="Select Commission Type"
              v-model="payment.commissionType"
              :success="!$v.payment.commissionType.$invalid"
              :error-messages="
                $v.payment.commissionType.$dirty &&
                !$v.payment.commissionType.required
                  ? ['Required']
                  : []
              "
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              data-lpignore="true"
              :prepend-inner-icon="mdiPercent"
              outlined
              dense
              label="Percent (Optional)"
              v-model="payment.percent"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :prepend-inner-icon="mdiAccount"
              outlined
              dense
              label="Select Agent"
              :items="agents"
              v-model="payment.agent"
              :success="!$v.payment.agent.$invalid"
              :error-messages="
                $v.payment.agent.$dirty && !$v.payment.agent.required
                  ? ['Required']
                  : []
              "
            />
          </v-col>
        </v-row>
      </v-sheet>
      <v-btn @click="save" :loading="loading" class="text-none" color="primary">
        <v-icon class="mr-1"> {{ mdiPlus }} </v-icon> Create Payment
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import PolicySearch from "@/components/shared/PolicySearch.vue";
import StatementSearch from "@/components/shared/StatementSearch.vue";

import { required } from "vuelidate/lib/validators";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { createPayment } from "@/api/payments.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";

import {
  mdiCurrencyUsd,
  mdiSack,
  mdiPercent,
  mdiAccount,
  mdiPlus
} from "@mdi/js";

const paymentFactory = (model = {}) => {
  return {
    policy: model?.policy || undefined,
    statement: model?.statement || undefined,
    premium: model?.premium || undefined,
    override: model?.override || undefined,
    commissionType: model?.commission_type || "First Year",
    percent: model?.percent || null,
    agent: model?.agent || undefined
  };
};

const paymentRequestFactory = model => {
  return {
    case: model.policy,
    commission_type: model.commissionType,
    premium: model.premium,
    percent: model.percent / 100,
    paid: 1,
    dollar: model.override,
    statement: model.statement,
    statement_date: model.statement.statement_date,
    statement_id: model.statement.id,
    appointment_case_id: model.agent.id,
    payor_id: model.statement.payor.id
  };
};

const COMMISSION_TYPES = [
  "First Year",
  "Excess",
  "Renewal",
  "PUA",
  "Term Rider",
  "Bonus"
];

export default {
  setup() {
    useHead({ title: "Create Payment" });
  },
  data() {
    return {
      loading: false,
      COMMISSION_TYPES,
      payment: paymentFactory(),
      formKey: 0,
      mdiCurrencyUsd,
      mdiSack,
      mdiPercent,
      mdiAccount,
      mdiPlus
    };
  },
  components: {
    PolicySearch,
    StatementSearch,
    CurrencyInput
  },
  computed: {
    carrierId() {
      return this.payment?.policy?.carrier?.id;
    },
    agents() {
      if (!this.payment?.policy?.appointment_cases?.length) {
        return [];
      }
      return this.payment.policy.appointment_cases.map(appointmentCase => {
        return {
          text: `${appointmentCase.owner.name} - ${
            appointmentCase.split_percent * 100
          }% - ${appointmentCase.marketing_manager.name}`,
          value: appointmentCase
        };
      });
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      createPayment({ payment: paymentRequestFactory(this.payment) })
        .then(() => {
          this.showSuccessSnackbar({
            message: "Successfully Created Payment",
            timeout: 5000
          });
          this.$v.$reset();
          this.payment = paymentFactory({ statement: this.payment.statement });
          this.formKey++;
        })
        .catch(e => {
          this.showErrorSnackbar({
            message: `Unable to create payment: ${parseErrorMessage(e)}`,
            timeout: 5000
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  validations: {
    payment: {
      policy: { required },
      statement: { required },
      premium: { required },
      override: { required },
      commissionType: { required },
      agent: { required }
    }
  }
};
</script>
