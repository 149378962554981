<template>
  <v-list-item
    link
    :class="{ 'primary white--text': isActive }"
    @click="emit('click')"
  >
    <v-list-item-avatar>
      <v-icon :class="{ 'white--text': isActive }">
        {{ icon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title :class="{ 'white--text': isActive }">
        {{ questionId }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="!readonly">
      <v-btn
        icon
        :color="isActive ? 'error lighten-1' : 'error'"
        @click="emit('delete')"
        @click.native.stop
      >
        <v-icon> {{ mdiDelete }} </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script setup>
import {
  mdiCalendar,
  mdiDelete,
  mdiDraw,
  mdiExport,
  mdiEyeOff,
  mdiProgressQuestion,
  mdiRadioboxMarked,
  mdiSignatureFreehand,
  mdiText
} from "@mdi/js";
import { defineProps, defineEmits, computed } from "vue";
const props = defineProps({
  questionId: String,
  currentFieldId: String,
  pdfFieldType: String,
  readonly: Boolean
});
const emit = defineEmits(["click", "delete"]);

const isActive = computed(() => props.questionId === props.currentFieldId);
const icon = computed(() => {
  const icons = {
    text: mdiText,
    text_output_verbatim_question: mdiExport,
    radio: mdiRadioboxMarked,
    signature: mdiDraw,
    date_signed: mdiCalendar,
    initials: mdiSignatureFreehand,
    not_on_pdf: mdiEyeOff
  };

  const icon = icons[props.pdfFieldType];
  return icon || mdiProgressQuestion;
});
</script>
