<template>
  <v-card>
    <v-card-title>Add Product Build Chart</v-card-title>
    <v-card-text>
      <v-select
        v-model="productBuildChart.buildChart"
        outlined
        dense
        label="Product Build Chart"
        item-value="additional"
        data-testid="build-chart-select"
        :prepend-inner-icon="mdiCube"
        :items="buildCharts.items"
        :loading="buildCharts.loading"
        v-bind="buildChartValidation"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        color="grey"
        :disabled="creatingBuildChart"
        @click="dialog.closeDialog()"
      >
        Close
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="confirm-button"
        :loading="creatingBuildChart"
        @click="save"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import {
  ProductBuildChart,
  ProductBuildChartToCreateRequest
} from "@/factories/Product";

import { computedValidation, parseErrorMessage } from "@/util/helpers";

import { addProductBuildChart } from "@/api/product-build-charts.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

import { mdiCube } from "@mdi/js";
import { ref, defineProps, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { useSearchItemsCache } from "@/stores/search-items-cache";

const props = defineProps({
  carrierId: {
    type: Number,
    required: true
  },
  productId: {
    type: Number,
    required: true
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const cache = useSearchItemsCache();
const buildChartObj = ref(
  cache.generateBuildChartsByCarrierId(props.carrierId)
);

const buildCharts = computed(() => buildChartObj.value.state);

const productBuildChart = ref(
  ProductBuildChart({ productId: props.productId })
);
const creatingBuildChart = ref(false);
const v$ = useVuelidate(
  {
    productBuildChart: {
      buildChart: { required: v => Boolean(v?.id) }
    }
  },
  { productBuildChart },
  { $scope: null, $autoDirty: false }
);

const buildChartValidation = computedValidation(
  v$.value.productBuildChart.buildChart,
  { required: "Required" }
);

async function getCharts() {
  try {
    buildChartObj.value.fetch();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  creatingBuildChart.value = true;
  try {
    const createdChart = await addProductBuildChart(
      ProductBuildChartToCreateRequest(productBuildChart.value)
    );
    dialog.closeDialog({ productBuildChart: createdChart });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: `Unable to create build chart. ${parseErrorMessage(e)}`,
      timeout: -1
    });
  } finally {
    creatingBuildChart.value = false;
  }
}

getCharts();
</script>
