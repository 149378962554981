var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('h1',{staticClass:"text-h5 black--text"},[_vm._v("Your Quote & Apply Websites")])]),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-spacer'):_vm._e(),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : null}},[_c('v-row',{staticClass:"ma-0"},[_c('v-text-field',{staticClass:"mr-3",attrs:{"data-lpignore":"true","prepend-inner-icon":_vm.mdiMagnify,"label":"Search for a Quote & Apply Website","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('toggle-condensed-view')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDatabase))])],1)]}}])},[_c('span',[_vm._v("Show Expanded View")])])],1)],1)],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0"},[_vm._l((_vm.currentSiteChunk),function(item,index){return [(item)?_c('v-col',{key:item.id,attrs:{"cols":"12","md":"4","lg":"3","sm":"6"}},[(item.id)?_c('v-card',{staticStyle:{"overflow":"hidden","position":"relative"},attrs:{"to":{
                name: 'ApprovedDomains',
                params: { id: item.id }
              },"min-height":"256","outlined":""}},[_c('v-card-title',[_vm._v(_vm._s(item.domain))]),_c('v-card-text',[_vm._v(" Advisor: "+_vm._s(item.advisorName)),_c('br'),_vm._v(" Marketing As: "+_vm._s(item.marketingAsName)),_c('br'),_vm._v(" Start Page: "),_c('start-page-formatter',{attrs:{"value":item.startPage}})],1),(!item.avatarUrl)?_c('div',{staticStyle:{"left":"40px","right":"0","top":"0","bottom":"0","position":"absolute","height":"256px","font-size":"3rem","color":"rgba(0, 0, 0, 0.06)","font-weight":"400","display":"flex","align-items":"center"}},[_vm._v(" "+_vm._s(item.domain)+" ")]):_c('div',{staticStyle:{"left":"0","right":"0","top":"0","bottom":"0","position":"absolute","height":"256px","font-size":"3rem","color":"rgba(0, 0, 0, 0.06)","font-weight":"400"}},[_c('v-img',{staticStyle:{"opacity":"0.06"},attrs:{"src":item.avatarUrl,"height":"100%","width":"100%","contain":""}})],1),_c('v-card-actions',{staticStyle:{"position":"absolute","bottom":"0","right":"0"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none",attrs:{"icon":"","color":"primary","href":`//${item.domain}`,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiOpenInNew))])],1)]}}],null,true)},[_c('span',[_vm._v("Launch Quote & Apply")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none",attrs:{"icon":"","color":"accent","to":{
                        name: 'ApprovedDomains',
                        params: { id: item.id }
                      }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiCog))])],1)]}}],null,true)},[_c('span',[_vm._v("Website Settings")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none",attrs:{"icon":"","color":"orange"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('code', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiCodeTags))])],1)]}}],null,true)},[_c('span',[_vm._v("View Embedded Code")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none",attrs:{"icon":"","color":"error"},on:{"click":function($event){$event.preventDefault();return (() => _vm.deleteDomain(item)).apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDelete))])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Website")])])],1)],1):_vm._e()],1):_c('v-col',{key:`loading-${index}`,attrs:{"cols":"12","md":"4","lg":"3","sm":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1)],1)]}),(_vm.siteChunkCount > 1)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.siteChunkCount,"total-visible":7},model:{value:(_vm.activeSitePage),callback:function ($$v) {_vm.activeSitePage=$$v},expression:"activeSitePage"}})],1):(!_vm.chunksLoading.length && !_vm.dataCardLoadedElements.length)?[(_vm.loaded)?_c('p',{staticClass:"pa-3"},[_vm._v("No results")]):_vm._e()]:_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }