<template>
  <v-autocomplete
    autocomplete="false"
    placeholder="Search Marketing Managers"
    label="Marketing Manager"
    outlined
    item-text="name"
    return-object
    dense
    v-model="marketingManager"
    :prepend-inner-icon="mdiAccountTie"
    no-filter
    hide-no-data
    :hide-details="hideDetails"
    :clearable="clearable"
    :disabled="disabled"
    :search-input.sync="marketingManagerSearchText"
    :success="success"
    :error-messages="errorMessages"
    :items="marketingManagers"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
    @change="$emit('change')"
    @click:clear="$emit('input', {})"
  >
    <template #append-outer>
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import { mdiAccountTie } from "@mdi/js";
import sortBy from "lodash/sortBy";
import { marketingManagerSearch } from "@/api/search.service";
export default {
  props: {
    hideDetails: Boolean,
    clearable: {
      type: Boolean,
      default: true
    },
    success: Boolean,
    errorMessages: Array,
    disabled: Boolean,
    value: [Object, String]
  },
  data() {
    let marketingManagers = [];
    let marketingManager = null;
    if (
      this.value &&
      Object.values(this.value).filter(
        v => Boolean(v) && !(typeof v === "function")
      ).length
    ) {
      marketingManagers.push(this.value);
      marketingManager = this.value;
    }
    return {
      marketingManagerSearchText: "",
      marketingManagers,
      marketingManagerTimer: null,
      marketingManager,
      loading: false,
      mdiAccountTie
    };
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.marketingManager = null;
        this.marketingManagers = [];
        return;
      }
      this.marketingManager = value;
      this.marketingManagers = [value];
    },
    marketingManager(value) {
      this.$emit("input", value);
    },
    marketingManagerSearchText(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (this.marketingManagers.some(val => val?.name === value)) {
        return;
      }

      if (this.marketingManagerTimer) {
        clearTimeout(this.marketingManagerTimer);
      }

      this.marketingManagerTimer = setTimeout(() => {
        this.loading = true;
        marketingManagerSearch(value)
          .then(response => {
            this.marketingManagers = sortBy(
              response.data.marketing_managers,
              "name"
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    clear() {
      this.marketingManager = null;
      this.marketingManagers = [];
    }
  }
};
</script>
