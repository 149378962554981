export default function CarrierFactory(model = {}) {
  return {
    avatarUrl: model?.avatarUrl || null,
    id: model?.id || undefined,
    name: model?.name || undefined,
    products: model?.products || [],
    phone: model?.phone || {},
    supportsInstantAps: model?.supportsInstantAps || false,
    setFromRequest(model = {}) {
      this.avatarUrl = model?.avatar_url;
      this.id = model?.id;
      this.name = model?.name;
      this.products = model?.products;
      this.phone = model?.phone;
      this.supportsInstantAps = model?.instant_aps;
    }
  };
}

export function Carrier(model = {}) {
  return {
    avatarUrl: model?.avatarUrl || null,
    id: model?.id || undefined,
    name: model?.name || undefined,
    products: model?.products || [],
    phone: model?.phone || {},
    supportsInstantAps: model?.supportsInstantAps || false
  };
}

export function setCarrierFromRaw(rawModel = {}) {
  const carrier = Carrier();
  carrier.avatarUrl = rawModel?.avatar_url;
  carrier.id = rawModel?.id;
  carrier.name = rawModel?.name;
  carrier.products = rawModel?.products;
  carrier.phone = rawModel?.phone;
  carrier.supportsInstantAps = rawModel?.instant_aps;
  return carrier;
}
