<template>
  <v-card flat tile color="section">
    <template v-if="!isAnnuity">
      <v-card-title>Charts & Profiles</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="4" sm="6">
            <product-view-build-charts />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <product-view-citizenship-profiles />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <product-view-smoker-charts />
          </v-col>
          <v-col v-if="isTiaEligible" cols="12">
            <product-view-temporary-insurance-profiles />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="my-3" />
    </template>
    <v-card-title>Engine</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-select
            v-model="type"
            data-testid="type"
            outlined
            dense
            label="Type"
            clearable
            :items="productTypes.items"
            :success="Boolean(type)"
            :loading="productTypes.loading"
            @change="updateType"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.type.controller.value"
              />
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="traits"
            data-testid="traits"
            label="Traits"
            outlined
            dense
            clearable
            multiple
            :items="productTraits.items"
            :success="Boolean(traits && traits.length)"
            :loading="productTraits.loading"
            @change="updateTraits"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.traits.controller.value"
              />
            </template>
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="formSet"
            label="Form Set"
            data-testid="form-set"
            dense
            outlined
            clearable
            :disabled="formSets.loading"
            :success="Boolean(formSet)"
            :loading="formSets.loading"
            :items="formSets.items"
            @change="updateFormSet"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-3"
                :controller="savingBuffer.formSet.controller.value"
              />
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="my-3" />
    <v-card-title>Visibility Control</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" lg="4" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="strifeEnabled"
              data-testid="strife-enabled"
              :success="Boolean(strifeEnabled)"
              @change="updateStrifeEnabled"
            >
              <template #label>
                Q&A Enabled
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer.strifeEnabled.controller.value"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          md="6"
          @click="primaryProductLocal = !primaryProductLocal"
        >
          <div class="checkbox-width">
            <v-checkbox
              v-model="primaryProductLocal"
              label="Primary Product"
              data-testid="primary-product"
              readonly
              :success="Boolean(primaryProductLocal)"
            >
              <template #label>
                Primary Product
                <active-save-indicator
                  class="ml-2"
                  :controller="savingBuffer.primaryProduct.controller.value"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="advisorUseOnly"
              data-testid="advisor-use-only"
              :success="Boolean(advisorUseOnly)"
              @change="updateAdvisorUseOnly"
            >
              <template #label>
                Advisor Use Only
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer.advisorUseOnly.controller.value"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="minAge"
            data-lpignore="true"
            label="Min Age"
            data-testid="min-age"
            outlined
            dense
            :success="Boolean(minAge) || minAge === 0"
            @input="updateMinAge"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-3"
                :controller="savingBuffer.minAge.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            data-lpignore="true"
            label="Max Age"
            v-model="maxAge"
            data-testid="max-age"
            outlined
            dense
            :success="Boolean(maxAge) || maxAge === 0"
            @input="updateMaxAge"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-3"
                :controller="savingBuffer.maxAge.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <currency-input
            v-model="minDeathBenefit"
            label="Min Death Benefit"
            data-testid="min-death-benefit"
            outlined
            dense
            :success="Boolean(minDeathBenefit) || minDeathBenefit === 0"
            @input="updateMinDeathBenefit"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-3"
                :controller="savingBuffer.minDeathBenefit.controller.value"
              />
            </template>
          </currency-input>
        </v-col>
        <v-col cols="12" md="6">
          <currency-input
            label="Max Death Benefit"
            v-model="maxDeathBenefit"
            data-testid="max-death-benefit"
            outlined
            dense
            :success="Boolean(maxDeathBenefit) || maxDeathBenefit === 0"
            @input="updateMaxDeathBenefit"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-3"
                :controller="savingBuffer.maxDeathBenefit.controller.value"
              />
            </template>
          </currency-input>
        </v-col>
      </v-row>
      <v-row v-if="isAnnuity" dense>
        <v-col cols="12" lg="4" md="6">
          <currency-input
            v-model="minPremium"
            label="Min Premium"
            data-testid="min-premium"
            outlined
            dense
            :success="Boolean(minPremium) || minPremium === 0"
            @input="updateMinPremium"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-3"
                :controller="savingBuffer.minPremium.controller.value"
              />
            </template>
          </currency-input>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <currency-input
            label="Max Premium"
            v-model="maxPremium"
            data-testid="max-premium"
            outlined
            dense
            :success="Boolean(maxPremium) || maxPremium === 0"
            @input="updateMaxPremium"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-3"
                :controller="savingBuffer.maxPremium.controller.value"
              />
            </template>
          </currency-input>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            v-model="payDuration"
            outlined
            dense
            label="Pay Duration"
            data-testid="pay-duration"
            multiple
            deletable-chips
            small-chips
            persistent-hint
            hint="Leave blank to match for all pay durations"
            :items="PRODUCT_API_PAY_DURATIONS"
            :success="Boolean(payDuration.length)"
            @input="updatePayDuration"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-3"
                :controller="savingBuffer.payDuration.controller.value"
              />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="my-3" />
    <v-card-title>Step Control</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" lg="4" md="6">
          <v-select
            v-model="instantAps"
            data-testid="instant-aps"
            label="Human API"
            outlined
            dense
            success
            :items="INSTANT_APS_OPTIONS"
            @change="updateInstantAps"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-3"
                :controller="savingBuffer.instantAps.controller.value"
              />
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="my-3" />
    <v-card-title>Riders</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" lg="4" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="criticalIllnessRider"
              data-testid="critical-illness-rider"
              :success="Boolean(criticalIllnessRider)"
              @change="updateCriticalIllnessRider"
            >
              <template #label>
                Critical Illness Rider
                <active-save-indicator
                  class="ml-3"
                  :controller="
                    savingBuffer.criticalIllnessRider.controller.value
                  "
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>

        <v-col cols="12" lg="4" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="noCostCriticalIllnessRider"
              data-testid="no-cost-critical-illness-rider"
              :success="Boolean(noCostCriticalIllnessRider)"
              @change="updateNoCostCriticalIllnessRider"
            >
              <template #label>
                No Cost Critical Illness Rider
                <active-save-indicator
                  class="ml-3"
                  :controller="
                    savingBuffer.noCostCriticalIllnessRider.controller.value
                  "
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>

        <v-col cols="12" lg="4" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="chronicIllnessRider"
              data-testid="chronic-illness-rider"
              :success="Boolean(chronicIllnessRider)"
              @change="updateChronicIllnessRider"
            >
              <template #label>
                Chronic Illness Rider
                <active-save-indicator
                  class="ml-3"
                  :controller="
                    savingBuffer.chronicIllnessRider.controller.value
                  "
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>

        <v-col cols="12" lg="4" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="noCostChronicIllnessRider"
              data-testid="no-cost-chronic-illness-rider"
              :success="Boolean(noCostChronicIllnessRider)"
              @change="updateNoCostChronicIllnessRider"
            >
              <template #label>
                No Cost Chronic Illness Rider
                <active-save-indicator
                  class="ml-3"
                  :controller="
                    savingBuffer.noCostChronicIllnessRider.controller.value
                  "
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="ltcRider"
              data-testid="ltc-rider"
              @change="updateLtcRider"
            >
              <template #label>
                LTC Rider
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer.ltcRider.controller.value"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="vitalityRider"
              data-testid="vitality-rider"
              @change="updateVitalityRider"
            >
              <template #label>
                Vitality Rider
                <active-save-indicator
                  class="ml-3"
                  :controller="savingBuffer.vitalityRider.controller.value"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="my-3" />
    <v-card-title>Params</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12"> </v-col>
        <v-col cols="12" lg="4" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="section1035Exchange"
              class="mt-1"
              data-testid="section-1035-exchange"
              @change="updateSection1035Exchange"
            >
              <template #label>
                Section 1035 Exchange
                <active-save-indicator
                  class="ml-3"
                  :controller="
                    savingBuffer.section1035Exchange.controller.value
                  "
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <product-solve-search
            v-model="solves"
            label="Valid Solves"
            data-testid="solves"
            :success="Boolean(solves.length)"
            @input="updateSolves"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.solves.controller.value"
              />
            </template>
          </product-solve-search>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="my-3" />
    <v-card-title>Product Aliases</v-card-title>
    <product-view-alias-table :id="id" />
  </v-card>
</template>

<script setup>
import ProductViewCitizenshipProfiles from "@/components/products/ProductViewCitizenshipProfiles.vue";
import ProductViewTemporaryInsuranceProfiles from "@/components/products/ProductViewTemporaryInsuranceProfiles.vue";
import ProductViewSmokerCharts from "@/components/products/ProductViewSmokerCharts.vue";
import ProductViewBuildCharts from "@/components/products/ProductViewBuildCharts.vue";
import ProductViewAliasTable from "@/components/products/ProductViewAliasTable.vue";
import ProductSolveSearch from "@/components/shared/ProductSolveSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import {
  PRODUCT_API_PAY_DURATIONS,
  INSTANT_APS_OPTIONS
} from "@/factories/Product";

import { useSearchItemsCache } from "@/stores/search-items-cache";
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed, markRaw } from "vue";
import { useProductView } from "@/stores/product-view";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const productView = useProductView();
const cache = useSearchItemsCache();
const {
  id,
  line,
  primaryProduct,
  type,
  traits,
  formSet,
  strifeEnabled,
  advisorUseOnly,
  minAge,
  maxAge,
  minDeathBenefit,
  maxDeathBenefit,
  minPremium,
  maxPremium,
  payDuration,
  instantAps,
  criticalIllnessRider,
  chronicIllnessRider,
  noCostChronicIllnessRider,
  noCostCriticalIllnessRider,
  ltcRider,
  vitalityRider,
  section1035Exchange,
  solves
} = storeToRefs(productView);

const { productTypes, productTraits, formSets } = storeToRefs(cache);

const savingBuffer = {
  engine: useActiveSave(),
  type: useActiveSave(),
  traits: useActiveSave(),
  strifeEnabled: useActiveSave(),
  criticalIllnessRider: useActiveSave(),
  chronicIllnessRider: useActiveSave(),
  noCostChronicIllnessRider: useActiveSave(),
  noCostCriticalIllnessRider: useActiveSave(),
  advisorUseOnly: useActiveSave(),
  ltcRider: useActiveSave(),
  vitalityRider: useActiveSave(),
  section1035Exchange: useActiveSave(),
  formSet: useActiveSave(),
  primaryProduct: useActiveSave(),
  instantAps: useActiveSave(),
  minAge: useActiveSave(),
  maxAge: useActiveSave(),
  minPremium: useActiveSave(),
  maxPremium: useActiveSave(),
  minDeathBenefit: useActiveSave(),
  maxDeathBenefit: useActiveSave(),
  payDuration: useActiveSave(),
  solves: useActiveSave()
};

const isAnnuity = computed(() => line.value === "annuity");
const isTiaEligible = computed(() =>
  ["linked_benefit", "life", "variable_life"].includes(line.value)
);
const primaryProductLocal = computed({
  get() {
    return primaryProduct.value;
  },
  set(val) {
    dialog.showDialog({
      component: markRaw(ConfirmationDialog),
      title: "Primary Product",
      subtitle:
        "Are you sure? Primary Product determines if a product is shown as a product within BOSS and Quote & Apply.",
      func: () => {
        primaryProduct.value = val;
        return productView.updatePrimaryProduct();
      }
    });
  }
});

async function getFormSets() {
  try {
    await cache.getFormSets();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function getCategories() {
  try {
    await cache.getProductMeta();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function updateType() {
  savingBuffer.type.debounceUpdate(productView.updateType);
}
function updateTraits() {
  savingBuffer.traits.debounceUpdate(productView.updateTraits);
}
function updateFormSet() {
  savingBuffer.formSet.debounceUpdate(productView.updateFormSet);
}
function updateStrifeEnabled() {
  savingBuffer.strifeEnabled.debounceUpdate(productView.updateStrifeEnabled);
}
function updateAdvisorUseOnly() {
  savingBuffer.advisorUseOnly.debounceUpdate(productView.updateAdvisorUseOnly);
}
function updateInstantAps() {
  savingBuffer.instantAps.debounceUpdate(productView.updateInstantAps);
}
function updateCriticalIllnessRider() {
  savingBuffer.criticalIllnessRider.debounceUpdate(
    productView.updateCriticalIllnessRider
  );
}
function updateChronicIllnessRider() {
  savingBuffer.chronicIllnessRider.debounceUpdate(
    productView.updateChronicIllnessRider
  );
}
function updateNoCostChronicIllnessRider() {
  savingBuffer.noCostChronicIllnessRider.debounceUpdate(
    productView.updateNoCostChronicIllnessRider
  );
}
function updateNoCostCriticalIllnessRider() {
  savingBuffer.noCostCriticalIllnessRider.debounceUpdate(
    productView.updateNoCostCriticalIllnessRider
  );
}
function updateLtcRider() {
  savingBuffer.ltcRider.debounceUpdate(productView.updateLtcRider);
}
function updateVitalityRider() {
  savingBuffer.vitalityRider.debounceUpdate(productView.updateVitalityRider);
}
function updateSection1035Exchange() {
  savingBuffer.section1035Exchange.debounceUpdate(
    productView.updateSection1035Exchange
  );
}
function updateSolves() {
  savingBuffer.solves.debounceUpdate(productView.updateSolves);
}

function updateMinAge() {
  savingBuffer.minAge.debounceUpdate(productView.updateMinAge);
}
function updateMaxAge() {
  savingBuffer.maxAge.debounceUpdate(productView.updateMaxAge);
}
function updateMinDeathBenefit() {
  savingBuffer.minDeathBenefit.debounceUpdate(
    productView.updateMinDeathBenefit
  );
}
function updateMaxDeathBenefit() {
  savingBuffer.maxDeathBenefit.debounceUpdate(
    productView.updateMaxDeathBenefit
  );
}
function updateMinPremium() {
  savingBuffer.minPremium.debounceUpdate(productView.updateMinPremium);
}
function updateMaxPremium() {
  savingBuffer.maxPremium.debounceUpdate(productView.updateMaxPremium);
}
function updatePayDuration() {
  savingBuffer.payDuration.debounceUpdate(productView.updatePayDuration);
}

getCategories();
getFormSets();
</script>
