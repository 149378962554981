<template>
  <v-card flat>
    <v-row class="ma-0">
      <v-card-title> Citizenship Profiles </v-card-title>
      <v-spacer />
      <v-btn
        class="text-none ma-3"
        color="primary"
        icon
        data-testid="add-product-citizenship-profile"
        @click="add"
      >
        <v-icon>{{ mdiPlus }}</v-icon>
      </v-btn>
    </v-row>
    <v-divider />
    <v-list>
      <v-list-item
        v-for="item in productCitizenshipProfiles"
        :key="item.id"
        data-testid="product-citizenship-profile"
      >
        <v-list-item-content>
          <v-list-item-title>{{
            item.citizenshipProfile.name
          }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            class="text-none"
            icon
            color="error"
            data-testid="remove"
            @click="remove(item)"
          >
            <v-icon>{{ mdiDelete }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="!productCitizenshipProfiles.length">
        <v-list-item-content>
          <v-list-item-title>No Citizenship Profiles</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script setup>
import AddProductCitizenshipProfileDialog from "@/components/products/dialogs/AddProductCitizenshipProfileDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { removeProductCitizenshipProfile } from "@/api/product-citizenship-profile.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiDelete, mdiPlus } from "@mdi/js";
import { markRaw } from "vue";
import { useProductView } from "@/stores/product-view";
import { storeToRefs } from "pinia";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const productView = useProductView();

const { productCitizenshipProfiles, id } = storeToRefs(productView);

async function remove(item) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Remove Product Citizenship Profile",
    subtitle: "This cannot be undone",
    func: async () => {
      await removeProductCitizenshipProfile(item.id);
      const index = productCitizenshipProfiles.value.findIndex(
        v => v.id === item.id
      );
      productCitizenshipProfiles.value.splice(index, 1);

      snackbar.showSuccessSnackbar({
        message: "Product Citizenship has been deleted",
        timeout: 6000
      });
    }
  });
}
async function add() {
  const res = await dialog.showDialog({
    component: markRaw(AddProductCitizenshipProfileDialog),
    productId: id.value
  });

  if (!res?.productCitizenshipProfile) return;
  productCitizenshipProfiles.value.push(res.productCitizenshipProfile);
  snackbar.showSuccessSnackbar({
    message: "Temporary Citizenship has been added",
    timeout: 6000
  });
}
</script>
