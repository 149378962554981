<template>
  <div class="flex-row">
    <v-row v-if="party.id" class="width-full" dense>
      <v-col cols="12" class="pb-0">
        <card :party="party" :type="type" />
      </v-col>
      <v-col cols="12" class="py-0" v-if="party.electronicApplicationId">
        <actions :party="party" />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-card flat tile>
          <v-tabs v-model="activeTab" show-arrows>
            <v-tab
              v-for="tab in tabs"
              :key="`${tab.text}-tab`"
              class="text-none"
            >
              <v-icon class="mr-1"> {{ tab.icon }} </v-icon> {{ tab.text }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab" touchless>
            <v-tab-item
              v-for="tab in tabs"
              :key="`${tab.text}-tab-body`"
              :eager="tab.component === 'PartyChat'"
            >
              <component :type="type" :is="tab.component" :party="party" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-col v-else>
      <v-row dense>
        <v-col>
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col>
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import Card from "@/components/parties/PartyCard.vue";
import PartyChat from "@/components/parties/PartyChat.vue";
import ElectronicApplications from "@/components/parties/PartyElectronicApplications.vue";
import Actions from "@/components/parties/PartyActions.vue";

import { fetchIndividual } from "@/api/individual.service";
import { fetchEntity } from "@/api/entities.service";
import { mapWritableState, mapActions } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiFileDocumentMultiple, mdiMessage } from "@mdi/js";

export default {
  components: {
    Actions,
    Card,
    PartyChat,
    ElectronicApplications
  },
  data() {
    return {
      party: {},
      activeTab: 0
    };
  },
  props: {
    type: String
  },
  setup({ type }) {
    const head = useHead({
      title: (type === "individual" ? "Individual" : "Entity") + " Viewer"
    });
    return { head };
  },
  created() {
    this.createdMethod();
  },
  destroyed() {
    this.breadcrumb = "";
  },
  computed: {
    ...mapWritableState(useInstanceStore, ["breadcrumb"]),
    tabs() {
      if (!this.party.id) return [];

      let tabs = [{ text: "Chat", component: "PartyChat", icon: mdiMessage }];

      if (this.party.electronicApplicationAmount > 0) {
        tabs.push({
          text: "eApps",
          component: "ElectronicApplications",
          icon: mdiFileDocumentMultiple
        });
      }

      return tabs;
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async createdMethod() {
      try {
        if (this.type === "individual") {
          this.party = await fetchIndividual(this.$route.params.id);
        } else {
          this.party = await fetchEntity(this.$route.params.id);
        }
        this.breadcrumb = this.party.name;
        this.head.patch({ title: this.party.name });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    }
  }
};
</script>
