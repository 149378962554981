<template>
  <v-row class="ma-0" dense>
    <v-col cols="12">
      <contract-party-search
        v-model="contractParty.party"
        :prepend-inner-icon="mdiAccount"
        outlined
        dense
        label="Party"
        super-simple
        data-testid="contract-party-search"
        :success="partyValidation.success"
        :error-messages="partyValidation.errorMessages"
      />
    </v-col>
    <v-col v-if="contractParty.party" cols="12">
      <case-role-input
        :validation-scope="validationScope"
        :roles.sync="contractParty.roles"
        :beneficiary-amount.sync="contractParty.beneficiaryAmount"
        :relationship.sync="contractParty.relationship"
        :type="contractParty.party.type"
        :line="props.line"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import ContractPartySearch from "@/components/shared/ContractPartySearch.vue";
import CaseRoleInput from "@/components/cases/case-dialogs/CaseRoleInput.vue";
import { caseContractPartyCrud } from "@/api/cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref, defineProps, defineExpose } from "vue";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { mdiAccount } from "@mdi/js";
import useVuelidate from "@vuelidate/core";
import {
  CONTINGENT_BENEFICIARY,
  INSURED,
  JOINT_INSURED,
  PRIMARY_BENEFICIARY
} from "@/factories/RoleFactory";

const props = defineProps({
  caseId: {
    type: Number,
    required: false,
    default: 0
  },
  line: {
    type: String,
    required: false,
    default: ""
  }
});

const snackbar = useSnackbarStore();

const validationScope = "preexisting-party";

const contractParty = ref({
  party: null,
  roles: [],
  relationship: null,
  beneficiaryAmount: null
});

const v$ = useVuelidate(
  {
    contractParty: {
      party: { required: Boolean }
    }
  },
  { contractParty },
  { $scope: validationScope, $autoDirty: true }
);

const partyValidation = computedValidation(v$.value.contractParty.party, {
  required: "Required"
});

async function create() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  const body = {
    contract_party: {
      roles_attributes: contractParty.value.roles.map(role => {
        const attributes = {
          role,
          case_id: props.caseId
        };

        if ([PRIMARY_BENEFICIARY, CONTINGENT_BENEFICIARY].includes(role)) {
          attributes.beneficiary_amount = +(
            contractParty.value.beneficiaryAmount / 100
          ).toFixed(2);
          attributes.beneficiary_qualifier = "Percent";
        }
        if (![INSURED, JOINT_INSURED].includes(role)) {
          attributes.relationship = contractParty.value.relationship;
        }

        return attributes;
      })
    }
  };
  try {
    const result = await caseContractPartyCrud(
      props.caseId,
      contractParty.value.party.id,
      body
    );
    return result;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

defineExpose({ create });
</script>
