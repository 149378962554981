<template>
  <v-card
    flat
    color="section"
    :style="{
      paddingBottom: table.mappedItems.value.length < 5 ? '10rem' : null
    }"
  >
    <v-card-text>
      <v-data-table
        data-testid="backnine-statement-payments-table"
        class="transparent-data-table"
        must-sort
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :server-items-length="table.meta.value.total"
        :loading="table.loading.value"
        :options="table.options.value"
        :footer-props="footerProps"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Payments</h1>
            <v-spacer />
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="payments-table-refresh"
                  @click="getData"
                >
                  <v-icon>{{ mdiRefresh }}</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
          <generic-table-filter-header
            key="Payments"
            :value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>
        <template #[`item.policyNumber`]="{ item }">
          <router-link :to="getRouterLink('Case', item.additional.caseId)">
            {{ item.policyNumber }}
          </router-link>
        </template>
        <template #[`item.premium`]="{ item }">
          <currency-formatter :value="item.premium" />
        </template>
        <template #[`item.percent`]="{ item }">
          <percentage-formatter
            :value="item.percent"
            is-decimal
            :decimal-length="3"
          />
        </template>
        <template #[`item.dollar`]="{ item }">
          <currency-formatter :value="item.dollar" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";

import { paymentTypes, commissionTypes } from "@/data/filter-options";
import { parseErrorMessage, getRouterLink } from "@/util/helpers";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { getPaymentsRelatedToStatement } from "@/api/statements.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { defineProps, computed } from "vue";
import { useTable } from "@/composables/table.composable";
import { mdiRefresh } from "@mdi/js";

const props = defineProps({ id: [Number, String] });

const table = useTable({
  headers: [
    new TableHeader({
      text: "Policy Number",
      value: "policyNumber",
      map: "policyNumber",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "cases.stripped_policy_number"
    }),
    new TableHeader({
      text: "Assigned Payable",
      value: "assignedPayable",
      map: "commissionAssignedPayableName",
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      sortFilterMap: [
        { key: "assigned_payable_id", value: "id" },
        { key: "assigned_payable_type", value: "type" }
      ]
    }),
    new TableHeader({
      text: "Commission Type",
      value: "commissionType",
      map: "commissionType",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      selectableOptions: commissionTypes,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "payments.commission_type"
    }),
    new TableHeader({
      text: "Payment Type",
      value: "paymentType",
      map: "paymentType",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      selectableOptions: paymentTypes,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "commissions.payment_type"
    }),
    new TableHeader({
      text: "Premium",
      value: "premium",
      map: "premium",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      sortFilterMap: "payments.premium"
    }),
    new TableHeader({
      text: "Percent",
      value: "percent",
      map: "percent",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      sortFilterMap: "payments.percent"
    }),
    new TableHeader({
      text: "Dollar",
      value: "dollar",
      map: "dollar",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      sortFilterMap: "payments.dollar"
    })
  ],
  getData: v => getPaymentsRelatedToStatement(props.id, v),
  options: new TableOptions(["policyNumber"], [true])
});

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

function updateFilter(filter) {
  table.resetPage();
  table.filter.value = filter;
  getData();
}

function updateOptions(options) {
  if (table.optionsEquivalence(options)) return;
  table.options.value = options;
  getData();
}

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getData();
</script>
