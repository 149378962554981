<template>
  <v-card>
    <v-card-title>
      <v-row class="ma-0" align="center">
        Add Party
        <v-spacer />
        <v-tabs v-model="activeTab" show-arrows right style="width: unset">
          <v-tab
            class="text-none"
            v-for="tab in tabs"
            :key="tab.text"
            :data-testid="`add-${tab.text}`"
          >
            <v-icon class="mr-1"> {{ tab.icon }} </v-icon>
            {{ tab.text }}
          </v-tab>
        </v-tabs>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-tabs-items v-model="activeTab" touchless>
        <v-tab-item v-for="tab in tabs" :key="tab.text + 'body'">
          <component
            :is="tab.component"
            :ref="tab.refName"
            :case-id="props.caseId"
            :insured-address="props.insuredAddress"
            :line="props.line"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="text-none"
        outlined
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        data-testid="add-party-create"
        class="text-none"
        color="primary"
        :loading="loading"
        @click="createRole"
      >
        Create Role
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import PreexistingParty from "@/components/cases/case-dialogs/PreexistingParty.vue";
import NewParty from "@/components/cases/case-dialogs/NewParty.vue";

import { useDialogStore } from "@/stores/dialog";
import { markRaw, ref, defineProps } from "vue";
import { mdiAccountPlus, mdiAccountSearch } from "@mdi/js";

const props = defineProps({
  caseId: {
    type: Number,
    required: false,
    default: null
  },
  insuredAddress: {
    type: Object,
    required: false,
    default: () => null
  },
  line: {
    type: String,
    required: true
  }
});

const dialog = useDialogStore();

const loading = ref(false);
const activeTab = ref(0);

const tabs = [
  {
    text: "Pre-Existing Party",
    icon: mdiAccountSearch,
    refName: "preexistingPartyRef",
    component: markRaw(PreexistingParty)
  },
  {
    text: "New Party",
    icon: mdiAccountPlus,
    refName: "newPartyRef",
    component: markRaw(NewParty)
  }
];

const newPartyRef = ref(null); //templateref
const preexistingPartyRef = ref(null); //templateref

// refs are arrays in vue 2 within a v-for
async function createRole() {
  let ref;
  if (activeTab.value === 0) {
    ref = preexistingPartyRef.value[0];
  } else {
    ref = newPartyRef.value[0];
  }

  if (!ref?.create) return;
  loading.value = true;
  const result = await ref.create();
  if (result) dialog.closeDialog({ party: result });
  loading.value = false;
}
</script>
