<template>
  <v-col v-if="loading" cols="12" class="pa-0">
    <v-skeleton-loader type="card" />
    <v-skeleton-loader type="card" />
  </v-col>
  <v-col v-else cols="12" class="pa-0">
    <impaired-risk-quote-view-card />
    <v-tabs v-model="activeTab">
      <v-tab
        v-for="tab in tabs"
        class="text-none"
        :key="`${tab.page}-tab`"
        :data-testid="`${tab.page}-tab`"
      >
        <v-icon class="pr-1">{{ tab.icon }}</v-icon>
        <v-badge v-if="tab.badgeProps" v-bind="tab.badgeProps">
          {{ tab.title }}
        </v-badge>
        <template v-else>{{ tab.title }}</template>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab" touchless>
      <v-tab-item
        v-for="tab in tabs"
        :key="`${tab.page}-body`"
        :eager="tab.eager"
      >
        <component :is="tab.component" v-bind="tab.props || {}" />
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</template>

<script setup>
import ImpairedRiskQuoteViewCard from "@/components/impaired-risk-quote/ImpairedRiskQuoteViewCard.vue";
import ImpairedRiskQuoteViewOffers from "@/components/impaired-risk-quote/ImpairedRiskQuoteViewOffers.vue";
import ImpairedRiskQuoteViewImpairments from "@/components/impaired-risk-quote/ImpairedRiskQuoteViewImpairments.vue";
import ImpairedRiskQuoteViewDetails from "@/components/impaired-risk-quote/ImpairedRiskQuoteViewDetails.vue";
import ImpairedRiskQuoteViewAccess from "@/components/impaired-risk-quote/ImpairedRiskQuoteViewAccess.vue";
import ImpairedRiskQuoteViewToDoList from "@/components/impaired-risk-quote/ImpairedRiskQuoteViewToDoList.vue";

import { storeToRefs } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { useImpairedRiskQuoteView } from "@/stores/impaired-risk-quote-view";
import { useHead } from "@unhead/vue";
import {
  markRaw,
  onBeforeUnmount,
  ref,
  watch,
  defineProps,
  toRef,
  computed
} from "vue";
import {
  mdiCheck,
  mdiFileDocumentEdit,
  mdiOffer,
  mdiShieldAccount,
  mdiStethoscope
} from "@mdi/js";
import { useRouter } from "@/composables/compatible.composables";
import { useImpairedRiskQuoteOffer } from "@/stores/impaired-risk-quote-offer";

const props = defineProps({
  id: {
    type: [String, Number],
    required: true
  },
  page: {
    type: String,
    default: ""
  },
  offer: {
    type: String,
    default: ""
  },
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  }
});
const highlightTodo = toRef(props, "highlightTodo");

const router = useRouter();

const head = useHead({ title: "Impaired Risk Quote Viewer" });

const impairedRiskQuoteView = useImpairedRiskQuoteView();
const impairedRiskQuoteOffer = useImpairedRiskQuoteOffer();
impairedRiskQuoteOffer.$reset();
impairedRiskQuoteView.$reset();

impairedRiskQuoteOffer.activeOfferKey = props.offer;

const instance = useInstanceStore();
const snackbar = useSnackbarStore();
const { insured, todoCount } = storeToRefs(impairedRiskQuoteView);
const { breadcrumb } = storeToRefs(instance);

const activeTab = ref(0);
const loading = ref(false);

const tabs = computed(() => [
  {
    title: "Offers",
    icon: mdiOffer,
    page: "offers",
    component: markRaw(ImpairedRiskQuoteViewOffers)
  },
  {
    title: "Impairments",
    icon: mdiStethoscope,
    page: "impairments",
    component: markRaw(ImpairedRiskQuoteViewImpairments)
  },
  {
    title: "Details",
    icon: mdiFileDocumentEdit,
    page: "details",
    component: markRaw(ImpairedRiskQuoteViewDetails)
  },
  {
    title: "To-Do",
    icon: mdiCheck,
    page: "to-do",
    component: markRaw(ImpairedRiskQuoteViewToDoList),
    props: {
      highlightTodo: highlightTodo.value
    },
    badgeProps: todoCount.value
      ? {
          color: "error",
          content: todoCount.value,
          "data-testid": "todo-badge"
        }
      : null,
    eager: true
  },
  {
    title: "Access",
    icon: mdiShieldAccount,
    page: "access",
    component: markRaw(ImpairedRiskQuoteViewAccess)
  }
]);

function syncQueryToTab() {
  if (!router) return;
  let page = tabs.value[activeTab.value].page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

onBeforeUnmount(() => (breadcrumb.value = ""));

async function getData() {
  try {
    loading.value = true;
    await impairedRiskQuoteView.getData(props.id);
    breadcrumb.value = insured.value;
    head.patch({ title: insured.value });
    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});
getData();
</script>
