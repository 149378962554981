<template>
  <v-card>
    <v-card-title>Application Request</v-card-title>
    <v-card-text>
      <v-form @submit.prevent="createApplicationRequest">
        <v-row class="pa-3">
          <v-col cols="12">
            <v-select
              :prepend-inner-icon="mdiHexagonMultiple"
              v-model="model.insuranceType"
              label="Line of Insurance"
              outlined
              dense
              :items="insuranceTypes"
              :success="insuranceTypeValidation.success"
              :error-messages="insuranceTypeValidation.errorMessages"
            />
          </v-col>
          <template v-if="model.insuranceType">
            <v-col cols="12" lg="6">
              <carrier-search
                v-model="model.carrier"
                label="Carrier"
                outlined
                dense
                :lines="[model.insuranceType]"
                :success="carrierValidation.success"
                :error-messages="carrierValidation.errorMessages"
                @change="getProducts"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <v-autocomplete
                :prepend-inner-icon="mdiCube"
                v-if="model.carrier"
                v-model="model.product"
                label="Product"
                outlined
                dense
                item-text="name"
                item-value="id"
                :items="products"
                :loading="loadingProducts"
                :success="productValidation.success"
                :error-messages="productValidation.errorMessages"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <date-input
                :prepend-inner-icon="mdiCalendar"
                v-model="model.dateOfBirth"
                :label="dateOfBirthLabel"
                outlined
                dense
                :success="dateOfBirthValidation.success"
                :error-messages="dateOfBirthValidation.errorMessages"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <v-autocomplete
                :prepend-inner-icon="mdiSignRealEstate"
                v-model="model.state"
                item-text="full"
                item-value="text"
                label="State"
                outlined
                dense
                :items="states"
                :success="stateValidation.success"
                :error-messages="stateValidation.errorMessages"
              />
            </v-col>
            <v-col cols="12" v-if="isLife">
              <currency-input
                :prepend-inner-icon="mdiCurrencyUsd"
                v-model="model.faceAmount"
                label="Death Benefit"
                outlined
                dense
                :success="faceAmountValidation.success"
                :error-messages="faceAmountValidation.errorMessages"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                :prepend-inner-icon="mdiTextBox"
                v-model="model.notes"
                label="Notes"
                outlined
                dense
                :success="notesValidation.success"
                :error-messages="notesValidation.errorMessages"
              />
            </v-col>
            <v-btn
              type="submit"
              color="primary"
              class="text-none mx-3"
              :loading="creatingApplication"
            >
              Create Application Request
            </v-btn>
          </template>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import DateInput from "@/components/shared/DateInput.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import { required } from "vuelidate/lib/validators";
import { states } from "@/data/states";
import { productSearch } from "@/api/search.service";

import ApplicationRequestFactory from "@/factories/ApplicationRequestFactory";
import { createApplication } from "@/api/applications.service";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import {
  mdiHexagonMultiple,
  mdiCube,
  mdiCalendar,
  mdiSignRealEstate,
  mdiCurrencyUsd,
  mdiTextBox
} from "@mdi/js";

const insuranceTypes = [
  { text: "Annuity", value: "annuity" },
  { text: "Disability", value: "disability" },
  { text: "Life", value: "life" },
  { text: "LTC", value: "ltc" }
];

export default {
  setup() {
    useHead({ title: "Application Request" });
  },
  components: {
    DateInput,
    CarrierSearch,
    CurrencyInput
  },
  data() {
    return {
      loadingProducts: false,
      creatingApplication: false,
      products: [],
      insuranceTypes,
      states,
      model: new ApplicationRequestFactory(),
      mdiHexagonMultiple,
      mdiCube,
      mdiCalendar,
      mdiSignRealEstate,
      mdiCurrencyUsd,
      mdiTextBox
    };
  },
  watch: {
    "model.insuranceType"(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.model.carrier = null;
      this.model.product = null;
    }
  },
  computed: {
    ...mapState(useUserStore, ["loginable"]),
    isLife() {
      return this.model.insuranceType === "life";
    },
    dateOfBirthLabel() {
      const prefix =
        this.model.insuranceType === "annuity" ? "Annuitant" : "Insured";
      return `${prefix} Date of Birth`;
    },
    insuranceTypeValidation() {
      const success = !this.$v.model.insuranceType.$invalid;
      const errorMessages = [];
      if (!this.$v.model.insuranceType.$dirty)
        return { errorMessages, success };
      if (!this.$v.model.insuranceType.required) errorMessages.push("Required");
      return { errorMessages, success };
    },
    carrierValidation() {
      const success = !this.$v.model.carrier.$invalid;
      const errorMessages = [];
      if (!this.$v.model.carrier.$dirty) return { errorMessages, success };
      if (!this.$v.model.carrier.required) errorMessages.push("Required");
      return { errorMessages, success };
    },
    productValidation() {
      const success = !this.$v.model.product.$invalid;
      const errorMessages = [];
      if (!this.$v.model.product.$dirty) return { errorMessages, success };
      if (!this.$v.model.product.required) errorMessages.push("Required");
      return { errorMessages, success };
    },
    dateOfBirthValidation() {
      const success = !this.$v.model.dateOfBirth.$invalid;
      const errorMessages = [];
      if (!this.$v.model.dateOfBirth.$dirty) return { errorMessages, success };
      if (!this.$v.model.dateOfBirth.required) errorMessages.push("Required");
      return { errorMessages, success };
    },
    stateValidation() {
      const success = !this.$v.model.state.$invalid;
      const errorMessages = [];
      if (!this.$v.model.state.$dirty) return { errorMessages, success };
      if (!this.$v.model.state.required) errorMessages.push("Required");
      return { errorMessages, success };
    },
    faceAmountValidation() {
      const success = !this.$v.model.faceAmount.$invalid;
      const errorMessages = [];
      if (!this.$v.model.faceAmount.$dirty) return { errorMessages, success };
      if (!this.$v.model.faceAmount.required) errorMessages.push("Required");
      return { errorMessages, success };
    },
    notesValidation() {
      const success = !this.$v.model.notes.$invalid;
      const errorMessages = [];
      if (!this.$v.model.notes.$dirty) return { errorMessages, success };
      if (!this.$v.model.notes.required) errorMessages.push("Required");
      return { errorMessages, success };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    createApplicationRequest() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.creatingApplication = true;
      createApplication(this.model.toCreateRequest())
        .then(() => {
          this.showSuccessSnackbar({
            message: "Successfully Created Application Request",
            timeout: 5000
          });
          this.resetData();
          this.$v.$reset();
        })
        .catch(() => {
          this.showErrorSnackbar({
            message: "Unable to create application request"
          });
        })
        .finally(() => {
          this.creatingApplication = false;
        });
    },
    getProducts() {
      setTimeout(() => {
        this.model.product = null;
        this.loadingProducts = true;
        productSearch(this.model.carrier.id, [this.model.insuranceType])
          .then(result => {
            this.products = result;
          })
          .finally(() => {
            this.loadingProducts = false;
          });
      }, 10);
    },
    resetData() {
      this.loadingProducts = false;
      this.creatingApplication = false;
      this.products = [];
      this.insuranceTypes = insuranceTypes;
      this.states = states;
      this.model = new ApplicationRequestFactory();
    }
  },
  validations() {
    const validations = {
      model: {
        insuranceType: {
          required
        },
        carrier: {
          required
        },
        product: {
          required
        },
        dateOfBirth: {
          required
        },
        state: {
          required
        },
        notes: {
          required
        }
      }
    };

    if (this.isLife) {
      validations.model.faceAmount = {
        required
      };
    }

    return validations;
  }
};
</script>
