<template>
  <v-data-table
    :headers="headers"
    :items="items"
    item-class="additional.rowClass"
    show-expand
    multi-sort
    :options="options"
    :loading="loading"
    item-key="additional.key"
  >
    <template #[`item.coverage`]="{ item }">
      {{ item.additional.start_year }} - {{ item.additional.end_year }}
    </template>
    <template #[`item.payment`]="{ item }">
      <td class="truncate-300">
        {{ item.additional.commission_type }},
        <percentage-formatter
          :value="item.additional.percent"
          is-decimal
          :decimal-length="3"
        />,
        {{ item.additional.payment_type }}
      </td>
    </template>
    <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
      <v-btn
        v-if="item.additional.isErrored"
        @click.stop="expand(!isExpanded)"
        icon
      >
        <v-icon color="white"> {{ mdiInformation }} </v-icon>
      </v-btn>
    </template>
    <template #[`item.commissionActions`]="{ item }">
      <v-menu>
        <template #activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" icon>
            <v-icon> {{ mdiDotsHorizontal }} </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="edit(item)">
            <v-list-item-icon>
              <v-icon> {{ mdiPencil }} </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Edit Commission </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!item.additional.paid" @click="destroy(item)">
            <v-list-item-icon>
              <v-icon> {{ mdiDelete }} </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Delete Commission </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template #expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        {{ item.additional.errors }}
      </td>
    </template>
  </v-data-table>
</template>

<script>
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import Table from "@/classes/data-table/Table";
import TableHeader from "@/classes/data-table/TableHeader";
import { deleteCommission, updateCommission } from "@/api/commissions.service";
import { mapActions } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import {
  mdiInformation,
  mdiDotsHorizontal,
  mdiPencil,
  mdiDelete
} from "@mdi/js";
export default {
  components: {
    PercentageFormatter
  },
  mixins: [GenericTableMixin],
  props: {
    commissions: Array,
    policy: Object,
    loading: Boolean
  },
  data() {
    const commissionHeaders = [
      new TableHeader({
        text: "Agent",
        value: "agent",
        map: "appointment_case.advisor.name"
      }),
      new TableHeader({
        text: "Payable",
        value: "payable",
        map: "payable.name"
      }),
      new TableHeader({
        text: "Assigned Payable",
        value: "assignedPayable",
        map: "assigned_payable.name"
      }),
      new TableHeader({
        text: "Payor",
        value: "payor",
        map: "payor.name"
      }),
      new TableHeader({
        text: "Payment",
        value: "payment",
        map: "paymentPercent"
      }),
      new TableHeader({
        text: "Coverage",
        value: "coverage",
        map: "coverage"
      }),
      new TableHeader({
        text: "Actions",
        value: "commissionActions",
        map: "commissionActions"
      })
    ];

    const commissionsTable = new Table({
      text: "Commissions",
      headers: commissionHeaders,
      key: "commissions"
    });

    return {
      table: commissionsTable,
      mdiInformation,
      mdiDotsHorizontal,
      mdiPencil,
      mdiDelete
    };
  },
  computed: {
    headers() {
      return this.table.headers;
    },
    items() {
      return this.tableMap(
        this.commissions.map(v => ({
          ...v,
          key: JSON.stringify(v),
          isErrored: Boolean(v.errors),
          rowClass: v.errors ? "error white--text" : null
        })),
        this.table.headers
      );
    },
    sort: {
      get() {
        return this.table.sort;
      },
      set(value) {
        this.table.sort = value;
      }
    },
    options: {
      get() {
        return this.table.options;
      },
      set(value) {
        this.table.options = value;
      }
    },
    sortFilterMap() {
      return this.table.sortFilterMap;
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    destroy(item) {
      this.showDialog({
        component: "DeleteDialog",
        title: `Delete Commission?`,
        subtitle: "This cannot be undone",
        func: () => deleteCommission(item.additional.id)
      }).then(result => {
        if (!result.delete) {
          return;
        }
        this.$emit("fetch");
      });
    },
    saveCommission(item) {
      const params = {
        id: item.commission_id,
        commission: {
          ...item
        }
      };
      return updateCommission(params).then(() => {
        this.$emit("fetch");
      });
    },
    edit(item) {
      this.showDialog({
        component: "CommissionDialog",
        editing: true,
        $commission: item.additional,
        policy: this.policy,
        func: this.saveCommission
      });
    }
  }
};
</script>
