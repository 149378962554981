import Vue from "vue";
import { cloneDeep } from "@/util/helpers";
import sortBy from "lodash/sortBy";
import {
  updateApplicationQuestionLink,
  createApplicationQuestionLink,
  deleteApplicationQuestionLink
} from "@/api/application-question-links.service";

import {
  addFormSetToForm,
  removeFormSetFromForm
} from "@/api/form-sets.service";

import {
  ApplicationQuestionLinkFactory,
  NOT_ON_PDF_OPTION_TYPE,
  TEXT_OPTION_TYPE,
  TEXT_VERBATIM_OPTION_TYPE,
  RADIO_OPTION_DIAMETER,
  SIGNATURE_OPTION_TYPE,
  SIGNATURE_DATE_OPTION_TYPE,
  getAqlCopyRequestBody,
  getAqlCreateRequestBody,
  getAqlUpdateRequestBody,
  setAqlFromRequest
} from "@/factories/FormMappingFactory";

import {
  fetchForm,
  updateForm,
  pdfUrl,
  pdfSvgUrl,
  verifyActiveStatus,
  deleteFormPage
} from "@/api/forms.service";
import { defineStore } from "pinia";
import { uuidv4 } from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";

export const useFormMappingStore = formId =>
  defineStore(`form-mapping-${formId}`, {
    state: () => ({
      id: formId,
      status: "",
      name: "",
      submissionMethod: "Paper",
      otherFormId: null,
      modelId: 0,
      currentPage: 0,
      carrier: null,
      advisor: null,
      category: null,
      currentFieldId: null,
      currentCoordinateIndex: 0,
      currentFieldHasChanges: false,
      currentFieldIsValid: null,
      followUpAt: null,
      form: null,
      formSets: [],
      isContracting: null,
      limits: {},
      dupeOffsetY: 0,
      dupeOffsetX: 0,
      pageCount: 0,
      viewport: {
        width: 1,
        height: 1
      },
      window: {
        width: 1,
        height: 1
      },
      states: [],
      fields: {
        by_id: {},
        ids: []
      },
      activeField: {}
    }),
    getters: {
      isEappSubmission() {
        return this.submissionMethod !== "Paper";
      },
      otherFields() {
        if (!this.otherFormId) return {};
        const otherStore = useFormMappingStore(this.otherFormId);
        return otherStore.fields;
      },
      otherActiveField() {
        if (!this.otherFormId) return null;
        const otherStore = useFormMappingStore(this.otherFormId);
        return otherStore.activeField;
      },
      scale() {
        return +(this.window.width / this.viewport.width).toFixed(2);
      },
      isReadonly() {
        return this.status.includes("Complete");
      },
      pdfUrl() {
        return pdfUrl(this.id);
      },
      pdfSvgUrl() {
        return pdfSvgUrl(this.id);
      },
      incompleteQuestionCount() {
        const incompleteQuestions = this.fields.ids?.filter(field => {
          const aqId = this.fields.by_id[field]?.applicationQuestion?.id;
          return !aqId;
        });
        return incompleteQuestions?.length;
      },
      fieldsByPosition() {
        const fieldsWith = [];
        const fieldsWithout = [];
        this.fields.ids.forEach(id => {
          if (this.fields.by_id[id].coordinates.length) {
            fieldsWith.push(this.fields.by_id[id]);
          } else {
            fieldsWithout.push(this.fields.by_id[id]);
          }
        });

        return { fieldsWith, fieldsWithout };
      },
      positionallySortedFields() {
        const { fieldsWith, fieldsWithout } = this.fieldsByPosition;

        const sorted = sortBy(fieldsWith, [
          field => field.coordinates.find(c => !c.virtual)?.page,
          field =>
            Math.ceil(field.coordinates.find(c => !c.virtual)?.y / 10) * 10,
          field =>
            Math.ceil(field.coordinates.find(c => !c.virtual)?.x / 10) * 10
        ]);

        return [...sorted, ...fieldsWithout];
      }
    },
    actions: {
      async initializeForm() {
        this.$reset();
        const {
          model_id,
          status,
          follow_up_at,
          name,
          application_question_links,
          carrier,
          ownable,
          contracting,
          category,
          form_sets,
          form,
          limits,
          states,
          submission_method
        } = await fetchForm(this.id);

        this.modelId = model_id;
        this.status = status;
        this.name = name;
        this.followUpAt = follow_up_at;
        this.isContracting = contracting;
        this.category = category;
        this.formSets = form_sets;
        this.form = form;
        this.limits = limits;
        this.states = states;
        this.submissionMethod = submission_method;

        let advisor;
        if (ownable?.id) {
          advisor = {
            id: ownable.id,
            type: ownable.type.model,
            name: ownable.name
          };
        }

        Vue.set(this, "carrier", carrier);
        Vue.set(this, "advisor", advisor);

        Vue.set(this.fields, "by_id", {});
        this.fields.ids.splice(0, this.fields.length);

        application_question_links.forEach(rawAql => {
          const aql = new ApplicationQuestionLinkFactory();
          setAqlFromRequest(aql, rawAql);
          Vue.set(this.fields.by_id, aql.id, aql);
          this.fields.ids.push(aql.id);
        });
      },
      async copyField(referenceField, referenceFormId) {
        const copiedFieldData = cloneDeep(referenceField);
        const copiedField = new ApplicationQuestionLinkFactory(copiedFieldData);
        copiedField.id = null;
        copiedField.parentQuestion = null;
        copiedField.referenceField = referenceField.id;
        copiedField.referenceForm = referenceFormId;
        if (copiedField.coordinates?.length) {
          for (let i = 0; i < copiedField.coordinates.length; i++) {
            copiedField.coordinates[i].page = this.currentPage;
          }
        }
        const body = getAqlCopyRequestBody(copiedField);
        const newId = await this.createLink({ body });
        this.changeActiveField(newId);
      },
      async copyToActiveField(aql, direction) {
        const skippableKeys = ["referenceField", "referenceForm", "id"];
        Object.keys(aql).forEach(attr => {
          if (skippableKeys.includes(attr)) return;
          this.activeField[attr] = aql[attr];
        });

        const isText = [
          TEXT_OPTION_TYPE,
          SIGNATURE_OPTION_TYPE,
          SIGNATURE_DATE_OPTION_TYPE
        ].includes(aql.pdfFieldType);

        aql.coordinates.forEach(({ height, width, virtual }, index) => {
          if (virtual) return;
          const offsetHeight = isText ? height : RADIO_OPTION_DIAMETER;
          const offsetWidth = isText ? width : RADIO_OPTION_DIAMETER;
          if (direction === "up") {
            this.activeField.coordinates[index].y += offsetHeight;
            this.activeField.coordinates[index].y += +this.dupeOffsetY;
          }
          if (direction === "down") {
            this.activeField.coordinates[index].y -= offsetHeight;
            this.activeField.coordinates[index].y -= +this.dupeOffsetY;
          }
          if (direction === "left") {
            this.activeField.coordinates[index].x -= offsetWidth;
            this.activeField.coordinates[index].x -= +this.dupeOffsetX;
          }
          if (direction === "right") {
            this.activeField.coordinates[index].x += offsetWidth;
            this.activeField.coordinates[index].x += +this.dupeOffsetX;
          }
        });

        this.currentFieldHasChanges = true;
      },
      async deleteApplicationQuestionLink(id) {
        await deleteApplicationQuestionLink(id);

        if (this.currentFieldId === id) {
          let index = this.positionallySortedFields.findIndex(
            val => val.id.toString() === id.toString()
          );
          if (index === 0) index = 1;
          else index--;

          this.currentFieldHasChanges = false;
          if (this.positionallySortedFields.length === 1) {
            this.changeActiveField(null);
          } else {
            this.changeActiveField(this.positionallySortedFields[index].id);
          }
        }
        const index = this.fields.ids.findIndex(i => i === id);
        this.fields.ids.splice(index, 1);
        Vue.delete(this.fields.by_id, id);
      },
      async updateApplicationQuestionLink(aql) {
        const body = getAqlUpdateRequestBody(aql);
        const updatedAql = await updateApplicationQuestionLink(aql.id, body);

        Vue.set(this.fields.by_id, aql.id, updatedAql);
        const childAqls = updatedAql.childAqls;
        if (childAqls?.length) {
          this.storeChildAqls(childAqls);
        }
      },
      async createLink({ body, fieldId = null }) {
        const aql = await createApplicationQuestionLink({
          body,
          form_id: this.id
        });

        if (fieldId) Vue.set(this.fields.by_id, fieldId, aql);
        else {
          Vue.set(this.fields.by_id, aql.id, aql);
          this.fields.ids.push(aql.id);
        }

        const childAqls = aql.childAqls;
        if (childAqls.length) this.storeChildAqls(childAqls);

        const parentQuestion = aql.parentAql;
        if (parentQuestion?.id) {
          const fieldAlreadyExists = this.fields.by_id[parentQuestion.id];
          const canAdd =
            !fieldAlreadyExists &&
            parentQuestion?.pdfFieldType === NOT_ON_PDF_OPTION_TYPE;

          if (canAdd) {
            const field = parentQuestion.id;
            Vue.set(this.fields.by_id, field, parentQuestion);
            this.fields.ids.push(field);
          }
        }
        return aql.id;
      },
      addNewFormSet(formSetId) {
        return addFormSetToForm(formSetId, this.id);
      },
      removeFormSet(formSetId) {
        return removeFormSetFromForm(formSetId, this.id);
      },
      updateFormName(name) {
        return this.updateForm({ name });
      },
      updateFormAdvisor(advisor) {
        return this.updateForm({
          ownable_type: advisor.type,
          ownable_id: advisor.id
        });
      },
      updateFormCategory(category) {
        return this.updateForm({ category });
      },
      updateFormStatus(status) {
        return this.updateForm({ status });
      },
      updateFormSubmissionMethod(submission_method) {
        return this.updateForm({ submission_method });
      },
      updateForm(form) {
        return updateForm(this.id, { form });
      },
      updateFormPdf(pdf) {
        return updateForm(this.id, { form: { file: pdf } });
      },
      generateAql(pdfFieldType) {
        const newAql = new ApplicationQuestionLinkFactory();
        newAql.pdfFieldType = pdfFieldType;
        if (
          [
            TEXT_VERBATIM_OPTION_TYPE,
            TEXT_OPTION_TYPE,
            SIGNATURE_OPTION_TYPE,
            SIGNATURE_DATE_OPTION_TYPE
          ].includes(pdfFieldType)
        ) {
          newAql.coordinates.push({
            page: this.currentPage,
            x: 5,
            y: this.viewport.height - 23,
            width: 100,
            height: 17,
            uuid: uuidv4()
          });
        }
        return newAql;
      },
      addPdfField(pdfFieldType) {
        const aql = this.generateAql(pdfFieldType);
        const body = getAqlCreateRequestBody(aql);
        return this.createLink({ body });
      },
      storeChildAqls(childAqls) {
        childAqls.forEach(question => {
          if (question.pdfFieldType !== NOT_ON_PDF_OPTION_TYPE) return;
          const fieldAlreadyExists = this.fields.by_id[question.id];
          if (fieldAlreadyExists) return;

          Vue.set(this.fields.by_id, question.id, question);
          this.fields.ids.push(question.id);
        });
      },
      async insertChild(parentQuestion, pdfFieldType) {
        const newAql = this.generateAql(pdfFieldType);

        newAql.parentQuestion = parentQuestion;
        const body = getAqlCreateRequestBody(newAql);

        return this.createLink({ body });
      },

      async verifyStatus() {
        this.followUpAt = await verifyActiveStatus(this.id);
      },
      async changeActiveField(fieldId) {
        if (this.currentFieldHasChanges) {
          let warningText = null;
          let confirmText = "Lose Changes";
          let func = null;
          if (this.currentFieldIsValid) {
            func = () => this.updateApplicationQuestionLink(this.activeField);
            confirmText = "Save Changes";
            warningText = "Lose Changes";
          }

          const dialogStore = useDialogStore();
          const { confirm, warning } = await dialogStore.showDialog({
            component: "ConfirmationDialog",
            title: "Unsaved Changes",
            subtitle:
              "You have unsaved changes, please press cancel if you'd like to keep these changes.",
            confirmText,
            warningText,
            func,
            cancelText: "Keep Changes",
            persistent: true
          });
          if (!confirm && !warning) return;
        }
        this.currentFieldHasChanges = false;
        this.currentFieldId = fieldId;
        let field = {};
        if (this.currentFieldId) {
          field = cloneDeep(this.fields.by_id[this.currentFieldId]);
        }
        Vue.set(this, "activeField", field);

        this.currentCoordinateIndex = field.coordinates.findIndex(
          c => !c.virtual
        );
      },
      deletePage() {
        return deleteFormPage(this.id, this.currentPage);
      }
    }
  })();
