<template>
  <v-autocomplete
    autocomplete="false"
    outlined
    dense
    v-model="payor"
    item-text="name"
    return-object
    :prepend-inner-icon="mdiAccount"
    no-filter
    hide-no-data
    auto-select-first
    :autofocus="autofocus"
    :loading="loading"
    :clearable="clearable"
    :placeholder="placeholder"
    :label="label"
    :search-input.sync="search"
    :success="success"
    :error-messages="errorMessages"
    :items="payors"
    :hide-details="hideDetails"
    :disabled="disabled"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    ref="search"
    @blur="$emit('blur')"
    @click:clear="$emit('input', {})"
    @change="$emit('change')"
  />
</template>

<script>
import sortBy from "lodash/sortBy";
import { payorSearch } from "@/api/search.service";
import { mdiAccount } from "@mdi/js";

export default {
  props: {
    autofocus: Boolean,
    success: Boolean,
    errorMessages: Array,
    value: [Object, String],
    clearable: Boolean,
    label: {
      required: false,
      default: "Advisor",
      type: String
    },
    placeholder: {
      required: false,
      default: "Search Advisors",
      type: String
    },
    hideDetails: Boolean,
    disabled: Boolean
  },
  data() {
    let payors = [];
    let payor = null;
    if (this.value && Object.keys(this.value).length) {
      payor = this.value;
      payors = [this.value];
    }
    return {
      search: null,
      payorTimer: null,
      loading: false,
      payors,
      payor,
      mdiAccount
    };
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.payor = null;
        this.payors = [];
        return;
      }
      this.payor = value;
      this.payors = [value];
    },
    payor(value) {
      this.$emit("input", value);
    },
    search(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }

      if (
        this.payors.some(payor => payor.name === value) &&
        this.payors.length === 1
      ) {
        return;
      }

      if (this.payorTimer) {
        clearTimeout(this.payorTimer);
      }

      this.payorTimer = setTimeout(() => {
        this.loading = true;
        payorSearch(value)
          .then(response => {
            this.payors = sortBy(response.data.payors, "name");
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    focus() {
      this.$refs.search.$refs.input.focus();
    },
    clear() {
      this.payor = null;
      this.payors = [];
    }
  }
};
</script>
