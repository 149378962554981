import RedtailIntegrationDialog from "@/components/settings/security/RedtailIntegrationDialog.vue";
import { deleteIdentity } from "@/api/security.service";
import { useUserStore } from "@/stores/user";
import format from "date-fns/format";
import { useDialogStore } from "@/stores/dialog";
import { computed, markRaw } from "vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import {
  executeWealthboxSso,
  executeMicrosoftSso
} from "@/components/settings/security/sso.composable";
import { storeToRefs } from "pinia";

export const useMicrosoftIntegration = () => {
  const user = useUserStore();
  const dialog = useDialogStore();

  const { integrations } = storeToRefs(user);

  return computed(() => {
    let actionText = "Get Started";

    let listeners = {
      click: executeMicrosoftSso
    };

    let value;
    if (integrations.value.microsoft?.enabled) {
      actionText = "Delete Connection";
      listeners.click = () =>
        dialog.showDialog({
          component: markRaw(ConfirmationDialog),
          title: "Delete Connection",
          subtitle:
            "Please confirm your intent to delete the connection to Microsoft.",
          func: async () => {
            await deleteIdentity(integrations.value.microsoft.id);
            integrations.value.microsoft.enabled = false;
            integrations.value.microsoft.id = null;
          }
        });
      const connected = format(
        new Date(integrations.value.microsoft.createdAt),
        "MM/dd/yyyy"
      );
      value = `You connected to Microsoft on ${connected}. `;
    }

    return {
      text: "Microsoft",
      darkImage: require("@/assets/img/microsoft.svg"),
      image: require("@/assets/img/microsoft.svg"),
      subtitle:
        "Sign-in with one click using your Microsoft account. No need to remember another password.",
      value,
      actionText,
      listeners,
      solid: Boolean(integrations.value.microsoft?.enabled)
    };
  });
};

export const useWealthboxIntegration = () => {
  const user = useUserStore();
  const dialog = useDialogStore();

  const { integrations } = storeToRefs(user);

  return computed(() => {
    let actionText = "Get Started";

    let listeners = {
      click: executeWealthboxSso
    };

    let value;
    if (integrations.value.wealthbox?.enabled) {
      actionText = "Delete Connection";
      listeners.click = () =>
        dialog.showDialog({
          component: markRaw(ConfirmationDialog),
          title: "Delete Connection",
          subtitle:
            "Please confirm your intent to delete the connection to Wealthbox.",
          func: async () => {
            await deleteIdentity(integrations.value.wealthbox.id);
            user.integrations.wealthbox.enabled = false;
            user.integrations.wealthbox.id = null;
          }
        });
      const connected = format(
        new Date(integrations.value.wealthbox.createdAt),
        "MM/dd/yyyy"
      );
      value = `You connected to Wealthbox on ${connected}. `;
    }

    return {
      text: "Wealthbox",
      darkImage: require("@/assets/img/wealthbox-dark.png"),
      image: require("@/assets/img/wealthbox.png"),
      subtitle:
        "Utilize our no cost integration within Wealthbox. Track the transaction from submission to commission and retain the policy image for current and future planning.",
      value,
      actionText,
      listeners,
      solid: Boolean(integrations.value.wealthbox?.enabled)
    };
  });
};

export const useRedtailIntegration = () => {
  const user = useUserStore();
  const dialog = useDialogStore();

  const { integrations } = storeToRefs(user);
  return computed(() => {
    let actionText = "Get Started";

    let listeners = {
      click: () =>
        dialog.showDialog({
          component: markRaw(RedtailIntegrationDialog)
        })
    };

    let value;
    if (integrations.value.redtail?.enabled) {
      actionText = "Delete Connection";
      listeners = {};
      const connected = format(
        new Date(integrations.value.redtail.createdAt),
        "MM/dd/yyyy"
      );
      value = `You connected to Redtail on ${connected}. `;
    }

    return {
      text: "Redtail",
      darkImage: require("@/assets/img/Redtail.svg"),
      image: require("@/assets/img/Redtail.svg"),
      subtitle: "Utilize our no cost integration within Redtail.",
      value,
      actionText,
      listeners,
      solid: Boolean(integrations.value.redtail?.enabled)
    };
  });
};
