<template>
  <v-row dense>
    <v-col cols="12" :md="dense ? 3 : 4">
      <integer-input
        v-model="build.inches"
        label="Inches"
        outlined
        dense
        data-testid="inches-input"
        v-bind="inchesValidation"
      />
    </v-col>
    <v-col cols="12" :md="dense ? 3 : 4">
      <integer-input
        v-model="build.minWeight"
        label="Min Weight"
        outlined
        dense
        data-testid="min-weight-input"
        v-bind="minWeightValidation"
      />
    </v-col>
    <v-col cols="12" :md="4">
      <integer-input
        v-model="build.maxWeight"
        label="Max Weight"
        outlined
        dense
        data-testid="max-weight-input"
        v-bind="maxWeightValidation"
      />
    </v-col>
    <v-col cols="12" md="2" v-if="dense">
      <v-row class="ma-0">
        <v-btn icon color="success" data-testid="save" @click="saveBuild">
          <v-icon>
            {{ build.id ? mdiContentSave : mdiContentSavePlusOutline }}
          </v-icon>
        </v-btn>
        <v-btn icon color="error" data-testid="delete" @click="destroyBuild">
          <v-icon>{{ build.id ? mdiDelete : mdiDeleteOutline }}</v-icon>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import IntegerInput from "@/components/shared/IntegerInput.vue";

import { createBuild, updateBuild, deleteBuild } from "@/api/builds.service";

import { Build } from "@/factories/Build";
import { useSnackbarStore } from "@/stores/snackbar";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import {
  mdiContentSave,
  mdiContentSavePlusOutline,
  mdiDelete,
  mdiDeleteOutline
} from "@mdi/js";
import useVuelidate from "@vuelidate/core";
import { defineProps, defineEmits, ref, defineExpose, toRefs } from "vue";
const props = defineProps({
  dense: Boolean,
  value: { type: Object, required: true },
  buildChartId: { type: Number, required: false, default: null },
  rating: { type: String, required: false, default: null },
  validationScope: { type: String, required: false, default: null }
});

const { rating, buildChartId } = toRefs(props);
const emit = defineEmits(["deleted", "saved"]);

const build = ref(Build(props.value));
const savingBuild = ref(false);
const snackbar = useSnackbarStore();
const v$ = useVuelidate(
  {
    buildChartId: { required: Boolean },
    rating: { required: Boolean },
    build: {
      inches: { inRange: val => val > 0 && val <= 100 },
      minWeight: {
        inRange: val => +val >= 0 && +val <= 999,
        lessThanMax: (val, parent) => +val < +parent.maxWeight
      },
      maxWeight: {
        inRange: val => +val > 0 && +val <= 999,
        greaterThanMin: (val, parent) => +val > +parent.minWeight
      }
    }
  },
  { build, rating, buildChartId },
  { $scope: props.validationScope, $autoDirty: true }
);

const inchesValidation = computedValidation(v$.value.build.inches, {
  inRange: "Must be between 0 and 100"
});

const minWeightValidation = computedValidation(v$.value.build.minWeight, {
  inRange: "Must be greater than 0 and less than or equal to 999",
  lessThanMax: "Must be less than specified Max Weight"
});

const maxWeightValidation = computedValidation(v$.value.build.maxWeight, {
  inRange: "Must be greater than 0 and less than or equal to 999",
  greaterThanMin: "Must be greater than specified Min Weight"
});

async function destroyBuild() {
  if (!build.value.id) {
    emit("deleted");
    return;
  }
  try {
    await deleteBuild(build.value.id);
    snackbar.showSuccessSnackbar({
      message: "Successfully Deleted Build"
    });

    emit("deleted");
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  }
}

async function saveBuild() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  savingBuild.value = true;

  const body = {
    build_chart_id: props.buildChartId,
    rating: props.rating,
    inches: +build.value.inches,
    min_weight: +build.value.minWeight,
    max_weight: +build.value.maxWeight
  };

  const func = build.value.id
    ? args => updateBuild(build.value.id, args)
    : args => createBuild(args);

  try {
    const res = await func(body);
    snackbar.showSuccessSnackbar({ message: "Saved" });
    build.value.id = res.id;
    return true;
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  } finally {
    savingBuild.value = false;
  }
}

defineExpose({ saveBuild });
</script>
