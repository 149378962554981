<template>
  <div>
    <v-row v-if="loading">
      <v-col cols="12">
        <v-skeleton-loader type="card" />
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader type="table" />
      </v-col>
    </v-row>
    <v-row v-else dense>
      <v-col cols="12" class="pb-0">
        <quote-card />
      </v-col>
      <v-col v-show="showActions" cols="12" class="py-0">
        <quote-actions :show.sync="showActions" />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-tabs v-model="activeTab" show-arrows class="no-bottom-radius">
          <v-tab
            v-for="tab in tabs"
            :key="tab.title + '-title'"
            class="text-none"
            :data-testid="`tab-${tab.title}`"
          >
            <v-icon class="pr-1">{{ tab.icon }}</v-icon>
            <v-badge v-if="tab.badgeProps" v-bind="tab.badgeProps">
              {{ tab.title }}
            </v-badge>
            <template v-else>{{ tab.title }}</template>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab" touchless>
          <v-tab-item
            v-for="tab in tabs"
            :eager="tab.eager"
            :key="tab.title + '-body'"
          >
            <component :is="tab.component" v-bind="tab.props || {}" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import QuoteDetails from "@/components/quotes/QuoteDetails.vue";
import QuoteCard from "@/components/quotes/QuoteCard.vue";
import QuoteActions from "@/components/quotes/QuoteActions.vue";
import QuoteChat from "@/components/quotes/QuoteChat.vue";
import QuoteParties from "@/components/quotes/QuoteParties.vue";
import QuoteViewToDoList from "@/components/quotes/QuoteViewToDoList.vue";
import QuoteAccesses from "@/components/quotes/QuoteAccesses.vue";

import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";

import { useQuoteViewStore } from "@/stores/quote-view";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";
import {
  computed,
  defineProps,
  markRaw,
  onBeforeUnmount,
  ref,
  toRef,
  watch
} from "vue";
import { useRouter } from "@/composables/compatible.composables";
import {
  mdiAccountMultiple,
  mdiCheck,
  mdiFileDocumentEdit,
  mdiShieldAccount,
  mdiTextBox
} from "@mdi/js";

const props = defineProps({
  id: { type: [String, Number], required: true },
  page: { type: String, default: "" },
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  }
});
const highlightTodo = toRef(props, "highlightTodo");

const head = useHead({ title: "Quote Viewer" });
const router = useRouter();

const instanceStore = useInstanceStore();
const quoteViewStore = useQuoteViewStore();
const snackbar = useSnackbarStore();

const { breadcrumb } = storeToRefs(instanceStore);
const { insuredName, todoCount } = storeToRefs(quoteViewStore);

const showActions = ref(false);
const loading = ref(false);
const activeTab = ref(0);

const tabs = computed(() => [
  {
    icon: mdiTextBox,
    title: "Notes",
    page: "notes",
    eager: true,
    component: markRaw(QuoteChat)
  },
  {
    icon: mdiAccountMultiple,
    title: "Parties",
    page: "parties",
    component: markRaw(QuoteParties)
  },
  {
    icon: mdiFileDocumentEdit,
    title: "Details",
    page: "details",
    component: markRaw(QuoteDetails)
  },
  {
    icon: mdiCheck,
    title: "To-Do",
    page: "to-do",
    component: markRaw(QuoteViewToDoList),
    props: {
      highlightTodo: highlightTodo.value
    },
    badgeProps: todoCount.value
      ? {
          color: "error",
          content: todoCount.value,
          "data-testid": "todo-badge"
        }
      : null,
    eager: true
  },
  {
    icon: mdiShieldAccount,
    title: "Access",
    page: "access",
    component: markRaw(QuoteAccesses)
  }
]);

async function fetchQuote() {
  loading.value = true;
  try {
    await quoteViewStore.initializeQuote(props.id);
    breadcrumb.value = insuredName.value;
    head.patch({ title: insuredName.value });
    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    if (router) router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

function syncQueryToTab() {
  if (!router) return;
  let page = tabs.value[activeTab.value].page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}
watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});

onBeforeUnmount(() => (breadcrumb.value = ""));

fetchQuote();
</script>
