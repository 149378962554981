<template>
  <v-row dense>
    <v-col cols="12">
      <v-card flat color="section">
        <v-card-text>
          <v-row align="start" dense>
            <v-col cols="12" align="center">
              <div>
                <img
                  :src="agent.avatar"
                  style="
                    object-fit: contain;
                    max-width: 150px;
                    max-height: 150px;
                  "
                />
              </div>
              <div class="pa-1">
                <v-btn
                  text
                  class="text-none"
                  color="primary"
                  data-testid="agent-profile-new-avatar"
                  :disabled="props.readonly"
                  @click="uploadNewAvatar"
                >
                  Upload New Avatar
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" :md="user.isGroupThreePlus ? 4 : 6">
              <v-text-field
                v-model="firstName"
                data-lpignore="true"
                label="Display First Name"
                outlined
                dense
                :prepend-inner-icon="mdiAccount"
                data-testid="agent-profile-display-first-name"
                :success="firstNameValidation.success"
                :error-messages="firstNameValidation.errorMessages"
                @input="saveFirstName"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer.firstName.controller.value"
                  />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" :md="user.isGroupThreePlus ? 4 : 6">
              <v-text-field
                v-model="lastName"
                data-lpignore="true"
                label="Display Last Name"
                outlined
                dense
                data-testid="agent-profile-display-last-name"
                :disabled="user.isGroupOne"
                :prepend-inner-icon="mdiAccount"
                hint="Please contact support@back9ins.com to change your display last name"
                persistent-hint
                :success="lastNameValidation.success"
                :error-messages="lastNameValidation.errorMessages"
                @input="saveLastName"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer.lastName.controller.value"
                  />
                </template>
              </v-text-field>
            </v-col>
            <v-col v-if="user.isGroupTwoPlus" cols="12" md="4">
              <marketing-manager-search
                v-model="marketingManager"
                data-testid="agent-marketing-manager"
                :success="marketingManagerValidation.success"
                :error-messages="marketingManagerValidation.errorMessages"
                :disabled="props.readonly"
                @input="saveMarketingManager"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer.marketingManager.controller.value"
                  />
                </template>
              </marketing-manager-search>
            </v-col>
            <v-col v-if="user.isGroupTwoPlus" cols="12" md="4">
              <v-autocomplete
                label="Category"
                v-model="category"
                data-testid="agent-category"
                outlined
                dense
                clearable
                :items="ADVISOR_CATEGORIES"
                :success="Boolean(category)"
                @change="saveCategory"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer.category.controller.value"
                  />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import MarketingManagerSearch from "@/components/shared/MarketingManagerSearch.vue";

import { validationComputeV2 } from "@/util/helpers";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed, defineProps } from "vue";

import useVuelidate from "@vuelidate/core";
import { storeToRefs } from "pinia";
import { ADVISOR_CATEGORIES } from "@/data/advisor-data";
import { mdiAccount } from "@mdi/js";

const props = defineProps({
  readonly: Boolean,
  isViewingSelf: Boolean
});

const agent = useAgentSettingsStore();
const user = useUserStore();

const dialog = useDialogStore();

const { firstName, lastName, marketingManager, category } = storeToRefs(agent);

const rules = {
  firstName: { required: Boolean },
  lastName: { required: Boolean },
  marketingManager: { required: Boolean }
};

const state = {
  firstName,
  lastName,
  marketingManager
};

const v$ = useVuelidate(rules, state, { $autoDirty: true, $scope: false });

const savingBuffer = {
  firstName: useActiveSave(),
  lastName: useActiveSave(),
  name: useActiveSave(),
  marketingManager: useActiveSave(),
  category: useActiveSave()
};

const firstNameValidation = computed(() => {
  const model = v$.value.firstName;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const lastNameValidation = computed(() => {
  const model = v$.value.lastName;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

function saveFirstName() {
  savingBuffer.firstName.debounceUpdate(async () => {
    const isValid = await v$.value.firstName.$validate();
    if (!isValid) return;
    return agent.updateFirstName();
  });
}
function saveLastName() {
  savingBuffer.lastName.debounceUpdate(async () => {
    const isValid = await v$.value.lastName.$validate();
    if (!isValid) return;
    return agent.updateLastName();
  });
}
function saveCategory() {
  savingBuffer.category.update(agent.updateCategory);
}

async function uploadNewAvatar() {
  if (props.readonly) return;
  let checkboxText = null;
  if (agent.showUseOwnersAvatar) {
    checkboxText = "Use avatar for all Quote & Apply Websites?";
  }
  const res = await dialog.showDialog({
    component: "ChangeAvatarDialog",
    type: "Agent",
    id: agent.id,
    checkboxText
  });
  if (!res.updated) return;
  agent.avatar = res.newUrl;
}
function saveMarketingManager() {
  if (!marketingManagerValidation.value.success) return;
  savingBuffer.marketingManager.update(agent.updateMarketingManager);
}

const marketingManagerValidation = computed(() => {
  const model = v$.value.marketingManager;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
</script>
