<template>
  <v-form>
    <v-row dense justify="center">
      <v-col cols="12">
        <h3 class="text-h4 mb-3 font-weight-light">
          <template v-if="user.isGroupOne"> Invite a Downline Agent </template>
          <template v-else> Invite an Agent </template>
        </h3>
        <p class="text-body-1">
          <template v-if="user.isGroupOne">
            Create an account that you have access to and receive the difference
            between your commission level and theirs
          </template>
          <template v-else>Create a new agent</template>
        </p>

        <v-alert type="info" outlined border="left">
          Looking to invite many agents?
          <a
            @click="emit('select-type', 'referring')"
            class="text-decoration-underline"
          >
            Use your referral link!
          </a>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <basic-email-input
          v-model="advisor.email"
          required
          autofocus
          outlined
          dense
          data-testid="advisor-email"
          :prepend-inner-icon="mdiEmail"
          label="E-Mail Address"
          :disabled="loading"
          :success="emailValidation.success"
          :error-messages="emailValidation.errorMessages"
          @valid="emailIsValid = $event"
        />
      </v-col>
      <v-col cols="12">
        <div class="checkbox-width">
          <v-checkbox
            v-model="agentHasNpn"
            class="mt-1"
            label="Agent has NPN"
            data-testid="agent-has-npn"
          />
        </div>
      </v-col>
      <v-col cols="12" v-if="agentHasNpn">
        <npn-input
          v-model="advisor.npn"
          validate
          data-testid="agent-npn"
          :disabled="loading"
          :success="npnValidation.success"
          :error-messages="npnValidation.errorMessages"
          @valid="npnIsValid = $event"
          @error-message="npnErrorMessage = $event"
        />
      </v-col>
      <template v-else>
        <v-col cols="12">
          <v-text-field
            v-model="advisor.firstName"
            data-lpignore="true"
            :prepend-inner-icon="mdiAccount"
            outlined
            dense
            label="First Name"
            data-testid="agent-first-name"
            :disabled="loading"
            :success="firstNameValidation.success"
            :error-messages="firstNameValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="advisor.lastName"
            data-lpignore="true"
            :prepend-inner-icon="mdiAccountMultiple"
            outlined
            dense
            label="Last Name"
            data-testid="agent-last-name"
            :disabled="loading"
            :success="lastNameValidation.success"
            :error-messages="lastNameValidation.errorMessages"
          />
        </v-col>
      </template>

      <v-col v-if="showTopLevelAdvisor" cols="12">
        <advisor-search
          v-model="advisor.ownable"
          label="Top Level Advisor"
          persistent-hint
          hint="Who is at the top of the hierarchy? Leave blank for no hierarchy."
          data-testid="agent-advisor"
          :disabled="loading"
          :success="ownableValidation.success"
          :error-messages="ownableValidation.errorMessages"
        />
      </v-col>

      <v-col v-if="showMarketingManagerInput" cols="12">
        <marketing-manager-search
          v-model="advisor.marketingManager"
          data-testid="agent-marketing-manager"
          return-object
          :disabled="loading"
          :success="marketingManagerValidation.success"
          :error-messages="marketingManagerValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12" v-if="canAssignCommissions">
        <div class="checkbox-width">
          <v-checkbox
            v-model="advisor.assignment"
            data-testid="agent-assign-commissions"
            :label="assignmentLabel"
            :disabled="loading"
            :success="advisor.assignment"
          />
        </div>
      </v-col>
      <v-col cols="12" v-if="advisor.assignment">
        <div class="checkbox-width">
          <v-checkbox
            v-model="advisor.lockCommissions"
            label="Lock Commissions"
            hint="A commission lock disables an agent's or agency's ability to modify Commission Splits, Commission Rates, and who they assign commissions to."
            persistent-hint
            data-testid="agent-lock-commissions"
            :disabled="loading"
            :success="advisor.assignment"
          />
        </div>
      </v-col>
      <v-col cols="12" class="mt-3">
        <p style="font-size: 16px; font-weight: bold">
          Subscribe to marketing emails?
        </p>
        <v-radio-group
          v-model="advisor.marketing"
          data-testid="agent-marketing"
          row
          :disabled="loading"
          :success="marketingValidation.success"
          :error-messages="marketingValidation.errorMessages"
        >
          <v-radio :value="true" label="Yes" />
          <v-radio :value="false" label="No" />
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          class="text-none"
          data-testid="agent-create"
          :loading="loading"
          @click="createAgent"
        >
          <v-icon class="mr-1"> {{ mdiPlus }} </v-icon> Create Agent
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup>
import MarketingManagerSearch from "@/components/shared/MarketingManagerSearch.vue";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import NpnInput from "@/components/shared/NpnInput.vue";

import { inviteDownline } from "@/api/invites.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { computed, ref, watch, defineEmits } from "vue";

import { mdiEmail, mdiAccount, mdiAccountMultiple, mdiPlus } from "@mdi/js";
import useVuelidate from "@vuelidate/core";
import { useRouter } from "@/composables/compatible.composables";

const router = useRouter();
const user = useUserStore();
const snackbar = useSnackbarStore();

const emit = defineEmits(["select-type"]);

const advisor = ref({
  firstName: null,
  lastName: null,
  email: null,
  npn: null,
  marketingManager: {},
  ownable: {},
  permissions: {
    appointments: true,
    appointmentsEmails: true,
    cases: true,
    casesEmails: true,
    contractParties: true,
    contractPartiesEmails: true,
    eapps: true,
    eappsEmails: true,
    payments: true,
    paymentsEmails: true,
    quickQuotes: true,
    quickQuotesEmails: true,
    quotes: true,
    quotesEmails: true
  }
});
const agentHasNpn = ref(true);
const emailIsValid = ref(null);
const loading = ref(false);
const npnErrorMessage = ref("");
const npnIsValid = ref(null);

watch(
  () => advisor.value.ownable,
  () => {
    if (!canAssignCommissions.value) advisor.value.assignment = false;
  }
);

watch(
  () => advisor.value.assignment,
  val => {
    if (!val) advisor.value.lockCommissions = false;
  }
);

const currentAssignable = computed(() => {
  if (user.isGroupOne) {
    if (user.loginable.type === "Agency") return user.loginable;
    return user.loginable?.assignable;
  }
  return advisor.value.ownable;
});
const canAssignCommissions = computed(() => {
  return (
    currentAssignable.value?.type === "Agency" ||
    currentAssignable.value?.assignable?.type === "Agency"
  );
});
const assignmentLabel = computed(() => {
  return `Assign Commissions to ${currentAssignable.value.name}`;
});
const showTopLevelAdvisor = computed(() => {
  return !user.isGroupOne && !advisor.value.marketingManager?.id;
});
const showMarketingManagerInput = computed(() => {
  return !user.isGroupOne && !advisor.value.ownable?.id;
});

const v$ = useVuelidate(
  {
    advisor: {
      email: {
        required: Boolean,
        isValid: () => emailIsValid.value === true
      },
      ownable: {
        required: (val, { marketingManager }) =>
          user.isGroupOne || Boolean(val?.id) || Boolean(marketingManager?.id)
      },
      npn: {
        required: val => !agentHasNpn.value || Boolean(val),
        isValid: value =>
          !agentHasNpn.value || (Boolean(value) && Boolean(npnIsValid.value))
      },
      firstName: {
        required: val => agentHasNpn.value || Boolean(val)
      },
      lastName: {
        required: val => agentHasNpn.value || Boolean(val)
      },
      marketingManager: {
        required: (val, { ownable }) =>
          user.isGroupOne || Boolean(val?.id) || Boolean(ownable?.id)
      },
      marketing: {
        required: val => [true, false].includes(val)
      }
    }
  },
  { advisor },
  { $scope: null, $autoDirty: true }
);

const ownableValidation = computedValidation(v$.value.advisor.ownable, {
  required: "Required"
});
const emailValidation = computedValidation(v$.value.advisor.email, {
  required: "Required",
  isValid: "Invalid Email"
});

const npnValidationErrorMessage = computed(
  () => npnErrorMessage.value || "Invalid NPN"
);
const npnValidation = computedValidation(v$.value.advisor.npn, {
  required: "Required",
  isValid: npnValidationErrorMessage
});
const firstNameValidation = computedValidation(v$.value.advisor.firstName, {
  required: "Required"
});
const lastNameValidation = computedValidation(v$.value.advisor.lastName, {
  required: "Required"
});
const marketingManagerValidation = computedValidation(
  v$.value.advisor.marketingManager,
  { required: "Required" }
);
const marketingValidation = computedValidation(v$.value.advisor.marketing, {
  required: "Required"
});
async function createAgent() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  try {
    loading.value = true;

    const body = {
      email: advisor.value.email,
      npn: undefined,
      subscribe_to_marketing_emails: advisor.value.marketing,
      assign_commissions: undefined,
      lock_commissions: undefined,
      marketing_manager_id: undefined,
      ownable_id: undefined,
      ownable_type: undefined,
      first_name: undefined,
      last_name: undefined
    };
    if (agentHasNpn.value) {
      body.npn = advisor.value.npn;
    } else {
      body.first_name = advisor.value.firstName;
      body.last_name = advisor.value.lastName;
    }

    if (advisor.value.assignment) {
      body.assign_commissions = advisor.value.assignment;
    }
    if (advisor.value.lockCommissions) {
      body.lock_commissions = advisor.value.lockCommissions;
    }

    if (!user.isGroupOne && advisor.value.ownable?.id) {
      body.ownable_id = advisor.value.ownable.id;
      body.ownable_type = advisor.value.ownable.type;
      body.marketing_manager_id = advisor.value.ownable.marketing_manager_id;
    } else if (!user.isGroupOne) {
      body.marketing_manager_id = advisor.value.marketingManager.id;
    }

    const id = await inviteDownline(body);
    router.push({
      name: "AgentView",
      params: {
        id
      }
    });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(error),
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}
</script>
