<template>
  <v-card color="section">
    <v-card-title>Product Details</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" lg="4" md="6">
          <v-text-field
            v-model="name"
            label="Name"
            data-lpignore="true"
            data-testid="name"
            outlined
            dense
            :prepend-inner-icon="mdiCubeOutline"
            @input="updateName"
            v-bind="nameValidation"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.name.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <v-select
            v-model="line"
            data-testid="line"
            outlined
            dense
            label="Line"
            placeholder="Select Line"
            :prepend-inner-icon="mdiCube"
            :items="LINES"
            v-bind="lineValidation"
            @change="updateLine"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.line.controller.value"
              />
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <v-select
            v-model="linePercentage"
            data-testid="line-percentage"
            outlined
            dense
            label="Line Percentage"
            :prepend-inner-icon="mdiPercent"
            :disabled="isAnnuity"
            :items="LINE_PERCENTAGES"
            v-bind="linePercentageValidation"
            @change="updateLinePercentage"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.linePercentage.controller.value"
              />
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <v-text-field
            v-model="activeYears"
            data-lpignore="true"
            data-testid="active-years"
            outlined
            dense
            label="Active Years"
            :prepend-inner-icon="mdiCalendar"
            v-bind="activeYearsValidation"
            @input="updateActiveYears"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.activeYears.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <v-text-field
            label="BackNine Policy Fee"
            data-testid="backnine-policy-fee"
            disabled
            outlined
            dense
            success
            :prepend-inner-icon="mdiCurrencyUsd"
            :value="125"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />

    <v-card-title>Product Percentages</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" lg="4" md="6">
          <v-text-field
            v-model="streetFirst"
            data-testid="street-first"
            data-lpignore="true"
            outlined
            dense
            label="Street First"
            type="text"
            inputmode="numeric"
            :prepend-inner-icon="mdiPercent"
            v-bind="streetFirstValidation"
            @input="updateStreetFirst"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.streetFirst.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <v-text-field
            v-model="streetExcess"
            data-lpignore="true"
            outlined
            dense
            label="Street Excess"
            type="text"
            inputmode="numeric"
            data-testid="street-excess"
            :prepend-inner-icon="mdiPercent"
            @input="updateStreetExcess"
            v-bind="streetExcessValidation"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.streetExcess.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <v-text-field
            v-model="streetRenewal"
            data-lpignore="true"
            outlined
            dense
            label="Street Renewal"
            type="text"
            inputmode="numeric"
            data-testid="street-renewal"
            :prepend-inner-icon="mdiPercent"
            @input="updateStreetRenewal"
            v-bind="streetRenewalValidation"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.streetRenewal.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6" v-if="showPua">
          <v-text-field
            v-model="streetPua"
            data-lpignore="true"
            outlined
            dense
            label="Street PUA"
            type="text"
            inputmode="numeric"
            data-testid="street-pua"
            :prepend-inner-icon="mdiPercent"
            @input="updateStreetPua"
            v-bind="streetPuaValidation"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.streetPua.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <v-text-field
            v-model="totalFirst"
            data-lpignore="true"
            outlined
            dense
            label="Total First"
            type="text"
            inputmode="numeric"
            data-testid="total-first"
            :prepend-inner-icon="mdiPercent"
            @input="updateTotalFirst"
            v-bind="totalFirstValidation"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.totalFirst.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <v-text-field
            v-model="totalExcess"
            data-lpignore="true"
            outlined
            dense
            label="Total Excess"
            type="text"
            inputmode="numeric"
            data-testid="total-excess"
            :prepend-inner-icon="mdiPercent"
            @input="updateTotalExcess"
            v-bind="totalExcessValidation"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.totalExcess.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <v-text-field
            v-model="totalRenewal"
            data-lpignore="true"
            outlined
            dense
            label="Total Renewal"
            type="text"
            inputmode="numeric"
            data-testid="total-renewal"
            :prepend-inner-icon="mdiPercent"
            @input="updateTotalRenewal"
            v-bind="totalRenewalValidation"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.totalRenewal.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6" v-if="showPua">
          <v-text-field
            v-model="totalPua"
            data-lpignore="true"
            outlined
            dense
            label="Total PUA"
            type="text"
            inputmode="numeric"
            data-testid="total-pua"
            :prepend-inner-icon="mdiPercent"
            @input="updateTotalPua"
            v-bind="totalPuaValidation"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.totalPua.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <v-text-field
            v-model="chargeback"
            data-lpignore="true"
            outlined
            dense
            label="Chargeback"
            data-testid="chargeback"
            :prepend-inner-icon="mdiCreditCard"
            :success="Boolean(chargeback)"
            @input="updateChargeback"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.chargeback.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6" v-if="showSurrenderPeriod">
          <v-text-field
            v-model="surrenderPeriod"
            data-lpignore="true"
            outlined
            dense
            label="Surrender Period"
            type="text"
            inputmode="numeric"
            data-testid="surrender-period"
            :prepend-inner-icon="mdiCalendar"
            :success="Boolean(surrenderPeriod)"
            @input="updateSurrenderPeriod"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.surrenderPeriod.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6" v-if="showRollingTarget">
          <v-text-field
            v-model="rollingTarget"
            data-lpignore="true"
            outlined
            dense
            label="Rolling Target"
            data-testid="rolling-target"
            :prepend-inner-icon="mdiSack"
            :success="Boolean(rollingTarget)"
            @input="updateRollingTarget"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.rollingTarget.controller.value"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6" v-if="showAgeNearest">
          <div class="checkbox-width">
            <v-checkbox
              v-model="ageNearest"
              dense
              label="Age Nearest"
              type="text"
              inputmode="numeric"
              class="mt-1"
              data-testid="age-nearest"
              :success="Boolean(ageNearest)"
              @change="updateAgeNearest"
            >
              <template #label>
                Age Nearest
                <active-save-indicator
                  class="ml-2"
                  :controller="savingBuffer.ageNearest.controller.value"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="eDelivery"
              dense
              class="mt-1"
              data-testid="e-delivery"
              :success="eDelivery"
              @change="updateEDelivery"
            >
              <template #label>
                Carrier eDelivery
                <active-save-indicator
                  class="ml-2"
                  :controller="savingBuffer.eDelivery.controller.value"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" v-if="showConversion">
          <v-textarea
            v-model="conversion"
            label="Conversion"
            outlined
            data-testid="conversion"
            :prepend-inner-icon="mdiText"
            :success="Boolean(conversion)"
            @input="updateConversion"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-2"
                :controller="savingBuffer.conversion.controller.value"
              />
            </template>
          </v-textarea>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="advisorGuide"
            label="Advisor Guide"
            clearable
            item-value="id"
            item-text="name"
            outlined
            dense
            data-testid="advisor-guide"
            :prepend-inner-icon="mdiAccountTie"
            :items="advisorGuides"
            :success="Boolean(advisorGuide)"
            @change="updateAdvisorGuide"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-2"
                :controller="savingBuffer.advisorGuide.controller.value"
              />
            </template>
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="consumerGuide"
            label="Consumer Guide"
            clearable
            outlined
            item-value="id"
            item-text="name"
            dense
            data-testid="consumer-guide"
            :prepend-inner-icon="mdiAccount"
            :success="Boolean(consumerGuide)"
            :items="consumerGuides"
            @change="updateConsumerGuide"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-2"
                :controller="savingBuffer.consumerGuide.controller.value"
              />
            </template>
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="underwritingGuide"
            label="Underwriting Guide"
            clearable
            outlined
            dense
            item-value="id"
            item-text="name"
            data-testid="underwriting-guide"
            :prepend-inner-icon="mdiStethoscope"
            :success="Boolean(underwritingGuide)"
            :items="underwritingGuides"
            @change="updateUnderwritingGuide"
          >
            <template #append-outer>
              <active-save-indicator
                class="ml-2"
                :controller="savingBuffer.underwritingGuide.controller.value"
              />
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { LINES, LINE_PERCENTAGES } from "@/factories/Product";

import { storeToRefs } from "pinia";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed, watch } from "vue";
import { useProductView } from "@/stores/product-view";
import useVuelidate from "@vuelidate/core";
import {
  mdiCubeOutline,
  mdiCube,
  mdiCalendar,
  mdiCurrencyUsd,
  mdiPercent,
  mdiCreditCard,
  mdiSack,
  mdiText,
  mdiAccountTie,
  mdiAccount,
  mdiStethoscope
} from "@mdi/js";
import { computedValidation } from "@/util/helpers";

const productView = useProductView();

const {
  guides,
  showConversion,
  conversion,
  line,
  linePercentage,
  planType,
  name,
  activeYears,
  streetFirst,
  streetExcess,
  streetRenewal,
  streetPua,
  totalFirst,
  totalExcess,
  totalRenewal,
  totalPua,
  chargeback,
  surrenderPeriod,
  rollingTarget,
  ageNearest,
  eDelivery,
  advisorGuide,
  underwritingGuide,
  consumerGuide
} = storeToRefs(productView);

const v$ = useVuelidate(
  {
    name: { required: Boolean },
    planType: { required: Boolean },
    line: { required: Boolean },
    linePercentage: { required: Boolean },
    activeYears: { required: Boolean },
    streetFirst: { required: Boolean },
    streetExcess: { required: Boolean },
    streetRenewal: { required: Boolean },
    streetPua: { required: val => !showPua.value || Boolean(val) },
    totalFirst: { required: Boolean },
    totalExcess: { required: Boolean },
    totalRenewal: { required: Boolean },
    totalPua: { required: val => !showPua.value || Boolean(val) }
  },
  {
    name,
    planType,
    line,
    linePercentage,
    activeYears,
    streetFirst,
    streetExcess,
    streetRenewal,
    streetPua,
    totalFirst,
    totalExcess,
    totalRenewal,
    totalPua
  },
  { $scope: false, $autoDirty: true }
);

const savingBuffer = {
  activeYears: useActiveSave(),
  ageNearest: useActiveSave(),
  carrier: useActiveSave(),
  eDelivery: useActiveSave(),
  chargeback: useActiveSave(),
  line: useActiveSave(),
  linePercentage: useActiveSave(),
  name: useActiveSave(),
  planType: useActiveSave(),
  rollingTarget: useActiveSave(),
  streetExcess: useActiveSave(),
  streetFirst: useActiveSave(),
  streetRenewal: useActiveSave(),
  streetPua: useActiveSave(),
  surrenderPeriod: useActiveSave(),
  totalExcess: useActiveSave(),
  totalFirst: useActiveSave(),
  totalRenewal: useActiveSave(),
  totalPua: useActiveSave(),
  conversion: useActiveSave(),
  advisorGuide: useActiveSave(),
  consumerGuide: useActiveSave(),
  underwritingGuide: useActiveSave()
};

const nameValidation = computedValidation(v$.value.name, {
  required: "Required"
});
const lineValidation = computedValidation(v$.value.line, {
  required: "Required"
});
const linePercentageValidation = computedValidation(v$.value.linePercentage, {
  required: "Required"
});
const activeYearsValidation = computedValidation(v$.value.activeYears, {
  required: "Required"
});
const streetFirstValidation = computedValidation(v$.value.streetFirst, {
  required: "Required"
});
const streetExcessValidation = computedValidation(v$.value.streetExcess, {
  required: "Required"
});
const streetRenewalValidation = computedValidation(v$.value.streetRenewal, {
  required: "Required"
});
const streetPuaValidation = computedValidation(v$.value.streetPua, {
  required: "Required"
});
const totalFirstValidation = computedValidation(v$.value.totalFirst, {
  required: "Required"
});
const totalExcessValidation = computedValidation(v$.value.totalExcess, {
  required: "Required"
});
const totalRenewalValidation = computedValidation(v$.value.totalRenewal, {
  required: "Required"
});
const totalPuaValidation = computedValidation(v$.value.totalPua, {
  required: "Required"
});

const underwritingGuides = computed(() =>
  guides.value.filter(({ category }) => category === "Underwriting Guide")
);
const consumerGuides = computed(() =>
  guides.value.filter(({ category }) => category === "Consumer Product Guide")
);
const advisorGuides = computed(() =>
  guides.value.filter(({ category }) => category === "Advisor Product Guide")
);
const showPua = computed(() => planType.value === "Whole Life");
const showAgeNearest = computed(() =>
  ["linked_benefit", "life", "variable_life", "ltc"].includes(line.value)
);
const showRollingTarget = computed(() =>
  ["linked_benefit", "life", "variable_life"].includes(line.value)
);
const showSurrenderPeriod = computed(() =>
  ["annuity", "linked_benefit", "life", "variable_life"].includes(line.value)
);
const isAnnuity = computed(() => line.value === "annuity");
watch(line, () => {
  if (isAnnuity.value) linePercentage.value = 5;
});

function updateName() {
  savingBuffer.name.debounceUpdate(productView.updateName);
}
function updateLine() {
  savingBuffer.line.debounceUpdate(productView.updateLine);
}
function updateLinePercentage() {
  savingBuffer.linePercentage.debounceUpdate(productView.updateLinePercentage);
}
function updateActiveYears() {
  savingBuffer.activeYears.debounceUpdate(productView.updateActiveYears);
}
function updateStreetFirst() {
  savingBuffer.streetFirst.debounceUpdate(productView.updateStreetFirst);
}
function updateStreetExcess() {
  savingBuffer.streetExcess.debounceUpdate(productView.updateStreetExcess);
}
function updateStreetRenewal() {
  savingBuffer.streetRenewal.debounceUpdate(productView.updateStreetRenewal);
}
function updateStreetPua() {
  savingBuffer.streetPua.debounceUpdate(productView.updateStreetPua);
}
function updateTotalFirst() {
  savingBuffer.totalFirst.debounceUpdate(productView.updateTotalFirst);
}
function updateTotalExcess() {
  savingBuffer.totalExcess.debounceUpdate(productView.updateTotalExcess);
}
function updateTotalRenewal() {
  savingBuffer.totalRenewal.debounceUpdate(productView.updateTotalRenewal);
}
function updateTotalPua() {
  savingBuffer.totalPua.debounceUpdate(productView.updateTotalPua);
}
function updateChargeback() {
  savingBuffer.chargeback.debounceUpdate(productView.updateChargeback);
}
function updateSurrenderPeriod() {
  savingBuffer.surrenderPeriod.debounceUpdate(
    productView.updateSurrenderPeriod
  );
}
function updateRollingTarget() {
  savingBuffer.rollingTarget.debounceUpdate(productView.updateRollingTarget);
}
function updateAgeNearest() {
  savingBuffer.ageNearest.debounceUpdate(productView.updateAgeNearest);
}
function updateEDelivery() {
  savingBuffer.eDelivery.debounceUpdate(productView.updateEDelivery);
}
function updateConversion() {
  savingBuffer.conversion.debounceUpdate(productView.updateConversion);
}
function updateAdvisorGuide() {
  savingBuffer.advisorGuide.debounceUpdate(productView.updateAdvisorGuide);
}
function updateConsumerGuide() {
  savingBuffer.consumerGuide.debounceUpdate(productView.updateConsumerGuide);
}
function updateUnderwritingGuide() {
  savingBuffer.underwritingGuide.debounceUpdate(
    productView.updateUnderwritingGuide
  );
}
</script>
