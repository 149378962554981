<template>
  <v-row
    id="editor-menu-container"
    class="ma-0"
    align="end"
    style="
      position: fixed;
      right: 20px;
      border-radius: 50px;
      width: 50px;
      height: 268px;
    "
    :style="{
      bottom: $vuetify.breakpoint.smAndDown ? '60px' : '20px'
    }"
  >
    <div style="position: relative">
      <div style="position: absolute; bottom: 0; left: -315px">
        <v-card
          v-if="showPositions && rectangle"
          class="mr-3"
          width="300px"
          elevation="8"
        >
          <v-card-title> Field Data </v-card-title>
          <v-card-text class="pb-0">
            <template v-if="!rectangle.isFieldOption">
              <v-text-field
                v-model="width"
                data-lpignore="true"
                type="text"
                inputmode="numeric"
                outlined
                dense
                label="Width"
              />
              <v-text-field
                v-model="height"
                data-lpignore="true"
                type="text"
                inputmode="numeric"
                outlined
                dense
                label="Height"
              />
            </template>
            <v-text-field
              v-model="x"
              data-lpignore="true"
              type="text"
              inputmode="numeric"
              outlined
              dense
              label="X"
            />
            <v-text-field
              v-model="y"
              data-lpignore="true"
              type="text"
              inputmode="numeric"
              outlined
              dense
              label="Y"
            />
          </v-card-text>
        </v-card>
      </div>
      <v-card elevation="8">
        <v-row dense class="py-2 ma-0">
          <v-col v-if="rectangle" cols="12">
            <v-btn icon @click="showPositions = !showPositions">
              <v-icon v-if="showPositions">{{ mdiCogOff }}</v-icon>
              <v-icon v-else>{{ mdiCog }}</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="$emit('toggle-visibility')"
                >
                  <v-icon v-if="visible">{{ mdiEyeOff }}</v-icon>
                  <v-icon v-else>{{ mdiEye }}</v-icon>
                </v-btn>
              </template>
              <span v-if="visible"> Hide Rectangle </span>
              <span v-else> Show Rectangle </span>
            </v-tooltip>
          </v-col>
          <v-col v-if="rectangle?.isFieldOption" cols="12">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="$emit('toggle-radio-value-visibility')"
                >
                  <v-icon v-if="allRadioValuesVisible">{{
                    mdiCircleOutline
                  }}</v-icon>
                  <v-icon v-else>{{ mdiCircleMultipleOutline }}</v-icon>
                </v-btn>
              </template>
              <span v-if="allRadioValuesVisible">
                Only Show Viewed Radio Value
              </span>
              <span v-else> Show All Radio Values </span>
            </v-tooltip>
          </v-col>
          <v-col cols="12">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="$emit('toggle-other-visibility')"
                >
                  <v-icon v-if="allVisible">{{ mdiTableEyeOff }}</v-icon>
                  <v-icon v-else>{{ mdiTableEye }}</v-icon>
                </v-btn>
              </template>
              <span v-if="allVisible"> Hide Other Rectangles </span>
              <span v-else> Show Other Rectangles </span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" style="cursor: move" id="editor-menu">
            <v-icon>{{ mdiDrag }}</v-icon>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-row>
</template>

<script>
import {
  mdiCogOff,
  mdiCog,
  mdiEyeOff,
  mdiEye,
  mdiCircleOutline,
  mdiCircleMultipleOutline,
  mdiTableEyeOff,
  mdiTableEye,
  mdiDrag
} from "@mdi/js";
export default {
  props: {
    pdfType: String,
    canCreateRectangle: Boolean,
    rectangle: Object,
    visible: Boolean,
    allVisible: Boolean,
    allRadioValuesVisible: Boolean
  },
  data: () => ({
    showPositions: false,
    mdiCogOff,
    mdiCog,
    mdiEyeOff,
    mdiEye,
    mdiCircleOutline,
    mdiCircleMultipleOutline,
    mdiTableEyeOff,
    mdiTableEye,
    mdiDrag
  }),
  mounted() {
    dragElement(document.getElementById("editor-menu"));
    const parentElement = document.getElementById("editor-menu-container");
    function dragElement(elmnt) {
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      elmnt.onmousedown = dragMouseDown;

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        parentElement.style.top = parentElement.offsetTop - pos2 + "px";
        parentElement.style.left = parentElement.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  },
  computed: {
    width: {
      get() {
        return this.rectangle?.width;
      },
      set(width) {
        this.$emit("update:rectangle", { ...this.rectangle, width });
      }
    },
    height: {
      get() {
        return this.rectangle?.height;
      },
      set(height) {
        this.$emit("update:rectangle", { ...this.rectangle, height });
      }
    },
    x: {
      get() {
        return this.rectangle?.x;
      },
      set(x) {
        this.$emit("update:rectangle", { ...this.rectangle, x });
      }
    },
    y: {
      get() {
        return this.rectangle?.y;
      },
      set(y) {
        this.$emit("update:rectangle", { ...this.rectangle, y });
      }
    }
  }
};
</script>
