<template>
  <v-data-table
    class="transparent-data-table"
    show-expand
    item-key="additional.id"
    data-testid="alias-table"
    :items="table.mappedItems.value"
    :headers="table.tableHeaders.value"
    :loading="table.loading.value"
  >
    <template #top>
      <v-row class="ma-0">
        <v-spacer />
        <v-btn
          icon
          :disabled="table.loading.value"
          data-testid="refresh"
          @click="getData"
        >
          <v-icon>{{ mdiRefresh }}</v-icon>
        </v-btn>
        <v-btn icon data-testid="create" @click="createNewAlias">
          <v-icon>{{ mdiPlus }}</v-icon>
        </v-btn>
      </v-row>
    </template>
    <template #[`expanded-item`]="{ item, headers }">
      <td :colspan="headers.length">
        <v-row class="ma-0">
          <v-col cols="12" data-testid="included-states">
            Included States:
            {{ listToSentence(item.additional.includedStates) }}
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="12" data-testid="excluded-states">
            Excluded States
            {{ listToSentence(item.additional.excludedStates) }}
          </v-col>
        </v-row>
      </td>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn icon color="accent" data-testid="edit" @click="editAlias(item)">
        <v-icon>{{ mdiPencil }}</v-icon>
      </v-btn>
      <v-btn
        icon
        color="error"
        data-testid="delete"
        @click="destroyAlias(item)"
      >
        <v-icon>{{ mdiDelete }}</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script setup>
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import AliasCreateOrEditDialog from "@/components/products/dialogs/AliasCreateOrEditDialog.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { mdiRefresh, mdiPlus, mdiPencil, mdiDelete } from "@mdi/js";
import { useTable } from "@/composables/table.composable";
import { parseErrorMessage, listToSentence } from "@/util/helpers";

import { deleteAlias, getProductAliases } from "@/api/products.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { markRaw, defineProps } from "vue";

const props = defineProps({ id: { type: Number, required: true } });

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const table = useTable({
  getData: () => getProductAliases(props.id),
  headers: [
    new TableHeader({
      text: "Engine",
      value: "engine",
      map: "engine",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Parameters",
      value: "parameters",
      map: "parameters",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      width: "120px"
    })
  ]
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
async function createNewAlias() {
  const res = await dialog.showDialog({
    component: markRaw(AliasCreateOrEditDialog),
    productId: props.id
  });
  if (res?.alias) getData();
}

async function editAlias(item) {
  const res = await dialog.showDialog({
    component: markRaw(AliasCreateOrEditDialog),
    value: item.additional,
    productId: props.id
  });
  if (!res?.alias) return;
  getData();
}
async function destroyAlias(item) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete Alias",
    subtitle: "Please confirm your intent ",
    func: async () => {
      await deleteAlias(props.id, item.additional.id);
      getData();
    }
  });
}

getData();
</script>
