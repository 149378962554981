<template>
  <v-row dense>
    <v-col
      v-for="(item, index) in resources"
      :key="index"
      cols="12"
      lg="3"
      md="4"
      sm="6"
    >
      <quote-method
        v-if="item.href"
        :href="item.href"
        target="_blank"
        :item="item"
      />
      <quote-method v-else-if="item.to" :to="item.to" :item="item" />
      <quote-method v-else @click="item.click" :item="item" />
    </v-col>
  </v-row>
</template>

<script>
import { getRangeOfExposureLink } from "@/api/boss.service";
import {
  navigateToExternalLink,
  openWindowWithPost,
  parseErrorMessage
} from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import { getResource } from "@/api/boss.service";

import QuoteMethod from "@/components/quotes/QuoteMethod.vue";
import { getAgentSummary } from "@/api/agents.service";
import { getAgencySummary } from "@/api/agencies.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiApplicationImport, mdiPageNext } from "@mdi/js";
export default {
  components: { QuoteMethod },
  data() {
    const fixed = {
      text: "Fixed",
      provider: "annuity_rate_watch",
      loading: false,
      ssoAction: "fixed_annuities",
      description: "Growth based on an annually declared rate.",
      type: "Annuity",
      img: require("@/assets/img/annuity-rate-watch.png")
    };
    fixed.click = () => this.getLink(fixed);

    const indexed = {
      text: "Indexed",
      provider: "annuity_rate_watch",
      loading: false,
      ssoAction: "fixed_indexed",
      description: "Growth based on the performance of an index.",
      type: "Annuity",
      img: require("@/assets/img/annuity-rate-watch.png")
    };
    indexed.click = () => this.getLink(indexed);
    const incomeRider = {
      text: "Income Rider",
      provider: "annuity_rate_watch",
      ssoAction: "income_rider",
      loading: false,
      description: "Indexed growth with guaranteed lifetime income.",
      type: "Annuity",
      img: require("@/assets/img/annuity-rate-watch.png")
    };
    incomeRider.click = () => this.getLink(incomeRider);
    const myga = {
      text: "MYGA",
      provider: "annuity_rate_watch",
      loading: false,
      ssoAction: "multi_year",
      description:
        "MYGA (Multi-Year Guaranteed Annuity) is a growth based fixed annuity with a guaranteed rate.",
      type: "Annuity",
      img: require("@/assets/img/annuity-rate-watch.png")
    };
    myga.click = () => this.getLink(myga);
    const spia = {
      text: "SPIA",
      provider: "annuity_rate_watch",
      loading: false,
      ssoAction: "spia",
      description: "Guaranteed income stream using a single premium.",
      type: "Annuity",
      img: require("@/assets/img/annuity-rate-watch.png")
    };
    spia.click = () => this.getLink(spia);

    const ltciRangeOfExposure = {
      text: "LTC Range of Exposure",
      description: "Determine how costly long-term care can be.",
      type: "LTC",
      loading: false,
      icon: mdiApplicationImport,
      iconColor: "primary"
    };
    ltciRangeOfExposure.click = () =>
      this.openRangeOfExposure(ltciRangeOfExposure);

    const ltciCostBenefit = {
      text: "LTC Cost & Benefit",
      description:
        "Cost & Benefit analyzer helps client make informed final decisions in 15-20 minutes",
      type: "LTC",
      loading: false,
      icon: mdiApplicationImport,
      iconColor: "primary"
    };
    ltciCostBenefit.click = () => this.openCostBenefit(ltciCostBenefit);

    const user = useUserStore();
    let action = {};
    const loginable = user.loginable;

    let npn = loginable.npn;
    if (loginable?.signer?.id) npn = loginable.signer?.npn;

    if (loginable.approved_domain_url) {
      action = { href: loginable.approved_domain_url };
    } else if (npn) {
      action = {
        click: () =>
          this.showDialog({
            component: "CreateQuoteAndApplySite",
            scrollable: true
          })
      };
    } else {
      action = {
        click: () =>
          this.showDialog({
            component: "ConfirmationDialog",
            title: "Missing NPN",
            subtitle:
              "You'll need your National Producer Number (NPN) added to use Quote & Apply. To do this, set a NPN in your settings.",
            func: () => {
              if (loginable?.signer?.id) {
                this.$router.push({
                  name: "AdvisorSettings",
                  params: {
                    id: loginable.signer.id,
                    type: loginable.signer.type
                  },
                  query: { page: "basics" }
                });
              } else {
                this.$router.push({
                  name: "LoggedInUserSettings",
                  query: { page: "basics" }
                });
              }
            }
          })
      };
    }

    const rawResources = [
      {
        text: "Quote & Apply",
        class: "quote-and-apply-card",
        description:
          "Agents and consumers can quote accidental death, IUL, final expense, linked benefit, long term care, term, universal life, and whole life.",
        type: "Life, LTC, and Linked Benefit",
        img: require("@/assets/img/quote-and-apply-logo.png"),
        ...action
      },
      {
        text: "Request a Quote",
        description:
          "Still need help? Create a quote request for BackNine to quote your case.",
        type: "All Lines",
        key: "requestAQuote",
        class: "request-a-quote-card",
        to: { name: "CreateQuote" },
        img: "https://d1usw6tyldpxhi.cloudfront.net/BOSS-Logo.png"
      },
      {
        text: "Impaired Risk",
        description:
          "Send client's information to multiple carriers and compare underwriting offers.",
        type: "Life",
        key: "impairedRiskQuote",
        class: "impaired-risk-card",
        to: { name: "ImpairedRiskQuoteCreate" },
        img: "https://d1usw6tyldpxhi.cloudfront.net/BOSS-Logo.png"
      },
      {
        text: "More Quoting Options",
        description:
          "Choose other life, long term care, and annuity quoting engines.",
        type: "All Lines",
        click: () => (this.showAllOptions = true),
        icon: mdiPageNext,
        iconColor: "primary"
      },
      {
        text: "iPipeline",
        href: "//lifepipe.ipipeline.com/LTSearch.aspx?GAID=2479",
        description:
          "Agents can quote guaranteed universal life, return of premium term, and term. Requires a BackNine iPipeline Account.",
        type: "Life",
        img: require("@/assets/img/ipipeline.png")
      },
      fixed,
      indexed,
      incomeRider,
      myga,
      spia,
      {
        text: "Traditional LTCi",
        click: () => {
          const form = document.createElement("form");
          form.style.display = "none";
          form.method = "post";
          form.action = "https://www.ltcselect.com";
          form.target = "_blank";

          const inputAction = document.createElement("input");
          inputAction.type = "hidden";
          inputAction.name = "Action";
          inputAction.value = "SetLogin";
          form.appendChild(inputAction);

          const inputAccountName = document.createElement("input");
          inputAccountName.type = "hidden";
          inputAccountName.name = "AccountName";
          inputAccountName.value = "b9user";
          form.appendChild(inputAccountName);

          const inputPassword = document.createElement("input");
          inputPassword.type = "hidden";
          inputPassword.name = "Password";
          inputPassword.value = "b9pw";
          form.appendChild(inputPassword);

          document.body.appendChild(form);
          form.submit();
        },
        description: "Quote one or more insurers side-by-side",
        type: "LTC",
        img: require("@/assets/img/stratecision.jpg")
      },
      ltciRangeOfExposure,
      ltciCostBenefit,
      {
        text: "State Life Care Solutions Calculator",
        href: "https://www.oneamerica.com/caresolutionscalculator/index",
        description:
          "Quickly and efficiently quote key figures for Asset-Care. Print the quotes in a simple format for clients to digest and see a visual on how the product works",
        type: "Linked Benefit",
        img: require("@/assets/img/oneamerica.jpeg")
      },
      {
        text: "WinFlex Web",
        href: "https://www.winflexweb.com/",
        description:
          "A standard entry to generate illustrations for multiple life insurance carriers",
        type: "Life",
        img: require("@/assets/img/zinnia.png")
      }
    ].filter(Boolean);
    return {
      rawResources,
      showAllOptions: false
    };
  },
  computed: {
    ...mapState(useUserStore, ["loginable"]),
    resources() {
      if (!this.showAllOptions) return this.rawResources.slice(0, 4);
      return this.rawResources.filter(
        val => val.text !== "More Quoting Options"
      );
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    showQuoteRequest() {
      this.$emit("show-quote-request");
    },
    getLink(item) {
      item.loading = true;
      getResource(item.provider, item.ssoAction)
        .then(response => {
          if (!response.data.link) {
            return;
          }
          navigateToExternalLink(response.data.link);
        })
        .finally(() => {
          item.loading = false;
        });
    },
    openRangeOfExposure(item) {
      item.loading = true;
      getRangeOfExposureLink()
        .then(url => {
          navigateToExternalLink(url);
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        })
        .finally(() => {
          item.loading = false;
        });
    },
    async openCostBenefit(item) {
      item.loading = true;

      try {
        const func =
          this.loginable.type === "Agent" ? getAgentSummary : getAgencySummary;
        const { advisor } = await func(this.loginable.id);
        const {
          phoneWork: phone,
          businessAddress,
          firstName,
          lastName,
          name,
          email
        } = advisor;

        if (!phone) {
          item.loading = false;
          return this.showErrorSnackbar({
            message:
              "Please add a work phone number to use this tool (available under Settings > Basics)"
          });
        } else if (!businessAddress.state) {
          item.loading = false;
          return this.showErrorSnackbar({
            message:
              "Please add a business address to use this tool (available under Settings > Basics)"
          });
        }

        openWindowWithPost("https://www.ltcicalc.com/sso.cfm?agency=BackNine", {
          UserName: email,
          FirstName: firstName || name,
          LastName: lastName || name,
          Email: email,
          Phone: phone,
          State: businessAddress.state,
          LogoutReturnUrl: "https://app.back9ins.com"
        });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        item.loading = false;
      }
    }
  }
};
</script>
