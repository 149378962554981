<template>
  <v-fade-transition mode="out-in">
    <data-card-list
      v-if="condensedView"
      key="list"
      ref="dataCardList"
      @toggle-condensed-view="condensedView = !condensedView"
      @code="codeDialog"
      :delete-dialog="deleteDialog"
    />
    <v-data-table
      v-else
      key="table"
      class="pa-0"
      no-data-text="No websites found, click add to get started."
      :headers="headers"
      :items="table.mappedItems"
      :server-items-length="table.meta.total"
      :loading="loading"
      @update:options="updateOptions"
      must-sort
      :options="options"
      :footer-props="footerProps"
      dense
    >
      <template #top>
        <v-row class="ma-0 pa-3" align="center" dense>
          <h1 class="text-h5">Widgets & Websites</h1>
          <v-spacer />
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-on="on"
                v-bind="attrs"
                class="text-none"
                @click="condensedView = true"
              >
                <v-icon> {{ mdiDatabaseOff }} </v-icon>
              </v-btn>
            </template>
            <span>Show Condensed View</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-on="on"
                v-bind="attrs"
                class="text-none"
                @click="getData"
              >
                <v-icon> {{ mdiRefresh }} </v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>
          <v-col cols="12">
            <generic-table-filter-header
              :show-view-filter="isGroupTwoPlus"
              :key="table.text"
              :value="filter"
              :headers="allHeaders"
              :loading="loading"
              @update="updateFilter"
            />
          </v-col>
        </v-row>
      </template>
      <template #[`item.domain`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-chip
              label
              color="primary"
              v-on="on"
              v-bind="attrs"
              :to="{
                name: 'ApprovedDomains',
                params: { id: item.additional.id }
              }"
            >
              <v-icon small class="mr-1">{{ mdiCogs }}</v-icon>
              <div
                :class="{
                  'truncate-400': $vuetify.breakpoint.mdAndUp,
                  'truncate-200': $vuetify.breakpoint.smAndDown
                }"
              >
                {{ item.domain }}
              </div>
            </v-chip>
          </template>
          <span>Customize Settings for {{ item.domain }}</span>
        </v-tooltip>
      </template>
      <template #[`item.startPage`]="{ item }">
        <start-page-formatter :value="item.startPage" />
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              :href="`//${item.domain}`"
              v-bind="attrs"
              v-on="on"
              target="_blank"
            >
              <v-icon color="primary">{{ mdiOpenInNew }}</v-icon>
            </v-btn>
          </template>
          <span>Go to {{ item.domain }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn icon @click="codeDialog(item.additional)">
                <v-icon :color="item.additional.domainKey ? 'green' : 'grey'">
                  {{ item.additional.domainKey ? mdiCodeTags : mdiClock }}
                </v-icon>
              </v-btn>
            </span>
          </template>
          <span>
            {{
              item.additional.domainKey
                ? "Open Quote & Apply Snippet"
                : "Pending"
            }}
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              @click="deleteDialog(item)"
              :loading="item.additional.tableHelpers.deleting"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="red">{{ mdiDelete }}</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-fade-transition>
</template>

<script>
import StartPageFormatter from "@/components/shared/formatters/StartPageFormatter.vue";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import DataCardList from "@/components/quote-and-apply/DataCardList.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import Table from "@/classes/data-table/Table";

import { parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import {
  archiveApprovedDomain,
  getApprovedDomains
} from "@/api/approved-domain.service";
import { startPageItems } from "@/data/filter-options";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

import {
  mdiDatabaseOff,
  mdiRefresh,
  mdiCogs,
  mdiOpenInNew,
  mdiCodeTags,
  mdiClock,
  mdiDelete
} from "@mdi/js";

export default {
  name: "QuoteAndApplySites",
  components: { DataCardList, GenericTableFilterHeader, StartPageFormatter },
  mixins: [GenericTableMixin],
  data() {
    const user = useUserStore();
    const headers = [
      new TableHeader({
        text: "Website",
        value: "domain",
        map: "domain",
        sortFilterMap: "domain",
        ...TableHeader.IS_STRING_FILTER_TYPE,
        ...TableHeader.IS_SORTABLE,
        ...TableHeader.IS_FILTERABLE
      }),
      new TableHeader({
        text: "Agent",
        value: "advisorName",
        map: "advisorName",
        sortFilterMap: [{ key: "agent_id", value: "id" }],
        ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER
      }),
      new TableHeader({
        text: "Commissions Assigned To",
        value: "appointmentAssignmentOwnableName",
        map: "appointmentAssignmentOwnableName",
        sortFilterMap: [
          { key: "appointment_assignment_ownable_id", value: "id" },
          { key: "appointment_assignment_ownable_type", value: "type" }
        ],
        ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER
      }),
      new TableHeader({
        text: "Start Page",
        value: "startPage",
        map: "startPage",
        sortFilterMap: "start_page",
        selectableOptions: startPageItems,
        ...TableHeader.IS_SELECT_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER
      }),
      new TableHeader({
        text: "Actions",
        value: "actions",
        map: ""
      }),
      new TableHeader({
        text: "View",
        value: "view",
        map: "view",
        selectableOptions: user.isGroupTwoPlus
          ? [
              { text: "My Websites", value: "my" },
              { text: "All Websites", value: "all" }
            ]
          : [],
        sortFilterMap: "view",
        ...TableHeader.IS_SELECT_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_MANDATORY,
        order: 1
      }),
      user.isGroupTwoPlus
        ? new TableHeader({
            text: "Pricing Plan",
            value: "pricingPlan",
            sortFilterMap: [{ key: "pricing_plan_id", value: "id" }],
            ...TableHeader.IS_PRICING_PLAN_SEARCH_TYPE,
            ...TableHeader.IS_FILTERABLE,
            ...TableHeader.IS_QUERY_FILTER,
            ...TableHeader.IS_ADDITIONAL
          })
        : null,
      user.isGroupTwoPlus
        ? new TableHeader({
            text: "Show Archived Sites",
            value: "archived",
            sortFilterMap: "archived",
            ...TableHeader.IS_CHECKBOX_TYPE,
            ...TableHeader.IS_FILTERABLE,
            ...TableHeader.IS_QUERY_FILTER,
            ...TableHeader.IS_ADDITIONAL
          })
        : null
    ].filter(Boolean);

    const table = new Table({
      text: "Websites",
      headers,
      filter: { view: user.isGroupThreePlus ? "all" : "my" }
    });
    return {
      initialLoad: false,
      table,
      showFilter: false,
      condensedView: user.isGroupOne,
      domainSearch: "",
      cancelToken: null,
      mdiDatabaseOff,
      mdiRefresh,
      mdiCogs,
      mdiOpenInNew,
      mdiCodeTags,
      mdiClock,
      mdiDelete
    };
  },
  watch: {
    condensedView() {
      this.getData();
    }
  },
  computed: {
    ...mapState(useUserStore, ["isGroupTwoPlus"]),
    allHeaders() {
      return this.table.headers;
    },
    titleText() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "text-h4";
      } else if (this.$vuetify.breakpoint.md) {
        return "text-h5";
      } else {
        return "text-h6";
      }
    },
    footerProps() {
      return {
        pageText: this.pageTextFormatter(this.table.options, this.table.meta),
        itemsPerPageOptions: [10, 20, 35, 50]
      };
    },
    filter: {
      get() {
        return this.table.filter;
      },
      set(filter) {
        this.table.filter = filter;
      }
    },
    options: {
      get() {
        return this.table.options;
      },
      set(options) {
        this.table.options = options;
      }
    },
    headers() {
      return this.table.headers.filter(val => !val.isAdditional);
    },
    loading() {
      return this.table.requests > 0;
    }
  },
  created() {
    this.getData();
  },

  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async getData(chunk) {
      if (chunk) {
        this.table.options.page = chunk;
      }
      try {
        const { approvedDomains, meta } = await getApprovedDomains(
          this.table.generateQuery(),
          this.cancelToken
        );
        this.table.rawItems = approvedDomains;
        this.table.meta = meta;
      } catch (e) {
        this.showErrorSnackbar({
          message: parseErrorMessage(e),
          color: "error"
        });
      } finally {
        this.table.loaded = true;
      }
    },
    updateOptions(newOptions) {
      if (this.optionsEquivalence(newOptions, this.options)) {
        return;
      }

      this.options = newOptions;
      this.getData();
    },
    updateFilter(newFilter) {
      this.table.resetPage();
      this.filter = newFilter;
      this.getData();
    },
    clearFilter(filter) {
      this.filter[filter] = undefined;
      this.getData();
    },
    codeDialog(props) {
      this.showDialog({
        component: "CopyDialog",
        text: props.htmlSnippet,
        title: "Quote & Apply Snippet",
        subtitle: "Just copy and paste this into your site!"
      });
    },
    deleteDialog(props) {
      const title = `Do you want to delete the website ${
        props.domain || props.additional.domain
      }?`;
      const subtitle =
        "This cannot be undone and will cause the website to no longer work";
      const func = () => {
        if (props.additional) props.additional.tableHelpers.deleting = true;
        return archiveApprovedDomain(props.id || props.additional.id)
          .then(() => {
            this.showSuccessSnackbar({
              message: `Successfully deleted ${
                props.domain || props.additional.domain
              }`,
              timeout: 5000
            });
            if (props.additional) this.getData();
          })
          .catch(e => {
            this.showErrorSnackbar({
              message: `Unable to delete ${props.domain}, ${parseErrorMessage(
                e
              )}`,
              timeout: 5000
            });
          })
          .finally(() => {
            if (props.additional)
              props.additional.tableHelpers.deleting = false;
          });
      };
      return this.showDialog({
        component: "DeleteDialog",
        title,
        subtitle,
        func
      });
    }
  }
};
</script>
