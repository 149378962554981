<template>
  <v-card tile flat>
    <v-card-text>
      <v-data-table
        must-sort
        data-testid="smoking-types-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :server-items-length="table.meta.value.total"
        :loading="table.loading.value"
        :options="table.options.value"
        :footer-props="footerProps"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Nicotine & Cannabis</h1>
            <v-spacer />
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="smoking-types-table-refresh"
                  @click="getData"
                >
                  <v-icon>{{ mdiRefresh }}</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
            <v-tooltip v-if="user.isGroupTwoPlus" top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="smoking-types-table-create"
                  @click="createNewSmokingType"
                >
                  <v-icon>{{ mdiPlus }}</v-icon>
                </v-btn>
              </template>
              <span>Create New Smoking Type</span>
            </v-tooltip>
          </v-row>
          <generic-table-filter-header
            :value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>
        <template #[`item.minimumAnnualFrequency`]="{ item }">
          <number-formatter :value="item.minimumAnnualFrequency" />
        </template>
        <template #[`item.maximumAnnualFrequency`]="{ item }">
          <number-formatter :value="item.maximumAnnualFrequency" />
        </template>
        <template #[`item.minimumLastUseDays`]="{ item }">
          <number-formatter :value="item.minimumLastUseDays" />
        </template>
        <template #[`item.maximumLastUseDays`]="{ item }">
          <number-formatter :value="item.maximumLastUseDays" />
        </template>
        <template #[`item.edit`]="{ item }">
          <v-row class="ma-0 flex-nowrap">
            <v-btn
              icon
              data-testid="smoking-type-edit"
              @click="editSmokingType(item.additional)"
              color="accent"
            >
              <v-icon> {{ mdiPencil }} </v-icon>
            </v-btn>
            <v-btn
              icon
              data-testid="smoking-type-delete"
              color="error"
              @click="deleteSmokingType(item.additional.id)"
            >
              <v-icon>{{ mdiDelete }}</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import NumberFormatter from "@/components/shared/formatters/NumberFormatter.vue";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";

import { ratingOptions, smokingOptions } from "@/data/filter-options";

import TableHeader from "@/classes/data-table/TableHeader";

import { deleteSmokerType, getSmokingTypes } from "@/api/smoking-types.service";

import { parseErrorMessage } from "@/util/helpers";
import { useTable } from "@/composables/table.composable";
import { computed } from "vue";

import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiRefresh, mdiPlus, mdiPencil, mdiDelete } from "@mdi/js";

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Smoker Chart",
      value: "chart",
      map: "smokerChart.name",
      sortFilterMap: "smoker_charts.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Category",
      value: "category",
      map: "category",
      selectableOptions: smokingOptions,
      sortFilterMap: "smoking_types.category",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Rating",
      value: "rating",
      map: "rating",
      selectableOptions: ratingOptions,
      sortFilterMap: "smoking_types.rating",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Minimum Annual Frequency",
      value: "minimumAnnualFrequency",
      map: "minimumAnnualFrequency",
      sortFilterMap: "smoking_types.min_annual_frequency",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Maximum Annual Frequency",
      value: "maximumAnnualFrequency",
      map: "maximumAnnualFrequency",
      sortFilterMap: "smoking_types.max_annual_frequency",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Minimum Last Use Days",
      value: "minimumLastUseDays",
      map: "minimumLastUseDays",
      sortFilterMap: "smoking_types.min_last_use_days",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Maximum Last Use Days",
      value: "maximumLastUseDays",
      map: "maximumLastUseDays",
      sortFilterMap: "smoking_types.max_last_use_days",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Annual Frequency",
      value: "annualFrequency",
      sortFilterMap: "annual_frequency",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_NUMBER_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Days Since Last Use",
      value: "lastUseDays",
      sortFilterMap: "last_use_days",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_NUMBER_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    })
  ],
  getData: getSmokingTypes
});

if (user.isGroupTwoPlus) {
  table.headers.value.push(
    new TableHeader({
      text: "Actions",
      value: "edit",
      map: "edit",
      ...TableHeader.IS_ACTION_FILTER_TYPE_TYPE
    })
  );
}

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

async function deleteSmokingType(id) {
  const result = await dialog.showDialog({
    component: "ConfirmationDialog",
    func: () => deleteSmokerType(id),
    title: "Are you sure that you want to delete this Smoking Type?",
    subtitle: "This cannot be undone."
  });

  if (result?.confirm) getData();
}

async function createNewSmokingType() {
  const result = await dialog.showDialog({
    component: "SmokingTypeDialog"
  });

  if (result?.created) getData();
}

async function editSmokingType(item) {
  const result = await dialog.showDialog({
    component: "SmokingTypeDialog",
    value: item
  });
  if (result?.updated) getData();
}

getData();
</script>
