<template>
  <v-list nav v-if="sidebar" id="user-menu" key="mobile-user-menu">
    <v-list-item-avatar>
      <v-img
        :src="avatar"
        max-width="40"
        max-height="40"
        style="border-radius: 20px"
        @error="fallbackAvatar"
      />
    </v-list-item-avatar>
    <v-list-group>
      <template #activator>
        <v-list-item-content>
          <v-list-item-title class="title" style="max-width: 126px">
            {{ compactUserName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ loginable.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <v-list>
        <user-menu-items
          v-for="(item, index) in listItems"
          :item="item"
          :key="item + index"
        />
      </v-list>
    </v-list-group>
    <v-divider />
  </v-list>
  <v-menu v-else offset-y key="lg-user-menu" eager>
    <template #activator="{ on, attrs }">
      <v-btn
        depressed
        text
        class="text-none"
        :class="{
          'header-btn-color-light':
            !$vuetify.theme.dark && $vuetify.breakpoint.lgAndUp
        }"
        v-bind="attrs"
        v-on="on"
        id="user-menu"
      >
        {{ compactUserName }}
        <v-icon>{{ mdiChevronDown }}</v-icon>
        <v-img
          :src="avatar"
          max-width="40"
          max-height="40"
          style="border-radius: 20px"
          class="ml-2"
        />
      </v-btn>
    </template>
    <v-list>
      <user-menu-items
        v-for="(item, index) in listItems"
        :item="item"
        :key="item + index"
      />
    </v-list>
  </v-menu>
</template>

<script>
import UserMenuItems from "@/components/UserMenuItems.vue";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiChevronDown } from "@mdi/js";

export default {
  components: { UserMenuItems },
  props: {
    sidebar: Boolean
  },
  data() {
    return {
      avatar: "",
      defaultAvatar: require("@/assets/img/Default.png"),
      mdiChevronDown
    };
  },
  created() {
    if (!this.loginable.avatar_url) this.avatar = this.defaultAvatar;
    else this.avatar = this.loginable.avatar_url;
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showInfoSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    fallbackAvatar() {
      this.avatar = this.defaultAvatar;
    },
    changeAccess() {
      this.showDialog({
        component: "ChangeAccessDialog"
      }).then(result => {
        if (!result.applying) {
          return;
        }

        this.showInfoSnackbar({
          message: "Applying Changes. Refreshing Page",
          timeout: 2000
        });
      });
    }
  },
  computed: {
    ...mapState(useUserStore, {
      logins: "logins",
      highestGroup: "highest_group",
      loginable: "loginable",
      compactUserName: "compactUserName"
    }),
    listItems() {
      const items = ["Profile", "BackNine Contacts"];

      if (this.$vuetify.breakpoint.lgAndUp) {
        items.push("Divider");
      } else {
        items.push("Schedule Meeting");
      }

      if (this.highestGroup >= 2 || this.logins.length >= 2) {
        items.push("Change Access");
      }

      items.push("Advisor Edit", "LightDark Mode", "Logout");
      return items;
    }
  }
};
</script>
