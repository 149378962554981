<template>
  <v-list-item @click="logout">
    <v-list-item-icon>
      <v-icon>{{ mdiLogout }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>Sign out {{ compactUserName }} </v-list-item-title>
  </v-list-item>
</template>

<script setup>
import { signOut } from "@/api/auth.service";
import { useUserStore } from "@/stores/user";
import { useRouter } from "@/composables/compatible.composables";
import { useInstanceStore } from "@/stores/instance";
import { storeToRefs } from "pinia";
import { mdiLogout } from "@mdi/js";

const user = useUserStore();
const instance = useInstanceStore();
const router = useRouter();
async function logout() {
  await signOut();
  await instance.setCsrfToken();
  await router.push({ name: "SignIn" });
  user.logoutUser();
}

const { compactUserName } = storeToRefs(user);
</script>
