<template>
  <div>
    <v-tabs show-arrows v-model="page">
      <v-tab
        v-for="table in tables"
        :key="table.title + 'header'"
        class="text-none"
        :data-testid="`tab-${table.title}`"
      >
        {{ table.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="page" touchless>
      <v-tab-item v-for="table in tables" :key="table.title + 'header'">
        <component :is="table.component" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script setup>
import AdminTable from "@/views/AdminTable.vue";
import AdvisorsDashboard from "@/components/advisors/AdvisorTable.vue";
import AffiliationsTable from "@/components/affiliations/AffiliationsTable.vue";
import AppointmentsTable from "@/components/appointments/AppointmentsTable.vue";
import CarriersTable from "@/components/carriers/CarriersTable.vue";
import ContractPartiesTable from "@/components/contract-parties/ContractPartiesTable.vue";
import CustomChatTemplatesTable from "@/components/custom-chat-templates/CustomChatTemplatesTable.vue";
import EmployeesDashboard from "@/components/employees/EmployeesDashboard.vue";
import LeadsTable from "@/components/leads/LeadsTable.vue";
import MarketingManagersTable from "@/components/marketing-managers/MarketingManagersTable.vue";
import PersonnelTable from "@/components/personnel/PersonnelTable.vue";
import TasksTable from "@/components/tasks/TasksTable.vue";
import ToDosTable from "@/components/todo/ToDosTable.vue";
import UnderwritingTables from "@/views/UnderwritingTables.vue";

import { useUserStore } from "@/stores/user";
import { useHead } from "@unhead/vue";
import { ref, toRef, watch, markRaw, defineProps } from "vue";
import { useRouter } from "@/composables/compatible.composables";

const props = defineProps({
  page: {
    type: String,
    default: null
  }
});

const pageProp = toRef(props, "page");
const router = useRouter();
const user = useUserStore();

const head = useHead({ title: "Tables" });
const page = ref(0);
const tables = setTables();

function setTables() {
  const tables = [
    {
      title: "Advisors",
      component: markRaw(AdvisorsDashboard),
      page: "advisors"
    },
    {
      title: "Appointments",
      component: markRaw(AppointmentsTable),
      page: "appointments"
    },
    {
      title: "Carriers",
      component: markRaw(CarriersTable),
      page: "carriers"
    },
    {
      title: "Carrier Personnel",
      component: markRaw(PersonnelTable),
      page: "carrier-personnel"
    },
    {
      title: "Clients",
      component: markRaw(ContractPartiesTable),
      page: "clients"
    },
    {
      title: "To-Dos",
      component: markRaw(ToDosTable),
      page: "todos"
    },
    {
      title: "Underwriting",
      component: markRaw(UnderwritingTables),
      page: "underwriting"
    }
  ];

  if (user.isGroupTwoPlus) {
    tables.push(
      {
        title: "Affiliations",
        component: markRaw(AffiliationsTable),
        page: "affiliations"
      },
      {
        title: "Employees",
        component: markRaw(EmployeesDashboard),
        page: "employees"
      },
      {
        title: "Leads",
        component: markRaw(LeadsTable),
        page: "leads"
      },
      {
        title: "Marketing Managers",
        component: markRaw(MarketingManagersTable),
        page: "marketing-managers"
      }
    );
  }

  if (user.isGroupTwoPlus || user.isEmployee) {
    tables.push({
      title: "Custom Chat Templates",
      component: markRaw(CustomChatTemplatesTable),
      page: "custom-chat-templates"
    });
  }

  if (user.isGroupTwoPlus || user.loginable.show_backnine) {
    tables.push({
      title: "Tasks",
      component: markRaw(TasksTable),
      page: "tasks"
    });
  }

  tables.sort((a, b) => a.title.localeCompare(b.title));

  if (user.isGroupFour) {
    tables.push({
      title: "Admin",
      component: markRaw(AdminTable),
      page: "admin"
    });
  }

  return tables;
}

function setMetaFromPage() {
  const activeTable = tables[page.value];
  if (!router || pageProp.value === activeTable.page) return;
  router.replace({ query: { page: activeTable.page } });
  head.patch({ title: activeTable.title });
}

function setPageFromMeta() {
  const pageIndex = tables.findIndex(v => v.page === pageProp.value);
  if (pageIndex === -1 || pageIndex === page.value) return;
  page.value = pageIndex;
}

watch(page, setMetaFromPage);
watch(pageProp, setPageFromMeta, { immediate: true });
</script>
