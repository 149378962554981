export const INFORMAL_STATUSES = [
  "Informal Entered",
  "Gathering Medical Information",
  "Awaiting Carrier Offers",
  "All Offers In",
  "Informal Formalized",
  "Informal Closed"
];
export function InformalInquiry(model = {}) {
  return {
    agent: model?.agent,
    additionalAgents: model?.additionalAgents || {},
    carriers: model?.carriers || [],
    status: model?.status || null,
    faceAmount: model?.faceAmount || null,
    state: model?.state || null,
    party: model?.party || null
  };
}

export function InformalInquiryToCreateRequest(informal) {
  return {
    case: {
      face_amount: informal.faceAmount,
      line: "informal",
      party_type: "SSN",
      policy_number: "Informal",
      product_id: 389,
      state: informal.state,
      status: informal.status,
      case_accesses_attributes: [
        {
          agent: 1,
          ownable_id: informal.agent.id,
          ownable_type: informal.agent.type
        },
        ...Object.keys(informal.additionalAgents).map(key => ({
          agent: 1,
          ownable_id: informal.additionalAgents[key].id,
          ownable_type: informal.additionalAgents[key].type
        }))
      ],
      informal_offers_attributes: informal.carriers.map(id => ({
        name: "Pending",
        carrier_id: id,
        status: informal.status
      })),
      roles_attributes: [
        {
          contract_party_id: informal.party.id,
          role: "Insured"
        }
      ]
    }
  };
}
