<template>
  <v-row class="ma-0">
    <v-spacer />
    <v-btn
      v-if="eappLink"
      :to="eappLink"
      rounded
      outlined
      color="orange"
      class="text-none ma-1"
      data-testid="view-eapp-button"
    >
      <v-icon class="mr-1">{{ mdiOpenInNew }}</v-icon> View eApp
    </v-btn>
    <v-btn
      rounded
      outlined
      color="primary"
      class="text-none ma-1"
      data-testid="snapshot-button"
      :href="snapshotHref"
    >
      <v-icon class="mr-1">{{ mdiCamera }}</v-icon> Snapshot
    </v-btn>
    <v-btn
      v-if="user.isGroupThreePlus"
      rounded
      outlined
      color="accent"
      class="ma-1 text-none"
      :href="paperclipHref"
      data-testid="paperclip-button"
    >
      <v-icon class="mr-1">{{ mdiPaperclip }}</v-icon> Paperclip
    </v-btn>
    <v-btn
      outlined
      rounded
      color="primary"
      class="text-none ma-1"
      data-testid="request-quote-button"
      @click="requestQuote"
    >
      <v-icon class="mr-1">{{ mdiClipboardAccount }}</v-icon>
      Request Quote
    </v-btn>
  </v-row>
</template>

<script setup>
import SynthesizeQuoteRequestDialog from "@/dialogs/SynthesizeQuoteRequestDialog.vue";
import { getPaperclipHref, getSnapshotHref } from "@/api/cases.service";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useCaseViewStore } from "@/stores/case-view";
import { computed, markRaw } from "vue";
import {
  mdiOpenInNew,
  mdiCamera,
  mdiPaperclip,
  mdiClipboardAccount
} from "@mdi/js";

const user = useUserStore();
const { id, electronicApplication } = storeToRefs(useCaseViewStore());
const dialog = useDialogStore();

const eappLink = computed(() => {
  if (!electronicApplication.value?.id) {
    return null;
  }
  return {
    name: "ElectronicApplicationView",
    params: {
      id: electronicApplication.value.id,
      fromCase: true
    }
  };
});
const snapshotHref = computed(() => getSnapshotHref(id.value));
const paperclipHref = computed(() => getPaperclipHref(id.value));

function requestQuote() {
  dialog.showDialog({
    component: markRaw(SynthesizeQuoteRequestDialog),
    id: id.value,
    idBodyKey: "case_id",
    scrollable: true
  });
}
</script>
