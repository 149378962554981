<template>
  <v-card>
    <v-card-title class="pb-6">Manage Two Factor Authentication</v-card-title>
    <v-card-subtitle v-if="otp_enrolled">
      You are currently enrolled in Two Factor Authentication (2FA). It is
      strongly recommended to enrolled for security purposes.
    </v-card-subtitle>
    <v-card-subtitle v-else>
      <strong> Set Up Two Factor Authentication </strong>
      Two Factor Authentication (2FA) apps require you to scan a QR code or
      enter a code. See below for the QR code and code.
    </v-card-subtitle>
    <v-card-text v-if="otp_enrolled" class="pb-0">
      <v-text-field
        v-model="confirmationCode"
        label="Code"
        outlined
        dense
        autofocus
        data-testid="two-factor-confirmation"
        inputmode="numeric"
        :success="Boolean(confirmationCode)"
        :prepend-inner-icon="mdiTwoFactorAuthentication"
      />
    </v-card-text>
    <v-card-text v-else class="pb-0">
      <div class="flex-row justify-center width-full">
        <v-slide-fade-transition mode="out-in">
          <div
            v-if="loading"
            key="loader"
            class="mt-3 text-center"
            style="max-width: 20rem"
          >
            <v-progress-circular indeterminate />
            <span> Fetching Two Factor Data </span>
          </div>
          <div
            v-else
            key="ready"
            class="mt-3 text-center"
            style="max-width: 20rem"
          >
            <v-card light elevation="4">
              <v-card-text>
                <v-img data-testid="qr-code" :src="otp.qr" />
              </v-card-text>
            </v-card>
            <div class="py-2" data-testid="secret">
              <pre>{{ otp.secret }}</pre>
            </div>
            <v-text-field
              v-model="confirmationCode"
              label="Code"
              outlined
              dense
              autofocus
              inputmode="numeric"
              data-testid="two-factor"
              :success="Boolean(confirmationCode)"
              :prepend-inner-icon="mdiTwoFactorAuthentication"
            />
          </div>
        </v-slide-fade-transition>
      </div>
    </v-card-text>
    <v-card-actions>
      <div class="width-full">
        <v-btn
          v-if="otp_enrolled"
          class="text-none width-full"
          color="error"
          data-testid="unenroll-button"
          block
          :loading="saving"
          @click="unenroll"
        >
          Delete
        </v-btn>
        <v-btn
          v-else
          class="text-none width-full"
          color="primary"
          data-testid="enroll-button"
          block
          :loading="saving"
          @click="enroll"
        >
          Enroll
        </v-btn>

        <v-btn
          class="text-none width-full mt-1"
          text
          block
          @click="dialog.closeDialog()"
        >
          Cancel
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import {
  createTwoFactorData,
  deleteTwoFactorEnrollment,
  generateTwoFactorData
} from "@/api/security.service";
import { parseErrorMessage } from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { useUserStore } from "@/stores/user";
import { mdiTwoFactorAuthentication } from "@mdi/js";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const { otp_enrolled } = storeToRefs(useUserStore());
const loading = ref(false);
const saving = ref(false);
const confirmationCode = ref("");

const otp = ref({
  qr: null,
  secret: null
});

async function getTwoFactorData() {
  loading.value = true;
  try {
    const { secret, qr_code } = await generateTwoFactorData();
    otp.value.qr = qr_code;
    otp.value.secret = secret;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

async function enroll() {
  saving.value = true;
  try {
    await createTwoFactorData(confirmationCode.value);
    snackbar.showSuccessSnackbar({
      message: "Successfully enrolled in Two Factor Authentication"
    });
    dialog.closeDialog();
    otp_enrolled.value = true;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

async function unenroll() {
  try {
    await deleteTwoFactorEnrollment(confirmationCode.value);
    dialog.closeDialog();
    otp_enrolled.value = false;
    snackbar.showSuccessSnackbar({
      message: "Successfully removed Two Factor Authentication enrollment"
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

if (!otp_enrolled.value) getTwoFactorData();
</script>
