<template>
  <v-card>
    <v-card-title> Case Concierge and Case Manager </v-card-title>
    <v-card-text>
      <v-row class="my-0">
        <v-col cols="12" md="6">
          <v-select
            v-model="caseConcierge"
            dense
            outlined
            success
            return-object
            hide-details
            item-text="name"
            label="Case Concierge"
            :prepend-inner-icon="mdiRoomService"
            data-testid="change-case-concierge"
            :items="eligibleManagement"
            @change="updateCurrentConsistency"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="caseManager"
            dense
            outlined
            success
            return-object
            hide-details
            item-text="name"
            label="Case Manager"
            :prepend-inner-icon="mdiAccountSupervisor"
            data-testid="change-case-manager"
            :items="eligibleManagement"
            @change="updateCurrentConsistency"
          />
        </v-col>
        <v-col v-if="!currentIsConsistent || existingAreInconsistent" cols="12">
          <v-row dense>
            <v-col cols="12">
              <h3>
                <template v-if="existingAreInconsistent">
                  Current Policies Contain Management Inconsistencies
                </template>
                <template v-else-if="!currentIsConsistent">
                  This Case Contains
                  <template v-if="!sameManagerForParty"> Permitted </template>
                  <template v-else> Unpermitted </template>
                  Management Inconsistencies.
                  <template v-if="currentWasConsistent">
                    Are you sure?
                  </template>
                </template>
              </h3>
            </v-col>
            <v-col v-if="!currentIsConsistent" cols="12">
              <v-fade-transition mode="out-in">
                <div v-if="sameManagerForParty" key="same-manager">
                  This Case is required to have the same Case Manager and Case
                  Concierge as the rest of {{ insuredName }}'s Cases. If the
                  Case Manager/Concierge deviates, this Case will be included in
                  the daily report of Cases with deviating Case
                  Managers/Concierges.
                </div>
                <div v-else key="different-manager">
                  This Case is not required to have the same Case Manager and
                  Case Concierge from the rest of {{ insuredName }}'s Cases.
                  This Case is excluded from the daily report of Cases with
                  deviating Case Managers/Concierges.
                </div>
              </v-fade-transition>
            </v-col>
            <v-col
              cols="12"
              v-for="{
                caseIds,
                caseConcierge,
                caseManager,
                policyNumbers,
                sameManagerForParty
              } in uniqueCombinations"
              :key="`${caseConcierge.id}-${caseManager.id}`"
              class="mx-0"
            >
              <div>
                Cases where

                <strong class="accent--text">{{ caseConcierge.name }} </strong>
                is the <span class="accent--text"> Case Concierge </span>
                and
                <strong class="primary--text">{{ caseManager.name }} </strong>
                is the <span class="primary--text">Case Manager: </span>
              </div>
              <div class="mx-n1">
                <v-btn
                  v-for="(caseId, index) in caseIds"
                  class="text-none ma-1"
                  color="primary"
                  small
                  target="_blank"
                  :key="caseId"
                  :to="getRouterLink('Case', caseId)"
                >
                  <v-icon class="mr-1" small>{{ mdiBriefcase }}</v-icon>
                  {{ policyNumbers[index] }}
                  <span v-if="!sameManagerForParty[index]">*</span>
                </v-btn>
              </div>
            </v-col>
            <v-col v-if="hasPermittedAnomalies" cols="12">
              * = Permitted Anomalies
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="!currentIsConsistent" cols="12">
          <div class="checkbox-width">
            <v-checkbox
              v-model="sameManagerForParty"
              :true-value="false"
              :false-value="true"
              dense
              :success="sameManagerForPartyValidation.success"
              :error-messages="sameManagerForPartyValidation.errorMessages"
              :hide-details="sameManagerForPartyValidation.success !== false"
              class="mt-0 pt-0"
              label="Permit Case Manager/Concierge Combination Anomaly"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" outlined @click="dialog.closeDialog()">
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        :loading="loading"
        data-testid="save-case-management-changes"
        @click="saveManagementChanges()"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import {
  updateCase,
  updateCaseAccess,
  managerCheck
} from "@/api/cases.service";
import {
  parseErrorMessage,
  validationComputeV2,
  getRouterLink
} from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import useVuelidate from "@vuelidate/core";
import { computed, defineProps, ref, watch } from "vue";

import { mdiRoomService, mdiAccountSupervisor, mdiBriefcase } from "@mdi/js";

const props = defineProps({
  sameManagerForPartyValue: Boolean,
  insuredName: { type: String, required: true },
  eligibleManagement: { type: Array, required: true },
  caseManagerValue: { type: Object, required: true },
  caseConciergeValue: { type: Object, required: true },
  caseId: { type: Number, required: true }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const sameManagerForParty = ref(false);

const loading = ref(false);

const caseConcierge = ref({
  id: null,
  name: null
});
const caseManager = ref({
  id: null,
  name: null
});

const uniqueCombinations = ref([]);
const existingCombinations = ref([]);
const existingAreInconsistent = ref(null);
const hasPermittedAnomalies = ref(null);
const currentWasConsistent = ref(null);
const currentIsConsistent = ref(null);

const v$ = useVuelidate(
  {
    sameManagerForParty: {
      required: v => {
        return currentIsConsistent.value ? v === true : v === false;
      }
    }
  },
  {
    sameManagerForParty
  },
  { $autoDirty: true, $scope: false }
);

const sameManagerForPartyValidation = computed(() => {
  return validationComputeV2(v$.value.sameManagerForParty, [
    {
      key: "required",
      message: "Please confirm that this Case is a management anomaly."
    }
  ]);
});

watch(currentIsConsistent, v => {
  if (v) sameManagerForParty.value = true;
});

function setCaseConcierge() {
  if (!props.caseConciergeValue?.id) return;
  caseConcierge.value.id = props.caseConciergeValue.id;
  caseConcierge.value.name = props.caseConciergeValue.name;
}

function setCaseManager() {
  if (!props.caseManagerValue?.id) return;
  caseManager.value.id = props.caseManagerValue.id;
  caseManager.value.name = props.caseManagerValue.name;
}

function setSameManagerForParty() {
  sameManagerForParty.value = props.sameManagerForPartyValue;
}

async function getExisingManagement() {
  try {
    const existing = await managerCheck(props.caseId);
    existingCombinations.value.splice(0, existingCombinations.value.length);
    existingCombinations.value.push(...existing);

    const unique = generateUniqueCombinations();
    uniqueCombinations.value.splice(0, uniqueCombinations.value.length);
    uniqueCombinations.value.push(...unique);

    hasPermittedAnomalies.value = existing.some(
      v => v.sameManagerForParty === false
    );

    existingAreInconsistent.value = existingCombinationsAreInconsistent();
    currentWasConsistent.value = consistencyCheck(
      props.caseManagerValue,
      props.caseConciergeValue
    );

    updateCurrentConsistency();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function saveManagementChanges() {
  const isValid = await v$.value.$validate();

  if (!isValid) return;

  try {
    loading.value = true;
    await updateCase(props.caseId, {
      same_manager_for_party: sameManagerForParty.value
    });

    const caseManagerAccessId = props.eligibleManagement.find(
      ({ id }) => id === caseManager.value.id
    ).caseAccessId;

    await updateCaseAccess(props.caseId, caseManagerAccessId, {
      case_manager: true
    });

    const caseConciergeAccessId = props.eligibleManagement.find(
      ({ id }) => id === caseConcierge.value.id
    ).caseAccessId;

    await updateCaseAccess(props.caseId, caseConciergeAccessId, {
      case_concierge: true
    });

    dialog.closeDialog({
      caseManager: caseManager.value,
      caseConcierge: caseConcierge.value,
      sameManagerForParty: sameManagerForParty.value
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  } finally {
    loading.value = false;
  }
}

function generateUniqueCombinations() {
  const combos = {};

  existingCombinations.value.forEach(c => {
    const key = `${c.caseManager.id}-${c.caseConcierge.id}`;
    if (!combos[key])
      combos[key] = {
        caseIds: [],
        policyNumbers: [],
        sameManagerForParty: [],
        caseManager: c.caseManager,
        caseConcierge: c.caseConcierge
      };
    combos[key].caseIds.push(c.caseId);
    combos[key].policyNumbers.push(c.policyNumber);
    combos[key].sameManagerForParty.push(c.sameManagerForParty);
  });

  return Object.values(combos);
}

function existingCombinationsAreInconsistent() {
  if (uniqueCombinations.value.length < 1) return false;
  return uniqueCombinations.value.every(
    v =>
      v.sameManagerForParty.every(smp => smp === true) ||
      v.sameManagerForParty.every(smp => smp === false)
  );
}

function consistencyCheck(cm, cc) {
  if (!existingCombinations.value.length) return true;
  if (existingAreInconsistent.value) return false;

  const consistentManagementCombination = uniqueCombinations.value.find(v =>
    v.sameManagerForParty.every(smp => smp === true)
  );

  return (
    consistentManagementCombination.caseManager.id === cm.id &&
    consistentManagementCombination.caseConcierge.id === cc.id
  );
}

function updateCurrentConsistency() {
  currentIsConsistent.value = consistencyCheck(
    caseManager.value,
    caseConcierge.value
  );
}

setCaseManager();
setCaseConcierge();
setSameManagerForParty();
getExisingManagement();
</script>
