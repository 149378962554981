import { ElectronicApplication } from "@/factories/ElectronicApplication";
import { defineStore } from "pinia";
import {
  updateEapp,
  resendEnvelope,
  getQuoteHistory,
  getElectronicApplication,
  addElectronicApplicationAccess,
  updateElectronicApplicationAccess,
  removeElectronicApplicationAccess
} from "@/api/electronic-application.service";
import { deleteElectronicApplicationQuote } from "@/api/electronic-application-quotes.service";

import Vue from "vue";

export const useEappViewStore = defineStore("eapp-view", {
  state: () => ({ ...ElectronicApplication(), todoCount: 0 }),
  actions: {
    async initialize(id) {
      this.$reset();
      const eApp = await getElectronicApplication(id);
      this.$patch(eApp);

      const owner = eApp.electronicApplicationAccesses.find(a => a.owner);
      Vue.set(this, "owner", owner);
      this.$patch({ owner });
    },
    // Consolidate to update EappAccess
    updateOwner() {
      const body = { owner: 1 };
      return updateElectronicApplicationAccess(this.id, this.owner.id, body);
    },
    deleteEappAccess(accessId) {
      return removeElectronicApplicationAccess(this.id, accessId);
    },
    updateEappAccess(accessId, attribute) {
      const access = this.electronicApplicationAccesses.find(
        a => a.id === accessId
      );
      const map = {
        advisorAccess: { advisor_access: access.advisorAccess },
        carrierAccess: { carrier_access: access.carrierAccess },
        carrierEmail: { carrier_email: access.carrierEmail },
        additionalCaseAccess: {
          additional_case_access: access.additionalCaseAccess
        },
        email: { email: access.email }
      };

      return updateElectronicApplicationAccess(
        this.id,
        accessId,
        map[attribute]
      );
    },
    createEappAccess(advisor) {
      const body = {
        electronic_application_id: this.id,
        ownable_id: advisor.id,
        ownable_type: advisor.type,
        owner: false
      };

      return addElectronicApplicationAccess(this.id, body);
    },
    updateEapp(attribute) {
      const map = {
        commissionsPaidTo: {
          commissions_paid_to_id: this.commissionsPaidTo?.id || null,
          commissions_paid_to_type: this.commissionsPaidTo?.type || null
        }
      };

      const body = map[attribute];
      return updateEapp(this.id, body);
    },
    resendEnvelope() {
      return resendEnvelope(this.id);
    },
    async getQuoteHistory() {
      const quoteHistory = await getQuoteHistory(this.id);
      // We can memoise these values + filters
      this.$patch({ quoteHistory });
      Vue.set(this, "quoteHistory", quoteHistory);
      return { items: this.quoteHistory };
    },
    deleteQuote(quoteId) {
      const quote = this.quoteHistory.find(q => q.id === quoteId);
      if (!quote) return;
      return deleteElectronicApplicationQuote(quoteId);
    }
  }
});
