<template>
  <v-text-field
    :ref="name"
    v-model="visibleSSN"
    autocomplete="false"
    data-lpignore="true"
    type="text"
    maxlength="11"
    inputmode="numeric"
    outlined
    dense
    class="button-append-inner"
    placeholder="###-##-####"
    :data-testid="dataTestid"
    :prepend-inner-icon="icon"
    :autofocus="autofocus"
    :name="name"
    :label="label"
    :disabled="disabled"
    :success="success"
    :error-messages="errorMessages"
    :persistent-hint="persistentHint"
    :hint="hint"
    @keydown="keydown"
    @paste.prevent="handlePaste"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
  >
    <template #append>
      <v-icon data-testid="toggle-visibility" @click="showSSN = !showSSN">
        {{ appendIcon }}
      </v-icon>
    </template>
    <template v-if="$slots['append-outer']" #append-outer>
      <slot name="append-outer" />
    </template>
  </v-text-field>
</template>

<script>
import { mdiAsterisk, mdiEye, mdiEyeOff } from "@mdi/js";
const PERMITTED_KEYCODES = {
  BACKSPACE: 8,
  DELETE: 46,
  ZERO: 48,
  ONE: 49,
  TWO: 50,
  THREE: 51,
  FOUR: 52,
  FIVE: 53,
  SIX: 54,
  SEVEN: 55,
  EIGHT: 56,
  NINE: 57,
  NUMPAD_0: 96,
  NUMPAD_1: 97,
  NUMPAD_2: 98,
  NUMPAD_3: 99,
  NUMPAD_4: 100,
  NUMPAD_5: 101,
  NUMPAD_6: 102,
  NUMPAD_7: 103,
  NUMPAD_8: 104,
  NUMPAD_9: 105
};
const ARROW_KEYCODES = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40
};
const META_KEYCODES = {
  V: 86,
  TAB: 9
};
export default {
  props: {
    dirty: Boolean,
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "Social Security"
    },
    value: {
      default: "",
      type: String
    },
    success: Boolean,
    errorMessages: {
      type: Array,
      default: () => []
    },
    disabled: Boolean,
    dataTestid: {
      type: String,
      required: false,
      default: "ssn"
    },
    showHidden: Boolean,
    hint: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: mdiAsterisk
    },
    persistentHint: Boolean
  },
  emits: ["input", "focus", "blur"],
  data() {
    let visibleSSN = null;
    let hiddenSSN = null;
    if (this.value === null) {
      visibleSSN = "";
      hiddenSSN = "";
    } else {
      const val = this.value.replace(/-/g, "");
      visibleSSN = this.createStarsAndFormatSSN(val);
      hiddenSSN = val;
    }
    return {
      visibleSSN,
      hiddenSSN, // hidden ssn is the unformatted SSN for easy manip
      showSSN: false,
      timer: null,
      autofocus: false
    };
  },
  computed: {
    appendIcon() {
      return this.showSSN ? mdiEye : mdiEyeOff;
    }
  },
  watch: {
    showSSN() {
      this.formatVisibleSsn();
    },
    hiddenSSN() {
      this.$emit("input", this.formatSSN(this.hiddenSSN));
    },
    showHidden(v) {
      this.showSSN = v;
    }
  },
  methods: {
    createStarsAndFormatSSN(ssn) {
      return this.formatSSN(this.createStars(ssn.length));
    },
    formatSSN(ssn) {
      const currSSN = ssn.replace(/-/g, "");
      const part1 = currSSN.substring(0, 3);
      if (currSSN.length <= 3) return part1;
      const part2 = currSSN.substring(3, 5);
      if (currSSN.length <= 5) return `${part1}-${part2}`;
      const part3 = currSSN.substring(5, 9);
      return `${part1}-${part2}-${part3}`;
    },
    createStars(n) {
      return "X".repeat(n);
    },
    formatVisibleSsn() {
      if (!this.showSSN) {
        const stars = this.createStars(this.hiddenSSN.length);
        this.visibleSSN = this.formatSSN(stars);
        return;
      }

      this.visibleSSN = this.formatSSN(this.hiddenSSN);
    },

    handlePaste(e) {
      let clipboardData, pastedData;

      e.stopPropagation();
      e.preventDefault();

      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData("Text");

      const val = pastedData.replace(/\D/g, "");
      this.hiddenSSN = val;
      this.formatVisibleSsn();
    },
    keydown(e) {
      const code = e.which;
      const ctrlDown = e.ctrlKey || e.metaKey;

      if (ctrlDown && code === META_KEYCODES.V) return;
      if (code === META_KEYCODES.TAB) return;
      if (Object.values(ARROW_KEYCODES).includes(code)) return;
      e.stopPropagation();
      e.preventDefault();
      if (!Object.values(PERMITTED_KEYCODES).includes(code)) return;
      // only delete is permitted for the max ssn length
      let withoutDashes = this.visibleSSN.replace(/-/g, "");
      let length = withoutDashes.length;

      const isDeleting = [
        PERMITTED_KEYCODES.BACKSPACE,
        PERMITTED_KEYCODES.DELETE
      ].includes(code);

      if (this.timer) clearTimeout(this.timer);
      let start = e.target.selectionStart;
      const end = e.target.selectionEnd;

      let ssn = this.formatSSN(this.hiddenSSN);
      if (isDeleting) {
        if (start === end && start > 0) start--;
        // deleting based on selection

        ssn = ssn.slice(0, start) + ssn.slice(end);
        this.hiddenSSN = ssn.replace(/-/g, "");
        this.formatVisibleSsn();
        this.$nextTick(() => {
          this.$refs[this.name].$el
            .querySelector("input")
            .setSelectionRange(start, start);
        });
        return;
      }

      // if trying to insert and max length is reached
      if (length >= 9 && start === end) return;

      const isAtEnd = start === ssn.length;
      ssn = ssn.slice(0, start) + e.key + ssn.slice(end);
      this.hiddenSSN = ssn.replace(/-/g, "");
      this.$nextTick(() => {
        if (isAtEnd) start = this.visibleSSN.length;
        else start++;
        this.$refs[this.name].$el
          .querySelector("input")
          .setSelectionRange(start, start);
      });

      if (this.showSSN) {
        this.formatVisibleSsn();
        return;
      }

      withoutDashes = this.hiddenSSN.replace(/-/g, "");
      length = withoutDashes.length;

      this.visibleSSN = this.formatSSN(
        this.createStars(length - 1) + this.hiddenSSN.substring(length - 1)
      );

      this.timer = setTimeout(() => {
        this.formatVisibleSsn();
      }, 800);
    }
  }
};
</script>
