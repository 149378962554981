<template>
  <v-autocomplete
    v-model="advisor"
    autocomplete="false"
    outlined
    dense
    return-object
    no-filter
    hide-no-data
    auto-select-first
    :autofocus="autofocus"
    :item-text="itemTextGenerator"
    :disabled="disabled"
    :prepend-inner-icon="prependInnerIcon"
    :loading="loading || externalLoading"
    :placeholder="placeholder"
    :label="label"
    :search-input.sync="search"
    :success="success"
    :error-messages="errorMessages"
    :items="advisors"
    :clearable="clearable"
    :hide-details="hideDetails"
    :solo="solo"
    :flat="flat"
    :persistent-hint="hasMessageSlot || persistentHint"
    :hint="hint || (hasMessageSlot ? ' ' : '')"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    ref="advisorInput"
    @blur="$emit('blur')"
    @click:clear="$emit('input', {})"
  >
    <template #append>
      <slot name="append" />
    </template>
    <template #append-outer>
      <slot name="append-outer" />
    </template>
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }} <v-spacer /> <slot name="message" />
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import {
  advisorSearch,
  simpleAdvisorSearch,
  simpleAdvisorPaymentSearch,
  advisorAppointmentSearch
} from "@/api/search.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiAccount } from "@mdi/js";

export default {
  props: {
    autofocus: Boolean,
    success: Boolean,
    errorMessages: Array,
    value: [Object, String],
    disabled: Boolean,
    persistentHint: Boolean,
    hint: String,
    clearable: {
      type: Boolean,
      default: true
    },
    label: {
      required: false,
      default: "Advisor",
      type: String
    },
    placeholder: {
      required: false,
      default: "Search Advisors",
      type: String
    },
    hideDetails: [Boolean, String],
    solo: Boolean,
    simple: Boolean,
    employee: Boolean,
    payment: Boolean,
    flat: Boolean,
    appointment: Boolean,
    externalLoading: Boolean,
    prependInnerIcon: {
      type: String,
      required: false,
      default: mdiAccount
    },
    carrierId: [Number, String]
  },
  data() {
    let advisors = [];
    let advisor = null;
    if (
      this.value &&
      Object.keys(this.value).length &&
      Object.values(this.value).filter(Boolean).length
    ) {
      advisor = this.value;
      advisors = [this.value];
    }
    return {
      search: null,
      timer: null,
      loading: false,
      advisors,
      advisor
    };
  },
  computed: {
    hasMessageSlot() {
      return Boolean(this.$slots.message);
    }
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.advisor = null;
        this.advisors = [];
        return;
      }
      this.advisor = value;
      this.advisors = [value];
    },
    advisor(value) {
      if (!value?.id && !this.clearable) return;
      if (value === this.value) return;
      this.$emit("input", value);
    },
    search(value) {
      if (!value || !Object.keys(value).length) return;

      if (
        this.advisors.some(
          advisor => this.itemTextGenerator(advisor) === value
        ) &&
        this.advisors.length === 1
      ) {
        return;
      }

      this.debounceAndSearchAdvisors();
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    debounceAndSearchAdvisors() {
      if (this.timer) clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.searchAdvisors();
      }, 200);
    },
    async searchAdvisors() {
      this.loading = true;
      let searchFunc = advisorSearch;
      if (this.payment) {
        searchFunc = simpleAdvisorPaymentSearch;
      } else if (this.simple) {
        searchFunc = simpleAdvisorSearch;
      } else if (this.appointment) {
        searchFunc = value => advisorAppointmentSearch(value, this.carrierId);
      } else if (this.employee) {
        searchFunc = value => advisorSearch(value, { employee: true });
      }
      try {
        const { data } = await searchFunc(this.search);
        this.advisors = data.advisors;
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    focus() {
      this.$refs.advisorInput.$refs.input.focus();
    },
    itemTextGenerator(val) {
      if (!val?.email) return val?.name;
      return `${val.name} · ${val.email}`;
    },
    clear() {
      this.advisor = null;
      this.advisors = [];
    }
  }
};
</script>
