<template>
  <v-card>
    <v-card-title>{{ editing ? "Edit" : "Create" }} Transaction</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col v-if="!editing" cols="12" lg="6">
          <statement-search
            v-model="$statement"
            placeholder="Search for a statement"
            autofocus
            :disabled="loading"
            :loading="loading"
            :success="$statementValidation.success"
            :error-messages="$statementValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" :lg="editing ? 12 : 6">
          <policy-search
            v-model="$policy"
            :disabled="fetchingPolicy || loading"
            :loading="fetchingPolicy || loading"
            type="transaction"
            :success="$policyValidation.success"
            :error-messages="$policyValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <v-select
            v-model="$commissionType"
            :prepend-inner-icon="mdiTable"
            outlined
            dense
            placeholder="Select Commission Type"
            label="Commission Type"
            :disabled="loading"
            :loading="loading"
            :items="commissionTypes"
            :success="$commissionTypeValidation.success"
            :error-messages="$commissionTypeValidation.errorMessages"
          />
        </v-col>

        <v-col cols="12" lg="4">
          <currency-input
            v-model="$premium"
            include-decimals
            include-negative
            :prepend-inner-icon="mdiCurrencyUsd"
            outlined
            dense
            label="Premium"
            :disabled="loading"
            :loading="loading"
            :success="$premiumValidation.success"
            :error-messages="$premiumValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <currency-input
            v-model="$override"
            include-decimals
            include-negative
            :prepend-inner-icon="mdiCurrencyUsd"
            outlined
            dense
            persistent-hint
            hint=" "
            :disabled="loading"
            :loading="loading"
            :label="statementFromCarrier ? 'Direct Override' : 'Total Override'"
            :success="$overrideValidation.success"
            :error-messages="$overrideValidation.errorMessages"
          >
            <template #message>
              <span v-if="statementFromCarrier">
                When the statement is from a Carrier, override should be the
                amount paid to BackNine on the Carrier's statement.
              </span>
              <span v-else>
                When the statement is not from a Carrier, override should be the
                total amount BackNine is assumed to receive.
              </span>
              <span v-if="showBackNineWritingAgentHint" class="warning--text">
                Writing agent commissions assigned to BackNine should be
                included in override.
              </span>
            </template>
          </currency-input>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="$memo"
            outlined
            dense
            label="Memo (Optional)"
            rows="2"
            :prepend-inner-icon="mdiText"
            :disabled="loading"
            :loading="loading"
            :success="Boolean($memo)"
          />
        </v-col>
        <v-col cols="12">
          <v-row class="ma-0">
            <v-btn
              class="text-none"
              color="primary"
              :loading="loading"
              @click="save"
            >
              <v-icon class="mr-1"> {{ mdiPlus }} </v-icon>
              {{ editing ? "Save" : "Create" }} Transaction
            </v-btn>
            <div class="checkbox-width">
              <v-checkbox
                v-if="!editing"
                class="mt-1 ml-3"
                label="Preserve Statement"
                v-model="preserveFieldValues"
              />
            </div>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="errorMessage">
          <v-alert type="error">
            {{ errorMessage }}
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import PolicySearch from "@/components/shared/PolicySearch.vue";
import StatementSearch from "@/components/shared/StatementSearch.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import { required } from "vuelidate/lib/validators";
import { commissionTypes } from "@/data/transaction-data";
import sortBy from "lodash/sortBy";

import { mdiTable, mdiCurrencyUsd, mdiText, mdiPlus } from "@mdi/js";

export default {
  props: {
    editing: Boolean,
    loading: Boolean,
    errorMessage: String,
    statement: Object,
    policy: Object,
    commissionType: String,
    premium: Number,
    override: Number,
    preserveFieldValues: Boolean,
    showBackNineWritingAgentHint: Boolean,
    fetchingPolicy: Boolean,
    memo: String
  },
  components: {
    PolicySearch,
    StatementSearch,
    CurrencyInput
  },
  data() {
    return {
      commissionTypes: sortBy(commissionTypes, "text"),
      mdiTable,
      mdiCurrencyUsd,
      mdiText,
      mdiPlus
    };
  },
  computed: {
    statementFromCarrier() {
      return this.$statement?.payor?.payable_type === "Carrier";
    },
    currentCarrierId() {
      return this.transaction?.$policy?.product?.carrier?.id;
    },
    $memo: {
      get() {
        return this.memo;
      },
      set(v) {
        if (this.memo === v) return;
        this.$emit("update:memo", v);
      }
    },
    $statement: {
      get() {
        return this.statement;
      },
      set(v) {
        if (this.statement?.id === v?.id) return;
        this.$emit("update:statement", v);
      }
    },
    $policy: {
      get() {
        return this.policy;
      },
      set(v) {
        if (this.policy?.id === v?.id) return;
        this.$emit("update:policy", v);
      }
    },
    $commissionType: {
      get() {
        return this.commissionType;
      },
      set(v) {
        if (this.commissionType === v) return;
        this.$emit("update:commission-type", v);
      }
    },
    $premium: {
      get() {
        return this.premium;
      },
      set(v) {
        if (this.premium === v) return;
        this.$emit("update:premium", v);
      }
    },
    $override: {
      get() {
        return this.override;
      },
      set(v) {
        if (this.override === v) return;
        this.$emit("update:override", v);
      }
    },
    $preserveFieldValues: {
      get() {
        return this.preserveFieldValues;
      },
      set(v) {
        if (v === this.preserveFieldValues) return;
        this.$emit("update:preserve-field-values", v);
      }
    },
    $statementValidation() {
      const success = !this.$v.$statement.$invalid;
      const errorMessages = [];
      if (!this.$v.$statement.$dirty) return { success, errorMessages };
      if (!this.$v.$statement.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    $policyValidation() {
      const success = !this.$v.$policy.$invalid;
      const errorMessages = [];
      if (!this.$v.$policy.$dirty) return { success, errorMessages };
      if (!this.$v.$policy.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    $commissionTypeValidation() {
      const success = !this.$v.$commissionType.$invalid;
      const errorMessages = [];
      if (!this.$v.$commissionType.$dirty) return { success, errorMessages };
      if (!this.$v.$commissionType.required) errorMessages.push("Required");
      if (!this.$v.$commissionType.inList) {
        errorMessages.push("Must be in list");
      }
      return { success, errorMessages };
    },
    $premiumValidation() {
      const success = !this.$v.$premium.$invalid;
      const errorMessages = [];
      if (!this.$v.$premium.$dirty) return { success, errorMessages };
      if (!this.$v.$premium.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    $overrideValidation() {
      const success = !this.$v.$override.$invalid;
      const errorMessages = [];
      if (!this.$v.$override.$dirty) return { success, errorMessages };
      if (!this.$v.$override.required) errorMessages.push("Required");
      return { success, errorMessages };
    }
  },
  watch: {
    $statement: {
      deep: true,
      handler(v) {
        this.$set(this, "$statement", v);
      }
    },
    $policy: {
      deep: true,
      handler(v) {
        this.$set(this, "$policy", v);
      }
    },
    $commissionType(v) {
      this.$commissionType = v;
    },
    $premium(v) {
      this.$premium = v;
    },
    $overrideValue(v) {
      this.$override = v;
    }
  },
  methods: {
    save() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.$emit("save");
    }
  },
  validations() {
    return {
      $policy: { required: v => Boolean(v?.id) },
      $commissionType: { required, inList: v => commissionTypes.includes(v) },
      $statement: { required: v => this.editing || Boolean(v?.id) },
      $premium: { required },
      $override: { required }
    };
  }
};
</script>
