<template>
  <v-autocomplete
    autocomplete="false"
    :prepend-inner-icon="mdiDomain"
    placeholder="Search Carriers"
    label="Carrier"
    outlined
    dense
    v-model="carrier"
    item-text="name"
    return-object
    no-filter
    hide-no-data
    auto-select-first
    :clearable="clearable"
    :disabled="disabled"
    :search-input.sync="carrierSearchText"
    :success="success"
    :error-messages="errorMessages"
    :items="carriers"
    :loading="loading"
    :hide-details="hideDetails"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    ref="carrierSearchText"
    @blur="$emit('blur')"
    @change="$emit('change')"
  >
    <template #append-outer v-if="hasAppendOuterSlot">
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import { searchCarriers } from "@/api/carrier.service";
import sortBy from "lodash/sortBy";
import { mdiDomain } from "@mdi/js";
export default {
  props: {
    clearable: Boolean,
    success: Boolean,
    errorMessages: Array,
    value: [Object, String],
    lines: {
      required: false,
      type: Array
    },
    disabled: Boolean,
    hideDetails: Boolean
  },
  data() {
    let carriers = [];
    let carrier = null;
    if (this.value && Object.keys(this.value).length && this.value.id) {
      carriers = [this.value];
      carrier = this.value;
    }
    return {
      carrierSearchText: "",
      carrierTimer: null,
      loading: false,
      carriers,
      carrier,
      mdiDomain
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append-outer"];
    }
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.carrier = null;
        this.carriers = [];
        return;
      }
      this.carrier = value;
      this.carriers = [value];
    },
    carrier(value) {
      this.$emit("input", value);
    },
    carrierSearchText(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (this.carriers.some(val => val?.name === value)) {
        return;
      }

      if (this.carrierTimer) {
        clearTimeout(this.carrierTimer);
      }

      this.carrierTimer = setTimeout(() => {
        this.loading = true;
        searchCarriers(value, this.lines)
          .then(response => {
            this.carriers = sortBy(response.data.carriers, "name");
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    focus() {
      this.$refs.carrierSearchText.$refs.input.focus();
    },
    clear() {
      this.carrier = null;
      this.carriers = [];
    }
  }
};
</script>
