<template>
  <v-data-table
    data-testid="admin-connections"
    class="transparent-data-table"
    disable-pagination
    hide-default-footer
    disable-filtering
    :options="table.options.value"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
  >
    <template #top>
      <v-btn
        class="text-none"
        color="accent"
        data-testid="admin-connections-add"
        @click="showAddConnectionDialog"
      >
        <v-icon> {{ mdiPlus }} </v-icon> Provide Access
      </v-btn>
    </template>

    <template #[`item.name`]="{ item }">
      <div data-testid="connection">
        <template v-if="item.additional.disabled">
          {{ item.name }}
        </template>
        <router-link v-else :to="item.additional.routerLink">
          {{ item.name }}
        </router-link>
      </div>
    </template>

    <template #[`item.actions`]="{ item }">
      <div class="row" v-if="!item.additional.disabled">
        <v-btn
          class="text-none"
          color="success"
          icon
          data-testid="admin-connections-edit"
          @click="showEditConnectionDialog(item)"
        >
          <v-icon> {{ mdiPencil }} </v-icon>
        </v-btn>
        <v-btn
          class="text-none"
          color="red"
          icon
          data-testid="admin-connections-delete"
          @click="deleteRow(item)"
        >
          <v-icon>{{ mdiDelete }} </v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>
<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useTable } from "@/composables/table.composable";
import { defineProps } from "vue";
import { useSettingsViewStore } from "@/stores/settings-view";
import { mdiPlus, mdiPencil, mdiDelete } from "@mdi/js";

const props = defineProps({
  readonly: Boolean,
  isViewingSelf: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});

let store;

if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const accessText = props.isViewingSelf
  ? "Access I'm Providing"
  : `Access ${store.name} is Providing`;
const headers = [
  new TableHeader({
    text: accessText,
    value: "name",
    map: "connectedAdvisorName",
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({ text: "Edit Access", value: "actions", map: "" })
];
const table = useTable({
  headers,
  options: new TableOptions(["name"], [false])
});
const user = useUserStore();
const dialog = useDialogStore();
function deleteRow(item) {
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Remove ${item.additional.connectedAdvisorName} from this Advisor?`,
    subtitle: "This cannot be undone",
    func: async () => {
      await store.deleteConnection(item.additional.id);
      updateTableItems();
    }
  });
}

async function showAddConnectionDialog() {
  const isAlreadyConnection = table.items.value.some(
    ({ additional }) =>
      user.loginable.id === additional?.connectedAdvisorId &&
      user.loginable.type === additional?.connectedAdvisorType
  );

  const res = await dialog.showDialog({
    component: "EditConnectionDialog",
    creating: true,
    isForAgent: store.type === "Agent",
    canAddSelf: !isAlreadyConnection && !props.isViewingSelf,
    advisorId: store.id,
    advisorType: store.type,
    advisorName: store.name,
    scrollable: true
  });
  if (!res?.connection) return;
  store.addConnection(res.connection);
  updateTableItems();
}

async function showEditConnectionDialog(item) {
  const res = await dialog.showDialog({
    component: "EditConnectionDialog",
    isForAgent: store.type === "Agent",
    connectionToEdit: store.connections[item.additional.id],
    advisorName: store.name,
    advisorId: store.id,
    advisorType: store.type,
    scrollable: true
  });
  if (!res?.connection) return;
  store.addConnection(res.connection);
  updateTableItems();
}

function updateTableItems() {
  table.items.value.splice(0, table.items.value.length);
  table.items.value.push(...Object.values(store.connections));
}

updateTableItems();
</script>
