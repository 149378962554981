<template>
  <v-sheet v-bind="sheetProps">
    <v-row dense class="ma-0">
      <v-col
        v-for="header in basicHeaders"
        :md="mdCols[header.filterType] || 3"
        :cols="cols[header.filterType] || 6"
        :key="header.text"
      >
        <table-filter-item
          v-model="filters[header.value]"
          :ref="header.text"
          :filter-type="header.filterType"
          :items="header.selectableOptions"
          :text="header.text"
          :is-currency="header.isCurrency"
          :is-mandatory="header.isMandatory"
          :icon="header.icon"
          :future-date-values="header.futureDateValues"
          :color="color"
          :checkbox-values="header.checkboxValues"
          @update-filter="updateFilter"
        />
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-row v-show="showAllFilters" dense class="ma-0">
        <v-col
          v-for="header in advancedHeaders"
          :md="mdCols[header.filterType] || 3"
          :cols="cols[header.filterType] || 6"
          :key="header.text"
        >
          <table-filter-item
            v-model="filters[header.value]"
            :ref="header.text"
            :filter-type="header.filterType"
            :items="header.selectableOptions"
            :text="header.text"
            :is-currency="header.isCurrency"
            :is-mandatory="header.isMandatory"
            :icon="header.icon"
            :future-date-values="header.futureDateValues"
            :color="color"
            :checkbox-values="header.checkboxValues"
            @update-filter="updateFilter"
          />
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-row dense class="ma-0">
      <v-col :md="hasAdvancedFilters ? 9 : 12" cols="12" order="1" order-md="0">
        <generic-table-active-filters
          :active-filter="filters"
          :show-view-filter="showViewFilter"
          :headers="headers"
          @clicked="handleClickedFilter"
          @clear-filter="clearFilter"
        />
      </v-col>
      <v-col
        v-if="hasAdvancedFilters && !alwaysShowAllFilters"
        md="3"
        cols="12"
        class="order-md-1 order-0"
      >
        <v-row class="justify-md-end justify-start ma-0">
          <a
            class="text-body-2"
            data-testid="advanced-filters-toggle"
            @click="showAllFilters = !showAllFilters"
          >
            <v-icon class="mr-1 mb-1" style="color: inherit" size="16">
              {{
                showAllFilters ? mdiFilterVariantMinus : mdiFilterVariantPlus
              }}
            </v-icon>
            {{ showAllFilters ? "Hide" : "Show" }} Advanced Filters
          </a>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import TableFilterItem from "@/components/shared/data-table/TableFilterItem.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { cloneDeep } from "@/util/helpers";
import { pastDateFilters as dateFilterItems } from "@/constants/date-filter.constants";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";

import GenericTableActiveFilters from "@/components/shared/data-table/GenericTableActiveFilters.vue";
import { mdiFilterVariantMinus, mdiFilterVariantPlus } from "@mdi/js";
const SELECT_TYPES = [
  TableHeader.SELECT_TYPE,
  TableHeader.AUTOCOMPLETE_TYPE,
  TableHeader.MULTI_SELECT_TYPE
];

const RANGE_TYPES = [TableHeader.DATE_TYPE, TableHeader.NUMBER_RANGE];

export default {
  components: {
    GenericTableActiveFilters,
    TableFilterItem
  },
  data() {
    return {
      mdCols: RANGE_TYPES.reduce((acc, t) => ({ ...acc, [t]: 12 }), {}),
      cols: RANGE_TYPES.reduce((acc, t) => ({ ...acc, [t]: 12 }), {}),
      filters: cloneDeep(this.value),
      dateFilterItems,
      TableHeader,
      showAllFilters: this.alwaysShowAllFilters,
      timer: null,
      mdiFilterVariantMinus,
      mdiFilterVariantPlus
    };
  },
  mixins: [GenericTableMixin],
  created() {
    this.filters = cloneDeep(this.value);
  },
  props: {
    headerProps: Object,
    alwaysShowAllFilters: Boolean,
    headers: Array,
    value: Object,
    showViewFilter: Boolean,
    hover: Boolean,
    color: {
      type: String,
      default: "section"
    }
  },
  watch: {
    value(val) {
      this.filters = cloneDeep(val);
    }
  },
  computed: {
    sheetProps() {
      return {
        rounded: true,
        class: "pa-3 mt-1 mx-0",
        color: this.color,
        elevation: this.hover ? 2 : 0,
        ...this.headerProps
      };
    },
    filterableHeaders() {
      return this.headers.filter(h => h.filterable);
    },
    hydratedHeaders() {
      return this.filterableHeaders.reduce((accumulator, header) => {
        const h = { ...header };
        if (SELECT_TYPES.includes(h.filterType)) {
          if (h.selectableOptionsFn) {
            h.selectableOptions = h.selectableOptionsFn(this.filters);
            h.sideEffect = this.handleDynamicFilterSideEffects;
          }

          if (h.selectableOptions?.length) accumulator.push(h);
        } else {
          accumulator.push(h);
        }

        return accumulator;
      }, []);
    },
    availableHeaders() {
      const rangeHeaders = [];
      const nonRangeHeaders = [];

      this.hydratedHeaders.forEach(h => {
        if (RANGE_TYPES.includes(h.filterType)) rangeHeaders.push(h);
        else nonRangeHeaders.push(h);
      });

      rangeHeaders.sort((a, b) => a.order - b.order);
      nonRangeHeaders.sort((a, b) => a.order - b.order);

      return nonRangeHeaders.concat(rangeHeaders);
    },
    hasAdvancedFilters() {
      const length = this.$vuetify.breakpoint.mdAndUp ? 4 : 2;
      return (
        this.availableHeaders.length > length ||
        this.availableHeaders.some(h => RANGE_TYPES.includes(h.filterType))
      );
    },
    collapsedCount() {
      return this.$vuetify.breakpoint.mdAndUp ? 4 : 2;
    },
    headersByPriority() {
      return this.availableHeaders.reduce((accumulator, header, index) => {
        const isRangeType = RANGE_TYPES.includes(header.filterType);
        const isInRange = index < this.collapsedCount;
        return {
          ...accumulator,
          [header.value]: !isRangeType && isInRange
        };
      }, {});
    },
    basicHeaders() {
      return this.availableHeaders.filter(h => this.headersByPriority[h.value]);
    },
    advancedHeaders() {
      return this.availableHeaders.filter(
        h => !this.headersByPriority[h.value]
      );
    }
  },
  methods: {
    async handleClickedFilter(filterValue) {
      if (this.advancedHeaders.includes(filterValue)) {
        this.showAllFilters = true;
      }
      await this.$nextTick();
      const header = this.availableHeaders.find(v => v.value === filterValue);
      this.$refs[header.text][0].focus();
    },
    handleDynamicFilterSideEffects(header, value) {
      if (!header) return null;
      const values = [];
      const shouldConvert = !Array.isArray(value);
      if (shouldConvert) values.push(value);
      else values.push(...value);

      values.forEach((v, index) => {
        const hasOldValue = !header.selectableOptions.some(
          o => o === v || o?.value === v
        );
        if (hasOldValue) values[index] = undefined;
      });
      const filteredValues = values.filter(v => v !== undefined);

      return shouldConvert ? filteredValues?.[0] : filteredValues;
    },
    updateFilter() {
      Object.keys(this.filters).forEach(f => {
        const header = this.hydratedHeaders.find(v => v.value === f);
        const unfilteredHeader = this.headers.find(v => v.value === f);
        if (!header && !unfilteredHeader) return;
        if (!header && unfilteredHeader) this.filters[f] = null;
        else if (header.sideEffect) {
          this.filters[f] = header.sideEffect(header, this.filters[f]);
        }
      });
      this.$nextTick(() => this.$emit("update", this.filters));
    },
    clearFilter(filter) {
      const header = this.availableHeaders.find(v => v.value === filter);
      this.$refs[header.text][0].clear();
    }
  }
};
</script>

<style lang="scss">
.expand-transition {
  transition: height 0.3s;
}

.expanded {
  height: fit-content;
}
</style>
