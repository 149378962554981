<template>
  <v-row class="ma-0" dense>
    <v-col cols="12">
      <h1>Create an Informal Inquiry</h1>
    </v-col>

    <v-col cols="12">
      <v-card flat>
        <v-card-title> Agents </v-card-title>
        <v-card-text>
          <v-row class="ma-0" dense>
            <v-col cols="12">
              <agent-search
                v-model="informal.agent"
                better-return
                label="Agent"
                data-testid="informal-agent"
                :clearable="false"
                :success="agentValidation.success"
                :error-messages="agentValidation.errorMessages"
              />

              <v-row
                v-for="(agent, agentUuid) in informal.additionalAgents"
                :key="agentUuid"
                dense
                align="center"
                class="ma-0 mb-6"
              >
                <agent-search
                  v-model="informal.additionalAgents[agentUuid]"
                  better-return
                  success
                  label="Agent"
                  :data-testid="`informal-agent-${agent.name}`"
                  :clearable="false"
                  hide-details
                />
                <v-btn
                  depressed
                  :data-testid="`informal-agent-${agent.name}-delete`"
                  class="text-none"
                  icon
                  @click="deleteAgent(agentUuid)"
                >
                  <v-icon color="error">{{ mdiDelete }}</v-icon>
                </v-btn>
              </v-row>

              <v-row
                v-if="informal.agent?.id"
                class="ma-0"
                style="gap: 9px"
                dense
              >
                <agent-search
                  better-return
                  :key="agentKey"
                  label="Additional Agent"
                  data-testid="informal-new-agent"
                  @input="addAgent"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <v-card-title>Contract Party</v-card-title>
        <v-card-text>
          <v-row class="ma-0" dense>
            <v-col cols="12" md="6">
              <party-search
                v-model="informal.party"
                outlined
                dense
                hint=" "
                persistent-hint
                data-testid="informal-party"
                :success="partyValidation.success"
                :error-messages="partyValidation.errorMessages"
              >
                <template #message>
                  <a
                    @click="createContractParty"
                    class="text-none"
                    data-testid="informal-create-party"
                  >
                    Can't find your contract party?
                  </a>
                </template>
              </party-search>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="informal.status"
                :prepend-inner-icon="mdiListStatus"
                outlined
                dense
                placeholder="Select Status"
                label="Status"
                data-testid="informal-status"
                :items="INFORMAL_STATUSES"
                :success="statusValidation.success"
                :error-messages="statusValidation.errorMessages"
              />
            </v-col>
            <v-col cols="12" md="6">
              <currency-input
                v-model="informal.faceAmount"
                :prepend-inner-icon="mdiCurrencyUsd"
                outlined
                dense
                label="Death Benefit"
                data-testid="informal-face-amount"
                :success="faceAmountValidation.success"
                :error-messages="faceAmountValidation.errorMessages"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="informal.state"
                :prepend-inner-icon="mdiSignRealEstate"
                outlined
                dense
                placeholder="Select State"
                label="State"
                data-testid="informal-state"
                :items="STATES"
                :success="stateValidation.success"
                :error-messages="stateValidation.errorMessages"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <v-card-title>
          <v-row dense align="center" class="ma-0">
            Carriers
            <v-spacer />
            <v-btn
              class="text-none"
              color="accent"
              data-testid="informal-select-qna-carriers"
              @click="selectAllQuoteAndApplyCarriers"
            >
              <quote-and-apply-icon class="mr-1" />
              Enable All Quote & Apply Carriers
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-chip-group
            v-if="carriers.length"
            v-model="informal.carriers"
            multiple
            column
            color="primary"
          >
            <template v-for="({ name, id }, index) in carriers">
              <v-chip
                :key="`item-${index}`"
                :value="id"
                :data-testid="`carrier-${name}`"
              >
                {{ name }}
              </v-chip>
            </template>
          </v-chip-group>
          <template v-else>
            <p style="text-align: center">Loading Carriers</p>
            <v-progress-linear indeterminate />
          </template>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-btn
        color="primary"
        class="text-none"
        data-testid="informal-create"
        :loading="loading"
        @click="save"
      >
        <v-icon class="mr-1">{{ mdiPlus }}</v-icon>
        Create Informal
      </v-btn>
    </v-col>
  </v-row>
</template>

<script setup>
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import QuoteAndApplyIcon from "@/components/shared/QuoteAndApplyIcon.vue";
import AgentSearch from "@/components/shared/AgentSearch.vue";
import PartySearch from "@/components/shared/ContractPartySearch.vue";
import NewPartyDialog from "@/components/cases/case-dialogs/NewPartyDialog.vue";

import { states as STATES } from "@/data/states";
import {
  computedValidation,
  getRouterLink,
  parseErrorMessage,
  uuidv4
} from "@/util/helpers";
import { getCarriers } from "@/api/carrier.service";
import { createCase } from "@/api/cases.service";
import {
  InformalInquiry,
  InformalInquiryToCreateRequest,
  INFORMAL_STATUSES
} from "@/factories/InformalInquiry";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useHead } from "@unhead/vue";
import { del, set, markRaw, ref } from "vue";

import {
  mdiDelete,
  mdiListStatus,
  mdiCurrencyUsd,
  mdiSignRealEstate,
  mdiPlus
} from "@mdi/js";

import useVuelidate from "@vuelidate/core";
import { useRouter } from "@/composables/compatible.composables";

useHead({ title: "Create Informal Case" });

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const router = useRouter();

const informal = ref(InformalInquiry());
const carriers = ref([]);
const loading = ref(false);
const agentKey = ref(null);

const v$ = useVuelidate(
  {
    informal: {
      agent: {
        required: Boolean
      },
      status: {
        required: Boolean
      },
      faceAmount: {
        required: Boolean
      },
      state: {
        required: Boolean
      },
      party: {
        required: Boolean
      }
    }
  },
  { informal },
  { $autoDirty: true, $scope: null }
);

const agentValidation = computedValidation(v$.value.informal.agent, {
  required: "Required"
});
const statusValidation = computedValidation(v$.value.informal.status, {
  required: "Required"
});
const faceAmountValidation = computedValidation(v$.value.informal.faceAmount, {
  required: "Required"
});
const stateValidation = computedValidation(v$.value.informal.state, {
  required: "Required"
});
const partyValidation = computedValidation(v$.value.informal.party, {
  required: "Required"
});

function selectAllQuoteAndApplyCarriers() {
  informal.value.carriers.splice(0, informal.value.carriers.length);
  carriers.value.forEach(c => {
    if (c.strifeApproved) informal.value.carriers.push(c.id);
  });
}

let carrierCancelToken = null;
async function fetchCarriers() {
  try {
    const params = new URLSearchParams();
    params.append("informal", "true");
    const allCarriers = await getCarriers(params, carrierCancelToken);
    carriers.value.push(
      ...allCarriers.map(({ name, id, availableInQuoteAndApply }) => ({
        name,
        id,
        strifeApproved: availableInQuoteAndApply
      }))
    );
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  try {
    loading.value = true;
    const id = await createCase(InformalInquiryToCreateRequest(informal.value));
    router.push(getRouterLink("Case", id));
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      duration: 5000
    });
  } finally {
    loading.value = false;
  }
}

function deleteAgent(uuid) {
  del(informal.value.additionalAgents, uuid);
}

function addAgent(value) {
  if (!value) return;
  set(informal.value.additionalAgents, uuidv4(), value);
  agentKey.value = uuidv4();
}

async function createContractParty() {
  const result = await dialog.showDialog({
    component: markRaw(NewPartyDialog),
    hideRole: true,
    hideNetWorth: true,
    line: "life",
    scrollable: true,
    onlyType: "Individual"
  });

  if (!result.party) return;
  informal.value.party = result.party;
}

fetchCarriers();
</script>
