<template>
  <v-sheet color="section">
    <v-col>
      <h1 class="text-h5 mb-3">Edit Signer Email</h1>
      <basic-email-input
        v-model="signerEmail"
        data-lpignore="true"
        label="Email"
        :prepend-inner-icon="mdiEmail"
        outlined
        dense
        data-testid="signer-edit-email"
        :success="emailValidation.success"
        :error-messages="emailValidation.errorMessages"
        @valid="emailIsValid = $event"
      />
      <v-row class="ma-0">
        <v-spacer />
        <v-btn
          outlined
          @click="dialog.closeDialog()"
          class="text-none"
          :disabled="saving"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
          class="text-none ml-1"
          data-testid="signer-edit-save"
          :loading="saving"
          >Save</v-btn
        >
      </v-row>
    </v-col>
  </v-sheet>
</template>

<script setup>
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { updateSignerEmail } from "@/api/signers.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import { ref, defineProps } from "vue";
import useVuelidate from "@vuelidate/core";
import { mdiEmail } from "@mdi/js";
const props = defineProps({
  value: String,
  signerId: Number
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const signerEmail = ref(props.value);
const saving = ref(false);
const emailIsValid = ref(true);

const v$ = useVuelidate(
  {
    signerEmail: {
      required: Boolean,
      valid: () => emailIsValid.value === true
    }
  },
  { signerEmail },
  { $scope: null, $autoDirty: true }
);

const emailValidation = computedValidation(v$.value.signerEmail, {
  required: "Required",
  valid: "Invalid Email"
});

async function save() {
  saving.value = true;
  try {
    await updateSignerEmail(props.signerId, signerEmail.value);
    dialog.closeDialog({ email: signerEmail.value });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
