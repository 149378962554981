<template>
  <v-card flat tile>
    <v-row align="center" class="ma-0">
      <v-col data-testid="carrier-name">
        <strong style="font-size: 1.2rem"> {{ name }} </strong>
        <br />
        Carrier
      </v-col>
      <v-col>
        <v-row justify="end" class="ma-0">
          <avatar-edit
            type="Carrier"
            data-testid="carrier-avatar"
            :avatar="avatar"
            :id="carrierId"
            :editable="isGroupTwoPlus"
            @avatar-update="updateAvatar"
          />
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col
            v-for="{ component, props, key } in details"
            :key="key"
            cols="12"
            xl="3"
            lg="3"
            md="4"
            sm="6"
            xs="6"
          >
            <component
              :is="component"
              v-bind="props"
              :data-testid="`card-${key}`"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import AvatarEdit from "@/components/shared/AvatarEdit.vue";
import CardItemText from "@/components/shared/card-items/CardItemText.vue";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useCarrierView } from "@/stores/carrier-view";
import { computed, markRaw, ref } from "vue";

const {
  id: carrierId,
  name,
  naic,
  amBestRating,
  strifeApproved,
  avatar: carrierAvatar
} = storeToRefs(useCarrierView());

const { isGroupTwoPlus } = storeToRefs(useUserStore());

const avatar = ref(generateAvatarUrl(carrierAvatar.value));

function generateAvatarUrl(uri) {
  return `${uri}?time=${new Date().getTime()}`;
}

function updateAvatar(newUri) {
  avatar.value = generateAvatarUrl(newUri);
}

const details = computed(() => {
  const generateTextItem = props => ({
    component: markRaw(CardItemText),
    key: props.title,
    props
  });

  return [
    generateTextItem({
      title: "Available in Quote & Apply",
      text: strifeApproved.value ? "Yes" : "No",
      class: strifeApproved.value ? "font-weight-bold success--text" : null
    }),
    generateTextItem({
      title: "NAIC Code",
      text: naic.value ? `${naic.value}` : ""
    }),
    generateTextItem({
      title: "AM Best Rating",
      text: amBestRating.value || ""
    })
  ];
});
</script>
