<template>
  <v-tooltip bottom v-if="!showSearchBar && !alwaysVisible">
    <template #activator="{ on, attrs }">
      <v-btn
        data-testid="show-search-toggle"
        v-on="on"
        v-bind="attrs"
        icon
        @click="showSearch"
      >
        <v-icon>{{ mdiMagnify }}</v-icon>
      </v-btn>
    </template>
    <span> Search BOSS </span>
  </v-tooltip>
  <sitewide-search
    v-else
    as-router-link
    :show-nav-helpers="!alwaysVisible"
    autocomplete="off"
    style="padding-top: 1rem"
    placeholder="Type to search BOSS"
    return-object
    hide-no-data
    hide-selected
    no-filter
    :prepend-inner-icon="mdiMagnify"
    tabindex="0"
    ref="searchRef"
    auto-select-first
    id="menu-search"
    inputmode="search"
    data-testid="search-bar"
    :key="$route.path"
    :autofocus="autofocus"
    :style="{ paddingTop: $vuetify.breakpoint.mdAndDown ? '.7rem' : '1rem' }"
    :flat="notDense"
    :outlined="!notDense"
    :class="{ 'mt-0': notDense, 'menu-width-full': notDense }"
    :menu-props="{
      maxHeight: notDense ? windowSize : 304,
      contentClass: 'sitewide-search'
    }"
    :attach="notDense"
    :dense="!notDense"
    :clearable="!notDense"
    :items="items"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    :label="label"
    @blur="hideSearch"
  >
    <template #icon>
      <slot name="icon" />
    </template>
  </sitewide-search>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import SitewideSearch from "@/components/shared/SitewideSearch.vue";

export default {
  components: {
    SitewideSearch
  },
  data() {
    return {
      timer: null,
      items: [],
      loading: false,
      showSearchBar: false,
      focusedIndex: 0,
      mdiMagnify
    };
  },
  props: {
    startExpanded: Boolean,
    alwaysVisible: Boolean,
    autofocus: Boolean,
    label: {
      default: "Search BOSS",
      type: String
    },
    notDense: Boolean
  },
  mounted() {
    this.$nextTick(() => {
      if (this.startExpanded) this.showSearch();
    });
  },
  computed: {
    windowSize() {
      return window.innerHeight - 51;
    }
  },
  methods: {
    toggleSearch() {
      if (this.showSearchBar) {
        this.showSearchBar = false;
        return;
      }

      this.showSearch();
    },
    showSearch() {
      // A 2 tick issue, takes a full 2 ticks to render the input
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.searchRef.focus();
        });
      });

      this.showSearchBar = true;
    },
    hideSearch() {
      this.showSearchBar = false;
    }
  }
};
</script>
