<template>
  <v-fade-transition mode="out-in">
    <v-row v-if="loading" dense key="loading">
      <v-col cols="12" class="pb-0">
        <v-skeleton-loader type="card" />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-sheet color="section" class="py-3 px-0">
          <v-skeleton-loader type="card" />
        </v-sheet>
      </v-col>
    </v-row>
    <v-col v-else dense key="loaded" class="pa-0 mb-12">
      <advisor-profile-view-card />
      <advisor-profile-view-actions />
      <v-tabs v-model="activeTab" class="pt-3" show-arrows>
        <v-tab
          v-for="tab in tabs"
          class="text-none"
          :key="tab.page"
          :data-testid="`tab-${tab.title}`"
        >
          <v-icon class="pr-1">{{ tab.icon }}</v-icon>
          <v-badge v-if="tab.badgeProps" v-bind="tab.badgeProps">
            {{ tab.title }}
          </v-badge>
          <template v-else>{{ tab.title }}</template>
        </v-tab>
      </v-tabs>
      <v-divider />
      <v-tabs-items v-model="activeTab" touchless>
        <v-tab-item
          v-for="(tab, index) in tabs"
          :key="tab.page + 'component'"
          :eager="tab.eager"
          class="section"
        >
          <component
            :is="tab.component"
            :active="activeTab === index"
            v-bind="tab.props || {}"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-fade-transition>
</template>

<script setup>
import AdvisorProfileViewActions from "@/components/advisor-profile/AdvisorProfileViewActions.vue";
import AdvisorProfileViewCard from "@/components/advisor-profile/AdvisorProfileViewCard.vue";

import AdvisorProfileViewToDoList from "@/components/advisor-profile/AdvisorProfileViewToDoList.vue";
import AdvisorProfileViewPerformanceSnapshot from "@/components/advisor-profile/AdvisorProfileViewPerformanceSnapshot.vue";
import AdvisorProfileViewLicenses from "@/components/advisor-profile/AdvisorProfileViewLicenses.vue";
import AdvisorProfileViewNotes from "@/components/advisor-profile/AdvisorProfileViewNotes.vue";
import AdvisorProfileViewActivity from "@/components/advisor-profile/AdvisorProfileViewActivity.vue";

import { parseErrorMessage } from "@/util/helpers";

import { storeToRefs } from "pinia";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";

import { useHead } from "@unhead/vue";
import {
  computed,
  defineProps,
  markRaw,
  onBeforeUnmount,
  ref,
  toRef,
  watch
} from "vue";
import { useRouter } from "@/composables/compatible.composables";
import {
  mdiCardAccountDetails,
  mdiChartAreaspline,
  mdiCheck,
  mdiNote,
  mdiTimeline
} from "@mdi/js";
import { useUserStore } from "@/stores/user";

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  type: {
    type: String,
    required: true,
    validates: value => ["Agent", "Agency"].includes(value)
  },
  page: {
    type: String,
    required: false,
    default: null
  },
  resource: {
    type: String,
    required: false,
    default: "cases"
  },
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  }
});

useHead({ title: "Advisor Profile" });
const snackbar = useSnackbarStore();
const router = useRouter();
const advisorProfile = useAdvisorProfileView();
const user = useUserStore();
const activeTab = ref(0);

const { isGroupTwoPlus } = storeToRefs(user);
const { name, todoCount } = storeToRefs(advisorProfile);
const { breadcrumb } = storeToRefs(useInstanceStore());

const loading = ref(false);
const highlightTodo = toRef(props, "highlightTodo");
const tabs = computed(() => {
  const tabs = [
    {
      title: "Activity",
      icon: mdiTimeline,
      component: markRaw(AdvisorProfileViewActivity),
      page: "activity",
      props: {
        resource: props.resource
      }
    },
    {
      title: "Licenses",
      icon: mdiCardAccountDetails,
      component: markRaw(AdvisorProfileViewLicenses),
      page: "licenses"
    },

    {
      title: "Performance",
      icon: mdiChartAreaspline,
      component: markRaw(AdvisorProfileViewPerformanceSnapshot),
      page: "performance"
    },

    {
      title: "To-Do",
      icon: mdiCheck,
      component: markRaw(AdvisorProfileViewToDoList),
      eager: true,
      page: "to-do",
      props: {
        highlightTodo: highlightTodo.value
      },
      badgeProps: todoCount.value
        ? {
            color: "error",
            content: todoCount.value,
            "data-testid": "todo-badge"
          }
        : null
    }
  ];

  if (isGroupTwoPlus.value) {
    tabs.push({
      title: "Notes",
      icon: mdiNote,
      eager: true,
      component: markRaw(AdvisorProfileViewNotes),
      page: "notes"
    });
  }

  tabs.sort((a, b) => a.title.localeCompare(b.title));

  return tabs;
});

async function getAdvisorInfo() {
  try {
    loading.value = true;
    await advisorProfile.getAdvisorInfo(props.id, props.type);

    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;
    else activeTab.value = tabs.value.findIndex(tab => tab.defaultTab);

    breadcrumb.value = name.value;
    useHead({ title: `${name.value}'s Profile` });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(error),
      timeout: -1
    });
    if (router) router.push({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

function syncQueryToTab() {
  if (!router) return;
  const tab = tabs.value[activeTab.value];
  if (!tab) return;
  let page = tab.page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});

onBeforeUnmount(() => (breadcrumb.value = ""));

getAdvisorInfo();
</script>
