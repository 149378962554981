<template>
  <v-card flat tile>
    <v-card-title> Basic Info</v-card-title>
    <v-card-subtitle> Tell us more about yourself. </v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <agent-profile
            :readonly="props.readonly"
            :is-viewing-self="props.isViewingSelf"
          />
        </v-col>

        <v-col cols="12">
          <v-card flat color="section">
            <v-card-title>Legal Information</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="legalFirstName"
                    data-lpignore="true"
                    data-testid="agent-legal-first-name"
                    :prepend-inner-icon="mdiAccount"
                    outlined
                    dense
                    :disabled="user.isGroupOne"
                    label="Legal First Name"
                    :success="legalFirstNameValidation.success"
                    :error-messages="legalFirstNameValidation.errorMessages"
                    :hint="
                      user.isGroupOne
                        ? 'Please contact support@back9ins.com to change your legal first name'
                        : null
                    "
                    :persistent-hint="user.isGroupOne"
                    @input="saveLegalFirstName"
                  >
                    <template #append-outer v-if="user.isGroupTwoPlus">
                      <active-save-indicator
                        :controller="
                          savingBuffer.legalFirstName.controller.value
                        "
                      />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="legalLastName"
                    data-lpignore="true"
                    data-testid="agent-legal-last-name"
                    :prepend-inner-icon="mdiAccountMultiple"
                    outlined
                    dense
                    :disabled="user.isGroupOne"
                    label="Legal Last Name"
                    :success="legalLastNameValidation.success"
                    :error-messages="legalLastNameValidation.errorMessages"
                    :hint="
                      user.isGroupOne
                        ? 'Please contact support@back9ins.com to change your legal last name'
                        : null
                    "
                    :persistent-hint="user.isGroupOne"
                    @input="saveLegalLastName"
                  >
                    <template #append-outer v-if="user.isGroupTwoPlus">
                      <active-save-indicator
                        :controller="
                          savingBuffer.legalLastName.controller.value
                        "
                      />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <input-ssn
                    v-model="ssn"
                    name="ssn"
                    outlined
                    dense
                    label="SSN"
                    required
                    data-testid="agent-ssn"
                    :disabled="props.readonly || ssnAlreadySaved"
                    :success="ssnValidation.success"
                    :error-messages="ssnValidation.errorMessages"
                    @input="saveSsn"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.ssn.controller.value"
                      />
                    </template>
                  </input-ssn>
                </v-col>
                <v-col cols="12" md="4">
                  <npn-input
                    v-model="npn"
                    validate
                    data-testid="agent-npn"
                    :disabled="npnLookedUp || props.readonly"
                    :success="npnValidation.success"
                    :error-messages="npnValidation.errorMessages"
                    @input="saveNpn"
                    @valid="validNpn = $event"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.npn.controller.value"
                      />
                    </template>
                  </npn-input>
                </v-col>
                <v-col cols="12" md="4">
                  <date-input
                    v-model="birthdate"
                    :prepend-inner-icon="mdiCalendar"
                    outlined
                    dense
                    data-testid="agent-birthdate"
                    label="Birthdate"
                    :disabled="props.readonly"
                    :success="birthdateValidation.success"
                    :error-messages="birthdateValidation.errorMessages"
                    @input="saveBirthdate"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.birthdate.controller.value"
                      />
                    </template>
                  </date-input>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="gender"
                    outlined
                    dense
                    label="Gender"
                    :prepend-inner-icon="mdiGenderMaleFemale"
                    data-testid="agent-gender"
                    :disabled="props.readonly"
                    :items="genders"
                    :success="genderValidation.success"
                    :error-messages="genderValidation.errorMessages"
                    @change="saveGender"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.gender.controller.value"
                      />
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <state-select
                    v-model="driversLicense.state"
                    outlined
                    dense
                    label="Drivers License State"
                    data-testid="agent-drivers-license-state"
                    :disabled="props.readonly"
                    :success="driversLicenseStateValidation.success"
                    :error-messages="
                      driversLicenseStateValidation.errorMessages
                    "
                    @input="saveDriversLicenseState"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="
                          savingBuffer.driversLicenseState.controller.value
                        "
                      />
                    </template>
                  </state-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="driversLicense.number"
                    data-lpignore="true"
                    outlined
                    dense
                    label="Drivers License Number"
                    data-testid="agent-drivers-license-number"
                    :prepend-inner-icon="mdiPound"
                    :disabled="props.readonly || !driversLicense.state"
                    :success="driversLicenseNumberValidation.success"
                    :error-messages="
                      driversLicenseNumberValidation.errorMessages
                    "
                    @input="saveDriversLicenseNumber"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="
                          savingBuffer.driversLicenseNumber.controller.value
                        "
                      />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="securitiesLicensed"
                    placeholder="Select"
                    outlined
                    data-testid="agent-drivers-securities-licensed"
                    :disabled="props.readonly"
                    :success="securitiesLicensedValidation.success"
                    :error-messages="securitiesLicensedValidation.errorMessages"
                    dense
                    :items="licenseStatusItems"
                    label="Securities License Status"
                    :prepend-inner-icon="mdiIdCard"
                    @change="validate"
                  />
                </v-col>
                <v-col v-if="securitiesLicensed" cols="12" md="4">
                  <crd-input
                    v-model="crdNumber"
                    data-testid="agent-drivers-crd-number"
                    :disabled="props.readonly"
                    :success="crdNumberValidation.success"
                    :error-messages="crdNumberValidation.errorMessages"
                    @input="saveCrdNumber"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.crdNumber.controller.value"
                      />
                    </template>
                  </crd-input>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat color="section">
            <v-card-title>Contact info</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="8">
                  <phone-input
                    v-model="phoneWork"
                    data-lpignore="true"
                    :prepend-inner-icon="mdiDeskphone"
                    outlined
                    dense
                    label="Office Phone"
                    data-testid="agent-office-phone"
                    :disabled="props.readonly"
                    :success="phoneWorkValidation.success"
                    :error-messages="phoneWorkValidation.errorMessages"
                    @input="savePhoneWork"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.phoneWork.controller.value"
                      />
                    </template>
                  </phone-input>
                </v-col>
                <v-col cols="4">
                  <integer-input
                    v-model="phoneWorkExtension"
                    data-lpignore="true"
                    :prepend-inner-icon="mdiAlphaX"
                    outlined
                    dense
                    data-testid="agent-office-phone-extension"
                    label="Office Phone Extension"
                    mask="######"
                    :disabled="props.readonly"
                    :success="Boolean(phoneWorkExtension)"
                    @input="savePhoneWorkExtension"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="
                          savingBuffer.phoneWorkExtension.controller.value
                        "
                      />
                    </template>
                  </integer-input>
                </v-col>
                <v-col cols="12" md="6">
                  <phone-input
                    v-model="phoneMobile"
                    data-lpignore="true"
                    :prepend-inner-icon="mdiCellphone"
                    outlined
                    dense
                    data-testid="agent-mobile-phone"
                    label="Mobile Phone"
                    :disabled="props.readonly"
                    :success="phoneMobileValidation.success"
                    :error-messages="phoneMobileValidation.errorMessages"
                    @input="savePhoneMobile"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.phoneMobile.controller.value"
                      />
                    </template>
                  </phone-input>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :value="agent.shippingAddress"
                    label="Shipping Address"
                    :prepend-inner-icon="mdiTruckFastOutline"
                    outlined
                    dense
                    item-value="id"
                    data-testid="shipping-address"
                    :items="agent.shippingAddresses"
                    :item-text="addressItemDisplay"
                    :success="shippingAddressValidation.success"
                    :error-messages="shippingAddressValidation.errorMessages"
                    @change="saveShippingAddress"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="
                          savingBuffer.shippingAddress.controller.value
                        "
                      />
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat color="section">
            <v-card-title>Home Address History</v-card-title>
            <v-card-subtitle>
              Please provide all of the residence addresses where you've lived
              for the past 5 years
            </v-card-subtitle>
            <v-card-text>
              <address-item
                v-for="(addressId, index) in agent.homeAddressOrder"
                :data-testid="`home-address-${index}`"
                :key="addressId"
                :readonly="props.readonly"
                :address="agent.homeAddresses[addressId]"
                deletable
                show-dates
                @edit="editAddress(addressId)"
                @delete="removeAddress(addressId)"
              />
              <v-fade-transition mode="out-in">
                <template v-if="agent.missingFiveYearsOfAddresses">
                  <v-alert
                    type="warning"
                    class="my-2"
                    prominent
                    dense
                    data-testid="missing-years-warning"
                    key="address-warning"
                  >
                    We're missing some of the home addresses where you've lived
                    for the past 5 years.
                  </v-alert>
                </template>
              </v-fade-transition>
              <v-btn
                color="accent"
                class="text-none"
                data-testid="create-address"
                @click="createAddress"
              >
                <v-icon class="mr-1"> {{ mdiPlus }}</v-icon> Add Address
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat color="section">
            <v-card-title class="mb-3">Business Address</v-card-title>
            <v-card-text>
              <address-item
                data-testid="business-address"
                :readonly="props.readonly"
                :address="businessAddress"
                @edit="editBusinessAddress"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <agent-quote-and-apply />
        </v-col>
        <v-col v-if="props.isViewingSelf" cols="12">
          <user-tutorials-manager />
        </v-col>
        <v-col v-if="agent.isMarketingManager" cols="12">
          <marketing-manager-credits />
        </v-col>
        <v-col cols="12">
          <agent-identifiers />
        </v-col>
      </v-row>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script setup>
import AddressItemDialog from "@/dialogs/AddressItemDialog.vue";
import AgentIdentifiers from "@/components/settings/account-details/AgentIdentifiers.vue";
import MarketingManagerCredits from "@/components/settings/account-details/MarketingManagerCredits.vue";
import UserTutorialsManager from "@/components/settings/account-details/UserTutorialsManager.vue";
import AgentQuoteAndApply from "@/components/settings/account-details/AgentQuoteAndApply.vue";
import InputSsn from "@/components/shared/InputSsn.vue";
import NpnInput from "@/components/shared/NpnInput.vue";
import CrdInput from "@/components/shared/CrdInput.vue";
import StateSelect from "@/components/shared/StateSelect.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import AddressItem from "@/components/settings/account-details/AddressItem.vue";
import DateInput from "@/components/shared/DateInput.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";
import AgentProfile from "@/components/settings/account-details/AgentProfile.vue";

// these are utilized in the template
import { genders, licenseStatusItems } from "@/data/advisor-data";

import AddressFactory, { formatAddress } from "@/factories/AddressFactory";

import { validationComputeV2 } from "@/util/helpers";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { ssn as validateSsn } from "@/util/vuelidateValidators";
import { isValid as validLicenseNumber } from "usdl-regex";

import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { useActiveSave } from "@/composables/active-save.composable";
import {
  defineProps,
  computed,
  ref,
  onMounted,
  defineEmits,
  markRaw
} from "vue";

import { storeToRefs } from "pinia";

import {
  mdiPlus,
  mdiAccount,
  mdiAccountMultiple,
  mdiCalendar,
  mdiGenderMaleFemale,
  mdiPound,
  mdiIdCard,
  mdiDeskphone,
  mdiAlphaX,
  mdiCellphone,
  mdiTruckFastOutline
} from "@mdi/js";

const savingBuffer = {
  legalFirstName: useActiveSave(),
  legalLastName: useActiveSave(),
  npn: useActiveSave(),
  phoneWork: useActiveSave(),
  phoneWorkExtension: useActiveSave(),
  phoneMobile: useActiveSave(),
  ssn: useActiveSave(),
  birthdate: useActiveSave(),
  gender: useActiveSave(),
  securitiesLicensed: useActiveSave(),
  crdNumber: useActiveSave(),
  assignment: useActiveSave(),
  signingOfficer: useActiveSave(),
  title: useActiveSave(),
  annualization: useActiveSave(),
  hideCommission: useActiveSave(),
  shippingAddress: useActiveSave(),
  driversLicenseState: useActiveSave(),
  driversLicenseNumber: useActiveSave()
};

const props = defineProps({
  readonly: Boolean,
  isViewingSelf: Boolean
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const agent = useAgentSettingsStore();
const user = useUserStore();

const {
  phoneWork,
  phoneWorkExtension,
  phoneMobile,
  legalFirstName,
  legalLastName,
  npn,
  ssn,
  birthdate,
  gender,
  driversLicense,
  securitiesLicensed,
  businessAddress,
  crdNumber,
  shippingAddress
} = storeToRefs(agent);

const state = {
  legalFirstName,
  legalLastName,
  shippingAddress, // computed getter
  npn,
  phoneWork,
  phoneMobile,
  ssn,
  birthdate,
  gender,
  businessAddress,
  driversLicense,
  securitiesLicensed,
  crdNumber
};

const npnLookedUp = Boolean(agent.npn);
const validNpn = ref(Boolean(agent.npn));

const ssnAlreadySaved = Boolean(ssn.value);

const rules = {
  legalFirstName: { required },
  legalLastName: { required },
  shippingAddress: { required },
  npn: { required, validNpn: validNpn.value === true },
  phoneWork: {
    required,
    validLength: val => {
      if (!val) return false;
      const matches = val.match(/\d/g);
      if (!matches) return false;
      return matches.join("").length === 10;
    }
  },
  phoneMobile: {
    required,
    validLength: val => {
      if (!val) return false;
      const matches = val.match(/\d/g);
      if (!matches) return false;
      return matches.join("").length === 10;
    }
  },
  ssn: { required, validateSsn },
  birthdate: { required },
  gender: { required },
  driversLicense: {
    number: {
      required,
      valid: (v, parent) => validLicenseNumber(parent.state, v)
    },
    state: { required }
  },
  securitiesLicensed: { required },
  crdNumber: {
    required: (value, parent) =>
      !parent.securitiesLicensed.value || Boolean(value)
  },
  businessAddress: {
    street_address: required,
    city: required,
    state: required,
    zip: required
  }
};

const v$ = useVuelidate(rules, state, { $autoDirty: true, $scope: false });

const npnValidation = computed(() => {
  const model = v$.value.npn;
  return validationComputeV2(
    model,
    [{ key: "required", message: "Required" }],
    { key: "validNpn", message: "Must be a valid NPN" }
  );
});
const shippingAddressValidation = computed(() => {
  const model = v$.value.shippingAddress;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const ssnValidation = computed(() => {
  const model = v$.value.ssn;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "validateSsn", message: "Invalid SSN" }
  ]);
});
const birthdateValidation = computed(() => {
  const model = v$.value.birthdate;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const genderValidation = computed(() => {
  const model = v$.value.gender;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const legalFirstNameValidation = computed(() => {
  const model = v$.value.legalFirstName;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const legalLastNameValidation = computed(() => {
  const model = v$.value.legalLastName;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const driversLicenseNumberValidation = computed(() => {
  const model = v$.value.driversLicense.number;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "valid", message: "Must be a valid drivers license number" }
  ]);
});
const driversLicenseStateValidation = computed(() => {
  const model = v$.value.driversLicense.state;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const securitiesLicensedValidation = computed(() => {
  const model = v$.value.securitiesLicensed;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const crdNumberValidation = computed(() => {
  const model = v$.value.crdNumber;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const phoneMobileValidation = computed(() => {
  const model = v$.value.phoneMobile;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    {
      key: "validLength",
      message: "Phone Numbers must be 10 digits in length"
    }
  ]);
});
const phoneWorkValidation = computed(() => {
  const model = v$.value.phoneWork;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    {
      key: "validLength",
      message: "Phone Numbers must be 10 digits in length"
    }
  ]);
});

function saveLegalFirstName() {
  if (!legalFirstNameValidation.value.success) return;
  savingBuffer.legalFirstName.debounceUpdate(agent.updateLegalFirstName);
  validate();
}
function saveLegalLastName() {
  if (!legalLastNameValidation.value.success) return;
  savingBuffer.legalLastName.debounceUpdate(agent.updateLegalLastName);
  validate();
}

function saveCrdNumber() {
  if (!crdNumberValidation.value.success) return;
  savingBuffer.crdNumber.debounceUpdate(agent.updateCrdNumber);
  validate();
}

function saveNpn() {
  if (!npnValidation.value.success) return;
  savingBuffer.npn.debounceUpdate(agent.updateNpn);
  validate();
}
function savePhoneWork() {
  if (!phoneWorkValidation.value.success) return;
  savingBuffer.phoneWork.debounceUpdate(agent.updatePhoneWork);
  validate();
}
function savePhoneWorkExtension() {
  if (!phoneWorkValidation.value.success) return;
  savingBuffer.phoneWorkExtension.debounceUpdate(agent.updatePhoneWork);
  validate();
}
function savePhoneMobile() {
  if (!phoneMobileValidation.value.success) return;
  savingBuffer.phoneMobile.debounceUpdate(agent.updatePhoneMobile);
  validate();
}
function saveSsn() {
  if (ssnAlreadySaved) return;
  if (!ssnValidation.value.success) return;
  savingBuffer.ssn.debounceUpdate(agent.updateSsn);
  validate();
}
function saveBirthdate() {
  if (!birthdateValidation.value.success) return;
  savingBuffer.birthdate.debounceUpdate(agent.updateBirthdate);
  validate();
}
function saveGender() {
  if (!genderValidation.value.success) return;
  savingBuffer.gender.update(agent.updateGender);
  validate();
}
function saveDriversLicenseState() {
  if (!driversLicenseStateValidation.value.success) return;
  if (!driversLicenseNumberValidation.value.success) return;
  savingBuffer.driversLicenseState.debounceUpdate(agent.updateDriversLicense);
  validate();
}
function saveDriversLicenseNumber() {
  if (!driversLicenseStateValidation.value.success) return;
  if (!driversLicenseNumberValidation.value.success) return;
  savingBuffer.driversLicenseNumber.debounceUpdate(agent.updateDriversLicense);
  validate();
}
function saveShippingAddress(id) {
  savingBuffer.shippingAddress.update(() => agent.updateShippingAddress(id));
  validate();
}

function createAddress() {
  let previousAddressId = 0;
  let previousAddress;
  let previousAddressStr = "";
  if (agent.homeAddressOrder?.length) {
    const addressId = agent.homeAddressOrder.find(id => {
      const { street_address, city, state, zip, startDate } =
        agent.homeAddresses[id];
      return !street_address || !city || !state || !zip || !startDate;
    });
    if (addressId) {
      snackbar.showErrorSnackbar({
        message: "Please complete the address form before adding a new address"
      });
      return editAddress(addressId);
    }
    const prevId = agent.homeAddressOrder[0];
    const prev = agent.homeAddresses[prevId];
    if (!prev.end_date) {
      previousAddressId = agent.homeAddressOrder[0];
      previousAddress = agent.homeAddresses[previousAddressId];
      previousAddressStr = previousAddress.street_address;
    }
  }
  dialog
    .showDialog({
      component: markRaw(AddressItemDialog),
      scrollable: true,
      minStartDate: birthdate.value,
      previousAddressId,
      previousAddress: previousAddressStr,
      newAddress: new AddressFactory({ addressType: "home" }),
      createFunc: agent.createAddress,
      updateFunc: agent.updateAddress
    })
    .then(({ address, updatedPreviousAddress }) => {
      if (!address?.id) return;
      agent.addNewAddress(address);
      if (updatedPreviousAddress) {
        agent.addNewAddress({
          ...previousAddress,
          endDate: address.startDate
        });
      }
      validate();
    });
}
function editBusinessAddress() {
  dialog
    .showDialog({
      component: markRaw(AddressItemDialog),
      excludeDates: true,
      scrollable: true,
      minStartDate: birthdate.value,
      newAddress: new AddressFactory({
        ...businessAddress.value,
        addressType: "business"
      }),
      createFunc: agent.createAddress,
      updateFunc: agent.updateAddress
    })
    .then(({ address }) => {
      if (!address?.id) return;
      agent.storeBusinessAddress(address);
      validate();
    });
}
function editAddress(addressId) {
  dialog
    .showDialog({
      component: markRaw(AddressItemDialog),
      scrollable: true,
      minStartDate: birthdate.value,
      newAddress: new AddressFactory({
        ...agent.homeAddresses[addressId],
        addressType: "home"
      }),
      createFunc: agent.createAddress,
      updateFunc: agent.updateAddress
    })
    .then(({ address }) => {
      if (!address?.id) return;
      agent.addNewAddress(address);
      validate();
    });
}
function removeAddress(addressId) {
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Delete Address Record",
    subtitle:
      "Are you sure that you want to delete this address record? To undo, you'll need to create another address record",
    func: async () => {
      await agent.deleteAddress(addressId);
      validate();
    }
  });
}
function addressItemDisplay(value) {
  const prefix = value.addressType === "home" ? "Home" : "Business";
  return `${prefix}: ${formatAddress(value)}`;
}

async function validate() {
  const isValid = await v$.value.$validate();
  const valid = Boolean(isValid && !agent.missingFiveYearsOfAddresses);
  agent.setValidPage({ page: "account-details", valid });
}

const emit = defineEmits(["mounted"]);
onMounted(() => emit("mounted"));
validate();
</script>
