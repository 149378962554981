<template>
  <v-card>
    <v-card-text>
      <v-data-table
        must-sort
        data-testid="backnine-statements-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :server-items-length="table.meta.value.total"
        :loading="table.loading.value"
        :options="table.options.value"
        :footer-props="footerProps"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0 mb-3">
            <h3 class="text-h4">Statements</h3>
            <v-spacer />
            <v-btn
              data-testid="statement-create"
              icon
              :to="{ name: 'StatementCreate' }"
            >
              <v-icon>{{ mdiPlus }}</v-icon>
            </v-btn>
            <v-btn
              icon
              :loading="table.loading.value"
              data-testid="backnine-statements-table-refresh"
              @click="getData"
            >
              <v-icon>{{ mdiRefresh }} </v-icon>
            </v-btn>
          </v-row>
          <generic-table-filter-header
            :value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>
        <template #[`item.id`]="{ item }">
          <router-link :to="{ name: 'StatementView', params: { id: item.id } }">
            {{ item.id }}
          </router-link>
        </template>
        <template #[`item.statementDate`]="{ item }">
          <timestamp-formatter :value="item.statementDate" parser="sole-day" />
        </template>
        <template #[`item.premium`]="{ item }">
          <currency-formatter :value="item.premium" />
        </template>
        <template #[`item.premiumSum`]="{ item }">
          <currency-formatter :value="item.premium_sum" />
        </template>
        <template #[`item.amount`]="{ item }">
          <span :class="{ 'error--text': item.amount < 0 }">
            <currency-formatter :value="item.amount" />
          </span>
        </template>
        <template #[`item.commissionSum`]="{ item }">
          <currency-formatter :value="item.commissionSum" />
        </template>
        <template #[`item.createdAt`]="{ item }">
          <timestamp-formatter :value="item.createdAt" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import { parseErrorMessage } from "@/util/helpers";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";
import { getBackNineStatementData } from "@/api/statements.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { computed } from "vue";
import { mdiPlus, mdiRefresh } from "@mdi/js";

useHead({ title: "Statements" });

const snackbar = useSnackbarStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Statement",
      value: "id",
      map: "id",
      sortFilterMap: "statements.id",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Payor",
      value: "payorName",
      map: "payorName",
      sortFilterMap: "payors.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Statement Type",
      value: "statementType",
      map: "statementType",
      sortFilterMap: "statements.statement_type",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Premium",
      value: "premium",
      map: "premium",
      sortFilterMap: "statements.premium",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Premium Sum",
      value: "premiumSum",
      map: "premiumSum",
      ...TableHeader.IS_STRING_FILTER_TYPE
    }),
    new TableHeader({
      text: "Commission",
      value: "amount",
      map: "amount",
      sortFilterMap: "statements.commission",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Commission Sum",
      value: "commissionSum",
      map: "commissionSum",
      ...TableHeader.IS_STRING_FILTER_TYPE
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      ...TableHeader.IS_STRING_FILTER_TYPE
    }),
    new TableHeader({
      text: "Statement Date",
      value: "statementDate",
      map: "statementDate",
      sortFilterMap: "statements.statement_date",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Created",
      value: "createdAt",
      map: "createdAt",
      sortFilterMap: "statements.created_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    })
  ],
  getData: getBackNineStatementData,
  options: new TableOptions(["id"], [true])
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

getData();
</script>
