<template>
  <v-card flat tile height="100%" width="100%" color="section" class="pa-0">
    <v-card-title class="pt-2 pb-2">
      <v-row dense align="center">
        <v-col cols="12">
          <v-row class="ma-0">
            <slot name="copy" />
            <v-autocomplete
              :prepend-inner-icon="mdiHelpNetwork"
              item-value="id"
              item-text="verboseDisplayText"
              label="Current Field"
              solo
              flat
              dense
              hide-details
              :value="currentFieldId"
              :items="positionallySortedFields"
              @input="changeActiveField"
            />
            <template v-if="formStatus === 'Incomplete' && !copiedForm">
              <form-mapping-form-aql-create-menu
                :form-id="formId"
                :loading="creatingField"
                @add-field="addField"
                @duplicate-field="duplicateField"
              />
              <v-btn @click="deleteCurrentField" icon color="error">
                <v-icon> {{ mdiDelete }} </v-icon>
              </v-btn>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
    <!-- only change rectangle if on pdf -->
    <v-card-text v-if="copiedForm || activeField.id">
      <form-mapping-form-aql
        v-if="activeField"
        ref="applicationQuestionLink"
        :key="currentFieldId"
        :form-id="formId"
        :readonly="copiedForm || readonly"
        :is-copying-form="isCopyingForm"
        :sample-id="sampleId"
      />
      <form-mapping-form-aql-relations
        v-if="isVerbatimQuestion"
        :readonly="readonly"
        :form-id="formId"
        :key="currentFieldId + 'relations'"
        @view="changeActiveField"
      >
        <template #add-child>
          <form-mapping-form-aql-create-menu
            :form-id="formId"
            :loading="creatingChild"
            no-duplication
            @add-field="addChild"
          />
        </template>
      </form-mapping-form-aql-relations>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiHelpNetwork, mdiDelete } from "@mdi/js";
import FormMappingFormAql from "@/components/form-mapping/FormMappingFormAql.vue";
import FormMappingFormAqlRelations from "@/components/form-mapping/FormMappingFormAqlRelations.vue";
import FormMappingFormAqlCreateMenu from "@/components/form-mapping/FormMappingFormAqlCreateMenu.vue";

import { parseErrorMessage } from "@/util/helpers";
import { useFormMappingStore } from "@/stores/form-mapping";
import { storeToRefs, mapActions } from "pinia";
import {
  ApplicationQuestionFactory,
  NOT_ON_PDF_OPTION_TYPE
} from "@/factories/FormMappingFactory";
import { cloneDeep } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  components: {
    FormMappingFormAql,
    FormMappingFormAqlRelations,
    FormMappingFormAqlCreateMenu
  },
  props: {
    formId: [Number, String],
    vertical: Boolean,
    isCopyingForm: Boolean,
    copiedForm: Boolean,
    readonly: Boolean,
    sampleId: [Number, String]
  },
  setup({ formId }) {
    const formStore = useFormMappingStore(formId);
    const {
      positionallySortedFields,
      status: formStatus,
      fields,
      activeField,
      currentFieldId,
      dupeOffsetY,
      dupeOffsetX
    } = storeToRefs(formStore);
    return {
      positionallySortedFields,
      formStatus,
      fields,
      activeField,
      currentFieldId,
      dupeOffsetY,
      dupeOffsetX,
      copyToActiveField: formStore.copyToActiveField,
      changeActiveField: formStore.changeActiveField,
      addPdfField: formStore.addPdfField,
      deleteField: formStore.deleteApplicationQuestionLink,
      insertChild: formStore.insertChild
    };
  },
  data: () => ({
    creatingField: false,
    creatingParent: false,
    creatingChild: false,
    mdiHelpNetwork,
    mdiDelete
  }),
  computed: {
    isVerbatimQuestion() {
      return (
        this.activeField?.applicationQuestion?.name === "Verbatim Question"
      );
    },
    fieldNotOnPdf() {
      return (
        this.activeField.pdfFieldType === NOT_ON_PDF_OPTION_TYPE &&
        !this.activeField.coordinates.length
      );
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    async addField(fieldType) {
      this.creatingField = true;
      try {
        const res = await this.addPdfField(fieldType);
        this.changeActiveField(res);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.creatingField = false;
      }
    },
    async duplicateField(direction) {
      this.creatingField = true;
      try {
        const currentActiveField = cloneDeep(this.activeField);
        const res = await this.addPdfField(this.activeField.pdfFieldType);
        this.changeActiveField(res);
        this.copyToActiveField(currentActiveField, direction);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.creatingField = false;
      }
    },
    async deleteCurrentField() {
      this.showDialog({
        component: "ConfirmationDialog",
        title: "Delete Question",
        subtitle: "Please confirm that you'd like to delete this question",
        func: () =>
          this.deleteField(this.currentFieldId).catch(e =>
            this.showErrorSnackbar({ message: parseErrorMessage(e) })
          )
      });
    },

    async addChild(fieldType) {
      this.creatingChild = true;
      try {
        const fieldId = await this.insertChild(this.activeField.id, fieldType);
        await this.changeActiveField(fieldId);
        this.activeField.applicationQuestion = new ApplicationQuestionFactory({
          id: 87,
          name: "Verbatim Question",
          required_of: null,
          verbatim_question: true
        });
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.creatingChild = false;
      }
    }
  }
};
</script>
