<template>
  <v-card :id="id" :height="height" flat :color="color" :tile="!rounded">
    <v-card-title data-testid="chat-header">
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-row class="ma-0" align="center">
            <slot name="title" />
            {{ title }}
            <v-spacer />
            <v-btn
              icon
              color="primary"
              @click="displayingDocuments = !displayingDocuments"
              v-if="documents.length"
            >
              <v-icon v-if="displayingDocuments"> {{ mdiFileCancel }} </v-icon>
              <v-tooltip v-else bottom>
                <template #activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs">
                    {{ mdiFileDocument }}
                  </v-icon>
                </template>
                <span>Show All Documents</span>
              </v-tooltip>
            </v-btn>
            <v-menu offset-y v-if="room">
              <template #activator="{ on, attrs }">
                <v-btn icon v-on="on" v-bind="attrs">
                  <v-icon>{{ mdiAccountMultiple }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(person, index) in room"
                  :key="index"
                  link
                  @click="displayContactDetails(person)"
                >
                  {{ person.name }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
        <v-col cols="12" class="pa-0" v-if="displayingDocuments">
          <v-row align="center" class="ma-0" justify="end">
            <a
              v-for="document in documents"
              :key="document.id"
              style="font-size: 12px"
              v-bind="downloadDocument(document.uid)"
            >
              {{ document.name }}
              <span v-if="document.fileSize" class="grey--text">
                ({{ document.fileSize }})
              </span>
              <v-icon color="primary" small> {{ mdiDownload }} </v-icon>
            </a>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-row class="ma-0">
      <v-col class="pa-0">
        <chat-body
          v-if="hasFocused"
          ref="chatBody"
          :messages="messages"
          @remove-message="removeMessage"
        />
        <chat-footer
          class="chat-footer"
          ref="chatFooter"
          :no-documents="noDocuments"
          :phone="phone"
          :email="email"
          :template="template"
          :checkboxes="checkboxes"
          :additional-buttons="additionalButtons"
          :create-func="createFunc"
          @send-message="sendMessage"
          @select-template="$emit('select-template')"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ChatFooter from "@/components/shared/chat/ChatFooter.vue";
import ChatBody from "@/components/shared/chat/ChatBody.vue";
import { downloadFileAsLink } from "@/util/helpers";
import { mapActions } from "pinia";
import { initSocketForChat } from "@/util/socket-helper-service";
import { getDocumentUrl } from "@/api/documents.service";
import { useDialogStore } from "@/stores/dialog";
import {
  mdiDownload,
  mdiFileCancel,
  mdiFileDocument,
  mdiAccountMultiple
} from "@mdi/js";
export default {
  name: "ChatViewer",
  components: {
    ChatFooter,
    ChatBody
  },
  props: {
    rounded: Boolean,
    height: String,
    requirements: Object,
    title: String,
    messages: Array,
    pusherId: String,
    includeTemplates: Boolean,
    room: Array,
    type: String,
    phone: Boolean,
    email: Boolean,
    template: Boolean,
    noDocuments: Boolean,
    //V2 Footer
    checkboxes: Array,
    additionalButtons: Array,
    createFunc: Function,
    color: {
      default: "section",
      required: false,
      type: String
    },
    id: {
      default: "chat-container",
      required: false,
      type: [String, Number]
    }
  },
  data() {
    return {
      mdiDownload,
      mdiFileCancel,
      mdiFileDocument,
      mdiAccountMultiple,
      displayingDocuments: false,
      downloadBuffer: {},
      pusher: null,
      hasFocused: false
    };
  },
  mounted() {
    this.subscribe();
    const el = document.getElementById(this.id);
    const handleResize = () => {
      this.handleChatScroll = Boolean(el.clientHeight);
      if (this.handleChatScroll && !this.hasFocused) {
        this.hasFocused = true;
      }
    };
    const observer = new ResizeObserver(handleResize);
    observer.observe(el);
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  computed: {
    documents() {
      let documents = [];
      this.messages.forEach(message => {
        if (!message?.documents) return;
        documents = [...documents, ...message.documents];
      });
      return documents;
    }
  },

  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    removeMessage(message) {
      this.$emit("remove-message", message);
    },
    unsubscribe() {
      if (this.pusher?.disconnect) this.pusher.disconnect();
    },
    subscribe() {
      this.pusher = initSocketForChat(this.pusherId, {
        newNoteHandler: note => this.$emit("new-note", note)
      });
    },
    sendMessage(value) {
      value.type = this.type || null;
      this.$emit("send-message", value);
    },
    displayContactDetails(contact) {
      this.showDialog({
        component: "ContactDetailsDialog",
        contact
      });
    },
    downloadDocument(uid) {
      return downloadFileAsLink(
        getDocumentUrl(uid),
        this.$vuetify.breakpoint.mdAndDown
      );
    }
  }
};
</script>
