<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card class="mb-0" rounded flat>
            <v-card-title>Change Agent and Assign To</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-select
                    data-testid="change-agent"
                    :prepend-inner-icon="mdiAccountTie"
                    outlined
                    dense
                    return-object
                    item-text="name"
                    label="Agent"
                    hint="Click the dropdown to change the agent on this Quote. Add the agent as an access if they do not appear in the list."
                    persistent-hint
                    :value="agent"
                    :success="Boolean(agent?.id)"
                    :disabled="props.readonly"
                    :items="agentOptions"
                    @input="handleAgentChange"
                  >
                    <template #append-outer>
                      <active-save-indicator :controller="savingBuffer.agent" />
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    data-testid="change-assignable"
                    :prepend-inner-icon="mdiAccountTie"
                    outlined
                    dense
                    return-object
                    item-text="name"
                    label="Assign To"
                    hint="Click the dropdown to change who this Quote is assigned to. Add the desired assignable as access if they do not appear in the list."
                    persistent-hint
                    :value="assignable"
                    :success="Boolean(assignable?.id)"
                    :disabled="props.readonly"
                    :items="agentOptions"
                    @input="handleAssignableChange"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.assignable"
                      />
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat>
            <v-card-title>Access Table </v-card-title>
            <access
              v-model="opportunities"
              data-testid="access-table"
              type="quote"
              button-color="accent"
              button-text="Add Access"
              :checkboxes="checkboxes"
              :update-func="updateAccess"
              :delete-func="removeAccess"
              :add-func="addAccess"
              :name-link="nameLinkGenerator"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import Access from "@/components/shared/Access.vue";
import {
  addQuoteAccess,
  updateQuoteAccess,
  removeQuoteAccess,
  updateQuote
} from "@/api/quotes.service";
import { useQuoteViewStore } from "@/stores/quote-view";

import { mdiAccountTie } from "@mdi/js";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed, defineProps } from "vue";
import { storeToRefs } from "pinia";

const props = defineProps({ readonly: Boolean });

const checkboxes = [
  {
    text: "Email",
    value: "email"
  }
];

const quoteStore = useQuoteViewStore();
const { agent, id, assignable, opportunities } = storeToRefs(quoteStore);

const savingBuffer = {
  agent: useActiveSave(),
  assignable: useActiveSave()
};

const agentOptions = computed(() => {
  return opportunities.value.filter(access =>
    access.ownerType?.toLowerCase()?.includes("agent")
  );
});

function handleAgentChange({ id: newAgentId }) {
  const prevAgent = agent.value?.id;
  setActiveAgent(newAgentId);

  savingBuffer.agent.update(() =>
    updateQuoteAccess(id.value, newAgentId, { agent: true }).catch(e => {
      if (prevAgent) setActiveAgent(prevAgent);
      throw e;
    })
  );
}
function handleAssignableChange({ ownerType, name, ownerId }) {
  assignable.value.id = ownerId;
  assignable.value.name = name;
  assignable.value.type = ownerType;

  savingBuffer.assignable.update(() =>
    updateQuote(id.value, {
      quote: {
        assignable_id: ownerId,
        assignable_type: ownerType
      }
    })
  );
}

function setActiveAgent(currId) {
  if (agent.value?.id) {
    const prevIndex = opportunities.value.findIndex(
      ({ id: i }) => agent.value.id === i
    );
    opportunities.value[prevIndex].agent = false;
  }

  const currIndex = opportunities.value.findIndex(({ id: i }) => currId === i);
  opportunities.value[currIndex].agent = true;

  quoteStore.updateAgent(opportunities.value[currIndex]);
}
async function addAccess(access) {
  if (!access) return;
  return addQuoteAccess(id.value, access);
}
async function updateAccess(access, attribute) {
  return await updateQuoteAccess(id.value, access.additional.id, {
    [attribute]: access[attribute]
  });
}
async function removeAccess(access) {
  return await removeQuoteAccess(id.value, access.additional.id);
}
function nameLinkGenerator(access) {
  const typeArr = [
    access.additional.ownable.type?.model,
    access.additional.ownable.type
  ];
  if (typeArr.includes("Agent")) {
    return {
      name: "AgentView",
      params: {
        id: access.additional.ownable.id
      }
    };
  } else if (typeArr.includes("Agency")) {
    return {
      name: "AgencyView",
      params: {
        id: access.additional.ownable.id
      }
    };
  }
  return null;
}
</script>
