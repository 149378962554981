<template>
  <v-card>
    <v-row class="ma-0" align="center">
      <v-card-title>Production by Carrier</v-card-title>
      <v-spacer />
      <v-text-field
        data-lpignore="true"
        label="Search"
        data-testid="search-production-by-carrier-table"
        :prepend-inner-icon="mdiMagnify"
        dense
        outlined
        v-model="search"
        hide-details
        class="mx-3"
      />
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            @click="getData"
            v-on="on"
            v-bind="attrs"
            :disabled="loading"
          >
            <v-icon> {{ mdiRefresh }} </v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
    </v-row>
    <v-data-table
      must-sort
      data-testid="production-by-carrier-table"
      :headers="table.tableHeaders.value"
      :items="table.mappedItems.value"
      :loading="table.loading.value"
      :search.sync="search"
    />
  </v-card>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import {
  currencyFormat,
  numberFormat,
  parseErrorMessage
} from "@/util/helpers";
import { getProductionByCarrierReport } from "@/api/reports.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTable } from "@/composables/table.composable";
import { ref, defineProps } from "vue";
import { mdiMagnify, mdiRefresh } from "@mdi/js";

const props = defineProps({
  all: Boolean
});
const snackbar = useSnackbarStore();
const loading = ref(false);
const search = ref("");

const formattedNumStringToNum = v => Number(`${v}`.replace(/(^\$|,)/g, ""));

const currentYear = new Date().getFullYear();
const previousYear = currentYear - 1;
const headers = [
  new TableHeader({
    text: "Carrier",
    value: "carrier",
    map: "carrier",
    ...TableHeader.IS_SORTABLE,
    ...TableHeader.IS_FILTERABLE
  }),
  new TableHeader({
    text: `${currentYear} Active Cases`,
    value: "currentYearCaseCount",
    map: v => numberFormat(v.currNumberOfCases),
    sort: (a, b) => formattedNumStringToNum(a) - formattedNumStringToNum(b),
    ...TableHeader.IS_SORTABLE,
    ...TableHeader.IS_FILTERABLE
  }),
  new TableHeader({
    text: `${currentYear} Production Credit`,
    value: "currentYearProductionCredit",
    map: v => currencyFormat(v.currProductionCredit, 0),
    sort: (a, b) => formattedNumStringToNum(a) - formattedNumStringToNum(b),
    ...TableHeader.IS_SORTABLE,
    ...TableHeader.IS_FILTERABLE
  }),
  new TableHeader({
    text: `${previousYear} Active Cases`,
    value: "previousYearCaseCount",
    map: v => numberFormat(v.prevNumberOfCases),
    sort: (a, b) => formattedNumStringToNum(a) - formattedNumStringToNum(b),
    ...TableHeader.IS_SORTABLE,
    ...TableHeader.IS_FILTERABLE
  }),
  new TableHeader({
    text: `${previousYear} Production Credit`,
    value: "previousYearProductionCredit",
    map: v => currencyFormat(v.prevProductionCredit, 0),
    sort: (a, b) => formattedNumStringToNum(a) - formattedNumStringToNum(b),
    ...TableHeader.IS_SORTABLE,
    ...TableHeader.IS_FILTERABLE
  })
];

const table = useTable({
  headers
});

async function getData() {
  table.loading.value = true;
  try {
    const params = new URLSearchParams();
    if (props.all) params.append("view", "all");
    table.items.value = await getProductionByCarrierReport(params);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    table.loading.value = false;
  }
}

getData();
</script>
