<template>
  <v-row class="ma-0">
    <v-col cols="12" v-if="!editing">
      <h2>Create a Personnel</h2>
    </v-col>
    <v-col cols="12">
      <v-card rounded class="my-2">
        <v-card-title>Personnel Information</v-card-title>
        <v-divider />
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="personnel.firstName"
                label="First Name"
                data-lpignore="true"
                :prepend-inner-icon="mdiAccount"
                outlined
                dense
                :disabled="isGroupOne"
                :success="firstNameValidation.success"
                :error-messages="firstNameValidation.errorMessages"
                @input="updateAttribute('firstName')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['firstName']"
                  />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="personnel.lastName"
                label="Last Name"
                data-lpignore="true"
                :prepend-inner-icon="mdiAccountMultiple"
                outlined
                dense
                :disabled="isGroupOne"
                :success="lastNameValidation.success"
                :error-messages="lastNameValidation.errorMessages"
                @input="updateAttribute('lastName')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['lastName']"
                  />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                data-lpignore="true"
                :disabled="isGroupOne"
                :prepend-inner-icon="mdiCardAccountDetails"
                outlined
                dense
                label="Title"
                v-model="personnel.title"
                :success="Boolean(personnel.title)"
                @input="updateAttribute('title')"
              >
                <template #append-outer>
                  <active-save-indicator :controller="savingBuffer['title']" />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-row dense>
                <v-col cols="12" md="8">
                  <phone-input
                    data-lpignore="true"
                    :disabled="isGroupOne"
                    :prepend-inner-icon="mdiDeskphone"
                    outlined
                    dense
                    label="Work Phone"
                    v-model="personnel.phoneWork"
                    :success="Boolean(personnel.phoneWork)"
                    @input="updateAttribute('phoneWork')"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer['phoneWork']"
                      />
                    </template>
                  </phone-input>
                </v-col>
                <v-col cols="12" md="4">
                  <integer-input
                    v-model="personnel.phoneWorkExtension"
                    data-lpignore="true"
                    :prepend-inner-icon="mdiDeskphone"
                    outlined
                    dense
                    label="Work Phone Extension"
                    mask="######"
                    :disabled="isGroupOne"
                    :success="Boolean(personnel.phoneWorkExtension)"
                    @input="updateAttribute('phoneWorkExtension')"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer['phoneWorkExtension']"
                      />
                    </template>
                  </integer-input>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="personnel.email"
                label="Email"
                data-lpignore="true"
                :prepend-inner-icon="mdiEmail"
                outlined
                dense
                :disabled="isGroupOne"
                :success="emailValidation.success"
                :error-messages="emailValidation.errorMessages"
                @input="updateAttribute('email')"
              >
                <template #append-outer>
                  <active-save-indicator :controller="savingBuffer['email']" />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <phone-input
                v-model="personnel.phoneMobile"
                data-lpignore="true"
                :prepend-inner-icon="mdiCellphone"
                outlined
                dense
                label="Mobile Phone"
                :disabled="isGroupOne"
                :success="Boolean(personnel.phoneMobile)"
                @input="updateAttribute('phoneMobile')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['phoneMobile']"
                  />
                </template>
              </phone-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6">
              <carrier-search
                v-model="personnel.carrier"
                :disabled="isGroupOne"
                :success="carrierValidation.success"
                :error-messages="carrierValidation.errorMessages"
                @change="updateAttribute('carrier')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['carrier']"
                  />
                </template>
              </carrier-search>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                :disabled="isGroupOne"
                :prepend-inner-icon="mdiOfficeBuilding"
                :items="departments"
                outlined
                dense
                label="Department"
                placeholder="Select Department"
                v-model="personnel.department"
                :success="departmentValidation.success"
                :error-messages="departmentValidation.errorMessages"
                @change="updateAttribute('department')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer['department']"
                  />
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card rounded class="my-2">
        <v-card-title>Home Address</v-card-title>
        <v-divider />
        <v-card-text>
          <basic-address-input
            key="personnel-home-address"
            type="home"
            autofill-label="Home Address"
            autofill-placeholder="Home Address"
            :disabled="isGroupOne"
            :street-address-model.sync="personnel.homeAddress.street_address"
            :city-model.sync="personnel.homeAddress.city"
            :state-model.sync="personnel.homeAddress.state"
            :zip-model.sync="personnel.homeAddress.zip"
            :street-address-validation="homeStreetValidation"
            :city-validation="homeCityValidation"
            :state-validation="homeStateValidation"
            :zip-validation="homeZipValidation"
            @update:street-model="homeAttrUpdate('street_address')"
            @update:city-model="homeAttrUpdate('city')"
            @update:state-model="homeAttrUpdate('state')"
            @update:zip-model="homeAttrUpdate('zip')"
            @autofill="handleHomeAutofill"
          >
            <template #append-outer-street-address>
              <active-save-indicator
                :controller="savingBuffer['home-street_address']"
              />
            </template>
            <template #append-outer-city>
              <active-save-indicator :controller="savingBuffer['home-city']" />
            </template>
            <template #append-outer-state>
              <active-save-indicator :controller="savingBuffer['home-state']" />
            </template>
            <template #append-outer-zip>
              <active-save-indicator :controller="savingBuffer['home-zip']" />
            </template>
          </basic-address-input>
        </v-card-text>
      </v-card>
      <v-card rounded class="my-2">
        <v-card-title>Business Address</v-card-title>
        <v-divider />
        <v-card-text>
          <basic-address-input
            key="personnel-business-address"
            type="home"
            autofill-label="Business Address"
            autofill-placeholder="Business Address"
            :disabled="isGroupOne"
            :street-address-model.sync="
              personnel.businessAddress.street_address
            "
            :city-model.sync="personnel.businessAddress.city"
            :state-model.sync="personnel.businessAddress.state"
            :zip-model.sync="personnel.businessAddress.zip"
            :street-address-validation="businessStreetValidation"
            :city-validation="businessCityValidation"
            :state-validation="businessStateValidation"
            :zip-validation="businessZipValidation"
            @update:street-model="businessAttrUpdate('street_address')"
            @update:city-model="businessAttrUpdate('city')"
            @update:state-model="businessAttrUpdate('state')"
            @update:zip-model="businessAttrUpdate('zip')"
            @autofill="handleBusinessAutofill"
          >
            <template #append-outer-street-address>
              <active-save-indicator
                :controller="savingBuffer['business-street_address']"
              />
            </template>
            <template #append-outer-city>
              <active-save-indicator
                :controller="savingBuffer['business-city']"
              />
            </template>
            <template #append-outer-state>
              <active-save-indicator
                :controller="savingBuffer['business-state']"
              />
            </template>
            <template #append-outer-zip>
              <active-save-indicator
                :controller="savingBuffer['business-zip']"
              />
            </template>
          </basic-address-input>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" v-if="!editing">
      <v-btn
        color="primary"
        :loading="loading"
        class="text-none"
        @click="create"
      >
        <v-icon class="mr-1">{{ mdiPlus }}</v-icon
        >Create Personnel
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import PhoneInput from "@/components/shared/PhoneInput.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";

import PersonnelFactory, { DEPARTMENTS } from "@/factories/PersonnelFactory";

import { required } from "vuelidate/lib/validators";
import { parseErrorMessage, someTextValidator } from "@/util/helpers";
import { createPersonnel, updatePersonnel } from "@/api/personnel.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { mapActions, mapState } from "pinia";
import { useHead } from "@unhead/vue";

import {
  mdiAccount,
  mdiAccountMultiple,
  mdiCardAccountDetails,
  mdiDeskphone,
  mdiEmail,
  mdiCellphone,
  mdiOfficeBuilding,
  mdiPlus
} from "@mdi/js";

export default {
  setup() {
    useHead({ title: "Create Personnel" });
  },
  mixins: [ActiveSaveMixin],
  components: {
    CarrierSearch,
    BasicAddressInput,
    ActiveSaveIndicator,
    PhoneInput,
    IntegerInput
  },
  props: {
    parentPersonnel: Object,
    editing: Boolean
  },
  data() {
    const personnel = this.parentPersonnel || new PersonnelFactory();
    const savingBuffer = this.generateSavingBuffer({
      items: [
        "firstName",
        "lastName",
        "email",
        "title",
        "carrier",
        "department",
        "phoneWork",
        "phoneWorkExtension",
        "phoneMobile",
        "homeAddress",
        "businessAddress"
      ],
      addressItems: ["business", "home"]
    });
    return {
      loading: false,
      departments: DEPARTMENTS,
      personnel,
      savingBuffer,
      mdiAccount,
      mdiAccountMultiple,
      mdiCardAccountDetails,
      mdiDeskphone,
      mdiEmail,
      mdiCellphone,
      mdiOfficeBuilding,
      mdiPlus
    };
  },
  computed: {
    ...mapState(useUserStore, ["isGroupOne"]),
    firstNameValidation() {
      return this.basicValidatorGen(this.$v.personnel.firstName);
    },
    lastNameValidation() {
      return this.basicValidatorGen(this.$v.personnel.lastName);
    },
    emailValidation() {
      return this.basicValidatorGen(this.$v.personnel.email);
    },
    carrierValidation() {
      return this.basicValidatorGen(this.$v.personnel.carrier.id);
    },
    departmentValidation() {
      return this.basicValidatorGen(this.$v.personnel.department);
    },
    homeStreetValidation() {
      return this.basicValidatorGen(
        this.$v.personnel.homeAddress.street_address
      );
    },
    homeStateValidation() {
      return this.basicValidatorGen(this.$v.personnel.homeAddress.state);
    },
    homeCityValidation() {
      return this.basicValidatorGen(this.$v.personnel.homeAddress.city);
    },
    homeZipValidation() {
      return this.zipValidatorGen(this.$v.personnel.homeAddress.zip);
    },
    businessStreetValidation() {
      return this.basicValidatorGen(
        this.$v.personnel.businessAddress.street_address
      );
    },
    businessStateValidation() {
      return this.basicValidatorGen(this.$v.personnel.businessAddress.state);
    },
    businessCityValidation() {
      return this.basicValidatorGen(this.$v.personnel.businessAddress.city);
    },
    businessZipValidation() {
      return this.zipValidatorGen(this.$v.personnel.businessAddress.zip);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    basicValidatorGen(validator) {
      let success = !validator.$invalid;
      if (!validator.$model) success = null;
      const errorMessages = [];
      if (!validator.$dirty) return { success, errorMessages };
      if (!validator.required) {
        errorMessages.push("Required");
      }
      return { success, errorMessages };
    },
    zipValidatorGen(validator) {
      let success = !validator.$invalid;
      if (!validator.$model) success = null;
      const errorMessages = [];
      if (!validator.$dirty) return { success, errorMessages };
      if (!validator.validLength) errorMessages.push("Must be 5 numbers long");
      return { success, errorMessages };
    },
    create() {
      if (this.editing || this.isGroupOne) {
        return;
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;
      createPersonnel({ personnel: this.personnel.toCreateRequest() })
        .then(response => {
          this.$router.push({
            name: "PersonnelView",
            params: {
              id: response.data.personnel.id
            }
          });
        })
        .catch(e => {
          this.showErrorSnackbar({
            message: parseErrorMessage(e),
            timeout: -1
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    homeAttrUpdate(attribute) {
      return this.updateAddressAttribute("home", attribute);
    },
    businessAttrUpdate(attribute) {
      return this.updateAddressAttribute("business", attribute);
    },
    updateAddressAttribute(type, attribute) {
      const bufferKey = `${type}-${attribute}`;
      if (this.savingBuffer[bufferKey].timer)
        clearTimeout(this.savingBuffer[bufferKey].timer);
      this.savingBuffer[bufferKey].timer = setTimeout(() => {
        if (!this.editing || this.isGroupOne) {
          return;
        }
        this.updateAttributeWrapper(
          () =>
            updatePersonnel(this.$route.params.id, {
              personnel: {
                addresses_attributes: [
                  this.personnel.getAttributeRequestValue([type])
                ]
              }
            }),
          this.savingBuffer[bufferKey]
        );
      }, 300);
    },
    updateAttribute(attribute) {
      if (this.savingBuffer[attribute].timer)
        clearTimeout(this.savingBuffer[attribute].timer);
      this.savingBuffer[attribute].timer = setTimeout(() => {
        if (!this.editing || this.isGroupOne) {
          return;
        }

        const updatePersonnelFunc = () =>
          updatePersonnel(this.$route.params.id, {
            personnel: this.personnel.getAttributeRequestValue([attribute])
          });

        this.updateAttributeWrapper(
          updatePersonnelFunc,
          this.savingBuffer[attribute]
        );
      }, 300);
    },
    handleHomeAutofill({ street_address, city, state, zip }) {
      this.personnel.homeAddress.street_address = street_address;
      this.personnel.homeAddress.city = city;
      this.personnel.homeAddress.state = state;
      this.personnel.homeAddress.zip = zip;
      this.homeAttrUpdate("street_address");
    },
    handleBusinessAutofill({ street_address, city, state, zip }) {
      this.personnel.businessAddress.street_address = street_address;
      this.personnel.businessAddress.city = city;
      this.personnel.businessAddress.state = state;
      this.personnel.businessAddress.zip = zip;
      this.businessAttrUpdate("street_address");
    }
  },
  validations: {
    personnel: {
      firstName: { required },
      lastName: { required },
      email: { required },
      carrier: { id: { required } },
      department: { required },
      homeAddress: {
        street_address: { required: v => !v || someTextValidator(true, v, 1) },
        state: { required: v => !v || someTextValidator(true, v, 1) },
        city: { required: v => !v || someTextValidator(true, v, 1) },
        zip: { validLength: v => !v || `${v}`.length === 5 }
      },
      businessAddress: {
        street_address: { required: v => !v || someTextValidator(true, v, 1) },
        state: { required: v => !v || someTextValidator(true, v, 1) },
        city: { required: v => !v || someTextValidator(true, v, 1) },
        zip: { validLength: v => !v || `${v}`.length === 5 }
      }
    }
  }
};
</script>
