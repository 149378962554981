<template>
  <v-card>
    <close-button @click="closeDialog" />
    <v-sheet
      color="secondary"
      rounded
      style="
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      "
    >
      <v-card-title>
        {{ editing ? "Edit" : "Create" }} Commission
      </v-card-title>
    </v-sheet>
    <v-card-text>
      <v-row class="pa-3">
        <template v-if="!paid">
          <v-col cols="12" md="6">
            <advisor-search
              v-model="commission.payable"
              label="Payable"
              placeholder="Search Payables"
              :success="!$v.commission.payable.$invalid"
              :error-messages="
                $v.commission.payable.$dirty && !$v.commission.payable.required
                  ? ['Required']
                  : []
              "
            />
          </v-col>
          <v-col cols="12" md="6">
            <advisor-search
              v-model="commission.assignedPayable"
              label="Assigned Payable"
              placeholder="Search Payables"
              :success="!$v.commission.assignedPayable.$invalid"
              :error-messages="
                $v.commission.assignedPayable.$dirty &&
                !$v.commission.assignedPayable.required
                  ? ['Required']
                  : []
              "
            />
          </v-col>
          <v-col cols="12" md="6">
            <payor-search
              v-model="commission.payor"
              label="Payor"
              placeholder="Search Payors"
              :success="!$v.commission.payor.$invalid"
              :error-messages="
                $v.commission.payor.$dirty && !$v.commission.payor.required
                  ? ['Required']
                  : []
              "
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              :prepend-inner-icon="mdiOfficeBuilding"
              label="Appointment Case"
              outlined
              dense
              item-text="advisor.name"
              item-value="id"
              v-model="commission.appointmentCaseId"
              :items="appointmentCases"
              :success="!$v.commission.appointmentCaseId.$invalid"
              :error-messages="
                $v.commission.appointmentCaseId.$dirty &&
                !$v.commission.appointmentCaseId.required
                  ? ['Required']
                  : []
              "
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="commission.commissionType"
              dense
              outlined
              label="Commission Type"
              :prepend-inner-icon="mdiChartAreaspline"
              :items="commissionTypes"
              :success="!$v.commission.commissionType.$invalid"
              :error-messages="
                $v.commission.commissionType.$dirty &&
                !$v.commission.commissionType.required
                  ? ['Required']
                  : []
              "
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="commission.paymentType"
              dense
              outlined
              label="Payment Type"
              :prepend-inner-icon="mdiCash"
              :items="paymentTypes"
              :success="!$v.commission.paymentType.$invalid"
              :error-messages="
                $v.commission.paymentType.$dirty &&
                !$v.commission.paymentType.required
                  ? ['Required']
                  : []
              "
            />
          </v-col>
        </template>
        <v-col cols="12" md="6">
          <v-text-field
            data-lpignore="true"
            v-model="commission.percent"
            dense
            outlined
            label="Percent"
            type="text"
            inputmode="numeric"
            :prepend-inner-icon="mdiPercent"
            :success="!$v.commission.percent.$invalid"
            :error-messages="
              $v.commission.percent.$dirty && !$v.commission.percent.required
                ? ['Required']
                : []
            "
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            data-lpignore="true"
            v-model="commission.startYear"
            dense
            outlined
            label="Start Year"
            type="text"
            inputmode="numeric"
            :prepend-inner-icon="mdiCalendarStart"
            :success="!$v.commission.startYear.$invalid"
            :error-messages="
              $v.commission.startYear.$dirty &&
              !$v.commission.startYear.required
                ? ['Required']
                : []
            "
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            data-lpignore="true"
            v-model="commission.endYear"
            dense
            outlined
            label="End Year"
            type="text"
            inputmode="numeric"
            :prepend-inner-icon="mdiCalendarEnd"
            :success="!$v.commission.endYear.$invalid"
            :error-messages="
              $v.commission.endYear.$dirty && !$v.commission.endYear.required
                ? ['Required']
                : []
            "
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn outlined class="text-none" color="grey" @click="closeDialog">
        Cancel
      </v-btn>
      <v-btn class="text-none" color="primary" :loading="loading" @click="save">
        {{ editing ? "Save" : "Create" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import PayorSearch from "@/components/shared/PayorSearch.vue";
import CloseButton from "@/dialogs/CloseButton.vue";

import { required } from "vuelidate/lib/validators";
import { commissionTypes, paymentTypes } from "@/data/transaction-data";
import sortBy from "lodash/sortBy";
import { mapActions } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import {
  mdiOfficeBuilding,
  mdiChartAreaspline,
  mdiCash,
  mdiPercent,
  mdiCalendarStart,
  mdiCalendarEnd
} from "@mdi/js";
export default {
  components: {
    AdvisorSearch,
    PayorSearch,
    CloseButton
  },
  props: {
    $commission: Object,
    editing: Boolean,
    policy: Object,
    func: Function
  },
  data() {
    return {
      paymentTypes: sortBy(paymentTypes, "text"),
      loading: false,
      paid: false,
      id: 0, //Used for editing
      policyId: 0, //Used for creating
      mdiOfficeBuilding,
      mdiChartAreaspline,
      mdiCash,
      mdiPercent,
      mdiCalendarStart,
      mdiCalendarEnd,
      commission: {
        payable: null,
        agent: null,
        assignedPayable: null,
        payor: null,
        commissionType: null,
        paymentType: null,
        percent: null,
        startYear: null,
        endYear: null,
        appointmentCaseId: null
      }
    };
  },
  computed: {
    appointmentCases() {
      if (this.policy?.appointment_cases) return this.policy.appointment_cases;
      if (this.$commission?.appointment_case)
        return [this.$commission?.appointment_case];
      return [];
    },
    commissionTypes() {
      return sortBy(commissionTypes, "text").map(
        value => `${this.toLine(this.policy.line)} ${value}`
      );
    }
  },
  mounted() {
    if (!this.editing) {
      this.policyId = this.policy.id;
      return;
    }
    this.paid = this.$commission.paid;
    this.id = this.$commission.id;
    this.commission.advisor = this.$commission.appointment_case.advisor;
    this.commission.payable = this.$commission.payable;
    this.commission.assignedPayable = this.$commission.assigned_payable;
    this.commission.payor = this.$commission.payor;
    this.commission.appointmentCaseId = this.$commission.appointment_case?.id;
    this.commission.commissionType = this.$commission.commission_type;
    this.commission.paymentType = this.$commission.payment_type;
    this.commission.percent = this.$commission.percent * 100;
    this.commission.startYear = this.$commission.start_year;
    this.commission.endYear = this.$commission.end_year;
  },
  methods: {
    ...mapActions(useDialogStore, ["closeDialog"]),
    cancel() {
      this.closeDialog();
    },
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      return this.editing ? this.update() : this.create();
    },
    paidAttributes() {
      return {
        start_year: this.commission.startYear,
        end_year: this.commission.endYear,
        percent: this.commission.percent / 100
      };
    },
    allAttributes() {
      return {
        assigned_payable_id: this.commission.assignedPayable.id,
        assigned_payable_type: this.commission.assignedPayable.type,
        case_id: this.policyId,
        commission_type: this.commission.commissionType,
        payable_id: this.commission.payable.id,
        payable_type: this.commission.payable.type,
        payment_type: this.commission.paymentType,
        payor_id: this.commission.payor.id,
        ...this.paidAttributes()
      };
    },
    create() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.func({
        appointment_case_id: this.commission.appointmentCaseId,
        ...this.allAttributes()
      })
        .then(() => {
          this.closeDialog();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const requestAttributes = {
        commission_id: this.id,
        ...(this.paid ? this.paidAttributes() : this.allAttributes())
      };
      this.func(requestAttributes)
        .then(() => {
          this.closeDialog();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toLine(value) {
      if (value === "life") return "Life";
      if (value === "linked_benefit") return "Linked Benefit";
      if (value === "Variable Life") return "Variable Life";
      if (value === "disability") return "Disability";
      if (value === "annuity") return "Annuity";
      if (value === "ltc") return "LTC";
      return value;
    }
  },
  validations() {
    const paidValidations = {
      startYear: { required },
      endYear: { required },
      percent: { required }
    };

    if (this.editing && this.paid) {
      return { commission: paidValidations };
    }

    const allValidations = {
      payable: { required },
      assignedPayable: { required },
      payor: { required },
      commissionType: { required },
      paymentType: { required },
      appointmentCaseId: { required },
      ...paidValidations
    };

    return { commission: allValidations };
  }
};
</script>
