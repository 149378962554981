const config = cspNonce => ({
  options: {
    customProperties: true
  },
  icons: {
    iconfont: "mdiSvg"
  },
  theme: {
    options: {
      cspNonce
    },
    themes: {
      light: {
        success: "#4caf50",
        accent: "#28a746",
        section: "#f8f8f8",
        info: "#17a2b8",
        sidebar: "#fff",
        background: "#ececec",
        secondary: "#dfdfdf",
        primary: "#1487e2",
        subsection: "#fafafa",
        gold: "#FFD700"
      },
      dark: {
        primary: "#4188d9",
        success: "#4caf50",
        section: "#2d2d2d",
        accent: "#28a746",
        info: "#17a2b8",
        background: "#121212",
        sidebar: "#050505",
        subsection: "#161616",
        gold: "#FFD700"
      }
    }
  }
});

export default config;
