<template>
  <v-card flat tile>
    <v-card-text>
      <v-row class="pa-3">
        <v-spacer />
        <template v-if="!isWhiteglove">
          <v-btn
            rounded
            color="accent"
            class="text-none mr-1"
            depressed
            data-testid="action-toggle-custom-avatar"
            :outlined="!useOwnersAvatar"
            :loading="changingAvatar"
            @click="toggleAvatar"
          >
            <template v-if="useOwnersAvatar">
              <v-icon class="mr-1">{{ mdiImage }}</v-icon>
              Switch to Custom Avatar
            </template>
            <template v-else>
              <v-icon class="mr-1">{{ mdiAccountCircle }}</v-icon>
              Switch to {{ assignable.name }}'s Avatar
            </template>
          </v-btn>
        </template>
        <v-btn
          rounded
          outlined
          class="text-none"
          color="primary"
          data-testid="action-view-embed-code"
          @click="codeDialog"
        >
          <v-icon class="mr-1">{{ mdiCodeTags }}</v-icon> Show Embed Code
        </v-btn>
        <v-btn
          v-if="isGroupTwoPlus && archived"
          rounded
          outlined
          color="brown"
          class="text-none ml-1"
          @click="unarchiveSite"
        >
          <v-icon>{{ mdiArchiveArrowDown }}</v-icon> Unarchive
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { parseErrorMessage } from "@/util/helpers";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { mapWritableState, mapActions, mapState } from "pinia";
import {
  mdiImage,
  mdiAccountCircle,
  mdiCodeTags,
  mdiArchiveArrowDown
} from "@mdi/js";

export default {
  data() {
    return {
      changingAvatar: false,
      mdiImage,
      mdiAccountCircle,
      mdiCodeTags,
      mdiArchiveArrowDown
    };
  },
  computed: {
    ...mapWritableState(useApprovedDomainView, ["useOwnersAvatar"]),
    ...mapState(useApprovedDomainView, [
      "htmlSnippet",
      "isWhiteglove",
      "assignable",
      "archived"
    ]),
    ...mapState(useUserStore, ["isGroupTwoPlus"])
  },
  methods: {
    ...mapActions(useApprovedDomainView, [
      "updateApprovedDomain",
      "unarchiveApprovedDomain"
    ]),
    ...mapActions(useSnackbarStore, [
      "showSuccessSnackbar",
      "showErrorSnackbar"
    ]),
    ...mapActions(useDialogStore, ["showDialog"]),
    toggleAvatar() {
      if (this.changingAvatar) return;
      this.useOwnersAvatar = !this.useOwnersAvatar;
      this.changingAvatar = true;
      try {
        this.updateApprovedDomain("useOwnersAvatar");
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.changingAvatar = false;
      }
    },
    codeDialog() {
      this.showDialog({
        component: "CopyDialog",
        text: this.htmlSnippet,
        title: "Quote & Apply Snippet",
        subtitle: "Just copy and paste this into your site!"
      });
    },
    async unarchiveSite() {
      try {
        await this.unarchiveApprovedDomain();
        this.showSuccessSnackbar({
          message: "Successfully unarchived.",
          timeout: -1
        });
      } catch (e) {
        this.showErrorSnackbar({
          message: parseErrorMessage(e),
          timeout: -1
        });
      }
    }
  }
};
</script>
