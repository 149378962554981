<template>
  <v-data-table
    data-testid="case-parties-table"
    class="transparent-data-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :search="search"
  >
    <template #[`item.partyName`]="{ item }">
      <router-link
        :to="{
          name: 'ContractPartyView',
          params: { id: item.additional.id }
        }"
      >
        {{ item.partyName }}
      </router-link>
    </template>

    <template #[`item.roles`]="{ item }">
      <role-formatter :value="item" />
    </template>

    <template #[`item.birthdate`]="{ item }">
      <timestamp-formatter :value="item.birthdate" parser="sole-day" />
    </template>

    <template #[`item.primaryPhone`]="{ item }">
      <a
        :href="`tel:${item.primaryPhone}`"
        :data-outbound-type="item.additional.type"
        :data-outbound-id="item.additional.id"
        :data-outbound-number="item.primaryPhone"
        v-text="item.primaryPhone"
      />
    </template>
    <template #[`item.email`]="{ item }">
      <a :href="`mailto:${item.email}`">{{ item.email }}</a>
    </template>

    <template #[`item.address`]="{ item }">
      <span v-if="item.additional.addresses?.length">
        {{ formatAddress(item.additional.addresses[0]) }}
      </span>
    </template>

    <template #[`item.ssn`]="{ item }">
      <sensitive-info-toggle
        :type="item.additional.type === 'Entity' ? 'tin' : 'ssn'"
        :fetch-func="() => fetchSensitiveInfo(item.additional)"
      />
    </template>

    <template #[`item.actions`]="{ item }">
      <v-row class="ma-0">
        <v-btn icon data-testid="edit-role" @click="editRole(item.additional)">
          <v-icon color="success">{{ mdiPencil }}</v-icon>
        </v-btn>
      </v-row>
    </template>
  </v-data-table>
</template>

<script setup>
import EditRoleDialog from "@/components/cases/case-dialogs/EditRoleDialog.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import SensitiveInfoToggle from "@/components/shared/SensitiveInfoToggle.vue";
import RoleFormatter from "@/components/shared/formatters/RoleFormatter.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import { getAgentSsn } from "@/api/agents.service";
import { getSensitiveContractPartyInformation } from "@/api/contract-party.service";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { useCaseViewStore } from "@/stores/case-view";
import { useTable } from "@/composables/table.composable";
import { defineProps, markRaw, set, toRef, watch } from "vue";
import { formatAddress } from "@/factories/AddressFactory";
import { mdiPencil } from "@mdi/js";
const props = defineProps({
  search: {
    type: String,
    required: false,
    default: ""
  }
});

const search = toRef(props, "search");

const dialog = useDialogStore();
const caseView = useCaseViewStore();
const { contractParties, id: caseId, line } = storeToRefs(caseView);

const user = useUserStore();
const headers = [
  new TableHeader({
    text: "Name",
    value: "partyName",
    map: "name",
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "Role",
    value: "roles",
    map: "roles",
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "Birthdate",
    value: "birthdate",
    map: "birthdate",
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "Phone",
    value: "primaryPhone",
    map: "primaryPhone",
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "Email",
    value: "email",
    map: "email",
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "Address",
    value: "address",
    map: "address",
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "SSN",
    value: "ssn",
    map: "partyId",
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_SORTABLE
  })
];
if (user.isGroupTwoPlus) {
  headers.push(
    new TableHeader({
      text: "Actions",
      value: "actions"
    })
  );
}

const table = useTable({
  headers,
  items: contractParties.value
});
function fetchSensitiveInfo(item) {
  if (item.advisor) return getAgentSsn(item.advisor.id);
  return getSensitiveContractPartyInformation(item.id);
}
async function editRole(contractParty) {
  const result = await dialog.showDialog({
    component: markRaw(EditRoleDialog),
    value: contractParty,
    line: line.value,
    caseId: caseId.value
  });
  if (!result?.party) return;
  const index = contractParties.value.findIndex(
    val => val.id === contractParty.id
  );
  const party = result.party;

  if (!party.roles.length) {
    contractParties.value.splice(index, 1);
  } else {
    set(contractParties.value, index, party);
  }
}

watch(contractParties, () => {
  table.items.value.splice(
    0,
    table.items.value.length,
    ...contractParties.value
  );
});
</script>
