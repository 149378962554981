<template>
  <!-- Change Access -->
  <v-list-item
    v-if="item === 'Change Access'"
    link
    @click="changeAccess"
    key="change-access-item"
  >
    <v-list-item-icon>
      <v-icon>{{ mdiKeyChange }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>Change Access</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <v-list-item
    v-else-if="item === 'Profile'"
    key="profile-item"
    link
    exact
    :to="{
      name: 'LoggedInUserProfile'
    }"
  >
    <v-list-item-icon>
      <v-icon> {{ mdiAccount }} </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title> Profile </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <!-- Advisor Edit -->
  <v-list-item
    v-else-if="item === 'Advisor Edit'"
    key="advisor-edit-access-item"
    link
    :to="{
      name: 'LoggedInUserSettings'
    }"
    id="v-step-2"
  >
    <v-list-item-icon>
      <v-icon>{{ mdiCog }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>Settings</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <!-- Schedule Meeting -->
  <v-list-item
    v-else-if="item === 'Schedule Meeting' && schedulingLink.url"
    key="schedule-item"
    link
    :href="schedulingLink.url"
    target="_blank"
  >
    <v-list-item-icon>
      <v-icon>{{ mdiCalendar }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ schedulingLink.text }}</v-list-item-title>
  </v-list-item>
  <!-- BackNine Contacts -->
  <v-list-item
    v-else-if="item === 'BackNine Contacts'"
    key="backnine-item"
    :to="{ name: 'Contact' }"
    link
  >
    <v-list-item-icon>
      <img
        :src="require('@/assets/img/backnines.png')"
        class="greyscale"
        style="height: 24px; width: 24px; object-fit: contain"
      />
    </v-list-item-icon>
    <v-list-item-title>BackNine Contacts</v-list-item-title>
  </v-list-item>
  <!-- Light/Dark Mode -->
  <v-list-item
    v-else-if="item === 'LightDark Mode'"
    @click="toggleDarkMode"
    @click.native.stop
    key="theme-item"
  >
    <v-list-item-icon>
      <v-icon v-if="darkMode === true">{{ mdiWeatherSunny }}</v-icon>
      <v-icon v-else-if="darkMode === false">{{ mdiThemeLightDark }}</v-icon>
      <v-icon v-else>{{ mdiWeatherNight }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        <template v-if="darkMode === true"> Enable Light Mode </template>
        <template v-else-if="darkMode === false">
          Enable Auto Light/Dark Mode
        </template>
        <template v-else> Enable Dark Mode </template>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <!-- Divider -->
  <v-divider v-else-if="item === 'Divider'" key="divider" />
  <!-- Logout -->
  <logout-menu-item v-else-if="item === 'Logout'" key="logout-item" />
</template>

<script>
import LogoutMenuItem from "@/components/LogoutMenuItem.vue";
import { updateUser } from "@/api/users.service";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import {
  mdiKeyChange,
  mdiAccount,
  mdiCog,
  mdiCalendar,
  mdiWeatherSunny,
  mdiThemeLightDark,
  mdiWeatherNight
} from "@mdi/js";

export default {
  components: { LogoutMenuItem },
  props: {
    item: String
  },
  data: () => ({
    mdiKeyChange,
    mdiAccount,
    mdiCog,
    mdiCalendar,
    mdiWeatherSunny,
    mdiThemeLightDark,
    mdiWeatherNight
  }),
  methods: {
    ...mapActions(useSnackbarStore, ["showInfoSnackbar"]),
    ...mapActions(useDialogStore, ["showDialog"]),
    changeAccess() {
      this.showDialog({
        component: "ChangeAccessDialog"
      }).then(result => {
        if (!result.applying) {
          return;
        }

        this.showInfoSnackbar({
          message: "Applying Changes. Refreshing Page",
          timeout: 2000
        });
      });
    },
    toggleDarkMode() {
      let darkMode = null;
      if (this.darkMode === true) darkMode = false;
      else if (this.darkMode === null) darkMode = true;
      this.darkMode = darkMode;
      updateUser(this.userId, { dark_mode: this.darkMode });
    }
  },
  computed: {
    ...mapWritableState(useUserStore, ["darkMode"]),
    ...mapState(useUserStore, {
      userId: "id",
      loginable: "loginable",
      schedulingLinks: s =>
        s.loginable?.marketing_manager?.scheduling_links || [],
      logins: "logins"
    }),
    avatar() {
      return this.loginable.avatar_url;
    },
    schedulingLink() {
      if (this.schedulinglinks?.length) return this.schedulingLinks[0];
      return { url: "", text: "" };
    }
  }
};
</script>
