<template>
  <v-data-table
    class="transparent-data-table"
    :headers="headers"
    :items="items"
    :server-items-length="meta.total"
    :loading="loading"
    @update:options="updateOptions"
    must-sort
    :options="options"
    :footer-props="footerProps"
  >
    <template #top>
      <generic-table-filter-header
        :key="tabs[activeMetaTab].text"
        :value="filter"
        :headers="allHeaders"
        :loading="loading"
        hover
        color="section"
        @update="updateFilter"
      />
    </template>
    <template #[`item.id`]="{ item }">
      <router-link
        :to="{
          name: 'PayPeriodView',
          params: { id: item.id }
        }"
      >
        {{ item.id }}
      </router-link>
    </template>
    <template #[`item.amount`]="{ item }">
      <currency-formatter :value="item.amount" />
    </template>
    <template #[`item.date`]="{ item }">
      <timestamp-formatter format="date-time" :value="item.date" />
    </template>
    <template #[`item.actions`]="{ item }">
      <span style="white-space: nowrap">
        <v-tooltip v-if="item.additional.editable" bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              :disabled="item.additional.loading"
              icon
              @click="editPayPeriod(item)"
              color="primary"
            >
              <v-icon> {{ mdiPencil }} </v-icon>
            </v-btn>
          </template>
          <span>Edit Statement</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.additional.payable">
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              :loading="item.additional.loading"
              icon
              @click="pay(item)"
              color="accent"
            >
              <v-icon> {{ mdiCurrencyUsd }} </v-icon>
            </v-btn>
          </template>
          <span>{{ item.additional.payment_method }}</span>
        </v-tooltip>
      </span>
    </template>
  </v-data-table>
</template>

<script>
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import GenericTableMixin from "@/components/shared/data-table/GenericTableMixin";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import Table from "@/classes/data-table/Table";
import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useUserStore } from "@/stores/user";

import { getPayPeriods, payPayPeriod } from "@/api/pay-periods.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTableStore } from "@/stores/table";
import { useHead } from "@unhead/vue";
import { mdiPencil, mdiCurrencyUsd } from "@mdi/js";
export default {
  setup() {
    useHead({ title: "Pay Periods" });
  },
  components: {
    GenericTableFilterHeader,
    CurrencyFormatter,
    TimestampFormatter
  },
  mixins: [GenericTableMixin],
  data() {
    const user = useUserStore();
    const isGroupTwoPlus = user.isGroupTwoPlus;
    const isGroupThreePlus = user.isGroupThreePlus;
    const isGroupFour = user.isGroupFour;
    const headers = [
      isGroupThreePlus
        ? new TableHeader({
            text: "Paid or Unpaid",
            value: "type",
            map: "type",
            ...TableHeader.IS_SELECT_FILTER_TYPE,
            selectableOptions: [
              { text: "Paid", value: "paid" },
              { text: "Unpaid", value: "unpaid" }
            ],
            ...TableHeader.IS_SORTABLE,
            ...TableHeader.IS_FILTERABLE,
            sortFilterMap: "type",
            ...TableHeader.IS_ADDITIONAL,
            ...TableHeader.IS_QUERY_FILTER,
            ...TableHeader.IS_MANDATORY
          })
        : null,
      new TableHeader({
        text: "Statement ID",
        value: "id",
        map: "id",
        ...TableHeader.IS_STRING_FILTER_TYPE,
        ...TableHeader.IS_SORTABLE,
        ...TableHeader.IS_FILTERABLE,
        sortFilterMap: "pay_periods.id"
      }),
      new TableHeader({
        text: "Paid To",
        value: "paidTo",
        map: "paid_to.name",
        ...TableHeader.IS_ADVISOR_PAYMENT_SEARCH_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER,
        sortFilterMap: [
          { key: "payable_id", value: "id" },
          { key: "payable_type", value: "type" }
        ]
      }),
      isGroupTwoPlus
        ? new TableHeader({
            text: "Paid By",
            value: "paid_by",
            map: "paid_by.name",
            ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
            ...TableHeader.IS_FILTERABLE,
            ...TableHeader.IS_QUERY_FILTER,
            sortFilterMap: [
              { key: "billable_id", value: "id" },
              { key: "billable_type", value: "type" }
            ]
          })
        : null,
      new TableHeader({
        text: "Amount",
        value: "amount",
        map: "paid_amount",
        ...TableHeader.IS_STRING_FILTER_TYPE,
        ...TableHeader.IS_SORTABLE,
        ...TableHeader.IS_FILTERABLE,
        sortFilterMap: "paid_amount_at"
      }),
      isGroupFour
        ? new TableHeader({
            text: "Transaction ID",
            value: "transaction_id",
            map: "transaction_id",
            ...TableHeader.IS_STRING_FILTER_TYPE,
            ...TableHeader.IS_FILTERABLE,
            sortFilterMap: "transaction_id"
          })
        : null,
      new TableHeader({
        text: "Payment Method",
        value: "payment_method",
        map: "paying_to",
        ...TableHeader.IS_STRING_FILTER_TYPE
      }),
      new TableHeader({
        text: "Paid Date",
        value: "date",
        map: "paid_date",
        ...TableHeader.IS_DATE_FILTER_TYPE,
        ...TableHeader.IS_SORTABLE,
        ...TableHeader.IS_FILTERABLE,
        sortFilterMap: "pay_periods.paid_date"
      }),
      isGroupFour
        ? new TableHeader({
            text: "Status",
            value: "status",
            map: "status",
            ...TableHeader.IS_STRING_FILTER_TYPE,
            ...TableHeader.IS_FILTERABLE,
            sortFilterMap: "pay_periods.status"
          })
        : null,
      isGroupFour
        ? new TableHeader({
            text: "Status Note",
            value: "status_note",
            map: "status_note",
            ...TableHeader.IS_STRING_FILTER_TYPE
          })
        : null,
      isGroupFour
        ? new TableHeader({
            text: "Errors",
            value: "errors",
            map: "errors",
            ...TableHeader.IS_STRING_FILTER_TYPE
          })
        : null,
      isGroupFour
        ? new TableHeader({
            text: "Actions",
            value: "actions",
            map: "",
            ...TableHeader.IS_ACTION_FILTER_TYPE_TYPE
          })
        : null
    ].filter(Boolean);

    let filter = {};
    const { payPeriodTable } = useTableStore();
    if (payPeriodTable.filter) {
      Object.keys(payPeriodTable.filter).forEach(key => {
        if (!headers.find(header => header.value === key)) return;
        filter[key] = payPeriodTable.filter[key];
      });
    }
    let options = new TableOptions(["id"], [true]);
    if (Object.keys(payPeriodTable.options).length) {
      options = payPeriodTable.options;
    }

    const payPeriods = new Table({
      text: "PayPeriods",
      method: getPayPeriods,
      options,
      headers,
      key: "pay_periods",
      filter
    });

    return {
      tabs: [payPeriods],
      activeTab: 0,
      activeMetaTab: 0,
      mdiPencil,
      mdiCurrencyUsd
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.$emit("get-data-func", this.getData);
  },
  watch: {
    stats() {
      this.$emit("stats", this.stats);
    }
  },
  computed: {
    footerProps() {
      return {
        pageText: this.pageTextFormatter(
          this.tabs[this.activeTab].options,
          this.tabs[this.activeTab].meta
        ),
        itemsPerPageOptions: [10, 20, 35, 50]
      };
    },
    sortFilterMap() {
      return this.tabs[this.activeTab].sortFilterMap;
    },
    headers() {
      return this.tabs[this.activeTab].headers.filter(val => {
        if (val.isAdditional) return false;
        if (
          ["Actions", "Errors"].includes(val.text) &&
          this.filter.type === "paid"
        )
          return false;
        return true;
      });
    },
    allHeaders() {
      return this.tabs[this.activeTab].headers;
    },
    meta() {
      return this.tabs[this.activeTab].meta;
    },
    loading() {
      return this.tabs[this.activeTab].requests > 0;
    },
    items() {
      return this.tableMap(
        this.tabs[this.activeTab].rawItems,
        this.tabs[this.activeTab].headers
      );
    },
    options: {
      get() {
        return this.tabs[this.activeTab].options;
      },
      set(options) {
        return (this.tabs[this.activeTab].options = options);
      }
    },
    filter: {
      get() {
        return this.tabs[this.activeTab].filter;
      },
      set(filter) {
        this.tabs[this.activeTab].filter = filter;
      }
    },
    activeFilter() {
      return this.tableFilterObject(
        this.tabs[this.activeTab].filter,
        this.tabs[this.activeTab].sortFilterMap
      );
    },
    sort() {
      return this.tableOptionsSort(this.options, this.sortFilterMap);
    },
    stats() {
      const stats = [];
      const score = { text: "Paid Total", icon: mdiCurrencyUsd };
      const statsObj = this.tabs[this.activeTab]?.meta?.stats;
      if (!statsObj) {
        return [{ ...score, type: "loading" }];
      }
      Object.keys(statsObj).forEach(stat => {
        if (stat.indexOf("paid_amount_at_sum") > -1) {
          stats.push({ ...score, value: statsObj[stat], type: "dollar" });
        }
      });
      return stats;
    }
  },

  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    ...mapActions(useDialogStore, ["showDialog"]),
    getData() {
      const activeTab = this.activeTab;
      this.tabs[activeTab].requests++;
      this.tabs[activeTab]
        .getData()
        .then(response => {
          if (!response?.data) return;
          this.tabs[activeTab].meta = response.data.meta;
          this.tabs[activeTab].rawItems = response.data[
            this.tabs[activeTab].key
          ].map(value => ({ ...value, loading: false }));
          this.$emit("stats", this.stats);
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        })
        .finally(() => {
          this.tabs[activeTab].requests--;
          this.tabs[activeTab].loaded = true;
        });
    },
    updateOptions(options) {
      if (this.optionsEquivalence(this.options, options)) {
        return;
      }
      this.options = options;
      this.getData();
    },
    updateFilter(filter) {
      this.tabs[this.activeTab].filter = filter;
      this.tabs[this.activeTab].resetPage();
      this.getData();
    },
    updateActiveTab(tab) {
      this.activeTab = tab;
      if (this.tabs[this.activeTab].loaded) {
        return;
      }
      this.getData();
    },
    editPayPeriod(payPeriod) {
      if (payPeriod.additional.loading) {
        return;
      }
      this.showDialog({
        component: "EditPayPeriodDialog",
        payPeriod: payPeriod.additional
      }).then(response => {
        if (!response.updated) {
          return;
        }

        this.getData();
      });
    },
    pay(payPeriod) {
      if (payPeriod.additional.loading) {
        return;
      }
      payPeriod.additional.loading = true;
      payPayPeriod(payPeriod.id)
        .then(() => {
          this.showSuccessSnackbar({
            message: "Successfully sent payment",
            timeout: 5000
          });
          this.getData();
        })
        .catch(e => {
          if (e.response.status == 422) {
            payPeriod.additional.loading = false;
            payPeriod.additional.errors = e.response.data.message;
            this.editPayPeriod(payPeriod);
          } else {
            this.showErrorSnackbar({
              message: parseErrorMessage(e),
              timeout: -1
            });
          }
        })
        .finally(() => {
          payPeriod.additional.loading = false;
        });
    }
  }
};
</script>
