import {
  getAgencySummary,
  refreshAgencyLicenses,
  getAgencyLicenses
} from "@/api/agencies.service";
import {
  getAgentSummary,
  refreshAgentLicenses,
  getAgentLicenses
} from "@/api/agents.service";
import {
  getRecentAdvisors,
  getRecentAppointments,
  getRecentCampagins,
  getRecentCases,
  getRecentContractParties,
  getRecentEApps,
  getRecentPayments,
  getRecentPayPeriods,
  getRecentQuotes
} from "@/api/recents.service";
import { getAgentSources } from "@/api/agent-sources.service";

import {
  AdvisorProfile,
  AdvisorProfileAdapter
} from "@/factories/AdvisorProfile";

import { formatAddress } from "@/factories/AddressFactory";
import { defineStore } from "pinia";
import { set } from "vue";
import { useUserStore } from "./user";

export const useAdvisorProfileView = defineStore("advisor-profile-view", {
  state: () => ({
    ...AdvisorProfile(),
    canViewContracting: false,
    affiliations: [],
    licenses: [],
    fetchingLicenses: false,
    advisors: [],
    appointments: [],
    campaigns: [],
    cases: [],
    contractParties: [],
    eapps: [],
    payments: [],
    payPeriods: [],
    quotes: [],
    todoCount: 0
  }),
  getters: {
    businessAddressDisplay() {
      return formatAddress(this.businessAddress);
    },
    isAgency() {
      return this.type === "Agency";
    },
    getResourceParams() {
      if (this.isAgency) return { agency_id: this.id };
      return { agent_id: this.id };
    },
    tableFilterData() {
      return {
        id: this.id,
        type: this.type,
        name: this.name
      };
    },
    pusherId() {
      return `private-${this.id}-${this.type}`;
    }
  },
  actions: {
    async getAdvisorInfo(id, type) {
      this.$reset();
      const isAgency = type === "Agency";
      let func = getAgentSummary;
      if (isAgency) {
        func = getAgencySummary;
      }

      const { advisor, canViewContracting } = await func(id);
      const user = useUserStore();

      advisor.notes.sort((a, b) => a.createdAt - b.createdAt);
      advisor.notes.unshift({
        note: `Hi ${user.loginable.name}, feel free to make notes that only group 2+ users can view.`,
        ownable: { name: "BackNine" }
      });

      set(this, "$state", AdvisorProfileAdapter(advisor));
      this.canViewContracting = canViewContracting;
    },
    async getAffiliations() {
      this.affiliations.splice(0, this.affiliations.length);
      this.affiliations.push(...(await getAgentSources(this.id)));
    },
    async refreshLicenses() {
      if (this.isAgency) {
        await refreshAgencyLicenses(this.id);
      } else {
        await refreshAgentLicenses(this.id);
      }
      await this.getLicenses();
    },
    async getLicenses() {
      try {
        this.licenses.splice(0, this.licenses.length);
        const func = this.isAgency ? getAgencyLicenses : getAgentLicenses;
        this.fetchingLicenses = true;
        const licenses = await func(this.id);
        this.licenses.push(...licenses);
        this.fetchingLicenses = false;
      } catch (e) {
        this.fetchingLicenses = false;
        throw e;
      }
    },
    async getRecentAdvisors() {
      this.advisors.splice(0, this.advisors.length);
      const advisors = await getRecentAdvisors(this.getResourceParams);
      this.advisors.push(...advisors.items);
    },
    async getRecentAppointments() {
      this.appointments.splice(0, this.appointments.length);
      const appointments = await getRecentAppointments(this.getResourceParams);
      this.appointments.push(...appointments.items);
    },
    async getRecentCampagins() {
      this.campaigns.splice(0, this.campaigns.length);
      const campaigns = await getRecentCampagins(this.getResourceParams);
      this.campaigns.push(...campaigns.items);
    },
    async getRecentCases() {
      this.cases.splice(0, this.cases.length);
      const cases = await getRecentCases(this.getResourceParams);
      this.cases.push(...cases.items);
    },
    async getRecentContractParties() {
      this.contractParties.splice(0, this.contractParties.length);
      const contractParties = await getRecentContractParties(
        this.getResourceParams
      );
      this.contractParties.push(...contractParties.items);
    },
    async getRecentEApps() {
      this.eapps.splice(0, this.eapps.length);
      const eapps = await getRecentEApps(this.getResourceParams);
      this.eapps.push(...eapps.items);
    },
    async getRecentPayments() {
      this.payments.splice(0, this.payments.length);
      const payments = await getRecentPayments(this.getResourceParams);
      this.payments.push(...payments.items);
    },
    async getRecentPayPeriods() {
      this.payPeriods.splice(0, this.payPeriods.length);
      const payPeriods = await getRecentPayPeriods(this.getResourceParams);
      this.payPeriods.push(...payPeriods.items);
    },
    async getRecentQuotes() {
      this.quotes.splice(0, this.quotes.length);
      const quotes = await getRecentQuotes(this.getResourceParams);
      this.quotes.push(...quotes.items);
    }
  }
});
