<template>
  <v-card>
    <v-card-title>Add a Comp Builder</v-card-title>
    <v-card-text class="pb-0">
      <carrier-comp-builder-form
        v-model="compBuilder"
        :validation-scope="validationScope"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :disabled="saving"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="create-comp-builder"
        :saving="saving"
        @click="addCompBuilder"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import CarrierCompBuilderForm from "@/components/carriers/CarrierCompBuilderForm.vue";
import { CompBuilder } from "@/factories/CompBuilder";
import { createCompBuilder } from "@/api/comp-builders.service";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { parseErrorMessage } from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
import { useCarrierView } from "@/stores/carrier-view";
import { useSnackbarStore } from "@/stores/snackbar";

const carrierView = useCarrierView();
const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const { id: carrierId, name: carrierName } = storeToRefs(carrierView);

const compBuilder = ref(
  CompBuilder({ carrier: { id: carrierId.value, name: carrierName.value } })
);

const validationScope = "compBuilderCreate";
const v$ = useVuelidate({}, {}, { $scope: validationScope });
const saving = ref(false);

async function addCompBuilder() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  saving.value = true;

  try {
    const createdCompBuilder = await createCompBuilder({
      carrier_id: carrierId.value,
      name: compBuilder.value.name,
      street: Boolean(compBuilder.value.street),
      no_pay: Boolean(compBuilder.value.noPay)
    });

    dialog.closeDialog({ compBuilder: createdCompBuilder });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
