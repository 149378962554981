<template>
  <v-card flat color="section">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <currency-input
            v-model="statement.premium"
            :prepend-inner-icon="mdiCurrencyUsd"
            label="Premium"
            include-decimals
            include-negative
            dense
            outlined
            :success="amountValidation.success"
            :error-messages="amountValidation.errorMessages"
            @input="saveAttribute('premium')"
          >
            <template #append-outer>
              <active-save-indicator :controller="savingBuffer['premium']" />
            </template>
          </currency-input>
        </v-col>
        <v-col cols="12" md="6">
          <currency-input
            v-model="statement.amount"
            :prepend-inner-icon="mdiCurrencyUsd"
            label="Commission"
            include-decimals
            include-negative
            dense
            outlined
            :success="premiumValidation.success"
            :error-messages="premiumValidation.errorMessages"
            @input="saveAttribute('amount')"
          >
            <template #append-outer>
              <active-save-indicator :controller="savingBuffer['amount']" />
            </template>
          </currency-input>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import { required } from "vuelidate/lib/validators";
import { cloneDeep } from "@/util/helpers";
import { updateStatement } from "@/api/statements.service";
import { mdiCurrencyUsd } from "@mdi/js";

export default {
  name: "BackNineStatementDetails",
  mixins: [ActiveSaveMixin],
  components: { ActiveSaveIndicator, CurrencyInput },
  props: {
    value: Object
  },
  data() {
    const savingBuffer = this.generateSavingBuffer({
      items: ["premium", "amount"]
    });
    return {
      statement: cloneDeep(this.value),
      savingBuffer,
      mdiCurrencyUsd
    };
  },
  watch: {
    statement: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      }
    }
  },
  computed: {
    amountValidation() {
      const success = !this.$v.statement.amount.$invalid;
      const errorMessages = [];
      if (!this.$v.$dirty) return { success, errorMessages };
      if (!this.$v.statement.amount.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    premiumValidation() {
      const success = !this.$v.statement.premium.$invalid;
      const errorMessages = [];
      if (!this.$v.$dirty) return { success, errorMessages };
      if (!this.$v.statement.premium.required) errorMessages.push("Required");
      return { success, errorMessages };
    }
  },
  methods: {
    saveAttribute(attribute) {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      if (this.savingBuffer[attribute].timer)
        clearTimeout(this.savingBuffer[attribute].timer);

      this.savingBuffer[attribute].timer = setTimeout(() => {
        this.updateAttributeWrapper(
          () =>
            updateStatement(
              this.statement.id,
              this.statement.getAttributeRequestValue(attribute)
            ),
          this.savingBuffer[attribute]
        );
      }, 300);
    }
  },
  validations: {
    statement: {
      premium: { required },
      amount: { required }
    }
  }
};
</script>
