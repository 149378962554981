<template>
  <v-col cols="12" style="padding-left: 12px">
    <div
      style="border-left: 5px solid #4caf50"
      :style="{
        borderLeftColor: documentIds.length === 0 ? 'red' : '#4caf50'
      }"
    >
      <v-card flat tile color="transparent">
        <v-card-title>Documents</v-card-title>
        <v-card-subtitle>
          Please provide at least one document for why you answered "Yes" to the
          question "{{ question.questionText }}"
        </v-card-subtitle>
        <v-card-text>
          <file-drag-and-drop
            v-model="newDocument"
            multiple
            upload
            :success="newDocumentValidation.success"
            :error-messages="newDocumentValidation.errorMessages"
            :disabled="readonly"
            :upload-func="createDocument"
          />
          <v-list class="py-0" style="max-width: 30rem">
            <v-list-item
              v-for="documentId in documentIds"
              :data-testid="`document-${documentId}`"
              :key="documentId"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ documents[documentId].name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="!readonly">
                <v-row class="ma-0">
                  <v-btn
                    icon
                    color="primary"
                    :data-testid="`document-${documentId}-download`"
                    v-bind="downloadDocument(documentId)"
                  >
                    <v-icon> {{ mdiDownload }} </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="error"
                    :data-testid="`document-${documentId}-delete`"
                    @click.stop="deleteConfirmation(documentId)"
                  >
                    <v-icon> {{ mdiDelete }} </v-icon>
                  </v-btn>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
  </v-col>
</template>

<script setup>
import {
  computedValidation,
  downloadFileAsLink,
  parseErrorMessage
} from "@/util/helpers";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import { storeToRefs } from "pinia";
import { getDocumentUrl } from "@/api/documents.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { computed, defineProps, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { useVuetify } from "@/composables/compatible.composables";

import { mdiDownload, mdiDelete } from "@mdi/js";

const props = defineProps({
  questionId: [String, Number],
  readonly: Boolean
});

const agent = useAgentSettingsStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const vuetify = useVuetify();

const { contractingQuestions } = storeToRefs(agent);

const newDocument = ref([]);

const question = computed({
  get: () => contractingQuestions.value[props.questionId],
  set(v) {
    contractingQuestions.value[props.questionId] = v;
  }
});

const documents = computed(() => question.value.documents);
const documentIds = computed(() => Object.keys(documents.value));

const v$ = useVuelidate(
  {
    documents: {
      atLeastOne: val => val?.length > 0
    },
    newDocument: {
      allValidSizes: val => !val.some(doc => doc?.size === 0)
    }
  },
  { documents, newDocument },
  { $scope: null, $autoDirty: true }
);

const newDocumentValidation = computedValidation(v$.value.documents, {
  allValidSizes:
    "Please confirm all files have data or try Re-uploading these files"
});

async function createDocument(file) {
  try {
    await agent.createAndAddDocument({ file, questionId: props.questionId });
    snackbar.showSuccessSnackbar({
      message: "Successfully Uploaded Document"
    });
    newDocument.value = [];
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
function deleteConfirmation(documentId) {
  if (props.readonly) return;

  dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Are you sure you want to delete this document?",
    subtitle: "Please confirm your intent",
    func: () =>
      agent.deleteAndRemoveDocument({
        questionId: props.questionId,
        documentUid: documents.value[documentId].uid,
        documentId
      })
  });
}
function downloadDocument(documentId) {
  return downloadFileAsLink(
    getDocumentUrl(documents.value[documentId].uid),
    vuetify.breakpoint.mdAndDown
  );
}
</script>
