<template>
  <v-card flat color="section">
    <v-card-title> Email Subscriptions </v-card-title>
    <v-card-subtitle>
      Enable or disable what to receive emails about
    </v-card-subtitle>
    <v-row class="ma-0">
      <!-- Appointments -->
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="store.communicationPreferences.appointments"
            :disabled="props.readonly"
            :data-testid="`${module}-notifications-appointments`"
            @change="updateAttribute('appointments')"
          >
            <template #label>
              Appointments
              <active-save-indicator
                :controller="savingBuffer.appointments.controller.value"
              />
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <!-- High Credit Case -->
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="store.communicationPreferences.highPremiumCases"
            :disabled="props.readonly"
            :data-testid="`${module}-notifications-high-premium-cases`"
            @change="updateAttribute('highPremiumCases')"
          >
            <template #label>
              Cases Over $5,000 of Production Credit
              <active-save-indicator
                :controller="savingBuffer.highPremiumCases.controller.value"
              />
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <!-- Low Credit Case -->
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="store.communicationPreferences.cases"
            :disabled="props.readonly"
            :data-testid="`${module}-notifications-cases`"
            @change="updateAttribute('cases')"
          >
            <template #label>
              Cases Under $5,000 of Production Credit
              <active-save-indicator
                :controller="savingBuffer.cases.controller.value"
              />
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <!-- Cross Sell -->
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="store.communicationPreferences.crossSell"
            :disabled="props.readonly"
            :data-testid="`${module}-notifications-cross-sell`"
            @change="updateAttribute('crossSell')"
          >
            <template #label>
              Cross Sell Opportunities
              <active-save-indicator
                :controller="savingBuffer.crossSell.controller.value"
              />
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <!-- Pay Periods i.e. Commissions -->
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="store.communicationPreferences.payPeriods"
            :disabled="props.readonly"
            :data-testid="`${module}-notifications-pay-periods`"
            @change="updateAttribute('payPeriods')"
          >
            <template #label>
              Commissions
              <active-save-indicator
                :controller="savingBuffer.payPeriods.controller.value"
              />
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <!-- eApps  -->
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="store.communicationPreferences.electronicApplications"
            :disabled="props.readonly"
            :data-testid="`${module}-notifications-electronic-applications`"
            @change="updateAttribute('electronicApplications')"
          >
            <template #label>
              eApps
              <active-save-indicator
                :controller="
                  savingBuffer.electronicApplications.controller.value
                "
              />
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <!-- IUL -->
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="store.communicationPreferences.iul"
            :disabled="props.readonly"
            :data-testid="`${module}-notifications-iul`"
            @change="updateAttribute('iul')"
          >
            <template #label>
              IUL
              <active-save-indicator
                :controller="savingBuffer.iul.controller.value"
              />
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <!-- Marketing -->
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="store.communicationPreferences.marketing"
            :disabled="props.readonly"
            :data-testid="`${module}-notifications-marketing`"
            @change="updateAttribute('marketing')"
          >
            <template #label>
              Marketing
              <active-save-indicator
                :controller="savingBuffer.marketing.controller.value"
              />
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <!-- Quick Quotes -->
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="store.communicationPreferences.quickQuotes"
            :disabled="props.readonly"
            :data-testid="`${module}-notifications-quick-quotes`"
            @change="updateAttribute('quickQuotes')"
          >
            <template #label>
              Quick Quotes
              <active-save-indicator
                :controller="savingBuffer.quickQuotes.controller.value"
              />
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <!-- Quotes -->
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="store.communicationPreferences.quotes"
            :disabled="props.readonly"
            :data-testid="`${module}-notifications-quotes`"
            @change="updateAttribute('quotes')"
          >
            <template #label>
              Quotes
              <active-save-indicator
                :controller="savingBuffer.quotes.controller.value"
              />
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <!-- Weekly Case Summary -->
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="store.communicationPreferences.caseSummaries"
            :disabled="props.readonly"
            :data-testid="`${module}-notifications-case-summaries`"
            @change="updateAttribute('caseSummaries')"
          >
            <template #label>
              Weekly Case Summaries
              <active-save-indicator
                :controller="savingBuffer.caseSummaries.controller.value"
              />
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <!-- Weekly Quote Summary -->
      <v-col cols="12" md="4">
        <div class="checkbox-width">
          <v-checkbox
            v-model="store.communicationPreferences.quoteSummaries"
            :disabled="props.readonly"
            :data-testid="`${module}-notifications-quote-summaries`"
            @change="updateAttribute('quoteSummaries')"
          >
            <template #label>
              Weekly Quote Summaries
              <active-save-indicator
                :controller="savingBuffer.quoteSummaries.controller.value"
              />
            </template>
          </v-checkbox>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { defineProps } from "vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { useSettingsViewStore } from "@/stores/settings-view";
const props = defineProps({
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  },
  readonly: Boolean
});

let store;

if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const savingBuffer = {
  appointments: useActiveSave(),
  caseSummaries: useActiveSave(),
  cases: useActiveSave(),
  crossSell: useActiveSave(),
  electronicApplications: useActiveSave(),
  highPremiumCases: useActiveSave(),
  iul: useActiveSave(),
  marketing: useActiveSave(),
  payPeriods: useActiveSave(),
  quickQuotes: useActiveSave(),
  quoteSummaries: useActiveSave(),
  quotes: useActiveSave()
};

function updateAttribute(type) {
  savingBuffer[type].update(() => store.updateCommunicationPreference(type));
}
</script>
