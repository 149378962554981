<template>
  <v-row class="pa-3" dense v-if="loaded">
    <template v-if="!hasCategoryStates">
      <h3>No Editable Form Relations.</h3>
    </template>
    <template v-if="hasCategoryStates">
      <div v-for="set in states" :key="set.id">
        <v-col cols="12" style="text-align: left">
          <v-row class="pa-3">
            <h2 class="mb-2">{{ set.name }} - Category States</h2>
            <active-save-indicator
              :controller="savingBuffer[`state_${set.id}`]"
            />
          </v-row>
        </v-col>
        <v-row class="ma-0 flex-wrap">
          <div v-for="state in set.states" :key="state.value" class="ma-1">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  :key="state.value"
                  :value="state.value"
                  :color="state.color"
                  :class="{ 'white--text': state.formId }"
                  class="text-none pr-1"
                  style="
                    border-bottom-right-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                  "
                  rounded
                  depressed
                  @click="updateCategoryState(set, state)"
                >
                  {{ state.text }}
                </v-btn>
              </template>
              <span v-if="state.color === 'primary'">
                Remove {{ name }}'s association with
                {{ state.text }}
              </span>
              <span v-else-if="state.color === 'orange'">
                Remove {{ name }}'s existing association and associate it with
                {{ state.text }}
              </span>
              <span v-else> Associate {{ name }} with {{ state.text }} </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  style="
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    min-width: 36px;
                  "
                  :color="state.color"
                  :class="{ 'white--text': state.formId }"
                  class="text-none px-0"
                  rounded
                  @click="toggleCategoryStateStatus(set, state)"
                  depressed
                >
                  <v-icon v-if="state.isNotRequired">
                    {{ mdiCircleOffOutline }}
                  </v-icon>
                  <v-icon v-else> {{ mdiAlertCircleOutline }} </v-icon>
                </v-btn>
              </template>
              <span v-if="state.isNotRequired"> Mark as Required </span>
              <span v-else> Mark as not required </span>
            </v-tooltip>
          </div>
        </v-row>
      </div>
      <v-sheet rounded class="pa-3 mt-3" outlined>
        <h4>Category States Legend</h4>
        <v-divider class="mt-1 mb-3" />
        <v-row align="center" class="pa-3">
          <div class="color-sample mr-3 ma-1 primary" />
          States Mapped to this Form
        </v-row>
        <v-row align="center" class="pa-3">
          <div class="color-sample mr-3 ma-1 orange" />
          States Mapped to other Form
        </v-row>
        <v-row align="center" class="pa-3">
          <div class="color-sample mr-3 ma-1 secondary" />
          Unmapped States
        </v-row>
      </v-sheet>
    </template>
  </v-row>
  <v-skeleton-loader v-else type="card" />
</template>

<script>
import { mdiCircleOffOutline, mdiAlertCircleOutline } from "@mdi/js";
import { states as rawStates } from "@/data/states";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import {
  searchForCategoryStates,
  updateIndividualCategoryState
} from "@/api/category-states.service";
import ActiveSaveMixin from "@/components/shared/active-save/ActiveSaveMixin.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { parseErrorMessage } from "@/util/helpers";
import { ref } from "vue";
import { storeToRefs, mapActions } from "pinia";
import { useFormMappingStore } from "@/stores/form-mapping";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  mixins: [ActiveSaveMixin],
  components: {
    ActiveSaveIndicator
  },
  props: {
    value: Object,
    formId: [String, Number],
    readonly: Boolean
  },
  setup({ formId }) {
    const formStore = useFormMappingStore(formId);
    const { name, isContracting } = storeToRefs(formStore);
    return {
      loaded: ref(false),
      otherCategoryStates: ref([]),
      savingBuffer: ref({}),
      limitTimer: ref(null),
      name,
      isContracting,
      mdiCircleOffOutline,
      mdiAlertCircleOutline
    };
  },
  created() {
    this.getData();
  },
  computed: {
    states() {
      const mappedStates = parent =>
        parent.category_states.map(stateObj => ({
          text: this.toFullStateName(stateObj.state.name),
          value: stateObj.id,
          formId: stateObj.form?.id,
          hasForm: Boolean(stateObj.form),
          isNotRequired: stateObj.status === "Not Required",
          color:
            +stateObj.form?.id === +this.formId
              ? "primary"
              : stateObj.form?.id
              ? "orange"
              : null,
          original: stateObj
        }));

      return this.otherCategoryStates.map(parent => ({
        name: parent.name,
        id: parent.id,
        states: sortBy(mappedStates(parent), "text")
      }));
    },
    hasCategoryStates() {
      return !this.isContracting;
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    getData() {
      this.loaded = false;
      searchForCategoryStates({ form_id: this.formId })
        .then(res => {
          this.otherCategoryStates = res;
          this.setSavingBuffer();
        })
        .catch(e => {
          this.showErrorSnackbar({
            message: parseErrorMessage(e)
          });
          this.otherCategoryStates = [];
        })
        .finally(() => {
          this.loaded = true;
        });
    },
    updateCategoryState(parent, state) {
      const parentState = this.otherCategoryStates.find(
        p => p.id === parent.id
      );
      const currentState = parentState.category_states.find(
        cs => cs.id === state.value
      );
      let value = null;
      if (+currentState.form?.id !== +this.formId) {
        value = this.formId;
      }
      const body = { form_id: value, status: undefined };
      if (value) {
        body.status = "Incomplete";
        this.$set(currentState, "status", "Incomplete");
      }
      this.$set(currentState, "form", value ? { id: value } : null);
      this.updateAttributeWrapper(() => {
        return updateIndividualCategoryState(currentState.id, body);
      }, this.savingBuffer[`state_${parentState.id}`]);
    },
    toggleCategoryStateStatus(parent, state) {
      const parentState = this.otherCategoryStates.find(
        p => p.id === parent.id
      );
      const currentState = parentState.category_states.find(
        cs => cs.id === state.value
      );
      let body = {};
      if (currentState.status !== "Not Required") {
        body = { status: "Not Required", form_id: null };
        this.$set(currentState, "status", "Not Required");
        this.$set(currentState, "form", null);
      } else {
        body = { status: "Incomplete" };
        this.$set(currentState, "status", "Incomplete");
      }

      this.updateAttributeWrapper(() => {
        return updateIndividualCategoryState(currentState.id, body);
      }, this.savingBuffer[`state_${parentState.id}`]);
    },
    setSavingBuffer() {
      this.savingBuffer = this.generateSavingBuffer({
        items: [...this.otherCategoryStates.map(val => `state_${val.id}`)]
      });
    },
    toFullStateName(state) {
      return find(rawStates, { text: state }).full;
    }
  }
};
</script>
