<template>
  <v-sheet :data-testid="dataTestid" :color="color" class="my-3" rounded>
    <v-row align="center" dense>
      <v-col cols="12">
        <v-row class="ma-0" align="center">
          <h3 style="font-weight: 400">{{ label }}</h3>
          <v-btn
            v-if="hasValue"
            icon
            class="ml-2"
            :disabled="loading"
            @click="reset"
          >
            <v-icon>{{ mdiUndo }}</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" :lg="isMinType && isMaxType ? 4 : 6" :key="label">
        <v-select
          v-model="number.type"
          dense
          attach
          outlined
          hide-details
          ref="type"
          label="Type"
          :data-testid="`${dataTestid}-type`"
          :items="numberFilterItems"
          :disabled="loading"
          :menu-props="{ bottom: true, offsetY: true }"
          @change="clearValue"
        />
      </v-col>
      <v-col
        cols="12"
        v-if="isMinType"
        :lg="isMinType && isMaxType ? 4 : 6"
        :key="label + '-min-range'"
      >
        <component
          :is="isCurrency ? 'currency-input' : 'integer-input'"
          v-model="number.value.min"
          dense
          outlined
          clearable
          hide-details
          ref="minInput"
          style="margin-right: 0.5rem"
          :data-testid="`${dataTestid}-min`"
          :label="isSoloType ? soloLabel : 'Min'"
          :prepend-inner-icon="isCurrency ? mdiCurrencyUsd : null"
          :decimal-length="0"
          :disabled="loading"
          @click:clear="number.value.min = undefined"
        />
      </v-col>
      <v-col
        v-if="isMaxType"
        cols="12"
        :lg="isMinType && isMaxType ? 4 : 6"
        :key="label + '-max-range'"
      >
        <component
          :is="isCurrency ? 'currency-input' : 'integer-input'"
          v-model="number.value.max"
          hide-details
          outlined
          dense
          clearable
          :data-testid="`${dataTestid}-max`"
          :label="isSoloType ? soloLabel : 'Max'"
          :prepend-inner-icon="isCurrency ? mdiCurrencyUsd : null"
          :disabled="loading"
          :decimal-length="0"
          @click:clear="number.value.max = undefined"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mdiUndo, mdiCurrencyUsd } from "@mdi/js";
import IntegerInput from "@/components/shared/IntegerInput.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import { numberFilters } from "@/constants/number-filter.constants";

export default {
  components: {
    CurrencyInput,
    IntegerInput
  },
  props: {
    color: {
      type: String,
      default: "white"
    },
    label: {
      type: String,
      required: true
    },
    isCurrency: Boolean,
    value: [Object, String],
    loading: Boolean,
    dataTestid: { type: String, default: "number" }
  },
  data: () => ({
    number: {
      type: ">=",
      value: {
        min: "",
        max: ""
      }
    },
    mdiUndo,
    mdiCurrencyUsd
  }),
  created() {
    this.setNumber(this.value);
  },
  mounted() {
    this.isMounted = true;
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        const isEqual =
          this.number.type === val?.type &&
          this.number.value.min === val?.value?.min &&
          this.number.value.max === val?.value?.max;

        if (isEqual) return;
        this.setNumber(val);
      }
    },
    number: {
      deep: true,
      handler(value) {
        const val = JSON.parse(JSON.stringify(value));
        if (this.isSoloType) return this.$emit("input", val);
        const isFilled = val.value.min && val.value.max;
        const isEmpty = !val.value.min && !val.value.max;
        const validCondition = isFilled || isEmpty;
        if (validCondition) this.$emit("input", val);
      }
    }
  },
  computed: {
    numberFilterItems() {
      return numberFilters;
    },
    hasValue() {
      return this.number.value.min || this.number.value.max;
    },
    isMinType() {
      return [">=", "range", "="].includes(this.number.type);
    },
    isMaxType() {
      return ["<=", "range"].includes(this.number.type);
    },
    isSoloType() {
      return [">=", "<=", "="].includes(this.number.type);
    },
    isRangeType() {
      return this.number.value.type === "range";
    },
    soloLabel() {
      return this.numberFilterItems.find(
        ({ value }) => value === this.number.type
      ).text;
    }
  },
  methods: {
    setNumber(val) {
      const value = JSON.parse(JSON.stringify(val));
      if (this.numberFilterItems.some(i => i.value === value?.type)) {
        this.number.type = value.type;
      }

      if (value?.value?.min) {
        this.number.value.min = value.value.min;
      }

      if (value?.value?.max) {
        this.number.value.max = value.value.max;
      }
    },
    unrefIfRef(value) {
      return value?.value || value;
    },
    focus() {
      this.$refs.minInput.$el.querySelector("input").focus();
    },
    reset() {
      this.number.type = ">=";
      this.clearValue();
    },
    clearValue() {
      this.number.value.min = null;
      this.number.value.max = null;
    }
  }
};
</script>
