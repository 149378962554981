<template>
  <v-card>
    <v-card-title> Create Quote Request </v-card-title>
    <v-card-subtitle>
      Use this form for BackNine's sales desk to run your quote.
    </v-card-subtitle>
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="12">
          <v-textarea
            v-model="quoteRequest.description"
            label="What changes to the current quote would you like?"
            outlined
            dense
            data-testid="synthesize-description"
            :prepend-inner-icon="mdiText"
            :disabled="loading"
            :success="descriptionValidation.success"
            :error-messages="descriptionValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <file-drag-and-drop
            v-model="quoteRequest.files"
            :disabled="loading"
            :success="Boolean(quoteRequest.files.length)"
            multiple
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        :loading="loading"
        data-testid="synthesize-create"
        @click="create"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";

import { createQuote } from "@/api/quotes.service";

import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { ref, defineProps } from "vue";
import useVuelidate from "@vuelidate/core";
import { useRouter } from "@/composables/compatible.composables";
import { mdiText } from "@mdi/js";

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  idBodyKey: {
    type: String,
    required: true
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const router = useRouter();

const quoteRequest = ref({ description: "", files: [] });
const loading = ref(false);

const v$ = useVuelidate(
  {
    quoteRequest: {
      description: { required: v => someTextValidator(true, v, 2) }
    }
  },
  { quoteRequest },
  { $scope: null, $autoDirty: true }
);

const descriptionValidation = computedValidation(
  v$.value.quoteRequest.description,
  { required: "Required" }
);

async function create() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  loading.value = true;
  const body = {
    [props.idBodyKey]: props.id,
    quote: {
      description: quoteRequest.value.description,
      documents_attributes: quoteRequest.value.files
    }
  };

  try {
    const quoteId = await createQuote(body);
    router.push({
      name: "QuoteView",
      params: {
        id: quoteId
      }
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
