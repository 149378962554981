var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{attrs:{"must-sort":"","data-testid":"electronic-applications-table","headers":_setup.table.tableHeaders.value,"items":_setup.table.mappedItems.value,"server-items-length":_setup.table.meta.value.total,"loading":_setup.table.loading.value,"options":_setup.table.options.value,"footer-props":_setup.footerProps},on:{"update:options":_setup.updateOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"ma-0",attrs:{"align":"center","dense":""}},[_c('h1',{staticClass:"text-h5 pa-3"},[_vm._v("eApps")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"auto","order-md":"1","order":"2"}},[_c(_setup.GenericTableStats,{attrs:{"data-testid":"electronic-applications-stats","end":"","stats":_setup.stats}})],1),_c('v-col',{attrs:{"cols":"auto","order-md":"2","order":"1"}},[(_setup.table.filter.value.view !== 'all')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-testid":"electronic-applications-export"},on:{"click":_setup.confirmAndEmailData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_setup.mdiFileExport))])],1)]}}],null,false,665921048)},[_c('span',[_vm._v("Email Data")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-testid":"electronic-applications-refresh"},on:{"click":_setup.getData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_setup.mdiRefresh))])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1)],1),_c('v-divider'),_c(_setup.GenericTableFilterHeader,{attrs:{"header-props":{
        class: 'mt-0 pa-3',
        rounded: false
      },"show-view-filter":_setup.user.isGroupTwoPlus,"value":_setup.table.filter.value,"headers":_setup.table.filterHeaders.value,"loading":_setup.table.loading.value},on:{"update":_setup.updateFilter}}),_c('v-divider')]},proxy:true},{key:`item.insuredName`,fn:function({ item }){return [_c('router-link',{staticClass:"text-none",attrs:{"to":{
        name: 'ElectronicApplicationView',
        params: {
          id: item.additional.eAppId
        }
      }}},[_vm._v(" "+_vm._s(item.insuredName)+" ")])]}},{key:`item.created`,fn:function({ item }){return [_c(_setup.TimestampFormatter,{attrs:{"value":item.created}})]}},{key:`item.website`,fn:function({ item }){return [_c('div',{class:{
        'truncate-400': _vm.$vuetify.breakpoint.mdAndUp,
        'truncate-200': _vm.$vuetify.breakpoint.smAndDown
      }},[_vm._v(" "+_vm._s(item.website)+" ")])]}},{key:`item.premium`,fn:function({ item }){return [(item.premium || item.premium === 0)?[_c(_setup.CurrencyFormatter,{attrs:{"value":item.premium,"decimal-length":0}}),_c('span',{staticClass:"grey--text"},[_vm._v(" ("),_c(_setup.ModeFormatter,{attrs:{"value":item.additional.mode}}),_vm._v(") ")],1)]:_vm._e()]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }