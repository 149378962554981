<template>
  <v-sheet :color="color" class="my-3" rounded :data-testid="dataTestid">
    <v-row align="center" dense>
      <v-col cols="12">
        <v-row class="ma-0">
          <h3 style="font-weight: 400">{{ headerText }}</h3>
          <v-btn
            icon
            v-if="hasValue"
            @click="reset"
            class="ml-2"
            :disabled="loading"
          >
            <v-icon>{{ mdiUndo }}</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        :lg="
          date.type === DATE_TYPES.RANGE
            ? 4
            : date.type === DATE_TYPES.SOLO
            ? 6
            : 12
        "
        :key="headerText"
      >
        <v-select
          attach
          label="Type"
          v-model="date.type"
          outlined
          dense
          hide-details
          :prepend-inner-icon="mdiCalendarCursor"
          ref="type"
          :data-testid="`${dataTestid}-type`"
          :items="dateFilterItems"
          :disabled="loading"
          :menu-props="{ bottom: true, offsetY: true }"
          @change="updateType"
        />
      </v-col>
      <template v-if="date.type === DATE_TYPES.RANGE">
        <v-col cols="12" md="4" :key="headerText + '-start-range'">
          <date-input
            label="Start"
            outlined
            dense
            style="margin-right: 0.5rem"
            hide-details
            v-model="date.value.start"
            ref="startInput"
            :prepend-inner-icon="mdiCalendarStart"
            clearable
            :disabled="loading"
            :data-testid="`${dataTestid}-start`"
            @input="emitChange"
            @click:clear="date.value.start = undefined"
          />
        </v-col>
        <v-col cols="12" md="4" :key="headerText + '-finish-range'">
          <date-input
            v-model="date.value.finish"
            hide-details
            label="Finish"
            outlined
            dense
            :prepend-inner-icon="mdiCalendarEnd"
            clearable
            :disabled="loading"
            :data-testid="`${dataTestid}-finish`"
            @input="emitChange"
            @click:clear="date.value.finish = undefined"
          />
        </v-col>
      </template>
      <v-col
        cols="12"
        md="6"
        v-else-if="date.type === DATE_TYPES.SOLO"
        :key="headerText + '-solo'"
      >
        <date-input
          v-model="date.value.start"
          label="Date"
          outlined
          hide-details
          dense
          ref="startInput"
          :prepend-inner-icon="mdiCalendar"
          clearable
          :data-testid="`${dataTestid}-specific`"
          :disabled="loading"
          @input="specifyFn"
          @click:clear="clearValue"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import {
  pastDateFilters,
  futureDateFilters
} from "@/constants/date-filter.constants";
import DateInput from "@/components/shared/DateInput.vue";
import isEqual from "lodash/isEqual";
import {
  mdiUndo,
  mdiCalendarCursor,
  mdiCalendarStart,
  mdiCalendarEnd,
  mdiCalendar
} from "@mdi/js";
const DATE_TYPES = {
  RANGE: "range",
  SOLO: "solo"
};
export default {
  components: { DateInput },
  props: {
    color: {
      type: String,
      default: "white"
    },
    futureDateValues: Boolean,
    headerText: String,
    value: [Object, String],
    loading: Boolean,
    dataTestid: {
      type: String,
      default: "date"
    }
  },
  data() {
    return {
      date: {
        type: "solo",
        value: {
          start: "",
          finish: ""
        }
      },
      isMounted: false,
      DATE_TYPES,
      mdiUndo,
      mdiCalendarCursor,
      mdiCalendarStart,
      mdiCalendarEnd,
      mdiCalendar
    };
  },
  created() {
    if (this.dateFilterItems.some(i => i.value === this.value?.type)) {
      this.date.type = this.value.type;
    }

    if (this.value?.value?.start) {
      this.date.value.start = this.value.value.start;
    }

    if (this.value?.value?.finish) {
      this.date.value.finish = this.value.value.finish;
    }
  },
  mounted() {
    this.isMounted = true;
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        if (isEqual(this.date, val)) return;
        this.date.type = val?.type;
        this.date.value.start = val?.value?.start;
        this.date.value.finish = val?.value?.finish;
      }
    }
  },
  computed: {
    hasValue() {
      return this.date.value.start && this.date.value.finish;
    },
    dateFilterItems() {
      const items = [{ text: "Specify Date", value: "solo" }];
      if (this.futureDateValues) items.push(...futureDateFilters);
      else items.push(...pastDateFilters);
      items.push({ text: "Custom Range", value: "range" });
      return items;
    }
  },
  methods: {
    specifyFn() {
      this.date.value.finish = this.date.value.start;
      this.emitChange();
    },
    focus() {
      if ([DATE_TYPES.RANGE, DATE_TYPES.SOLO].includes(this.date.type)) {
        this.$refs.startInput.$el.querySelector("input").focus();
      } else {
        this.$refs.type.$el.querySelector("input").focus();
      }
    },
    emitChange() {
      if (this.date.type === DATE_TYPES.SOLO) {
        this.$emit("input", this.date);
      } else if (this.date.type === DATE_TYPES.RANGE) {
        const isFilled = this.date.value?.start && this.date.value?.finish;
        if (isFilled) this.$emit("input", this.date);
      } else {
        this.$emit("input", this.date);
      }
    },
    reset() {
      this.date.type = "solo";
      this.clearValue();
    },
    clearValue() {
      this.date.value.start = null;
      this.date.value.finish = null;
      this.emitChange();
    },
    updateType() {
      let type = this.date.type || this.date.filterType;

      const filter = this.dateFilterItems.find(i => i.value === type);
      if (filter?.fn) {
        const value = filter.fn();
        this.date.value.start = value.start;
        this.date.value.finish = value.finish;
      } else {
        this.date.value = { start: null, finish: null };
      }

      this.emitChange();
    }
  }
};
</script>
