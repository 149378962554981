<template>
  <v-card v-cloak @drop.prevent="addDropFile" @dragover.prevent>
    <v-card-title class="mb-1">{{ title }} </v-card-title>
    <v-card-subtitle v-if="subtitle" class="pb-1">
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text>
      <v-col cols="12" class="pa-0">
        <v-subheader style="font-size: 1rem" class="pl-0 pb-1">
          Who is this website for?
        </v-subheader>

        <agent-search
          v-model="widget.agent"
          label="Agent"
          include-assignable
          :clearable="false"
          :success="agentValidation.success"
          :error-messages="agentValidation.errorMessages"
          @input="handleAgentChange"
        />

        <v-subheader style="font-size: 1rem" class="pl-0 pb-1">
          What would you like to name your website?
        </v-subheader>
        <v-text-field
          data-lpignore="true"
          v-if="$vuetify.breakpoint.mdAndUp"
          v-model="widget.uri"
          key="uri-input"
          outlined
          dense
          placeholder="your-desired-name"
          class="prepend-slot"
          :loading="loadingSettings"
          :success="uriValidation.success"
          :error-messages="uriValidation.errorMessages"
        >
          <template #prepend-inner v-if="availableDomains.length">
            <v-select
              v-if="availableDomains.length > 1"
              item-text="domain"
              return-object
              v-model="widget.domain"
              hide-details
              solo
              flat
              style="max-width: 20rem"
              class="pl-2"
              :items="availableDomains"
            />
            <span class="prepend-text" v-else>
              {{ domainPrefix }}
            </span>
          </template>
        </v-text-field>
        <v-select
          v-else-if="availableDomains.length > 1"
          key="sm-domain-select"
          item-text="domain"
          return-object
          v-model="widget.domain"
          hide-details
          solo
          flat
          style="max-width: 20rem"
          class="pl-2"
          :items="availableDomains"
        />
        <v-text-field
          data-lpignore="true"
          v-else
          v-model="widget.uri"
          key="sm-input"
          outlined
          dense
          :label="domainPrefix"
          placeholder="your-desired-name"
          :loading="loadingSettings"
          :success="uriValidation.success"
          :error-messages="uriValidation.errorMessages"
        />
        <v-subheader style="font-size: 1rem" class="pl-0 pb-1">
          How would you like to greet your customers?
        </v-subheader>
        <v-textarea
          label="Greeting"
          outlined
          dense
          rows="2"
          auto-grow
          :prepend-inner-icon="mdiHumanGreeting"
          v-model="widget.greeting"
          :success="greetingValidation.success"
          :error-messages="greetingValidation.errorMessages"
        />
        <v-subheader style="font-size: 1rem" class="pl-0 pb-1">
          Would you like to use a custom image?
        </v-subheader>
        <v-row class="ma-0">
          <div style="margin-right: 12px">
            <v-card
              height="165px"
              width="165px"
              hover
              @click="widget.avatarType = 'default'"
            >
              <v-row class="ma-0 height-full" align="center" justify="center">
                <v-fade-transition mode="out-in">
                  <div
                    v-if="widget.avatarType === 'default'"
                    style="
                      position: absolute;
                      background-color: rgba(255, 255, 255, 0.85);
                      z-index: 1;
                      border-radius: 50%;
                      padding: 6px;
                    "
                  >
                    <v-icon x-large color="accent">
                      {{ mdiCheckboxMarkedCircleOutline }}
                    </v-icon>
                  </div>
                </v-fade-transition>
                <v-img
                  :src="widget.agent.assignable.avatar_url"
                  height="100%"
                  width="100%"
                  contain
                />
              </v-row>
            </v-card>
            <v-card-subtitle class="pa-1"> Current Avatar </v-card-subtitle>
          </div>
          <div style="margin-right: 12px">
            <v-card
              height="165px"
              width="165px"
              hover
              @click="handleCustomClick"
            >
              <v-row class="ma-0 height-full" align="center" justify="center">
                <v-fade-transition mode="out-in">
                  <div
                    v-if="widget.avatarType === 'custom'"
                    style="
                      position: absolute;
                      background-color: rgba(255, 255, 255, 0.85);
                      z-index: 1;
                      border-radius: 50%;
                      padding: 6px;
                    "
                  >
                    <v-icon x-large color="accent">
                      {{ mdiCheckboxMarkedCircleOutline }}
                    </v-icon>
                  </div>
                </v-fade-transition>
                <v-img
                  v-if="customAvatarSrc"
                  :src="customAvatarSrc"
                  height="100%"
                  width="100%"
                  contain
                />
                <v-icon v-else x-large>{{ mdiImagePlus }}</v-icon>
              </v-row>
            </v-card>
            <v-card-subtitle class="pa-1 pb-0">
              Custom Avatar
              <a v-if="customAvatarSrc" @click="openUploadFile"> (Change) </a>
            </v-card-subtitle>
            <v-file-input
              v-model="widget.customAvatar"
              style="display: none"
              id="avatar"
              :accept="ACCEPTABLE_IMAGE_EXTENSIONS"
            />
          </div>
          <div style="margin-right: 12px">
            <v-card
              height="165px"
              width="165px"
              hover
              @click="widget.avatarType = 'none'"
            >
              <v-row class="ma-0 height-full" align="center" justify="center">
                <v-fade-transition mode="out-in">
                  <v-icon
                    v-if="widget.avatarType === 'none'"
                    x-large
                    color="accent"
                    key="selected"
                  >
                    {{ mdiCheckboxMarkedCircleOutline }}
                  </v-icon>
                  <v-icon v-else x-large key="not-selected">
                    {{ mdiImageOff }}
                  </v-icon>
                </v-fade-transition>
              </v-row>
            </v-card>
            <v-card-subtitle class="pa-1"> No Avatar </v-card-subtitle>
          </div>
        </v-row>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :disabled="creating"
        @click="closeDialog"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        :loading="creating"
        @click="createQuoteAndApplySite"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions, mapState } from "pinia";
import { createApprovedDomain } from "@/api/approved-domain.service";

import AgentSearch from "@/components/shared/AgentSearch.vue";
import { getAgentElectronicApplicationSettings } from "@/api/agents.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import {
  mdiHumanGreeting,
  mdiImagePlus,
  mdiCheckboxMarkedCircleOutline,
  mdiImageOff
} from "@mdi/js";

const ACCEPTABLE_IMAGE_EXTENSIONS = ".png,.svg,.jpeg,.jpg,.webp";

export default {
  props: {
    title: {
      type: String,
      default: "New Quote & Apply Website"
    },
    subtitle: {
      type: String,
      default:
        "Add Quote & Apply to your website for customers to purchase life insurance 24/7."
    }
  },
  components: {
    AgentSearch
  },
  data() {
    return {
      widget: {
        uri: "",
        greeting: "",
        agent: {},
        domain: "",
        customAvatar: null,
        avatarType: "default"
      },
      errors: {
        uri: ""
      },
      customAvatarSrc: null,
      isMounted: false,
      creating: false,
      customDomains: [],
      loadingSettings: false,
      ACCEPTABLE_IMAGE_EXTENSIONS,
      mdiHumanGreeting,
      mdiImagePlus,
      mdiCheckboxMarkedCircleOutline,
      mdiImageOff
    };
  },
  created() {
    let agent = this.loginable;
    if (this.isAgency) {
      agent = {
        id: this.loginable.signer.id,
        name: this.loginable.signer.name,
        email: this.loginable.signer.email,
        assignable: {
          type: "Agency",
          name: this.loginable.name,
          avatar_url: this.loginable.avatar_url
        }
      };
    }
    this.$set(this.widget, "agent", agent);

    this.initializeGreeting();
    this.getElectronicApplicationSettings();
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  computed: {
    ...mapState(useUserStore, ["loginable"]),
    potentialURL() {
      return `https://${this.widget.domain.domain}/${this.widget.uri}`;
    },
    isAgency() {
      return this.loginable.type === "Agency";
    },
    agentValidation() {
      const success = !this.$v.widget.agent.$invalid;
      const errorMessages = [];
      if (!this.$v.widget.agent.$dirty) return { success, errorMessages };
      else if (!this.$v.widget.agent.required) errorMessages.push("Required");
      else if (!this.$v.widget.agent.availableDomains)
        errorMessages.push("Agent has no Domains. Please Change this Agent");
      return { success, errorMessages };
    },
    uriValidation() {
      const success = !this.$v.widget.uri.$invalid;
      const errorMessages = [];
      if (!this.$v.widget.uri.$dirty) return { success, errorMessages };
      if (!this.$v.widget.uri.required) errorMessages.push("Required");
      if (!this.$v.widget.uri.validUri)
        errorMessages.push(
          "Can only contain letters, numbers, underscores, and dashes"
        );
      if (!this.$v.widget.uri.serverError) errorMessages.push(this.errors.uri);
      return { success, errorMessages };
    },
    greetingValidation() {
      const success = !this.$v.widget.greeting.$invalid;
      const errorMessages = [];
      if (!this.$v.widget.greeting.$dirty) return { success, errorMessages };
      if (!this.$v.widget.greeting.required) errorMessages.push("Required");
      return { success, errorMessages };
    },
    domainPrefix() {
      if (!this.widget.domain?.domain) return null;
      return `https://${this.widget.domain.domain}/`;
    },
    availableDomains() {
      return this.customDomains || [];
    }
  },
  watch: {
    "widget.uri"() {
      this.errors.uri = "";
    },
    "widget.customAvatar"() {
      this.widget.avatarType = "custom";
      this.customAvatarSrc = URL.createObjectURL(this.widget.customAvatar);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, [
      "showErrorSnackbar",
      "showSuccessSnackbar"
    ]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    addDropFile(e) {
      const options = ACCEPTABLE_IMAGE_EXTENSIONS.split(",").map(v => v.trim());
      const validFiles = Array.from(e.dataTransfer.files).filter(file => {
        return options.some(extension =>
          file.name.toLowerCase().endsWith(extension)
        );
      });
      if (!validFiles.length) return;

      this.$set(this.widget, "customAvatar", validFiles[0]);
    },
    handleCustomClick() {
      if (this.widget.customAvatar) this.widget.avatarType = "custom";
      else this.openUploadFile();
    },
    openUploadFile() {
      document.getElementById("avatar").click();
    },
    handleAgentChange() {
      if (!this.isMounted) return;
      this.getElectronicApplicationSettings();
      this.initializeGreeting();
    },
    initializeGreeting() {
      if (this.widget.agent.assignable.type === "Agency") {
        this.widget.greeting = `Welcome to ${this.widget.agent.assignable.name}! We'll get your life insurance quotes in seconds. Ready to go?`;
      } else {
        this.widget.greeting = `Hi, I'm ${this.widget.agent.name}! I'll get your life insurance quotes in seconds. Ready to go?`;
      }
    },
    async getElectronicApplicationSettings() {
      if (!this.widget.agent.id) return [];
      this.loadingSettings = true;
      try {
        const { customDomains } = await getAgentElectronicApplicationSettings(
          this.widget.agent.id
        );
        this.$set(this, "customDomains", customDomains);
        this.widget.domain = customDomains[0];
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loadingSettings = false;
      }
    },
    async createQuoteAndApplySite() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.creating = true;
      try {
        let avatar = undefined;
        if (this.widget.avatarType === "custom") {
          avatar = this.widget.customAvatar;
        } else if (this.widget.avatarType === "none") {
          avatar = null;
        }
        const body = {
          domain: this.potentialURL,
          custom_domain_id: this.widget.domain.id,
          greeting: this.widget.greeting,
          avatar
        };

        const { id } = await createApprovedDomain(body);

        this.$router.push({
          name: "ApprovedDomains",
          params: { id }
        });
        this.closeDialog();
      } catch (e) {
        const message = parseErrorMessage(e);
        if (
          message?.toLowerCase()?.includes("domain path has already been taken")
        ) {
          this.errors.uri = `${this.potentialURL} has already been taken`;
          this.showErrorSnackbar({
            message: this.errors.uri,
            timeout: 10000
          });
        } else {
          this.showErrorSnackbar({
            message: parseErrorMessage(e),
            timeout: 10000
          });
        }
      } finally {
        this.creating = false;
      }
    }
  },
  validations() {
    return {
      widget: {
        uri: {
          required,
          validUri: v => {
            //if value contains something other than numbers, letters, underscores, or dashes it fails
            const validator = new RegExp(/^[A-Za-z0-9_-]*$/);
            return validator.test(v);
          },
          serverError: () => !this.errors.uri
        },
        agent: {
          required,
          availableDomains: () => this.availableDomains.length > 0
        },
        greeting: { required }
      }
    };
  }
};
</script>
