<template>
  <v-row :data-testid="props.dataTestid" class="ma-0" dense>
    <v-col cols="12">
      <v-row class="ma-0">
        <v-select
          v-model="impairment.concern"
          :prepend-inner-icon="mdiHeartPulse"
          outlined
          dense
          placeholder="Choose a Concern"
          label="Concern"
          :data-testid="`${dataTestid}-concern`"
          :items="IMPAIRMENT_BASIC_CONCERNS"
          :success="concernValidation.success"
          :error-messages="concernValidation.errorMessages"
        />
        <v-btn
          depressed
          class="text-none"
          icon
          :data-testid="`${dataTestid}-delete`"
          @click="$emit('delete-impairment')"
        >
          <v-icon color="error">{{ mdiDelete }}</v-icon>
        </v-btn>
      </v-row>
    </v-col>
    <template v-if="impairment.concern && impairment.concern !== 'Non Medical'">
      <v-col cols="12">
        <v-text-field
          v-model="impairment.age"
          data-lpignore="true"
          :prepend-inner-icon="mdiCalendar"
          type="text"
          inputmode="numeric"
          outlined
          dense
          label="Age at Onset"
          :data-testid="`${dataTestid}-age`"
          :success="ageValidation.success"
          :error-messages="ageValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="impairment.diagnosis"
          :prepend-inner-icon="mdiStethoscope"
          outlined
          dense
          label="Diagnosis"
          :data-testid="`${dataTestid}-diagnosis`"
          :success="diagnosisValidation.success"
          :error-messages="diagnosisValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="impairment.treatment"
          :prepend-inner-icon="mdiBottleTonicPlus"
          outlined
          dense
          label="Treatment"
          :data-testid="`${dataTestid}-treatment`"
          :success="treatmentValidation.success"
          :error-messages="treatmentValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12" v-if="impairment.concern === 'Cancer'">
        <v-textarea
          :prepend-inner-icon="mdiFileDocument"
          outlined
          dense
          label="Pathology Report"
          :data-testid="`${dataTestid}-pathology`"
          :success="pathologyReportValidation.success"
          :error-messages="pathologyReportValidation.errorMessages"
          v-model="impairment.pathologyReport"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="impairment.other"
          :prepend-inner-icon="mdiTextBox"
          outlined
          dense
          label="Other Information"
          success
          :data-testid="`${dataTestid}-other`"
        />
      </v-col>
    </template>
    <template v-else-if="impairment.concern === 'Non Medical'">
      <v-col cols="12">
        <v-textarea
          v-model="impairment.other"
          :prepend-inner-icon="mdiTextBox"
          outlined
          dense
          label="Other Information"
          :data-testid="`${dataTestid}-other`"
          :success="otherValidation.success"
          :error-messages="otherValidation.errorMessages"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script setup>
import {
  IMPAIRMENT_BASIC_CONCERNS,
  NewImpairedRiskQuoteImpairment
} from "@/factories/ImpairedRiskQuote";
import { validationComputeV2 } from "@/util/helpers";
import { computed, defineProps, ref, watch, defineEmits } from "vue";
import useVuelidate from "@vuelidate/core";
import {
  mdiHeartPulse,
  mdiDelete,
  mdiCalendar,
  mdiStethoscope,
  mdiBottleTonicPlus,
  mdiFileDocument,
  mdiTextBox
} from "@mdi/js";

const props = defineProps({
  value: { type: Object, required: true },
  dataTestid: String
});

const emit = defineEmits(["input"]);

const impairment = ref(NewImpairedRiskQuoteImpairment());

const v$ = useVuelidate(
  {
    impairment: {
      concern: { required: Boolean },
      diagnosis: {
        required: (v, vm) => vm.concern === "Non Medical" || Boolean(v)
      },
      age: {
        required: (v, vm) => vm.concern === "Non Medical" || Boolean(v)
      },
      treatment: {
        required: (v, vm) => vm.concern === "Non Medical" || Boolean(v)
      },
      other: {
        required: (v, vm) => vm.concern !== "Non Medical" || Boolean(v)
      },
      pathologyReport: {
        required: (v, vm) => vm.concern !== "Cancer" || Boolean(v)
      }
    }
  },
  { impairment },
  { $autoDirty: true, $scope: "impaired-risk-quote" }
);

const concernValidation = computed(() => {
  const model = v$.value.impairment.concern;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const ageValidation = computed(() => {
  const model = v$.value.impairment.age;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const diagnosisValidation = computed(() => {
  const model = v$.value.impairment.diagnosis;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const treatmentValidation = computed(() => {
  const model = v$.value.impairment.treatment;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const pathologyReportValidation = computed(() => {
  const model = v$.value.impairment.pathologyReport;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});
const otherValidation = computed(() => {
  const model = v$.value.impairment.other;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

watch(
  impairment,
  () => {
    emit("input", impairment.value);
  },
  { deep: true }
);

function initialize() {
  impairment.value.uuid = props.value.uuid;
  impairment.value.concern = props.value.concern;
  impairment.value.diagnosis = props.value.diagnosis;
  impairment.value.age = props.value.age;
  impairment.value.treatment = props.value.treatment;
  impairment.value.other = props.value.other;
}

initialize();
</script>
