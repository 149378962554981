<template>
  <div class="pa-0 ma-0" data-testid="file-drag-and-drop">
    <v-textarea
      readonly
      dense
      no-resize
      outlined
      v-cloak
      rows="2"
      :prepend-inner-icon="prependInnerIcon"
      :success="success"
      :error-messages="errorMessages"
      :label="label ? label : ''"
      :placeholder="placeholder"
      :disabled="disabled || uploading || loading"
      :loading="uploading || loading"
      :persistent-hint="Boolean($slots.message)"
      :hint="$slots.message ? ' ' : null"
      :hide-details="hideDetails"
      @click="openFileInput"
      @drop.prevent="addDropFile"
      @dragover.prevent
      @blur="$emit('blur')"
    >
      <template #append>
        <v-progress-circular v-if="uploading" indeterminate />
        <v-file-input
          v-else
          v-model="readonlyFiles"
          ref="fileInput"
          hide-input
          hide-details
          class="pt-0 mt-0"
          :multiple="multiple"
          :disabled="disabled || uploading || loading"
          :accept="accept"
        />
      </template>
      <template #append-outer>
        <slot name="append-outer" />
      </template>
      <template #message="{ message }">
        <v-row class="ma-0">
          {{ message }}
          <v-spacer />
          <slot name="message" />
        </v-row>
      </template>
    </v-textarea>
    <v-row v-if="files.length > 0" class="pa-3 pt-0" align="center">
      <v-chip-group column>
        <v-chip
          v-for="(file, index) in files"
          pill
          close
          :close-icon="mdiClose"
          color="accent"
          :key="index"
          :disabled="disabled || uploading || loading"
          @click:close="removeFile(index)"
        >
          {{ file.name }}
        </v-chip>
      </v-chip-group>
    </v-row>
  </div>
</template>

<script>
import { mdiClose } from "@mdi/js";
import capitalize from "lodash/capitalize";
import { ALL_FILE_EXTENSIONS } from "@/data/allowed-file-extensions";
export default {
  props: {
    prependInnerIcon: String,
    multiple: Boolean,
    success: Boolean,
    errorMessages: Array,
    label: String,
    value: [Array, File],
    disabled: Boolean,
    accept: {
      type: String,
      default: ALL_FILE_EXTENSIONS
    },
    upload: Boolean,
    uploadFunc: Function,
    loading: Boolean,
    hideDetails: Boolean
  },
  data() {
    let files = [];

    if (this.value) {
      if (Array.isArray(this.value)) {
        files = files.concat(this.value);
      } else {
        files.push(this.value);
      }
    }

    return {
      fileOrFiles: this.multiple ? "files" : "file",
      files,
      uploading: false,
      mdiClose
    };
  },
  watch: {
    value(val) {
      this.$nextTick(() => {
        if (Array.isArray(val)) {
          this.files = val;
          return;
        }
        this.files = [val].filter(Boolean);
      });
    },
    files: {
      deep: true,
      handler() {
        const files = this.multiple ? this.files : this.files[0];
        this.$emit("input", files);
        if (!this.upload) return;
        if (!files || (Array.isArray(files) && !files.length)) return;
        this.uploading = true;
        this.uploadFunc(this.files).finally(() => {
          this.uploading = false;
        });
      }
    }
  },
  computed: {
    readonlyFiles: {
      get() {
        return this.files;
      },
      set(val) {
        let newFile = val;
        if (!Array.isArray(val)) {
          newFile = [val].filter(Boolean);
        }
        if (!this.multiple) {
          this.files = newFile;
          return;
        }
        this.files = this.files.concat(newFile);
      }
    },
    placeholder() {
      if (this.files.length === 0)
        return `Drag and drop your ${this.fileOrFiles} here or click to upload`;

      let fileNames = "";

      this.files.forEach(file => {
        fileNames += `${file.name}, `;
      });

      return `${capitalize(this.fileOrFiles)} to be uploaded: ${fileNames
        .trim()
        .slice(0, -1)}.`;
    }
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    addDropFile(e) {
      if (this.disabled) return;

      let validatedFiles = Array.from(e.dataTransfer.files);
      const acceptableExtensions = this.accept.split(",").filter(Boolean);
      validatedFiles = validatedFiles.filter(val => {
        const split = val.name.toLowerCase().split(".").filter(Boolean);
        if (split.length === 1) return false;
        const extension = `.${split[split.length - 1].trim().toLowerCase()}`;
        const isValid = acceptableExtensions.some(
          val => val.trim().localeCompare(extension) === 0
        );

        return isValid;
      });
      if (!validatedFiles.length) return;
      if (!this.multiple) {
        this.files = [validatedFiles[0]];
      } else {
        this.files.push(...validatedFiles);
      }
    },
    removeFile(index) {
      if (this.disabled) return;
      this.files.splice(index, 1);
      return;
    }
  }
};
</script>
