<template>
  <div
    style="position: fixed; right: 15px; z-index: 10"
    :style="{ bottom: isMobile ? '60px' : '15px' }"
  >
    <v-btn
      rounded
      color="accent"
      class="text-none"
      :class="{ 'px-0': isMobile }"
      :style="{ 'min-width': isMobile ? '36px !important' : undefined }"
      @click="$emit('click')"
    >
      <v-icon>{{ mdiHelpCircleOutline }}</v-icon>
      <div v-if="!isMobile" class="ml-1">Need Help?</div>
    </v-btn>
  </div>
</template>

<script setup>
import { useVuetify } from "@/composables/compatible.composables";

import { useZendeskStore } from "@/stores/zendesk";
import { computed, onBeforeUnmount, onMounted } from "vue";
import { mdiHelpCircleOutline } from "@mdi/js";

const vuetify = useVuetify();

const isMobile = computed(() => vuetify.breakpoint.smAndDown);

const zendesk = useZendeskStore();
onMounted(() => {
  zendesk.hide();
});

onBeforeUnmount(() => {
  zendesk.show();
});
</script>
