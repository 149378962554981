<template>
  <v-card>
    <v-card-text>
      <v-row class="ma-0" align="center">
        <v-tabs style="width: unset" v-model="activeTab">
          <v-tab
            v-for="tab in TABS"
            class="text-none text-h5"
            :key="tab.key + 'title'"
          >
            <v-icon class="mr-1">{{ tab.icon }}</v-icon> {{ tab.text }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <generic-table-stats v-if="stats" :stats="stats" end />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              @click="TABS[activeTab].getData"
            >
              <v-icon>{{ mdiRefresh }}</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
        <v-tooltip bottom v-if="TABS[activeTab].canEmail">
          <template #activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon @click="confirmAndEmailData">
              <v-icon>{{ mdiFileExport }}</v-icon>
            </v-btn>
          </template>
          <span>Email a CSV</span>
        </v-tooltip>
      </v-row>
      <v-tabs-items class="px-0" v-model="activeTab" touchless>
        <v-tab-item v-for="tab in TABS" :key="tab.key + 'body'">
          <div class="text-subtitle-1 grey--text mt-3">
            {{ tab.description }}
          </div>
          <component
            :is="tab.component"
            @stats="val => (tab.stats = val)"
            @can-email="val => (tab.canEmail = val)"
            @get-data-func="val => (tab.getData = val)"
            @email-data-func="val => (tab.emailData = val)"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>
<script>
import {
  mdiRefresh,
  mdiFileExport,
  mdiCheckbook,
  mdiCalendarWeek
} from "@mdi/js";
import GenericTableStats from "@/components/shared/data-table/GenericTableStats.vue";
import Payments from "@/components/commissions/Payments.vue";
import PayPeriods from "@/components/commissions/PayPeriods.vue";
import { mapActions } from "pinia";
import { useDialogStore } from "@/stores/dialog";
const TABS = [
  {
    key: "payments",
    text: "Payments",
    icon: mdiCheckbook,
    component: "Payments",
    description:
      "A Payment is any payment from a carrier or BackNine. Payments from BackNine are grouped into Statements.",
    getData: () => {},
    emailData: () => {},
    canEmail: false,
    stats: []
  },
  {
    key: "pay-periods",
    text: "BackNine Statements",
    icon: mdiCalendarWeek,
    component: "PayPeriods",
    description:
      "A Statement is the summation of Payments paid by BackNine. Statements from carriers are available on the carriers' websites.",
    getData: () => {},
    stats: []
  }
];
export default {
  name: "CommissionsView",
  components: { PayPeriods, Payments, GenericTableStats },
  data() {
    return {
      TABS,
      activeTab: 0,
      activeStats: null,
      viewingOwnResources: false,
      mdiRefresh,
      mdiFileExport
    };
  },
  created() {
    if (!this.$route.query.page) return;

    const viewingPayments = this.$route.query.page === "payments";
    const page = viewingPayments ? "payments" : "pay-periods";
    if (page !== this.$route.query.page) {
      this.$router.replace({ query: { page } });
    }

    this.activeTab = TABS.findIndex(val => val.key === this.$route.query.page);
  },
  computed: {
    computedActiveTab() {
      return this.TABS[this.activeTab];
    },
    stats() {
      return this.computedActiveTab.stats;
    }
  },
  watch: {
    activeTab() {
      const page = this.activeTab ? "pay-periods" : "payments";
      if (page === this.$route.query.page) return;
      this.$router.replace({ query: { page } });
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    confirmAndEmailData() {
      this.showDialog({
        component: "ConfirmationDialog",
        title: "Email Data?",
        subtitle: "Please confirm you would like this data emailed to you.",
        func: () => this.TABS[this.activeTab].emailData()
      });
    }
  }
};
</script>
