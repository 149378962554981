<template>
  <v-card>
    <v-card-title data-tesid="dialog-title">
      {{ editing ? "Update" : "Add" }} Address History Record
    </v-card-title>
    <v-card-subtitle>
      <template v-if="editing">
        Make any necessary changes to this address history record and then press
        "Save".
      </template>
      <template v-else>
        Enter information about a current or previous address history and then
        press "Create"
      </template>
    </v-card-subtitle>
    <v-card-text id="address-container">
      <v-row dense class="my-0">
        <template v-if="!excludeDates">
          <v-col cols="12" md="6">
            <date-input
              v-model="address.startDate"
              label="When Did You Start Living at This Home Address"
              data-testid="start-date"
              outlined
              dense
              autofocus
              clearable
              :success="startDateValidation.success"
              :error-messages="startDateValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-input
              v-model="address.endDate"
              label="End Date (Leave Blank if Current)"
              data-testid="end-date"
              outlined
              dense
              clearable
              :success="endDateValidation.success && Boolean(address.endDate)"
              :error-messages="endDateValidation.errorMessages"
            />
          </v-col>
        </template>
        <v-col cols="12">
          <basic-address-input
            autofill-label="Physical Address"
            autofill-placeholder="Physical Address"
            data-testid="address-item"
            :street-address-model.sync="address.street_address"
            :state-model.sync="address.state"
            :city-model.sync="address.city"
            :zip-model.sync="address.zip"
            :street-address-validation="streetValidation"
            :state-validation="stateValidation"
            :city-validation="cityValidation"
            :zip-validation="zipValidation"
            @autofill="handleAutofill"
          />
        </v-col>
      </v-row>
      <v-checkbox
        v-if="previousAddress"
        v-model="willUpdatePreviousAddress"
        data-testid="checkbox-update-previous"
        success
        hide-details
      >
        <template #label>
          <template v-if="address.startDate">
            Did you move out of {{ previousAddress }} on
            <timestamp-formatter :value="address.startDate" class="ml-1" />?
          </template>
          <template v-else>
            Did you move out of {{ previousAddress }}?
          </template>
        </template>
      </v-checkbox>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        :loading="loading"
        data-testid="save-address"
        @click="save"
      >
        <template v-if="editing"> Save </template>
        <template v-else> Create </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import DateInput from "@/components/shared/DateInput.vue";

import { computedValidation, parseErrorMessage } from "@/util/helpers";

import { setRawFromAddress, Address } from "@/factories/AddressFactory";

import addMonths from "date-fns/addMonths";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import subYears from "date-fns/subYears";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { isSameDay } from "date-fns";
import { computed, ref, defineProps, nextTick } from "vue";
import useVuelidate from "@vuelidate/core";
import { useVuetify } from "@/composables/compatible.composables";

const easyParse = v => {
  try {
    const parsed = parse(v, "yyyy-MM-dd", new Date());
    if (!isValid(parsed)) return null;
    return parsed;
  } catch (e) {
    return null;
  }
};

const props = defineProps({
  excludeDates: Boolean, // used by business address
  minStartDate: String,
  newAddress: {
    type: Object,
    required: true
  },
  previousAddress: {
    type: String,
    required: false,
    default: ""
  },
  previousAddressId: {
    type: Number,
    required: false,
    default: 0
  },
  updateFunc: Function,
  createFunc: Function
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const vuetify = useVuetify();

const willUpdatePreviousAddress = ref(
  Boolean(props.previousAddressId && !props.newAddress.id)
);
const address = ref(Address(props.newAddress));
const editing = Boolean(props.newAddress.id);
const loading = ref(false);
const actualMinStartDate = computed(() => {
  let minStartDate = subYears(new Date(), 150);
  if (props.minStartDate) {
    minStartDate = easyParse(props.minStartDate, "yyyy-MM-dd", new Date());
  }
  return minStartDate;
});

const v$ = useVuelidate(
  {
    address: {
      startDate: {
        required: v => props.excludeDates || Boolean(easyParse(v)),
        minStartDate: v => {
          // start date cannot be before the addressable's birthdate if present
          // If addressable's birthdate is blank, start date cannot be more than 150 years ago
          if (props.excludeDates) return true;

          const startDate = easyParse(v, "yyyy-MM-dd", new Date());
          if (!startDate) return false;
          return (
            isAfter(startDate, actualMinStartDate.value) ||
            isSameDay(startDate, actualMinStartDate.value)
          );
        },
        beforeEndDate: (v, vm) => {
          // start date cannot be after end date

          if (props.excludeDates) return true;
          if (!vm.endDate) return true;
          const startDate = easyParse(v, "yyyy-MM-dd", new Date());
          const endDate = easyParse(vm.endDate, "yyyy-MM-dd", new Date());
          if (!endDate || !startDate) return false;
          return isBefore(startDate, endDate);
        }
      },
      endDate: {
        atMost6Months: v => {
          // end date cannot be more than 6 months from now
          if (props.excludeDates) return true;
          if (!v) return true;
          const endDate = easyParse(v, "yyyy-MM-dd", new Date());
          if (!endDate) return false;
          return isBefore(endDate, addMonths(new Date(), 6));
        }
      }, //optional
      street_address: { required: Boolean },
      state: { required: Boolean },
      city: { required: Boolean },
      zip: { validLength: v => `${v}`.length === 5 }
    }
  },
  { address },
  { $scope: null, $autoDirty: true }
);

const startDateValidation = computedValidation(v$.value.address.startDate, {
  required: "Required",
  minStartDate: "Cannot be before birthdate",
  beforeEndDate: "Must be before end date"
});

const endDateValidation = computedValidation(v$.value.address.endDate, {
  atMost6Months: "Cannot be more than 6 months in the future"
});

const streetValidation = computedValidation(v$.value.address.street_address, {
  required: "Required"
});

const stateValidation = computedValidation(v$.value.address.state, {
  required: "Required"
});

const cityValidation = computedValidation(v$.value.address.city, {
  required: "Required"
});

const zipValidation = computedValidation(v$.value.address.zip, {
  validLength: "Required"
});

function handleAutofill({ street_address, state, city, zip }) {
  address.value.street_address = street_address;
  address.value.state = state;
  address.value.city = city;
  address.value.zip = zip;
}
function goToInvalid() {
  const els = document.getElementsByClassName("v-input error--text");
  if (!els?.length) return;
  vuetify.goTo(els[0], {
    container: "#address-container"
  });
}
async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    nextTick(() => goToInvalid());
    return;
  }
  loading.value = true;
  try {
    let func = createAddress;
    if (editing) func = updateAddress;
    if (willUpdatePreviousAddress.value) await updatePreviousAddress();
    const newAddress = await func();
    dialog.closeDialog({
      address: newAddress,
      updatedPreviousAddress: willUpdatePreviousAddress.value
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
function createAddress() {
  return props.createFunc(setRawFromAddress(address.value));
}
function updateAddress() {
  return props.updateFunc(address.value.id, setRawFromAddress(address.value));
}
function updatePreviousAddress() {
  return props.updateFunc(props.previousAddressId, {
    end_date: address.value.startDate
  });
}
</script>
