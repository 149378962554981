<template>
  <v-text-field
    v-model="model"
    type="email"
    :prepend-inner-icon="mdiEmailOutline"
    outlined
    data-lpignore="true"
    inputmode="email"
    :clearable="clearable"
    :persistent-hint="persistentHint"
    :hint="hint"
    :dense="dense"
    :disabled="disabled"
    :data-testid="dataTestid"
    :loading="validating"
    :autofocus="autofocus"
    :label="label"
    :success="success"
    :error-messages="validating || isTyping ? [] : errorMessages"
    @input="handleTyping"
  >
    <template v-if="$scopedSlots['append-outer']" #append-outer>
      <slot name="append-outer" />
    </template>
    <template v-if="$scopedSlots['message']" #message>
      <slot name="message" />
    </template>
  </v-text-field>
</template>

<script>
import { validateEmail } from "@/api/boss.service";
import { email as emailValidator } from "@/util/vuelidateValidators";
import { mdiEmailOutline } from "@mdi/js";
export default {
  name: "BasicEmailInput",
  props: {
    dense: {
      type: Boolean,
      default: true
    },
    clearable: Boolean,
    disabled: Boolean,
    autofocus: Boolean,
    success: Boolean,
    persistentHint: Boolean,
    hint: String,
    errorMessages: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    dataTestid: {
      type: String,
      default: ""
    },
    validateOnCreated: Boolean
  },
  emits: ["input", "valid"],
  data() {
    return {
      model: this.value,
      emailTimer: null,
      emailLoading: false,
      validating: false,
      typingTimer: null,
      isTyping: false,
      mdiEmailOutline
    };
  },
  created() {
    if (this.validateOnCreated && this.model) this.validate();
  },
  watch: {
    value() {
      if (this.value === this.model) return;
      this.model = this.value;
    }
  },
  methods: {
    handleTyping() {
      this.isTyping = true;
      this.$emit("valid", null);
      if (this.typingTimer) clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.isTyping = false;
      }, 500);

      this.$emit("input", this.model);
      this.debounceAndValidate();
    },
    debounceAndValidate() {
      if (this.emailTimer) clearTimeout(this.emailTimer);
      this.emailTimer = setTimeout(this.validate, 300);
    },
    async validate() {
      if (!this.model && this.clearable) {
        this.$emit("valid", true);
        return;
      }
      if (!emailValidator(this.model)) {
        this.$emit("valid", false);
        return;
      }

      this.validating = true;
      let valid;
      valid = await validateEmail({ email: this.model });
      this.$emit("valid", valid);
      this.validating = false;
    }
  }
};
</script>
