<template>
  <v-autocomplete
    v-model="model"
    outlined
    dense
    return-object
    no-filter
    hide-no-data
    item-text="domain"
    :data-testid="dataTestid"
    :disabled="disabled"
    :prepend-inner-icon="prependInnerIcon"
    :loading="loading"
    :placeholder="placeholder"
    :label="label"
    :search-input.sync="search"
    :success="success"
    :error-messages="errorMessages"
    :items="items"
    :clearable="clearable"
    :hide-details="hideDetails"
    :persistent-hint="persistentHint"
    :hint="hint"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    ref="searchInput"
    @blur="$emit('blur')"
    @click:clear="$emit('input', {})"
  >
    <template #append>
      <slot name="append" />
    </template>
    <template #append-outer>
      <slot name="append-outer" />
    </template>
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }} <v-spacer /> <slot name="message" />
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script setup>
import { getDefaultableApprovedDomains } from "@/api/agents.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import {
  defineProps,
  ref,
  watch,
  defineExpose,
  defineEmits,
  onMounted,
  nextTick
} from "vue";
import { mdiAccount } from "@mdi/js";
const props = defineProps({
  success: Boolean,
  errorMessages: Array,
  value: Object,
  disabled: Boolean,
  persistentHint: Boolean,
  hideDetails: Boolean,
  hint: String,
  clearable: Boolean,
  agentId: { type: [Number, String], required: true },
  dataTestid: {
    type: String,
    required: false,
    default: "approved-domain-search"
  },
  label: {
    required: false,
    default: "Approved Domain",
    type: String
  },
  placeholder: {
    required: false,
    default: "Search Approved Domains",
    type: String
  },
  prependInnerIcon: {
    type: String,
    required: false,
    default: mdiAccount
  }
});

const emit = defineEmits(["input"]);

const items = ref([]);
const model = ref(null);
const search = ref("");
const isMounted = ref(false);
let timer = null;
const loading = ref(false);

function isSettableValue(v) {
  return v && Object.keys(v).length && Object.values(v).filter(Boolean).length;
}

const preExistingValue = isSettableValue(props.value);
if (preExistingValue) {
  items.value.push(props.value);
  model.value = props.value;
}

watch(
  () => props.value,
  value => {
    if (isSettableValue(value)) {
      model.value = value;
      items.value.splice(0, items.value.length);
      items.value.push(value);
    } else {
      model.value = null;
      items.value.splice(0, items.value.length);
    }
  }
);

watch(model, () => {
  if (!model.value?.id && !props.clearable) return;
  if (model.value?.id === props.value?.id) return;
  emit("input", model.value);
});

watch(search, () => {
  if (!isMounted.value) return;
  if (items.value.some(v => v.domain === search.value)) return;
  if (search.value) debounceAndSearch();
});

const snackbar = useSnackbarStore();

function debounceAndSearch() {
  if (timer) clearTimeout(timer);

  timer = setTimeout(() => searchAdvisors(), 200);
}
async function searchAdvisors() {
  loading.value = true;

  const params = new URLSearchParams();
  params.append("domain", search.value);

  try {
    const domains = await getDefaultableApprovedDomains(props.agentId, params);
    items.value.splice(0, items.value.length);

    items.value.push(...domains);
    items.value.sort((a, b) => a.domain.localeCompare(b.domain));
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

const searchInput = ref(null); //template ref

function focus() {
  searchInput.$refs.input.focus();
}

defineExpose({ focus });

onMounted(() => nextTick(() => (isMounted.value = true)));
</script>
