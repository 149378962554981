<template>
  <v-card>
    <v-card-title v-if="affiliation.id">Update an Affiliation</v-card-title>
    <v-card-title v-else>Create an Affiliation</v-card-title>
    <v-card-subtitle>Affiliations are for new agent sign ups.</v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="affiliation.name"
            data-lpignore="true"
            data-testid="affiliation-name"
            label="Name"
            :prepend-inner-icon="mdiCube"
            outlined
            dense
            :error-messages="nameValidation.errorMessages"
            :success="nameValidation.success"
            :disabled="creating"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="affiliation.category"
            label="Category"
            data-testid="affiliation-category"
            :prepend-inner-icon="mdiTag"
            outlined
            dense
            :error-messages="categoryValidation.errorMessages"
            :success="categoryValidation.success"
            :items="AFFILIATION_CATEGORIES"
            :disabled="creating"
          />
        </v-col>
        <v-col cols="12">
          <advisor-search
            v-model="affiliation.ownable"
            data-testid="affiliation-linked-advisor"
            label="Linked Advisor (Optional)"
            :prepend-inner-icon="mdiDomain"
            outlined
            dense
            clearable
            :success="Boolean(affiliation.ownable?.id)"
            :disabled="creating"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :disabled="creating"
        @click="dialog.closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        data-testid="affiliation-create"
        :loading="creating"
        @click="save"
      >
        <template v-if="affiliation.id"> Update </template>
        <template v-else> Create </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";

import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";

import {
  createAffiliation,
  updateAffiliation
} from "@/api/affiliation.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import {
  Affiliation,
  AFFILIATION_CATEGORIES
} from "@/factories/AffiliationFactory";

import { computed, defineProps, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { mdiCube, mdiTag, mdiDomain } from "@mdi/js";

const props = defineProps({ affiliation: Object });

const affiliation = ref(Affiliation());
const creating = ref(false);

if (props.affiliation?.id) {
  affiliation.value.category = props.affiliation.category;
  affiliation.value.id = props.affiliation.id;
  affiliation.value.ownable = props.affiliation.ownable;
  affiliation.value.name = props.affiliation.name;
}

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const v$ = useVuelidate(
  {
    affiliation: {
      name: { required: Boolean },
      category: { required: Boolean }
    }
  },
  { affiliation },
  { $autoDirty: true, $scope: false }
);

const nameValidation = computed(() => {
  const model = v$.value.affiliation.name;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

const categoryValidation = computed(() => {
  const model = v$.value.affiliation.category;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  creating.value = true;

  try {
    if (affiliation.value.id) {
      await updateAffiliation(affiliation.value);
    } else {
      await createAffiliation([affiliation.value]);
    }
    dialog.closeDialog({ created: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    creating.value = false;
  }
}
</script>
