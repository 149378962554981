<template>
  <v-row class="ma-0">
    <v-col cols="12" class="px-0">
      <v-card outlined v-if="canCreateParentQuestion || parentQuestion">
        <!-- Parent Questions -->
        <v-card-title> Parent Question: <v-spacer /> </v-card-title>
        <v-list class="pa-3" v-if="parentQuestion">
          <v-list-item link @click="$emit('view', parentQuestion.id)">
            <v-list-item-content>
              <v-list-item-title>
                {{ parentQuestion.id }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="activeField.requiredParentValue">
                This question is displayed if
                <strong class="accent--text">
                  {{ activeField.requiredParentValue }}
                </strong>
                is selected
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                Missing required value
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="!fieldHasPositionalData(parentQuestion)">
              <v-row class="pa-3" align="center">
                <v-icon class="mr-1"> {{ mdiFileHidden }} </v-icon> Field not on
                PDF
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <!-- Child Questions -->

    <v-col
      cols="12"
      class="px-0"
      v-if="canCreateChildQuestions || childQuestions.length"
    >
      <v-card outlined>
        <v-card-title>
          Child Questions: <v-spacer />
          <slot v-if="canCreateChildQuestions" name="add-child" />
        </v-card-title>
        <v-list class="pa-3" v-if="childQuestions.length">
          <v-list-item
            v-for="question in childQuestions"
            :key="question.id"
            link
            @click="$emit('view', question.id)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ question.id }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="question.requiredParentValue">
                If
                <strong class="accent--text">
                  {{ question.requiredParentValue }}
                </strong>
                is selected
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                Missing required value
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="!fieldHasPositionalData(question)">
              <v-row class="pa-3" align="center">
                <v-icon class="mr-1"> {{ mdiFileHidden }} </v-icon> Field not on
                PDF
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiFileHidden } from "@mdi/js";
import { useFormMappingStore } from "@/stores/form-mapping";
import { storeToRefs } from "pinia";
export default {
  props: {
    readonly: Boolean,
    formId: [String, Number]
  },
  setup({ formId }) {
    const formStore = useFormMappingStore(formId);

    const { activeField, fields } = storeToRefs(formStore);

    return {
      activeField,
      fields,
      mdiFileHidden
    };
  },
  computed: {
    canCreateChildQuestions() {
      if (this.readonly) return false;
      return !this.activeField?.referenceField;
    },
    canCreateParentQuestion() {
      if (this.readonly) return false;
      if (this.parentQuestion) return false;
      return !this.activeField?.referenceField;
    },
    parentQuestion() {
      if (!this.activeField?.parentQuestion) return null;
      return this.fields.by_id[this.activeField?.parentQuestion];
    },
    childQuestions() {
      const childQuestions = [];
      if (!this.activeField?.id) return childQuestions;

      this.fields.ids.forEach(id => {
        const isParent =
          `${this.fields.by_id[id].parentQuestion}` ===
          `${this.activeField.id}`;

        if (isParent) childQuestions.push(this.fields.by_id[id]);
      });

      return childQuestions;
    }
  },
  methods: {
    fieldHasPositionalData(field) {
      return Boolean(field?.coordinates?.length);
    }
  }
};
</script>
